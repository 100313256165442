import { TableCell, TableRow, TextField } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useState } from 'react';

const Rows = ({ elem, targetData, setTargetData, index, setSuperTotal }) => {
    const [total, setTotal] = useState(0);

    const setphaseValueinTargetData = (name, value) => {
        let copiedData = { ...targetData };
        eval(`copiedData.data${name}`).target = value;
        setTargetData(copiedData);
    };

    const calculateTotal = (dataIndex) => {
        const phaseList = eval(`targetData.data[${dataIndex}].phaseList`);
        let phaseTotal = 0;
        phaseList?.map(phase => {
            phaseTotal += parseInt(phase?.target || 0);
            return [];
        });
        setTotal(phaseTotal);

        let sTotal = 0;
        targetData?.data?.map(elem => {
            elem?.phaseList?.map(phase => {
                if (phase?.target) {
                    sTotal += parseInt(phase?.target || 0);
                };
                return [];
            });
            return [];
        });

        setSuperTotal(parseInt(sTotal));
    };

    useEffect(() => {
        if (targetData) {
            calculateTotal(index);
        }
    }, [targetData]);

    return (
        <TableRow key={index}>
            <TableCell style={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 1 }}>
                {elem.sno}</TableCell>
            <TableCell style={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 1 }}>
                {elem.milestone}</TableCell>

            {elem?.phaseList?.map((phase, phaseIndex) => <TableCell style={{ maxWidth: 200, minWidth: 130 }} key={`[${index}]?.phaseList[${phaseIndex}]`}>
                <TextField
                    key={`[${index}]?.phaseList[${phaseIndex}]`}
                    type={"number"}
                    id={""}
                    name={`[${index}].phaseList[${phaseIndex}]`}
                    className="inputRounded"
                    label={""}
                    placeholder={""}
                    fullWidth
                    value={phase?.target}
                    onBlur={(e) => calculateTotal(index)}
                    onKeyDown={(e) => { if (["-", "+", "e", "."].includes(e.key)) e.preventDefault() }}
                    onChange={(e) => setphaseValueinTargetData(e.target.name, e.target.value)}
                    disabled={false}
                    style={{ borderRadius: 10 }}
                    variant="outlined"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            backgroundColor: 'white',
                            paddingRight: '8px',
                            fontSize: 14
                        }
                    }}
                    onWheel={(e) => e.target.blur()}
                />
            </TableCell>
            )}
            <TableCell style={{ minWidth: 100, position: "sticky", right: 0, background: "white" }} align='center'>{total || "-"}</TableCell>
        </TableRow>
    )
}

export default Rows;