export const LINE_ITEM_LIST_REQUEST = 'LINE_ITEM_LIST_REQUEST'
export const LINE_ITEM_LIST_SUCCESS = 'LINE_ITEM_LIST_SUCCESS'
export const LINE_ITEM_LIST_FAIL = 'LINE_ITEM_LIST_FAIL'

export const LINE_ITEM_CREATE_FAIL = 'LINE_ITEM_CREATE_FAIL'
export const LINE_ITEM_CREATE_REQUEST = 'LINE_ITEM_CREATE_REQUEST'
export const LINE_ITEM_CREATE_SUCCESS = 'LINE_ITEM_CREATE_SUCCESS'

export const LINE_ITEM_UPDATE_REQUEST = 'LINE_ITEM_UPDATE_REQUEST'
export const LINE_ITEM_UPDATE_SUCCESS = 'LINE_ITEM_UPDATE_SUCCESS'
export const LINE_ITEM_UPDATE_FAIL = 'LINE_ITEM_UPDATE_FAIL'