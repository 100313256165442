export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'
export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'


export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'

export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'

export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'