import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

function LoadingBox({ type }) {
  return (
    <div className="animate-pulse w-full h-full flex flex-col justify-center items-center space-x-4">
      <Grid className="w-full justify-start items-center" container spacing={2}>
        <Grid className={type === "table" ? "hidden" : "block"} item xs={6} sm={6} md={4} lg={3}>
          <Skeleton height={56} className="w-full" animation="wave" />
        </Grid>
        <Grid className={type === "table" ? "hidden" : "block"} item xs={6} sm={6} md={4} lg={3}>
          <Skeleton height={56} className="w-full" animation="wave" />
        </Grid>
        <Grid className={type === "table" ? "hidden" : "block"} item xs={6} sm={6} md={4} lg={3}>
          <Skeleton height={56} className="w-full" animation="wave" />
        </Grid>
        <Grid className={type === "table" ? "hidden" : "block"} item xs={6} sm={6} md={4} lg={3}>
          <Skeleton height={56} className="w-full" animation="wave" />
        </Grid>
        <Grid className={type === "table" ? "hidden" : "block"} item xs={6} sm={6} md={4} lg={3}>
          <Skeleton height={56} className="w-full" animation="wave" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="w-full  flex flex-col justify-start items-center">
            <Skeleton height={56} className="w-full" animation="wave" />
            <Skeleton height={56} className="w-full" animation="wave" />
            <Skeleton height={56} className="w-full" animation="wave" />
            <Skeleton height={56} className="w-full" animation="wave" />
            <Skeleton height={56} className="w-full" animation="wave" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoadingBox;
