import { Box, Typography } from '@material-ui/core'
import React ,{useEffect,useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AdminUsersCRUDForm from './AdminUsersCRUDForm';
import { useDispatch, useSelector } from 'react-redux';
import { userListAction } from 'src/Redux/Actions/UserAction';
import { fetchWrapper } from 'src/services/http_requests';

function AdminUsersCRUDFormView() {
    const selectedUser = JSON.parse(localStorage.getItem('selectedUser'));
    const [crudAction, setCrudAction] = useState({
        create: false,
        edit: false,
        view: false,
        delete: false
    });
    const path = window.location.pathname;
    const {id:paramsID} = useParams();
    const {users} =useSelector(state => state.userList)
    useEffect(() => {
      if (path === `/app/admin/users/create`){
        setCrudAction({
            create: true,
            edit: false,
            view: false,
            delete: false
        })
      } else if(path === `/app/admin/users/edit/${paramsID}`){
        setCrudAction({
            create: false,
            edit: true,
            view: false,
            delete: false
        })
    }else if(path === `/app/admin/users/delete/${paramsID}`){
        setCrudAction({
            create: false,
            edit: false,
            view: false,
            delete: true
        })
    }else{
        setCrudAction({
            create: false,
            edit: false,
            view: true,
            delete: false
        })
    }
    }, [path,paramsID])

    
    const dispatch = useDispatch();
    useEffect(()=>{
      let mounted = true;

      if(paramsID && mounted){
      dispatch(userListAction({role_id: selectedUser.ngo ? 2 : selectedUser.fo ? 3 : selectedUser?.sakhi? 4 : '','id':paramsID}))
      }

      return () => (mounted = false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[paramsID])

    useEffect(() => {
      window.scrollTo(0, 0)
    localStorage.setItem('previousUrl',window.location.href)

      return() => (fetchWrapper?.finishPendingRequests())
    },[])

    const userDetails = users?.data?.find(item => Number(item?.id) === Number(paramsID))
    
  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box className='w-full h-full'>
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box className="flex justify-center items-center space-x-2">
            <Link to="/app/admin/users">
              <ArrowBackIcon />
            </Link>
            <Typography ><span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>{crudAction.create && `Add ${selectedUser?.sakhi ? 'Sakhi' : selectedUser?.fo ? 'FO' : selectedUser?.ngo ? 'NGO' : ''} User`} {crudAction.edit && `Update ${selectedUser?.sakhi ? 'Sakhi' : selectedUser?.fo ? 'FO' : selectedUser?.ngo ? 'NGO' : ''} User Details`} {crudAction.view && `View ${selectedUser?.sakhi ? 'Sakhi' : selectedUser?.fo ? 'FO' : selectedUser?.ngo ? 'NGO' : ''} User Details`} {crudAction.delete && `Delete ${selectedUser?.sakhi ? 'Sakhi' : selectedUser?.fo ? 'FO' : selectedUser?.ngo ? 'NGO' : ''} User Details`}</span></Typography>
          </Box>
        </Box>
        <AdminUsersCRUDForm crudAction={crudAction} userDetails={userDetails} selectedUser={selectedUser} />
      </Box>
    </Box>
  )
}

export default AdminUsersCRUDFormView