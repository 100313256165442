import { createTheme, colors, responsiveFontSizes } from "@material-ui/core";
import { shadows } from "./shadows";

let theme = createTheme({
  components: {
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       "&::placeholder": {
    //         opacity: 0.86,
    //         color: "#42526e",
    //       },
    //     },
    //   },
    // },
    // MuiButton: {
    //   defaultProps: {
    //     size: "small",
    //   },
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       fontSize: '1rem',
    //     },
    //   },
    // },
    // MuiFilledInput: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiFormControl: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiFormHelperText: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiIconButton: {
    //   defaultProps: {
    //     size: "small",
    //   },
    // },
    // MuiInputBase: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiInputLabel: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiListItem: {
    //   defaultProps: {
    //     dense: true,
    //   },
    // },
    // MuiOutlinedInput: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiFab: {
    //   defaultProps: {
    //     size: "small",
    //   },
    // },
    // MuiTable: {
    //   defaultProps: {
    //     size: "small",
    //   },
    // },
    // MuiTextField: {
    //   defaultProps: {
    //     margin: "dense",
    //   },
    // },
    // MuiToolbar: {
    //   defaultProps: {
    //     variant: "dense",
    //   },
    // },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       backgroundColor: "#000",
    //       color: "#fff"
    //     }
    //   }
    // }
  },

  palette: {
    action: {
      active: "#6b778c",
    },
    mode: "dark",
    background: {
      default: "#f4f5f7",
      paper: "#ffffff",
    },
    error: {
      contrastText: "#ffffff",
      main: "#f44336",
    },
    secondary: {
      main: colors.red[800],
    },
    primary: {
      contrastText: "#ffffff",
      main: "#D6002A",
    },
    success: {
      contrastText: "#ffffff",
      main: "#4caf50",
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
    },
    warning: {
      contrastText: "#ffffff",
      main: "#ff9800",
    },
  },
  shadows: shadows,
});

theme = responsiveFontSizes(theme);

export default theme;
