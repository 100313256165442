import { Button, Divider, Box, Typography, Dialog, DialogTitle, DialogContent, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import StickyHeadTable from "src/components/CommonComponents/Table";
import FilterComponent from "src/components/CommonComponents/FilterComponent";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { masterCommonFilterData } from "src/utils/filterInputData";
import { useSelector } from "react-redux";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { purposeMapperListAction } from "src/Redux/Actions/PurposeMapperAction";
import { purposeListAction } from "src/Redux/Actions/PurposeAction";
import { districtListAction } from "src/Redux/Actions/DistrictAction";
import { blockListAction } from "src/Redux/Actions/BlockAction";
import { villageListAction } from "src/Redux/Actions/VillageAction";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import LoadingBox from "src/components/LoadingBox";
import { centreListAction } from "src/Redux/Actions/CentreAction";
import { useMemo } from "react";
import { fetchApiDataList, fetchWrapper } from "src/services/http_requests";
import { generateSerialNo } from "src/utils";
import { Add, DeleteOutlineOutlined, EditOutlined, KeyboardArrowDownOutlined, PictureAsPdf, VisibilityOutlined } from "@material-ui/icons";
import Drawer from "src/components/CommonComponents/Drawer";
import MastersCRUDForm from "./MastersCRUDForm/MastersCRUDForm";
import DialogView from "src/components/CommonComponents/DialogView";
import MouMilestoneCRUDForm from "./MastersCRUDForm/MouMilestoneCRUDForm";
import { milestoneListAction } from "src/Redux/Actions/MilestoneAction";
import { mouListAction } from "src/Redux/Actions/MOUAction";
import moment from "moment";
import { toast } from "react-toastify";

let columns = [];
const stateColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "state", label: "State" },
  { id: "action", label: "Action" },
];
const districtColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "state", label: "State" },
  { id: "district", label: "District" },
  { id: "action", label: "Action" },
];
const blockColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "state", label: "State" },
  { id: "district", label: "District" },
  { id: "block", label: "Block" },
  { id: "action", label: "Action" },
];
const villageColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "state", label: "State" },
  { id: "district", label: "District" },
  { id: "block", label: "Block" },
  { id: "village", label: "Village" },
  { id: "action", label: "Action" },
];
const ngoColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "ngo", label: "NGO" },
  { id: "state", label: "State" },
  { id: "district", label: "District" },
  { id: "action", label: "Action" },
];
const centreColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "state", label: "State" },
  { id: "district", label: "District" },
  { id: "block", label: "Block" },
  { id: "centre", label: "Centre" },
  { id: "action", label: "Action" },
];
const purposeColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "purpose", label: "Purpose" },
  { id: "action", label: "Action" },
];
const purposeMapperColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "state", label: "State" },
  { id: "purpose", label: "Purpose" },
  { id: "purpose_mapper", label: "Scheme" },
  { id: "action", label: "Action" },
];
const milestoneColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "name", label: "Milestone" },
  { id: "action", label: "Action" },
];
const targetColumns = [
  { id: 'sno', label: 'S.No' },
  { id: "ngo", label: "NGO" },
  { id: "po_number", label: "PO Number" },
  { id: "file", label: "MOU" },
  { id: "phases", label: "MOU Phase" },
  { id: "action", label: "Action" },
];


function MastersView() {
  // let currentPath;
  const previousUrl = localStorage.getItem('previousUrl');
  const previousSelectedMaster = JSON.parse(localStorage.getItem("selectedMaster"));
  const pageInfo = localStorage.getItem('pageInfo');
  const totalData = localStorage.getItem('totalData');

  const [selectedDetails, setSelectedDetails] = useState({});
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openView, setOpenView] = useState(false)
  const path = window.location.pathname;
  const [currentPath, setCurrentPath] = useState(() => previousSelectedMaster ? previousSelectedMaster : 'state');
  const [total, setTotal] = useState(() => totalData ? parseInt(totalData) : 0);
  const [page, setPage] = useState(() => pageInfo ? parseInt(pageInfo) : 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterInputData, setFilterInputData] = useState([])
  const [filterData, setFilterData] = useState({
    state_id: "",
    district_id: "",
    block_id: "",
    village_id: "",
    ngo_id: "",
    centre_id: "",
    purpose_id: "",
    scheme_id: "",
    po_number: ""
  });
  const [allStates, setAllStates] = useState([]);
  const [allDistricts, setAllDistricts] = useState(undefined);
  const [allBlocks, setAllBlocks] = useState(undefined);
  const [allVillages, setAllVillages] = useState(undefined);
  const [allNgos, setAllNgos] = useState(undefined);
  const [allCentres, setAllCentres] = useState(undefined);
  const [allPurpose, setAllPurpose] = useState([]);
  const [allPurposeMappers, setAllPurposeMappers] = useState(undefined);
  const [allPoNumber, setAllPoNumber] = useState(undefined);
  const [openMenu, setOpenMenu] = useState(null);
  const [isViewOpen, setIsViewOpen] = useState({ status: false, type: "", details: null });
  const { loading: stateLoading, states } = useSelector((state) => state.statesList);
  const { loading: districtLoading, districts, } = useSelector((state) => state.districtList);
  const { loading: blockLoading, blocks } = useSelector((state) => state.blockList);
  const { loading: villageLoading, villages, } = useSelector((state) => state.villageList);
  const { loading: ngoLoading, ngos } = useSelector((state) => state.ngoList);
  const { loading: centreLoading, centres } = useSelector((state) => state.centreList);
  const { loading: purposeLoading, purposes, } = useSelector((state) => state.purposeList);
  const { loading: purposeMapperLoading, purposeMappers, } = useSelector((state) => state.purposeMapperList);
  const { loading: milestoneLoading, milestone: milestoneList } = useSelector((state) => state.milestoneList);
  const { loading: mouLoading, mou: mouList } = useSelector((state) => state.mouList);

  const dispatch = useDispatch();

  const resetFilter = () => {
    let spreadedFilter = { ...filterData };
    for (let elem in spreadedFilter) {
      spreadedFilter = { ...spreadedFilter, [elem]: "" };
    };
    setFilterData(spreadedFilter);
  };

  const fetchApi = (selectedPage) => {
    if (["state"].includes(currentPath)) fetchApiDataList("state", { ...filterData, page: page }, setAllStates);
    else if (["district"].includes(currentPath)) dispatch(districtListAction({ ...filterData, page: selectedPage }));
    else if (["block"].includes(currentPath)) dispatch(blockListAction({ ...filterData, page: selectedPage }));
    else if (["village"].includes(currentPath)) dispatch(villageListAction({ page: selectedPage, ...filterData, }));
    else if (["ngo"].includes(currentPath)) dispatch(ngoListAction({ page: selectedPage, ...filterData, }));
    else if (["centre"].includes(currentPath)) dispatch(centreListAction({ page: selectedPage, ...filterData, }));
    else if (["purpose"].includes(currentPath)) dispatch(purposeListAction({ ...filterData, page: selectedPage }));
    else if (["purpose_mapper"].includes(currentPath)) dispatch(purposeMapperListAction({ ...filterData, page: selectedPage }));
    else if (["milestone"].includes(currentPath)) dispatch(milestoneListAction({ page: selectedPage }));
    else if (["target"].includes(currentPath)) dispatch(mouListAction({ ...filterData, page: selectedPage }));
    else return;
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      localStorage.setItem("pageInfo", 1);
      localStorage.removeItem("selectedMaster");
      (fetchWrapper?.finishPendingRequests())
    }
  }, []);


  // setting data count in total
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (["state"].includes(currentPath)) {
        if (states?.total) {
          setTotal(states?.total);
          localStorage.setItem("totalData", states.total);
        }
      } else if (["district"].includes(currentPath)) {
        if (districts?.total) {
          setTotal(districts?.total);
          localStorage.setItem("totalData", districts.total);
        }
      } else if (["block"].includes(currentPath)) {
        if (blocks?.total) {
          setTotal(blocks?.total);
          localStorage.setItem("totalData", blocks.total);
        }
      } else if (["village"].includes(currentPath)) {
        if (villages?.total) {
          setTotal(villages?.total);
          localStorage.setItem("totalData", villages.total);
        }
      } else if (["ngo"].includes(currentPath)) {
        if (ngos?.total) {
          setTotal(ngos?.total);
          localStorage.setItem("totalData", ngos.total);
        }
      } else if (["centre"].includes(currentPath)) {
        if (centres?.total) {
          setTotal(centres?.total);
          localStorage.setItem("totalData", centres.total);
        }
      } else if (["purpose"].includes(currentPath)) {
        if (purposes?.total) {
          setTotal(purposes?.total);
          localStorage.setItem("totalData", purposes.total);
        }
      } else if (["purpose_mapper"].includes(currentPath)) {
        if (purposeMappers?.total) {
          setTotal(purposeMappers?.total);
          localStorage.setItem("totalData", purposeMappers.total);
        }
      } else if (["milestone"].includes(currentPath)) {
        if (milestoneList?.total) {
          setTotal(milestoneList?.total);
          localStorage.setItem("totalData", milestoneList.total);
        }
      } else if (["target"].includes(currentPath)) {
        if (mouList?.total) {
          setTotal(mouList?.total);
          localStorage.setItem("totalData", mouList.total);
        }
      }
    }

    return () => (mounted = false);
  }, [
    currentPath,
    states,
    districts,
    blocks,
    villages,
    ngos,
    centres,
    purposes,
    purposeMappers,
    milestoneList,
    mouList
  ]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (page) {
        fetchApi(page);
      }
    }
    return () => (mounted = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentPath]);

  //master filter base on route
  const filterInputDataMemo = useMemo(() => {
    let inputData = masterCommonFilterData
      ?.filter((data) =>
        (currentPath === "state"
          ? ["state_id"]
          : currentPath === "district"
            ? ["state_id", "district_id"]
            : currentPath === "block"
              ? ["state_id", "district_id", "block_id"]
              : currentPath === "village"
                ? ["state_id", "district_id", "block_id", "village_id"]
                : currentPath === "ngo"
                  ? ["state_id", "ngo_id"]
                  : currentPath === "centre"
                    ? ["state_id", "district_id", "block_id", "centre_id"]
                    : currentPath === "purpose"
                      ? ["purpose_id"]
                      : currentPath === "purpose_mapper"
                        ? ["state_id", "purpose_id", "scheme_id"]
                        : currentPath === "target"
                          ? ["ngo_id", "po_number"]
                          : []
        ).includes(data.name)
      )
      ?.map((data) =>
        data.name === "state_id" ? { ...data, options: states?.data }
          : data.name === "district_id" ? { ...data, options: allDistricts, }
            : data.name === "block_id" ? { ...data, options: allBlocks, }
              : data.name === "village_id" ? { ...data, options: allVillages, }
                : data.name === "ngo_id" ? { ...data, options: allNgos, }
                  : data.name === "centre_id" ? { ...data, options: allCentres, }
                    : data.name === "purpose_id" ? { ...data, options: allPurpose }
                      : data.name === "scheme_id" ? { ...data, options: allPurposeMappers, }
                        : data.name === "po_number" ? { ...data, options: allPoNumber }
                          : data
      );

    setFilterInputData(inputData)

  }, [currentPath, states, allDistricts, allBlocks, allVillages, allNgos, allCentres, allPurpose, allPurposeMappers, allPoNumber])
  //creating the table columns base on the routs

  currentPath === "state"
    ? (columns = stateColumns)
    : currentPath === "district"
      ? (columns = districtColumns)
      : currentPath === "block"
        ? (columns = blockColumns)
        : currentPath === "village"
          ? (columns = villageColumns)
          : currentPath === "ngo"
            ? (columns = ngoColumns)
            : currentPath === "centre"
              ? (columns = centreColumns)
              : currentPath === "purpose"
                ? (columns = purposeColumns)
                : currentPath === "purpose_mapper"
                  ? (columns = purposeMapperColumns)
                  : currentPath === "milestone"
                    ? (columns = milestoneColumns)
                    : currentPath === "target"
                      ? (columns = targetColumns)
                      : (columns = []);

  //creating the table rows as on the routes
  function createData(
    sno,
    state,
    district,
    block,
    village,
    ngo,
    centre,
    purpose,
    purpose_mapper,
    action
  ) {
    return currentPath === "state"
      ? { sno, state, action }
      : currentPath === "district"
        ? { sno, state, district, action }
        : currentPath === "block"
          ? { sno, state, district, block, action }
          : currentPath === "village"
            ? { sno, state, district, block, village, action }
            : currentPath === "ngo"
              ? { sno, state, district, ngo, action }
              : currentPath === "centre"
                ? { sno, state, district, block, centre, action }
                : currentPath === "purpose"
                  ? { sno, purpose, action }
                  : currentPath === "purpose_mapper"
                    ? { sno, state, purpose, purpose_mapper, action }
                    : currentPath === "target"
                      ? { sno, ngo: state, po_number: district, file: block, phases: purpose_mapper, action }
                      : currentPath === "milestone"
                        ? { sno, name: state, action }
                        : [];
  };

  const createActionButton = (item, actionList) => {
    return <Box className="space-x-3">
      {actionList.includes("edit") && <button
        className="cursor-pointer"
        onClick={() => {
          setCrudAction({
            create: false,
            edit: true,
            view: false,
            delete: false,
          });
          setOpenDrawer(true);
          setSelectedDetails(item);
        }
        }
      >< EditOutlined color="primary" />
      </button >}

      {actionList.includes("view") && <button
        className="cursor-pointer"
        onClick={() => {
          setCrudAction({
            create: false,
            edit: false,
            view: true,
            delete: false,
          });
          setOpenView(true);
          setSelectedDetails(item);
        }}
      ><VisibilityOutlined color="primary" />
      </button>}
      {actionList.includes("delete") && <button
        className="cursor-pointer"
        onClick={() => {
          setCrudAction({
            create: false,
            edit: false,
            view: false,
            delete: true,
          });
          setOpenDrawer(true);
          setSelectedDetails(item);
        }}
      ><DeleteOutlineOutlined color="primary" />
      </button>}
    </Box >
  }

  let rows =
    currentPath === "state"
      ? allStates?.map((item, i) => {
        return createData(
          generateSerialNo(i, page),
          item?.name,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          createActionButton(item, ["edit", "view", "delete"])
        );
      })
      : currentPath === "district"
        ? districts?.data?.map((item, i) => {
          return createData(
            generateSerialNo(i, page),
            item?.state_name,
            item.name,
            "",
            "",
            "",
            "",
            "",
            "",
            createActionButton(item, ["edit", "view", "delete"])
          );
        })
        : currentPath === "block"
          ? blocks?.data?.map((item, i) => {
            return createData(
              generateSerialNo(i, page),
              item?.state_name,
              item?.district_name,
              item?.name,
              "",
              "",
              "",
              "",
              "",
              createActionButton(item, ["edit", "view", "delete"])
            );
          })
          : currentPath === "village"
            ? villages?.data?.map((item, i) => {
              return createData(
                generateSerialNo(i, page),
                item?.state_name,
                item?.district_name,
                item?.block_name,
                item?.name,
                "",
                "",
                "",
                "",
                createActionButton(item, ["edit", "view", "delete"])
              );
            })
            : currentPath === "ngo"
              ? ngos?.data?.map((item, i) => {
                return createData(
                  generateSerialNo(i, page),
                  item?.state_name,
                  item?.district_name,
                  "",
                  "",
                  item?.name,
                  "",
                  "",
                  "",
                  createActionButton(item, ["edit", "view", "delete"])
                );
              })
              : currentPath === "centre"
                ? centres?.data?.map((item, i) => {
                  return createData(
                    generateSerialNo(i, page),
                    item?.state_name,
                    item?.district_name,
                    item?.block_name,
                    "",
                    "",
                    item?.name,
                    "",
                    "",
                    createActionButton(item, ["view"])
                  );
                })
                : currentPath === "purpose"
                  ? purposes?.data?.map((item, i) => {
                    return createData(
                      generateSerialNo(i, page),
                      item?.state_name,
                      "",
                      "",
                      "",
                      "",
                      "",
                      item?.name,
                      "",
                      createActionButton(item, ["edit", "view", "delete"])
                    );
                  })
                  : currentPath === "purpose_mapper"
                    ? purposeMappers?.data?.map((item, i) => {
                      return createData(
                        generateSerialNo(i, page),
                        item?.state_name,
                        "",
                        "",
                        "",
                        "",
                        "",
                        item?.purpose_name,
                        item?.name,
                        createActionButton(item, ["edit", "view", "delete"])
                      );
                    })
                    : currentPath === "milestone"
                      ? milestoneList?.data?.map((item, i) => {
                        return createData(
                          generateSerialNo(i, page),
                          item?.name,
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          createActionButton(item, ["edit", "delete"])
                        );
                      })
                      : currentPath === "target"
                        ? mouList?.data?.map((item, i) => {
                          return createData(
                            generateSerialNo(i, page),
                            item?.ngo_partner?.name || "-",
                            item?.po_number,
                            <PictureAsPdf
                              className="cursor-pointer"
                              color="primary"
                              onClick={() => {
                                // setAttachedFile(item?.mou_file);
                                setIsViewOpen({ status: true, type: "file", details: item?.mou_file });
                              }}
                            />,
                            "",
                            "",
                            "",
                            "",
                            <button
                              className="text-blue-500 cursor-pointer"
                              onClick={() => {
                                setIsViewOpen({ status: true, type: 'array', details: item })
                              }}
                            >
                              {item?.mou_phase_mappers?.length}
                            </button>,
                            createActionButton(item, ["edit", "delete"])
                          );
                        })
                        : [];

  const phaseRows = () => isViewOpen?.details?.mou_phase_mappers?.map((elem, index) => {
    const phaseValue = `MOU P${index + 1} (${moment(elem?.start_month).format("MMM, YYYY")} - ${moment(elem?.end_month).format("MMM, YYYY")})`
    return {
      sno: generateSerialNo(index, 1),
      mou_phase: phaseValue
    }
  });

  const handleFilterSearch = () => {
    fetchWrapper?.finishPendingRequests();
    if (parseInt(page) === 1) {
      fetchApi(1);
    } else {
      localStorage.setItem("pageInfo", page);
      setPage(1);
    }
  };

  const handleRestFilter = () => {
    resetFilter();
    if (parseInt(page) === 1) {
      if (["state"].includes(currentPath)) {
        fetchApiDataList("state", { page: 1 }, setAllStates);
        dispatch(stateListAction({ page: 1 }));
      }
      else if (["district"].includes(currentPath)) dispatch(districtListAction({ page: 1 }));
      else if (["block"].includes(currentPath)) dispatch(blockListAction({ page: 1 }));
      else if (["village"].includes(currentPath)) dispatch(villageListAction({ page: 1 }));
      else if (["ngo"].includes(currentPath)) dispatch(ngoListAction({ page: 1 }));
      else if (["centre"].includes(currentPath)) dispatch(centreListAction({ page: 1 }));
      else if (["purpose"].includes(currentPath)) dispatch(purposeListAction({ page: 1 }));
      else if (["purpose_mapper"].includes(currentPath)) dispatch(purposeMapperListAction({ page: 1 }));
      else if (["target"].includes(currentPath)) dispatch(mouListAction({ page: 1 }));
      else if (["milestone"].includes(currentPath)) dispatch(milestoneListAction({ page: 1 }));
      else return;
    } else {
      localStorage.setItem("pageInfo", page);
      setPage(1);
    }
  };

  useEffect(() => {
    if (currentPath === 'purpose') fetchApiDataList('purpose', { fetched: "All" }, setAllPurpose);
    else if (currentPath === "purpose_mapper") {
      fetchApiDataList('purpose', { fetched: "All" }, setAllPurpose);
      // fetchApiDataList("purpose_mapper", { state_id: filterData?.state_id, fetched: "All" }, setAllPurposeMappers);
    }
    else return;
  }, [currentPath])


  useEffect(() => {
    if (filterData.state_id) {
      (currentPath !== 'ngo' || currentPath !== 'state') && fetchApiDataList("district", { state_id: filterData?.state_id, fetched: "All" }, setAllDistricts);
      (currentPath === 'ngo' || currentPath !== 'state') && fetchApiDataList("ngo_partner", { state_id: filterData?.state_id, fetched: "All" }, setAllNgos);
    } else {
      setAllDistricts(undefined);
      setAllNgos(undefined);
      currentPath == 'ngo' && setFilterData({ ...filterData, ngo_id: "" });
      (currentPath !== 'ngo' || currentPath !== 'state') && setFilterData({ ...filterData, district_id: "" });
    }
  }, [filterData.state_id]);

  useEffect(() => {
    if (filterData.district_id) {
      currentPath !== 'district' && fetchApiDataList("block", { state_id: filterData?.state_id, district_id: filterData?.district_id, fetched: "All" }, setAllBlocks);
    } else {
      setAllBlocks(undefined);
      setFilterData({ ...filterData, block_id: "" });
    }
  }, [filterData.district_id]);

  useEffect(() => {
    if (filterData.block_id) {
      currentPath === 'village' && fetchApiDataList("village", { state_id: filterData?.state_id, district_id: filterData?.district_id, block_id: filterData?.block_id, fetched: "All" }, setAllVillages);
      currentPath === 'centre' && fetchApiDataList("centre", { state_id: filterData?.state_id, district_id: filterData?.district_id, block_id: filterData?.block_id, fetched: "All" }, setAllCentres);
    } else {
      setAllVillages(undefined);
      setAllCentres(undefined);
      setFilterData({ ...filterData, village_id: "", center_id: "" });

    }
  }, [filterData.block_id]);

  useEffect(() => {
    if (filterData.purpose_id) {
      currentPath === 'purpose_mapper' && fetchApiDataList("purpose_mapper", { state_id: filterData?.state_id, purpose_id: filterData.purpose_id, fetched: "All" }, setAllPurposeMappers);
    } else {
      setFilterData({ ...filterData, scheme_id: "" });
      setAllPurposeMappers(undefined);
    }
  }, [filterData.purpose_id]);

  useEffect(() => {
    if (currentPath === "target") fetchApiDataList("ngo_partner", { fetched: "All" }, setAllNgos);
  }, [currentPath]);

  useEffect(() => {
    if (currentPath === 'target' && filterData?.ngo_id) {
      const getPoNumberList = async () => {
        try {
          const { data } = await fetchWrapper.get(
            `${process.env.REACT_APP_API_URL}/mou/getListPONumber?ngo_id=${filterData?.ngo_id}`
          );
          const newData = data?.map(elem => {
            return elem = { id: elem?.id, name: elem?.po_number };
          });
          setAllPoNumber(newData);
        }
        catch (error) {
          toast.error(error);
          throw (error);
        }
      };
      getPoNumberList();
    } else setAllPoNumber(undefined);
  }, [currentPath, filterData.ngo_id])


  const refetchDataList = () => {
    handleRestFilter();
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (openDrawer === false && Object.keys(selectedDetails)) setSelectedDetails({});
  }, [openDrawer]);

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box width="100%" height="100%">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexdirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>
            <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
              {currentPath === "purpose_mapper"
                ? currentPath.split("_").join(" ")
                : currentPath === "ngo" ? "NGO" : currentPath}{" "}
              Masters
            </span>
          </Typography>
          <Box className="space-x-2 ">

            {currentPath !== 'centre' ? <Button startIcon={<Add />}
              onClick={(e) => {
                if (currentPath === "target") {
                  setOpenMenu(e.currentTarget)
                } else {
                  setCrudAction({
                    create: true,
                    edit: false,
                    view: false,
                    delete: false,
                  });
                  setOpenDrawer(true);
                  setSelectedDetails({});
                }
              }}
              variant="contained" color="primary" size="small">
              Add{" "}
              <span className="capitalize ml-1">
                {currentPath === "purpose_mapper" ? currentPath.split("_").join(" ")
                  : currentPath === "target" ? <KeyboardArrowDownOutlined /> : currentPath}
              </span>
            </Button> : null}
            <Menu
              id="basic-menu"
              anchorEl={openMenu}
              open={Boolean(openMenu)}
              onClose={() => setOpenMenu(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => {
                setOpenMenu(null);
                setCrudAction({
                  create: true,
                  edit: false,
                  view: false,
                  delete: false,
                });
                setOpenDrawer(true);
                setSelectedDetails({});
              }}>+ Add MOU</MenuItem>
              <MenuItem><Link to={"/app/admin/master/target"}>+ Add Target</Link></MenuItem>
            </Menu>
          </Box>
        </Box>
        <Divider sx={{ maxWidth: "100%" }} />
        <Box className="w-full flex justify-between items-center">
          <Box className="flex justify-start items-center">
            {[
              { label: "State", name: 'state' },
              { label: "District", name: 'district' },
              { label: "Block", name: 'block' },
              { label: "Village", name: 'village' },
              { label: "Centre", name: 'centre' },
              { label: "NGO", name: 'ngo' },
              { label: "Purpose", name: 'purpose' },
              { label: "Purpose Mapper", name: 'purpose_mapper' },
              { label: "Target", name: 'target' },
              { label: "Milestone Indicators", name: 'milestone' },
            ].map((master) => (
              <Box
                variant="contained"
                className={
                  currentPath === master?.name
                    ? "px-3 py-1.5 font-semibold capitalize cursor-pointer  text-black  bg-white  border-b-2 border-primary"
                    : "px-3 py-1.5 font-semibold capitalize cursor-pointer text-black bg-white  border-b-2"
                }
                onClick={() => {
                  fetchWrapper?.finishPendingRequests();
                  setCurrentPath(master.name);
                  resetFilter();
                  localStorage.setItem(
                    "selectedMaster",
                    JSON.stringify(master.name)
                  );
                  localStorage.setItem("pageInfo", 1);
                  setPage(1);
                }}
              >
                <Typography variant="caption">
                  {master.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <FilterComponent
          handleFilterSearch={handleFilterSearch}
          handleRestFilter={handleRestFilter}
          commonFilterData={filterInputData}
          filterData={filterData}
          setFilterData={setFilterData}
        />

        {(["state"].includes(currentPath) ? (stateLoading)
          : ["district"].includes(currentPath) ? (districtLoading)
            : ["block"].includes(currentPath) ? (blockLoading)
              : ["village"].includes(currentPath) ? (villageLoading)
                : ["ngo"].includes(currentPath) ? (ngoLoading)
                  : ["centre"].includes(currentPath) ? (centreLoading)
                    : ["purpose"].includes(currentPath) ? (purposeLoading)
                      : ["purpose_mapper"].includes(currentPath) ? (purposeMapperLoading)
                        : ["target"].includes(currentPath) ? (mouLoading)
                          : ["milestone"].includes(currentPath) ? milestoneLoading
                            : false)
          ? (
            <LoadingBox type="table" />) :
          <StickyHeadTable
            columns={columns}
            rows={rows}
            loading={
              ["state"].includes(currentPath) ? stateLoading
                : ["district"].includes(currentPath) ? districtLoading
                  : ["block"].includes(currentPath) ? blockLoading
                    : ["village"].includes(currentPath) ? villageLoading
                      : ["ngo"].includes(currentPath) ? ngoLoading
                        : ["centre"].includes(currentPath) ? centreLoading
                          : ["purpose"].includes(currentPath) ? purposeLoading
                            : ["purpose_mapper"].includes(currentPath) ? purposeMapperLoading
                              : ["target"].includes(currentPath) ? mouLoading
                                : ["milestone"].includes(currentPath) ? milestoneLoading
                                  : false
            }
            total={total}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        }
      </Box>

      <Drawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        title={<span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>
          {crudAction.create && `Add `} {crudAction.edit && `Edit `}{" "}
          {crudAction.view && `View `}
          <span className="capitalize">{currentPath === 'purpose_mapper' ? currentPath.split("_").join(" ") : currentPath === "target" ? "MOU" : currentPath}</span>{" "}
        </span>}>
        {["target", "milestone"].includes(currentPath) ?
          <MouMilestoneCRUDForm
            crudAction={crudAction}
            prefilledDetails={selectedDetails}
            ngos={ngos}
            currentPath={currentPath}
            refetchDataList={refetchDataList}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer} />
          : <MastersCRUDForm
            crudAction={crudAction}
            updateDetails={selectedDetails}
            states={states}
            districts={districts}
            blocks={blocks}
            ngos={ngos}
            purposes={purposes}
            currentPath={currentPath}
            refetchDataList={refetchDataList}
          />}
      </Drawer>
      <DialogView openView={openView} handleCloseView={() => { setOpenView(false); setSelectedDetails({}) }}
        title={<span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>
          {crudAction.create && `Add `} {crudAction.edit && `Edit `}{" "}
          {crudAction.view && `View `}
          <span className="capitalize">{currentPath === 'purpose_mapper' ? currentPath.split("_").join(" ") : currentPath}</span>{" "}
        </span>}  >
        <MastersCRUDForm
          crudAction={crudAction}
          updateDetails={selectedDetails}
          states={states}
          districts={districts}
          blocks={blocks}
          ngos={ngos}
          purposes={purposes}
          currentPath={currentPath}
          refetchDataList={refetchDataList}
        />
      </DialogView>
      <Dialog
        open={isViewOpen.status}
        onClose={() => setIsViewOpen({ status: false, type: "", details: null })}
        fullWidth={true}
        maxWidth={isViewOpen.type === "file" ? "md" : "sm"}>
        {isViewOpen?.type === "file" ? <iframe src={isViewOpen.details} title="File View" className="w-full" height={1000}></iframe>
          : isViewOpen?.details &&
          (<>
            <DialogTitle>
              {isViewOpen?.details?.ngo_partner?.name || ""}
            </DialogTitle>
            <DialogContent dividers>
              <StickyHeadTable
                columns={[
                  { id: "sno", label: "S.No" },
                  { id: "mou_phase", label: "MOU Phase" },
                ]}
                rows={phaseRows()}
              />
            </DialogContent>

          </>)
        }
      </Dialog>
    </Box>
  );
}

export default MastersView;
