import { toast } from "react-toastify";
import { fetchWrapper } from "src/services/http_requests";
import { paramsUrl } from "src/utils/halper";

const {
  NGO_LIST_REQUEST,
  NGO_LIST_SUCCESS,
  NGO_LIST_FAIL,
  NGO_CREATE_REQUEST,
  NGO_CREATE_FAIL,
  NGO_CREATE_SUCCESS,
  NGO_UPDATE_REQUEST,
  NGO_UPDATE_FAIL,
  NGO_DELETE_REQUEST,
  NGO_DELETE_FAIL,
  NGO_UPDATE_SUCCESS,
  NGO_DELETE_SUCCESS,
} = require("../Conistance/NgoConistance");

const BASE_URL = process.env.REACT_APP_API_URL;

export const ngoListAction = (filter) => async (dispatch) => {
  dispatch({ type: NGO_LIST_REQUEST });
  try {
    const data = await fetchWrapper.get(
      `${BASE_URL}/ngo_partner/list${paramsUrl(filter)}`
    );

    dispatch({ type: NGO_LIST_SUCCESS, success: true, payload: data });
  } catch (error) {
    dispatch({ type: NGO_LIST_FAIL, payload: error });
    toast.error(error);
  }
};

export const ngoCreateAction = (body, refetchDataList) => async (dispatch) => {
  dispatch({ type: NGO_CREATE_REQUEST });
  try {
    const data = await fetchWrapper.post(
      `${BASE_URL}/ngo_partner/create`,
      JSON.stringify(body)
    );
    dispatch({ type: NGO_CREATE_SUCCESS, payload: data });
    toast.success(data?.message);
    if (data?.success) {
      setTimeout(() => {
        refetchDataList && refetchDataList();
      }, 600);
    }
  } catch (error) {
    dispatch({ type: NGO_CREATE_FAIL, payload: error });
    toast.error(error);
  }
};

export const ngoUpdateAction = (ngoId, body, refetchDataList) => async (dispatch) => {
  dispatch({ type: NGO_UPDATE_REQUEST });
  try {
    const data = await fetchWrapper.put(
      `${BASE_URL}/ngo_partner/update/${ngoId}`,
      JSON.stringify(body)
    );
    dispatch({ type: NGO_UPDATE_SUCCESS, payload: data });
    toast.success(data?.message);
    if (data?.success) {
      setTimeout(() => {
        refetchDataList && refetchDataList();
      }, 600);
    }
  } catch (error) {
    dispatch({ type: NGO_UPDATE_FAIL, payload: error });
    toast.error(error);
  }
};

export const ngoDeleteAction = (ngoId, refetchDataList) => async (dispatch) => {
  dispatch({ type: NGO_DELETE_REQUEST });
  try {
    const data = await fetchWrapper.put(
      `${BASE_URL}/ngo_partner/delete/${ngoId}`
    );
    dispatch({ type: NGO_DELETE_SUCCESS, success: true, payload: data });
    toast.success(data?.message);
    if (data?.success) {
      setTimeout(() => {
        refetchDataList && refetchDataList();
      }, 600);
    }
  } catch (error) {
    dispatch({ type: NGO_DELETE_FAIL, payload: error });
    toast.error(error);
  }
};
