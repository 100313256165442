import {
  TextField,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
  ListItemText,
  Checkbox,
  OutlinedInput,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Formik } from "formik";
import { rest } from "lodash";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { districtListAction } from "src/Redux/Actions/DistrictAction";
import { centreListAction } from "src/Redux/Actions/CentreAction";
import { blockListAction } from "src/Redux/Actions/BlockAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import {
  userCreateAction,
  userDeleteAction,
  userUpdateAction,
} from "src/Redux/Actions/UserAction";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { fetchApiDataList, fetchWrapper } from "src/services/http_requests";
import AutocompleteComponent from "src/components/CommonComponents/AutocompleteComponent";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import KeyboardDatePickerComponent from "src/components/CommonComponents/KeyboardDatePickerComponent";
import ScrollBar from "react-perfect-scrollbar";
import CustomInputs from "src/components/CommonComponents/CustomInputs";
import { mdiConsoleLine } from "@mdi/js";
import DialogView from "src/components/CommonComponents/DialogView";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
const ageRegExp = /^[1-9]\d{1}/;

function FoDetailsCRUDForm({ crudAction, userDetails, refetchUserList, isFormOpen, setCenterNotAvalablePop, centerNotAvalablePop }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    txn_id: uuidv4(),
    name: "",
    role_id: "3",
    age: "",
    mobile: "",
    email: "",
    state_id: "",
    district_id: "",
    block_id: [],
    center_id: [],
    // ngo_id: "",
    // fo_status: "inactive",
    date_of_joining: null,
    date_of_leaving: null,
  });

  const [isOpenJ, setIsOpenJ] = useState(false);
  const [minDate, setMinDate] = useState("");

  const statesList = useSelector((state) => state.statesList);
  const { states } = statesList;
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlock] = useState([]);
  const [centers, setCenter] = useState(null);
  const [ngos, setNgos] = useState([]);
  // const { ngos } = useSelector((state) => state.ngoList);
  const { success } = useSelector((state) => state.userCreate);

  const dispatch = useDispatch();
  const [isChangedOnUpdate, setChangedOnUpdate] = useState(false);

  const initialValues = {
    txn_id: "",
    role_id: "",
    name: "",
    age: "",
    mobile: "",
    state_id: "",
    district_id: "",
    block_id: "",
    center_id: "",
    // ngo_id: "",
    date_of_joining: "",
    date_of_leaving: "",
    is_active: ""
  };

  const validation = Yup.object().shape({
    mobile: Yup.string()
      .required("Mobile no is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Too short"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    // ngo_id: Yup.string().required("Name of NGO is required"),
    age: Yup.string()
      .required("Age is required")
      .matches(ageRegExp, "Age is not valid")
      .max(2, "The maximum age is 99 year's old"),
    state_id: Yup.string().required("State is required"),
    district_id: Yup.string().required("District is required"),
    block_id: Yup.string().required("Block is required"),
    center_id: Yup.string().required("Centre is required"),
    date_of_joining: Yup.date()
      .nullable()
      .test("date_of_joining", "Date of joining is required", function (value) {
        return value;
      }),
    is_active: Yup.string().required("Status is required"),
  })

  const foFormInput = [
    {
      key: "name",
      name: "name",
      label: "Fo Name",
      inputType: "text",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Enter Name",
    },
    {
      key: "age",
      name: "age",
      label: "Age",
      inputType: "number",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Enter Age",
    },
    {
      key: "mobile",
      name: "mobile",
      label: "Contact Number",
      inputType: "number",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Enter Contact number",
    },
    {
      key: "state_id",
      name: "state_id",
      label: "State",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: states?.data || [],
      value: "",
      placeholder: "Select State",
    },
    {
      key: "district_id",
      name: "district_id",
      label: "District",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: districts || [],
      value: "",
      placeholder: "Select District",
    },
    {
      key: "block_id",
      name: "block_id",
      label: "Block",
      inputType: "autoComplete",
      multiple: true,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: blocks || [],
      value: "",
      placeholder: "Select Block",
    },
    {
      key: "center_id",
      name: "center_id",
      label: "Name of Center",
      inputType: "autoComplete",
      multiple: true,
      required: true,
      disabled: true,
      optionArray: centers || [],
      value: "",
      placeholder: "Select Center",
    },
    // {
    //   key: "ngo_id",
    //   name: "ngo_id",
    //   label: "Name of NGO",
    //   inputType: "autoComplete",
    //   multiple: false,
    //   required: true,
    //   disabled: crudAction?.delete || crudAction?.view ? true : false,
    //   optionArray: ngos || [],
    //   value: "",
    //   placeholder: "Select NGO",
    // },
    {
      key: "date_of_joining",
      name: "date_of_joining",
      label: "Date of Joining *",
      inputType: "date",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Select Date",
    },
    {
      key: "date_of_leaving",
      name: "date_of_leaving",
      label: "Date of Leaving",
      inputType: "date",
      required: false,
      disabled: crudAction?.delete || crudAction?.view ? true : minDate ? false : true,
      value: "",
      placeholder: "Select Date",
      min: minDate,
      max: ""
    },
    {
      key: "is_active",
      name: "is_active",
      label: "Status",
      inputType: "radio",
      required: true,
      disabled: crudAction?.edit || crudAction?.create ? false : true,
      value: "",
      optionArray: [{ id: 1, label: "Active", value: "1" }, { id: 0, label: "InActive", value: "0" }]
    },
  ];

  return (
    <div className={`h-auto  ${crudAction.view ? "pb-4 mx-4" : "pb-12 mx-2"}`}>
      <Formik
        enableReinitialize={crudAction.create ? false : true}
        initialValues={initialValues}
        // enableReinitialize ={crudAction.create ? false : true}
        validationSchema={validation}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let data = {
            ...values,
            txn_id: values.txn_id ? values.txn_id : uuidv4(),
            role_id: values.role_id || 3,
            age: typeof values.age == 'number' ? values.age.toString() : values.age,
            mobile: typeof values.mobile == 'number' ? values.mobile.toString() : values.mobile,
            block_id: Array.isArray(values?.block_id) ? values?.block_id?.filter(Boolean) : values?.block_id,
            // center_id: Array.isArray(values?.center_id) ? values?.center_id?.join(",") : values?.center_id,
            date_of_leaving:
              values?.date_of_leaving ? values?.date_of_leaving : null,
          };

          crudAction?.create
            ? dispatch(userCreateAction(data, refetchUserList))
            : crudAction?.edit
              ? dispatch(userUpdateAction(userDetails?.id, data, refetchUserList))
              : dispatch(userDeleteAction(userDetails?.id, refetchUserList));
        }}
      >
        {function Render({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          touched,
          values,
        }) {
          useEffect(() => {
            if (isFormOpen === false) {
              for (let elem in initialValues) {
                setFieldTouched(elem, false);
                setFieldValue(elem, "");
              }
            }
          }, [isFormOpen]);


          useEffect(() => {
            if (values.state_id) {
              fetchApiDataList("district", { state_id: values?.state_id, fetched: "All" }, setDistricts);
              if (!isChangedOnUpdate) {
                setFieldValue("district_id", []);
                setDistricts([]);
              }
            } else {
              setFieldValue("district_id", "");
              setDistricts([]);
            }
          }, [values.state_id]);

          useEffect(() => {
            if (values.district_id) {
              fetchApiDataList("block", { state_id: values?.state_id, district_id: values?.district_id, fetched: "All", }, setBlock);
              if (!isChangedOnUpdate) {
                setFieldValue("block_id", []);
                setBlock([]);
              }
            } else {
              setFieldValue("block_id", []);
              setBlock([]);
            }
          }, [values.district_id]);

          useEffect(() => {
            if (values.block_id?.length > 0) {
              fetchApiDataList("centre", {
                state_id: values?.state_id,
                district_id: Array.isArray(values?.district_id) ? values?.district_id?.filter(Boolean) : values?.district_id,
                block_id: Array.isArray(values?.block_id) ? values?.block_id?.filter(Boolean) : values?.block_id,
                fetched: "All",
              }, setCenter);
              // setCenter([]);
            } else {
              setCenter(null);
              setFieldValue("center_id", []);
            }
          }, [values.block_id]);

          useEffect(() => {
            if (centers !== null && values.block_id?.length > 0) {
              if (centers?.length > 0) {
                let centerArr = [];
                let centerAvailabilityStauts = [];
                for (let i = 0; i < values.block_id?.length; i++) {
                  let isCenterAvaibale = centers?.some(elem => {
                    if (Array.isArray(elem?.block_id)) {
                      return elem?.block_id?.includes(values.block_id[i]?.toString());
                    } else if (typeof elem?.block_id === "string") {
                      return elem?.block_id?.split(",")?.includes(values.block_id[i]?.toString());
                    } else return parseInt(elem.block_id) == values.block_id[i];
                  });
                  centerAvailabilityStauts = [...centerAvailabilityStauts, isCenterAvaibale];
                }
                if (centerAvailabilityStauts?.every(elem => elem === true)) {
                  let k = centers?.map(elem => centerArr = [...centerArr, elem?.id]);
                  setFieldValue("center_id", centerArr);
                } else {
                  // setTimeout(() => {
                  // alert("No center is available. Please create a center first");
                  crudAction.view !== true && setCenterNotAvalablePop(!centerNotAvalablePop);
                  // }, 100);
                  values.block_id?.pop();
                  setFieldValue("block_id", values.block_id);
                }
              } else {
                setFieldValue("block_id", []);
                // setTimeout(() => {
                // alert("No  is available. Please create a center first");
                crudAction.view !== true && setCenterNotAvalablePop(!centerNotAvalablePop);
                // }, 100);

              }
            }
          }, [centers]);

          useEffect(() => {
            if (values.date_of_joining) {
              setMinDate(values.date_of_joining)
            } else setMinDate("");
          }, [values.date_of_joining]);

          useEffect(() => {
            if (userDetails) {
              for (let elem in initialValues) {
                if (elem === "block_id") setFieldValue("block_id", userDetails?.block_id ?
                  Array.isArray(userDetails?.block_id) ? userDetails?.block_id
                    : userDetails?.block_id?.split(",") : []);
                else setFieldValue(elem, userDetails[elem]);
              }
              setChangedOnUpdate(true)
            }
          }, [userDetails]);

          return (
            <form
              className={"bg-transparent"}
              onSubmit={handleSubmit}
            >
              <Grid
                spacing={3}
                container
                className="bg-white rounded-md py-2 shadow"
              >
                {foFormInput?.map((elem, INDEX) => {
                  return (
                    <CustomInputs
                      name={elem?.name}
                      id={elem?.key + INDEX.toString()}
                      label={elem?.label}
                      inputType={elem?.inputType}
                      value={values[elem.name]}
                      required={elem?.required}
                      handleBlur={handleBlur}
                      onClick={() => setFieldTouched(elem.name, true)}
                      onChange={(event, value) => {
                        setChangedOnUpdate(false)
                        if (['autoComplete', 'date', "radio"].includes(elem.inputType)) setFieldValue(event, value);
                        else handleChange(event);
                      }}
                      error={errors[elem.name] && touched[elem.name]}
                      helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                      placeholder={elem?.placeholder}
                      disabled={elem?.disabled}
                      multiple={elem?.multiple}
                      optionArray={elem?.optionArray}
                      acceptedFiles={elem?.acceptedFiles}
                      crudAction={crudAction}
                      min={elem?.min}
                      max={elem?.max}
                      initialValues={initialValues}
                    />
                  )
                })}

                <Grid item xs={12} sx={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12
                }>
                  {!crudAction.view && (
                    <Box className="w-full bg-gray-100 mt-10">
                      <Button
                        type="submit"
                        className="w-full "
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        {crudAction.create
                          ? "CREATE"
                          : crudAction.edit
                            ? "UPDATE"
                            : "DELETE"}
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>

    </div>
  );
}

export default FoDetailsCRUDForm;
