import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { rest } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchWrapper } from "src/services/http_requests";
import theme from "src/theme";
import { loadData } from "src/utils/sessionStorage";
import * as Yup from "yup";

// import theme from 'src/theme'

const BASE_URL = process.env.REACT_APP_API_URL;

function ViewForm({ sakhiDetails, crudAction, refetchUserList }) {
  const { role_id, name } = loadData('loginInfo')?.data;
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [transactionViewData, setTransactionViewData] = useState({
    beneficiary_name: sakhiDetails?.ben_name || "",
    beneficiary_id: sakhiDetails?.beneficiary_id || "",
    fathers_husbands_name: sakhiDetails?.father_name || "",
    mobile_number: sakhiDetails?.mobile || "",
    meeting_date: sakhiDetails?.meeting_date || "",
    purpose: sakhiDetails?.purpose_name || "",
    schema_list: sakhiDetails?.scheme || "",
    remuneration_amount: sakhiDetails?.remuneration_amount || "",
    evidence: sakhiDetails?.image || "",
    remarks_by_sakhi: sakhiDetails?.remark || "",
    comments_by_FO: sakhiDetails?.is_approve_rejected_reason_fo || " ",
    concluding_remarks_by_NGO:
      sakhiDetails?.is_approve_rejected_reason_ngo || "",
  });
  const [action, setAction] = useState({
    approve: false,
    reject: false,
  });
  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenConfirm = () => {
    setOpenConfirmation(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  const handleCloseConfirm = (value) => {
    setOpenConfirmation(false);
  };
  const putApproveAndRejectSakhi = async (body) => {
    try {
      const data = await fetchWrapper.put(
        `${BASE_URL}/beneficiary_transaction/isApproved/${sakhiDetails?.id}`,
        body
      );
      if (data.success) {
        toast.success(data?.message);
        setOpenConfirmation(false);
        if (data?.success) {
          setTimeout(() => {
            refetchUserList()
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTransactionViewData({
      beneficiary_name: sakhiDetails?.ben_name || "",
      beneficiary_id: sakhiDetails?.beneficiary_id || "",
      fathers_husbands_name: sakhiDetails?.father_name || "",
      mobile_number: sakhiDetails?.mobile || "",
      meeting_date: sakhiDetails?.meeting_date || "",
      purpose: sakhiDetails?.purpose_name || "",
      schema_list: sakhiDetails?.scheme || "",
      remuneration_amount: sakhiDetails?.remuneration_amount || "",
      evidence: sakhiDetails?.image || "",
      remarks_by_sakhi: sakhiDetails?.remark || "",
      comments_by_FO: sakhiDetails?.is_approve_rejected_reason_fo || " ",
      concluding_remarks_by_NGO:
        sakhiDetails?.is_approve_rejected_reason_ngo || "",
    });
  }, [sakhiDetails]);

  const checkIsActionBtnVisible = () => {
    if (role_id === 2 && Number(sakhiDetails?.is_approve_status_fo) === 2) {
      return [null, 0].includes(sakhiDetails?.ngo_action_count)
    } else return false;
  };

  return (
    <Box className="w-full h-full flex flex-col justify-start items-start py-8">
      <Formik
        enableReinitialize={crudAction.create ? false : true}
        initialValues={{
          beneficiary_name: transactionViewData?.beneficiary_name || "",
          beneficiary_id: transactionViewData?.beneficiary_id || "",
          fathers_husbands_name:
            transactionViewData?.fathers_husbands_name || "",
          mobile_number: transactionViewData?.mobile_number || "",
          meeting_date:
            moment(transactionViewData?.meeting_date).format(
              "DD/MM/YYYY HH:MM"
            ) || "",
          purpose: transactionViewData?.purpose || "",
          schema_list: transactionViewData?.schema_list || "",
          remuneration_amount: transactionViewData?.remuneration_amount || "",
          evidence: transactionViewData?.evidence || "",
          remarks_by_sakhi: transactionViewData?.remarks_by_sakhi || "",
          comments_by_FO: transactionViewData?.comments_by_FO || "",
          concluding_remarks_by_NGO:
            transactionViewData?.concluding_remarks_by_NGO,
        }}
        validationSchema={Yup.object().shape({
          concluding_remarks_by_NGO: Yup.string().required("Requierd Field")
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleClickOpenConfirm();
          // let statusObj = {
          //   is_approved_status: action.approve ? 2 : action.reject ? 3 : '',
          //   is_approved_date: moment(),
          //   is_rejected_reason: values?.concluding_remarks_by_NGO,
          //   ngo_action_count: sakhiDetails?.ngo_action_count == null ? 0 : 1,
          // }
          // putApproveAndRejectSakhi(statusObj);
        }}
      >
        {function Render({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          touched,
          setErrors,
          setStatus,
          setSubmitting,
          values,
          setFieldError,
          resetForm
        }) {
          useEffect(() => {
            return () => resetForm();
          }, []);
          return (
            <form className="w-full bg-white  overflow-hidden" onSubmit={handleSubmit} {...rest}>
              {/* <ScrollBar 
              className="overflow-y-scroll  overflow-x-hidden mb-2"
              // style={{ height: "500px" }}
            > */}
              <Dialog style={{ maxWidth: "100%" }} onClose={handleClose} open={open}>
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "calc(100vh - 150px)",
                    objectPosition: "content",
                  }}
                  className='px-2 pt-2'
                  src={values?.evidence}
                  alt="ima-alt"
                />
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>

              <Grid
                container
                spacing={2}
                className="w-full bg-white  px-4 "
              >
                <Grid item xs={12} sx={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.beneficiary_name && errors.beneficiary_name}
                    helperText={touched.beneficiary_name && errors.beneficiary_name}
                    name="beneficiary_name"
                    className="inputRounded"
                    label='Beneficary Name'
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.beneficiary_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12} xs={12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.beneficiary_id && errors.beneficiary_id}
                    helperText={touched.beneficiary_id && errors.beneficiary_id}
                    name="beneficiary_id"
                    className="inputRounded"
                    label='Beneficary ID'
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.beneficiary_id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.fathers_husbands_name && errors.fathers_husbands_name}
                    helperText={touched.fathers_husbands_name && errors.fathers_husbands_name}
                    name="fathers_husbands_name"
                    className="inputRounded"
                    label="Father's/Husband's Name"
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.fathers_husbands_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: "2px",
                        fontSize: 14,
                        marginLeft: 0
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.mobile_number && errors.mobile_number}
                    helperText={touched.mobile_number && errors.mobile_number}
                    name="mobile_number"
                    className="inputRounded"
                    label="Mobile Number"
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.mobile_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.meeting_date && errors.meeting_date}
                    helperText={touched.meeting_date && errors.meeting_date}
                    name="meeting_date"
                    className="inputRounded"
                    label="Meeting Date"
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.meeting_date}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.purpose && errors.purpose}
                    helperText={touched.purpose && errors.purpose}
                    name="purpose"
                    className="inputRounded"
                    label="Purpose"
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.purpose}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.schema_list && errors.schema_list}
                    helperText={touched.schema_list && errors.schema_list}
                    name="schema_list"
                    className="inputRounded"
                    label="Scheme List"
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.schema_list}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.remuneration_amount && errors.remuneration_amount}
                    helperText={touched.remuneration_amount && errors.remuneration_amount}
                    name="remuneration_amount"
                    className="inputRounded"
                    label="Remuneration Amount"
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.remuneration_amount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="content" style={{ color: "black" }}>
                      Evidence
                    </Typography> */}
                  </InputLabel>
                  <Box className="w-full flex justify-start items-center space-x-2">
                    <Button
                      onClick={() => handleClickOpen()}
                      className="w-full py-2"
                      variant="contained"
                      color="primary"
                    >
                      View Evidence
                    </Button>

                    {/* <TextField
                    error={touched.evidence && errors.evidence}
                    fullWidth
                    helperText={touched.evidence && errors.evidence}
                    onBlur={handleBlur}
                    name="evidence"
                    onChange={handleChange}
                    value={values.evidence}
                    variant="outlined"
                    disabled={crudAction?.view ? true : false}
                    style={{
                      backgroundColor: theme.palette.grey[100],
                      color: "black",
                    }}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                  /> */}
                    {/* <Visibility color='primary' onClick={() => setViewImage(pre => !pre)} /> */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="caption" style={{ color: "black" }}>
                    Mobile no.<span className="text-red-500">*</span>
                  </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.remarks_by_sakhi && errors.remarks_by_sakhi}
                    helperText={touched.remarks_by_sakhi && errors.remarks_by_sakhi}
                    name="remarks_by_sakhi"
                    className="inputRounded"
                    label="Remarks by Sakhi"
                    placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                    fullWidth
                    value={values.remarks_by_sakhi}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={crudAction.view || crudAction?.delete ? true : false}
                    required
                    // style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="content" style={{ color: "black" }}>
                      Comments by FO
                    </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.comments_by_FO && errors.comments_by_FO}
                    fullWidth
                    multiline
                    label="Comments by FO"
                    helperText={touched.comments_by_FO && errors.comments_by_FO}
                    name="comments_by_FO"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.comments_by_FO}
                    placeholder="Start typing from here"
                    variant="outlined"
                    disabled={crudAction?.view ? true : false}
                    style={{
                      backgroundColor: 'white',
                      color: "black",
                    }}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    maxRows={3}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                      }
                    }}
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>
                  <InputLabel className="mb-2">
                    {/* <Typography variant="content" style={{ color: "black" }}>
                      Comments by FO
                    </Typography> */}
                  </InputLabel>
                  <TextField
                    error={touched.concluding_remarks_by_NGO && errors.concluding_remarks_by_NGO}
                    fullWidth
                    multiline
                    label="Concluding remarks by NGO*"
                    helperText={touched.concluding_remarks_by_NGO && errors.concluding_remarks_by_NGO ? errors.concluding_remarks_by_NGO : ""}
                    name="concluding_remarks_by_NGO"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.concluding_remarks_by_NGO}
                    placeholder="Start typing from here"
                    variant="outlined"
                    disabled={!checkIsActionBtnVisible()}
                    style={{
                      backgroundColor: 'white',
                      color: "black",
                    }}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                    maxRows={3}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14,
                        width: '100%'
                      }
                    }}
                  />

                </Grid>


                <Grid item xs={12} sm={12} md={crudAction?.view && Object.keys(values)?.length > 4 ? 6 : 12}>

                  {checkIsActionBtnVisible() && (
                    <Box className="w-full  my-4 py-8 flex justify-start items-center space-x-3">
                      <Button
                        className="w-6/12 md:2/12"
                        variant="contained"
                        onClick={() => {
                          setFieldError("concluding_remarks_by_NGO", "");
                          setAction({
                            approve: true,
                            reject: false,
                          });
                          handleClickOpenConfirm();
                        }}
                        // type="submit"
                        style={{
                          backgroundColor: theme.palette.success.main,
                          color: "white",
                        }}
                        disabled={sakhiDetails?.ngo_action_count === 0 && sakhiDetails.is_approve_status_ngo === 2}
                      >
                        APPROVE
                      </Button>
                      <Button
                        className="w-6/12 md:2/12"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setFieldTouched("concluding_remarks_by_NGO", true);
                          setAction({
                            approve: false,
                            reject: true,
                          });
                          // handleClickOpenConfirm();
                        }}
                        type="submit"
                        disabled={sakhiDetails?.ngo_action_count === 0 && sakhiDetails.is_approve_status_ngo === 3}
                      >REJECT
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Dialog
                open={openConfirmation}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation Alert"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure {action?.approve ? "Approve" : "Reject"} the
                    transaction?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseConfirm}>Disagree</Button>
                  <Button onClick={() => {
                    let statusObj = {
                      is_approved_status: action.approve ? 2 : action.reject ? 3 : '',
                      is_approved_date: moment(),
                      is_rejected_reason: values?.concluding_remarks_by_NGO,
                      ngo_action_count: sakhiDetails?.ngo_action_count == null ? 0 : 1,
                    }
                    putApproveAndRejectSakhi(statusObj);
                  }} autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
              {/* </ScrollBar> */}
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}

export default React.memo(ViewForm);
