const { MOU_CREATE_REQUEST, MOU_CREATE_SUCCESS, MOU_CREATE_FAIL, MOU_LIST_REQUEST, MOU_LIST_SUCCESS, MOU_LIST_FAIL, MOU_DELETE_REQUEST, MOU_UPDATE_SUCCESS, MOU_UPDATE_REQUEST, MOU_UPDATE_FAIL, MOU_DELETE_SUCCESS, MOU_DELETE_FAIL } = require("../Conistance/MOUConistance");

export const mouCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case MOU_CREATE_REQUEST:
            return { loading: true };
        case MOU_CREATE_SUCCESS:
            return { loading: false, success: true, mou: action.payload }
        case MOU_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const mouListReducer = (state = [], action) => {
    switch (action.type) {
        case MOU_LIST_REQUEST:
            return { loading: true }
        case MOU_LIST_SUCCESS:
            return { loading: false, mou: action.payload }
        case MOU_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const mouUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case MOU_UPDATE_REQUEST:
            return { loading: true };
        case MOU_UPDATE_SUCCESS:
            return { loading: false, mou: action.payload }
        case MOU_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const mouDeleteReducer = (success = '', action) => {
    switch (action.type) {
        case MOU_DELETE_REQUEST:
            return { loading: true }
        case MOU_DELETE_SUCCESS:
            return { loading: false, success: true }
        case MOU_DELETE_FAIL:
            return { loading: false, success: false };
        default:
            return success
    }
}