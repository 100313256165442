const { CENTRE_CREATE_REQUEST, CENTRE_CREATE_SUCCESS, CENTRE_CREATE_FAIL, CENTRE_LIST_REQUEST, CENTRE_LIST_SUCCESS, CENTRE_LIST_FAIL, CENTRE_DELETE_REQUEST, CENTRE_UPDATE_SUCCESS, CENTRE_UPDATE_REQUEST, CENTRE_UPDATE_FAIL, CENTRE_DELETE_SUCCESS, CENTRE_DELETE_FAIL } = require("../Conistance/CentreConistance");

export const centreCreateReducer = (state = {}, action) => {
    switch(action.type){
        case CENTRE_CREATE_REQUEST:
            return {loading: true};
        case CENTRE_CREATE_SUCCESS:
            return { loading: false, success: true, centre: action.payload}
        case CENTRE_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const centreListReducer = (state = [], action) => {
    switch(action.type){
        case CENTRE_LIST_REQUEST:
            return {loading: true}
        case CENTRE_LIST_SUCCESS:
            return { loading: false, centres: action.payload}
        case CENTRE_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const centreUpdateReducer = (state = {},action) => {
    switch(action.type){
        case CENTRE_UPDATE_REQUEST:
            return {loading: true};
        case CENTRE_UPDATE_SUCCESS:
            return {loading: false , centre: action.payload}
        case CENTRE_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const centreDeleteReducer = (success = '', action) => {
    switch(action.type){
        case CENTRE_DELETE_REQUEST:
            return {loading: true}
        case CENTRE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case CENTRE_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}