const { PURPOSE_MAPPER_CREATE_REQUEST, PURPOSE_MAPPER_CREATE_SUCCESS, PURPOSE_MAPPER_CREATE_FAIL, PURPOSE_MAPPER_LIST_REQUEST, PURPOSE_MAPPER_LIST_SUCCESS, PURPOSE_MAPPER_LIST_FAIL, PURPOSE_MAPPER_DELETE_REQUEST, PURPOSE_MAPPER_UPDATE_SUCCESS, PURPOSE_MAPPER_UPDATE_REQUEST, PURPOSE_MAPPER_UPDATE_FAIL, PURPOSE_MAPPER_DELETE_SUCCESS, PURPOSE_MAPPER_DELETE_FAIL } = require("../Conistance/PurposeMapperConistance");

export const purposeMapperCreateReducer = (state = {}, action) => {
    switch(action.type){
        case PURPOSE_MAPPER_CREATE_REQUEST:
            return {loading: true};
        case PURPOSE_MAPPER_CREATE_SUCCESS:
            return { loading: false, success: true, purposeMapper: action.payload}
        case PURPOSE_MAPPER_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const purposeMapperListReducer = (state = [], action) => {
    switch(action.type){
        case PURPOSE_MAPPER_LIST_REQUEST:
            return {loading: true}
        case PURPOSE_MAPPER_LIST_SUCCESS:
            return { loading: false, purposeMappers: action.payload}
        case PURPOSE_MAPPER_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const purposeMapperUpdateReducer = (state = {},action) => {
    switch(action.type){
        case PURPOSE_MAPPER_UPDATE_REQUEST:
            return {loading: true};
        case PURPOSE_MAPPER_UPDATE_SUCCESS:
            return {loading: false , purposeMapper: action.payload}
        case PURPOSE_MAPPER_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const purposeMapperDeleteReducer = (success = '', action) => {
    switch(action.type){
        case PURPOSE_MAPPER_DELETE_REQUEST:
            return {loading: true}
        case PURPOSE_MAPPER_DELETE_SUCCESS:
            return {loading: false, success: true}
        case PURPOSE_MAPPER_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}