import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { memo } from 'react'
import theme from 'src/theme'

const maxValue = (arr) => {
  if (arr?.length > 0) {
    return Math.max(...arr)
  } else {
    return 0;
  }
}
const minValue = (arr) => {
  if (arr?.length > 0) {
    return Math.min(...arr)
  } else {
    return 0;
  }
}

function TopWorstBlock({ worstFiveBlockTransaction, topFiveBlockSakhi, topFiveBlockTransaction, worstFiveSakhiTransaction, clicktopFiveBlockTransaction, clicktopFiveBlockSakhi, config }) {
  return (
    topFiveBlockSakhi ? <Box className="flex justify-end items-end space-x-2">
      <Typography
        variant="subtitle2"
        style={{ color: theme?.palette?.grey[500] }}
      >
        {["5 Performing Blocks"]?.includes(config?.title)
          ? clicktopFiveBlockTransaction === "top"
            ? topFiveBlockTransaction?.find((val) => Number(val?.total_transaction) === Math.max(...topFiveBlockTransaction.map((val) => val.total_transaction)))?.block_name
            : worstFiveBlockTransaction?.find((val) => Number(val?.total_transaction) === Math.min(...worstFiveBlockTransaction.map((val) => val.total_transaction)))?.block_name
          : ["5 Best Performing Sakhis"]?.includes(config?.title)
            ? clicktopFiveBlockSakhi === "top"
              ? topFiveBlockSakhi.find((val) => Number(val.total_sakhi) === Math.max(...topFiveBlockSakhi.map((val) => val.total_sakhi)))?.sakhi_name
              : worstFiveSakhiTransaction?.find((val) => Number(val?.total_sakhi) === Math.min(...worstFiveSakhiTransaction.map((val) => val.total_sakhi)))?.sakhi_name
            : null}
      </Typography>
      <Typography variant="h5" style={{ fontWeight: 600 }}>
        {" "}
        {["5 Performing Blocks"]?.includes(config?.title)
          ? clicktopFiveBlockTransaction === "top"
            ? maxValue(topFiveBlockTransaction.map((val) => val.total_transaction)) || 0
            : minValue(worstFiveBlockTransaction.map((val) => val.total_transaction)) || 0
          : ["5 Best Performing Sakhis"]?.includes(config?.title)
            ? clicktopFiveBlockSakhi === "top"
              ? maxValue(topFiveBlockSakhi.map((val) => val.total_sakhi)) || 0
              : minValue(worstFiveSakhiTransaction.map((val) => val.total_sakhi)) || 0
            : 0}{" "}
      </Typography>
    </Box> : null
  )
}

export default memo(TopWorstBlock)