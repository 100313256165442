import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react'

function AutocompleteComponent({ options, label, value, name, disable, required, setFieldValue, touched, errors, handleBlur, multiple, formData, setFormData, reset }) {
  return (
    <Autocomplete
      noOptionsText='No Data Found'
      size='small'
      // disablePortal
      id="size-small-filled"
      multiple={multiple}
      onBlur={handleBlur}
      name={name}
      value={options?.find(
        (val) => val?.id ? val.id === parseInt(value) : val === parseInt(value)
      ) || null}
      disableClearable={
        value === "" ||
          value === undefined ||
          value === null
          ? true
          : false
      }
      // defaultValue={data?.options?.find(item => item?.id == value)?.name}
      options={options}
      onChange={(event, value) => {
        const e = {
          target: {
            value: value || "",
          },
        };
        if (typeof e.target.value === "object") {
          let value = e?.target?.value?.id;
          setFieldValue(name, value)
          //   Object.keys(reset).map(key => setFieldValue([key], null))
          setFormData && setFormData({ ...formData, [name]: value, ...reset })
          Object.keys(reset).map(
            key => setFieldValue(key, '')
          )
        } else {
          let value = e?.target?.value;
          setFieldValue(name, value)
          setFormData && setFormData({ ...formData, [name]: value, ...reset })
          Object.keys(reset).map(
            key => setFieldValue(key, '')
          )
        }
      }}
      disabled={
        options === undefined || options === "" || disable
          ? true
          : false
      }

      className="autoComplete"
      getOptionLabel={(option) => option?.name ? option?.name : option}
      // disabled ={data.name === 'foName' && (role_id == 1 && filterData?.ngoName === '' ) ? true : false || data.name === 'sakhiName' && filterData?.foName === '' ? true : false}
      required={required}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
            style: {
              backgroundColor: 'white',
              paddingRight: '8px',
              fontSize: 14
            }
          }}
          // InputProps={{ ...params.InputProps, style: { fontSize: 15,textOverflow: 'ellipsis',whiteSpace: "nowrap"} }}
          error={touched[name] && errors[name]}
          helperText={touched[name] && errors[name]}
          variant="outlined"
          placeholder={disable ? "" : "Enter"}
          className="h-0 rounded-2xl"
          label={label}
        />
      )}
      renderOption={(option) => (
        <React.Fragment>
          <Typography variant="subtitle2" style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }}>
            {option?.name ? option?.name : option}
          </Typography>
        </React.Fragment>
      )}
    />
  )
}

export default AutocompleteComponent