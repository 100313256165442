import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, colors, LinearProgress, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import noDataSvg from 'src/assets/images/no_data.svg'

function ReportTable({
  columns,
  rows,
  loading,
  total,
  page,
  setPage,
  rowsPerPage = 10,
  rowsLoading
}) {
  const handleChangePage = (event, newPage) => {
    localStorage.setItem("pageInfo", newPage);
    setPage(newPage);
  };

  return (
    <>
      <Box className="py-5">
      </Box>
      {loading ? (
        <Box className="w-full " sx={{ width: "100%" }}>
          <LinearProgress className="w-full" />
        </Box>
      ) : (
        <Paper m={10} sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440, borderRadius: "40px" }}>
            <Table aria-label="sticky table">
              <TableHead className="bg-slate-200">
                <TableRow>
                  {columns?.firstHeader?.map((column, index) => (
                    <TableCell
                      colSpan={
                        column?.colSpan ? column?.colSpan :
                          ["data_base", "phase", "geography"].includes(column?.id) ? 2
                            : column?.id === "sno" ? 1
                              : column?.id === "monthly" ? 12
                                : column?.id === "linkages" ? 5
                                  : 3
                      }
                      key={column.id + index}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        justifyContent: "center",
                        textAlign: column?.align || "center",
                        backgroundColor: "#D6002A",
                        borderRight: `1px solid ${colors.grey[400]}`,
                        color: "white",
                        paddingBlock: 20,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        className="font-semibold text-xs"
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {columns?.secoundHeader?.map((column, sIndex) => (
                    <TableCell
                      key={column.id + sIndex}
                      colSpan={
                        column?.colSpan ? column?.colSpan :
                          column?.id === "key_matrics" ? 2
                            : column?.id === "geography" ? 2
                              : column?.id === "matrics" ? 3
                                : 1
                      }
                      style={{
                        minWidth: column.minWidth,
                        justifyContent: "center",
                        textAlign: "center",
                        paddingBlock: 10,
                        borderRight: `1px solid ${colors.grey[400]}`,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        className="font-semibold text-xs"
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsLoading ? null : rows?.map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                    >
                      {columns?.secoundHeader?.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{
                              minWidth: column.minWidth,
                              justifyContent: "center",
                              textAlign: "center",
                              paddingBlock: 10,
                              borderRight: `1px solid ${colors.grey[400]}`,
                            }}
                            colSpan={
                              ["key_matrics", "phase", "geography"].includes(column?.id) ? 2
                                : column?.id === "matrics" ? 3
                                  : 1
                            }
                            key={column.id}
                            align="center"
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {rowsLoading ? <Box className="w-full" sx={{ width: "100%" }}>
              <LinearProgress className="w-full" />
            </Box>
              : null}
            {rows?.length < 1 && (
              <Box className="w-full h-96 flex flex-col justify-center items-center space-y-2">
                <Box className="w-64 h-64 flex justify-center items-center  ">
                  <img
                    src={noDataSvg}
                    alt="log"
                    className="w-full h-full object-contain"
                  />
                </Box>
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                  No Data Found
                </Typography>
              </Box>
            )}
          </TableContainer>
          {rows?.length > 0 && <Box width="100%" display="flex" justifyContent="center" py={1}>
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={Math.ceil(total / rowsPerPage) || 1}
              shape="rounded"
              showFirstButton
              showLastButton
              color="primary"
            />
          </Box>}
        </Paper>
      )}
    </>
  );
}

export default React.memo(ReportTable);
