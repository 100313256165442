const { LINE_ITEM_CREATE_REQUEST, LINE_ITEM_CREATE_SUCCESS, LINE_ITEM_CREATE_FAIL, LINE_ITEM_LIST_REQUEST, LINE_ITEM_LIST_SUCCESS, LINE_ITEM_LIST_FAIL, LINE_ITEM_UPDATE_SUCCESS, LINE_ITEM_UPDATE_REQUEST, LINE_ITEM_UPDATE_FAIL } = require("../Conistance/LineItemConistance");

export const lineItemCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case LINE_ITEM_CREATE_REQUEST:
            return { loading: true };
        case LINE_ITEM_CREATE_SUCCESS:
            return { loading: false, success: true, lineItem: action.payload }
        case LINE_ITEM_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const lineItemListReducer = (state = [], action) => {
    switch (action.type) {
        case LINE_ITEM_LIST_REQUEST:
            return { loading: true }
        case LINE_ITEM_LIST_SUCCESS:
            return { loading: false, lineItem: action.payload }
        case LINE_ITEM_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const lineItemUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case LINE_ITEM_UPDATE_REQUEST:
            return { loading: true };
        case LINE_ITEM_UPDATE_SUCCESS:
            return { loading: false, lineItem: action.payload }
        case LINE_ITEM_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
