import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: 54,
    height: 64,
  },
  xl: {
    width: 88,
    height: 88,
  },
}));
const Logo = ({ size, variant, src }) => {
  const classes = useStyles();

  return (
    <Avatar
      variant={variant ? variant : 'square'}
      src={src}
      className={classes[size]}
    />
  );
};

export default Logo;
