const { NGO_CREATE_REQUEST, NGO_CREATE_SUCCESS, NGO_CREATE_FAIL, NGO_LIST_REQUEST, NGO_LIST_SUCCESS, NGO_LIST_FAIL, NGO_DELETE_REQUEST, NGO_UPDATE_SUCCESS, NGO_UPDATE_REQUEST, NGO_UPDATE_FAIL, NGO_DELETE_SUCCESS, NGO_DELETE_FAIL } = require("../Conistance/NgoConistance");

export const ngoCreateReducer = (state = {}, action) => {
    switch(action.type){
        case NGO_CREATE_REQUEST:
            return {loading: true};
        case NGO_CREATE_SUCCESS:
            return { loading: false, success: true, ngo: action.payload}
        case NGO_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const ngoListReducer = (state = [], action) => {
    switch(action.type){
        case NGO_LIST_REQUEST:
            return {loading: true}
        case NGO_LIST_SUCCESS:
            return { loading: false, ngos: action.payload}
        case NGO_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const ngoUpdateReducer = (state = {},action) => {
    switch(action.type){
        case NGO_UPDATE_REQUEST:
            return {loading: true};
        case NGO_UPDATE_SUCCESS:
            return {loading: false , ngo: action.payload}
        case NGO_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const ngoDeleteReducer = (success = '', action) => {
    switch(action.type){
        case NGO_DELETE_REQUEST:
            return {loading: true}
        case NGO_DELETE_SUCCESS:
            return {loading: false, success: true}
        case NGO_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}