const { PURPOSE_CREATE_REQUEST, PURPOSE_CREATE_SUCCESS, PURPOSE_CREATE_FAIL, PURPOSE_LIST_REQUEST, PURPOSE_LIST_SUCCESS, PURPOSE_LIST_FAIL, PURPOSE_DELETE_REQUEST, PURPOSE_UPDATE_SUCCESS, PURPOSE_UPDATE_REQUEST, PURPOSE_UPDATE_FAIL, PURPOSE_DELETE_SUCCESS, PURPOSE_DELETE_FAIL } = require("../Conistance/PurposeConistance");

export const purposeCreateReducer = (state = {}, action) => {
    switch(action.type){
        case PURPOSE_CREATE_REQUEST:
            return {loading: true};
        case PURPOSE_CREATE_SUCCESS:
            return { loading: false, success: true, purpose: action.payload}
        case PURPOSE_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const purposeListReducer = (state = [], action) => {
    switch(action.type){
        case PURPOSE_LIST_REQUEST:
            return {loading: true}
        case PURPOSE_LIST_SUCCESS:
            return { loading: false, purposes: action.payload}
        case PURPOSE_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const purposeUpdateReducer = (state = {},action) => {
    switch(action.type){
        case PURPOSE_UPDATE_REQUEST:
            return {loading: true};
        case PURPOSE_UPDATE_SUCCESS:
            return {loading: false , purpose: action.payload}
        case PURPOSE_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const purposeDeleteReducer = (success = '', action) => {
    switch(action.type){
        case PURPOSE_DELETE_REQUEST:
            return {loading: true}
        case PURPOSE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case PURPOSE_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}