import { Box, Typography } from "@material-ui/core";
import React from "react";
import ChangePassword from "../auth/ChangePassword";

function SettingView() {
  return (
    <Box className="w-full min-h-screen bg-gray-100 p-4">
    <Box className="w-full h-full bg-white pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box width="100%" height="100%">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography>
            <span className="capitalize font-semibold  text-md md:text-lg lg:text-3xl text-black">
              Setting
            </span>
          </Typography>
        </Box>
      </Box>
      <Box className="w-full flex justify-start items-center">
        <Box className="py-1 border-b-2 border-primary px-2">
        <Typography variant="caption" style={{fontSize: 14 ,fontWeight: 600}}>Password</Typography>
        </Box>
      </Box>
      <Box className="container py-10">
        <ChangePassword />
      </Box>
      </Box>
    </Box>
  );
}

export default SettingView;
