import {
  TextField,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
  Checkbox,
} from "@material-ui/core";

import { Formik } from "formik";
import { rest } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import {
  stateCreateAction,
  stateDeleteAction,
  stateListAction,
  stateUpdateAction,
} from "src/Redux/Actions/StateAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  purposeCreateAction,
  purposeDeleteAction,
  purposeListAction,
  purposeUpdateAction,
} from "src/Redux/Actions/PurposeAction";
import {
  purposeMapperCreateAction,
  purposeMapperDeleteAction,
  purposeMapperListAction,
  purposeMapperUpdateAction,
} from "src/Redux/Actions/PurposeMapperAction";
import {
  districtCreateAction,
  districtDeleteAction,
  districtListAction,
  districtUpdateAction,
} from "src/Redux/Actions/DistrictAction";
import {
  blockCreateAction,
  blockDeleteAction,
  blockListAction,
  blockUpdateAction,
} from "src/Redux/Actions/BlockAction";
import {
  villageCreateAction,
  villageDeleteAction,
  villageUpdateAction,
} from "src/Redux/Actions/VillageAction";
import {
  ngoCreateAction,
  ngoDeleteAction,
  ngoListAction,
  ngoUpdateAction,
} from "src/Redux/Actions/NgoAction";
import AutocompleteComponent from "src/components/CommonComponents/AutocompleteComponent";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { useMemo } from "react";
import { fetchApiDataList } from "src/services/http_requests";

function MastersCRUDForm({ crudAction, updateDetails, currentPath, refetchDataList }) {
  const [formData, setFormData] = useState(null);

  const [stateChange, setStateChange] = useState(0);
  const { states } = useSelector((state) => state.statesList);
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const [ngoList, setNgoList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      !states && dispatch(stateListAction({ fetched: "All" }));
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormData({
      state_id: ["ngo", 'purpose_mapper'].includes(currentPath)
        ? updateDetails?.state_id
          ?.split(",")
          ?.filter(Boolean)
          ?.map((item) => parseInt(item)) || []
        : Number(updateDetails?.state_id) || "",
      district_id: ["ngo"].includes(currentPath)
        ? updateDetails?.district_id
          ?.split(",")
          ?.filter(Boolean)
          ?.map((item) => parseInt(item)) || []
        : updateDetails?.district_id || "",
      block_id: updateDetails?.block_id || "",
      purpose_id: updateDetails?.purpose_id || "",
      ngo_id: updateDetails?.ngo_id || "",
      name: updateDetails?.name || "",
      purpose_id_mapper: "",
      code: updateDetails?.code || "",
    })
  }, [updateDetails])

  const updateApi = useMemo(() => {
    if (formData) {
      if (["block"].includes(currentPath)) {
        if (formData?.state_id !== "" && formData?.district_id === "") {
          fetchApiDataList("district", { fetched: "All", state_id: formData?.state_id, }, setDistrictList);
        } else if (updateDetails?.id) {
          fetchApiDataList("district", { fetched: "All", state_id: formData?.state_id, }, setDistrictList);
        }
      } else if (["village"].includes(currentPath)) {
        if (formData?.state_id !== "" && formData?.district_id === "") {
          fetchApiDataList("district", { fetched: "All", state_id: formData?.state_id, }, setDistrictList);
        } else if (
          formData?.state_id !== "" &&
          formData?.district_id !== "" &&
          formData?.block_id === ""
        ) {
          fetchApiDataList("block", { fetched: "All", state_id: formData?.state_id, district_id: formData?.district_id }, setBlockList);
        } else if (updateDetails?.id) {
          fetchApiDataList("district", { fetched: "All", state_id: formData?.state_id, }, setDistrictList);
          fetchApiDataList("block", { fetched: "All", state_id: formData?.state_id, district_id: formData?.district_id }, setBlockList);
        }
      } else if (["ngo"].includes(currentPath)) {
        if (formData?.state_id?.length > 0 && formData?.district_id?.length < 1) {
          fetchApiDataList("district", { fetched: "All", state_id: formData?.state_id, }, setDistrictList);
        } else if (updateDetails?.id) {
          fetchApiDataList("district", { fetched: "All", state_id: formData?.state_id, }, setDistrictList);
        }
      } else if (["centre"].includes(currentPath)) {
        if (updateDetails?.id) {
          fetchApiDataList("district", { fetched: "All", state_id: formData?.state_id, }, setDistrictList);
          fetchApiDataList("block", { fetched: "All", state_id: formData?.state_id, district_id: formData?.district_id }, setBlockList);
          fetchApiDataList("ngo_partner", { fetched: "All", state_id: formData?.state_id, }, setNgoList);
        }
      } else if (["purpose"].includes(currentPath)) {
        // dispatch(purposeListAction({ fetched: 'All' }));
      } else if (["purpose_mapper"].includes(currentPath)) {
        if (formData?.state_id !== "" && formData?.purpose_id === "") {
          fetchApiDataList("purpose", { fetched: "All" }, setPurposeList);
        } else if (updateDetails?.id) {

          fetchApiDataList("purpose", { fetched: "All" }, setPurposeList);
        }
      }
    }
  }, [formData, updateDetails]);

  useEffect(() => {
    if (stateChange > 0) {
      const districtArray = districtList?.map((val) => parseInt(val.id));
      setFormData({
        ...formData,
        district_id: ["ngo"].includes(currentPath)
          ? updateDetails?.district_id
            ?.split(",")
            ?.filter(Boolean)
            ?.map((item) => districtArray?.includes(item) && parseInt(item))
            ?.filter(Boolean) || []
          : updateDetails?.district_id || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateChange]);

  return (
    <Formik
      enableReinitialize={crudAction.create ? false : true}
      initialValues={{
        state_id: formData?.state_id || "",
        district_id: formData?.district_id || "",
        block_id: formData?.block_id || "",
        purpose_id: formData?.purpose_id || "",
        ngo_id: formData?.ngo_id || "",
        name: formData?.name || "",
        code: formData?.code || "",
      }}
      validationSchema={Yup.object().shape({
        name:
          [
            "state",
            "district",
            "block",
            "village",
            "ngo",
            "centre",
            "purpose",
            "purpose_mapper",
          ].includes(currentPath) &&
          Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Name is required"),
        ngo_id:
          ["centre"].includes(currentPath) &&
          Yup.string().required("NGO is required"),
        state_id:
          [
            "district",
            "block",
            "village",
            "centre",
            "purpose_mapper",
            "ngo",
          ].includes(currentPath) && Yup.string().required("State is required"),
        district_id:
          ["block", "village", "centre"].includes(currentPath) &&
          Yup.string().required("District is required"),
        block_id:
          ["village", "centre"].includes(currentPath) &&
          Yup.string().required("Block is required"),
        purpose_id:
          ["purpose_mapper"].includes(currentPath) &&
          Yup.string().required("Purpose is required"),
        // date_of_leaving: Yup.date()
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        for (let key in values) {
          if (values[key] === "") {
            delete values[key];
          }
        }
        ["state"].includes(currentPath)
          ? crudAction.create
            ? dispatch(stateCreateAction(values, refetchDataList))
            : crudAction.edit
              ? dispatch(stateUpdateAction(updateDetails.id, values, refetchDataList))
              : dispatch(stateDeleteAction(updateDetails.id, refetchDataList))
          : ["district"].includes(currentPath)
            ? crudAction.create
              ? dispatch(districtCreateAction(values, refetchDataList))
              : crudAction.edit
                ? dispatch(districtUpdateAction(updateDetails.id, values, refetchDataList))
                : dispatch(districtDeleteAction(updateDetails.id, refetchDataList))
            : ["block"].includes(currentPath)
              ? crudAction.create
                ? dispatch(blockCreateAction(values, refetchDataList))
                : crudAction.edit
                  ? dispatch(blockUpdateAction(updateDetails.id, values, refetchDataList))
                  : dispatch(blockDeleteAction(updateDetails.id, refetchDataList))
              : ["village"].includes(currentPath)
                ? crudAction.create
                  ? dispatch(villageCreateAction(values, refetchDataList))
                  : crudAction.edit
                    ? dispatch(villageUpdateAction(updateDetails.id, values, refetchDataList))
                    : dispatch(villageDeleteAction(updateDetails.id, refetchDataList))
                : ["ngo"].includes(currentPath)
                  ? crudAction.create
                    ? dispatch(
                      ngoCreateAction({
                        ...values,
                        state_id: values?.state_id?.join(","),
                        district_id: values?.district_id?.join(","),
                      }, refetchDataList)
                    )
                    : crudAction.edit
                      ? dispatch(
                        ngoUpdateAction(updateDetails.id, {
                          ...values,
                          state_id: values?.state_id?.join(","),
                          district_id: values?.district_id
                            ?.map(
                              (item) =>
                                districtList?.map((val) => val.id)?.includes(item) &&
                                item
                            )
                            ?.filter(Boolean)
                            .join(","),
                        }, refetchDataList)
                      )
                      : dispatch(ngoDeleteAction(updateDetails.id, refetchDataList))
                  : ["purpose"].includes(currentPath)
                    ? crudAction.create
                      ? dispatch(purposeCreateAction(values, refetchDataList))
                      : crudAction.edit
                        ? dispatch(purposeUpdateAction(updateDetails.id, values, refetchDataList))
                        : dispatch(purposeDeleteAction(updateDetails.id, refetchDataList))
                    : ["purpose_mapper"].includes(currentPath)
                      ? crudAction.create
                        ? dispatch(purposeMapperCreateAction({
                          ...values,
                          state_id: values?.state_id?.join(",")
                        }, refetchDataList))
                        : crudAction.edit
                          ? dispatch(purposeMapperUpdateAction(updateDetails.id, {
                            ...values,
                            state_id: values?.state_id?.join(",")
                          }, refetchDataList))
                          : dispatch(purposeMapperDeleteAction(updateDetails.id, refetchDataList))
                      : console.log("this");
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        setErrors,
        setStatus,
        setSubmitting,
        values,
      }) => {
        return (
          <form
            className="w-full overflow-hidden bg-transparent"
            onSubmit={handleSubmit}
            {...rest}
          >
            <Grid
              spacing={2}
              container
              className="w-full bg-white px-4 pb-6 pt-4 "
            >
              {[
                "district",
                "block",
                "village",
                "centre",
                "purpose_mapper",
                "ngo",
              ].includes(currentPath) && (
                  <Grid item xs={12} sx={12} md={12}>
                    {['ngo', 'purpose_mapper'].includes(currentPath) ? (
                      <Autocomplete
                        noOptionsText='No Data Found'
                        multiple
                        error={touched.state_id && errors.state_id}
                        helperText={touched.state_id && errors.state_id}
                        onBlur={handleBlur}
                        name="state_id"
                        id="checkboxes-tags-demo"
                        options={states?.data || []}
                        className="autoComplete"
                        getOptionLabel={(option) =>
                          option?.name ? option?.name : option
                        }
                        value={
                          states?.data?.filter(
                            (val) => val?.id && values?.state_id?.includes(val?.id)
                          ) || []
                        }
                        onChange={(e, valuess) => {
                          const value = valuess?.map((val) => val?.id);
                          // handleChange(e);
                          setFieldValue("state_id", value);
                          setFormData({
                            ...formData,
                            state_id: value,
                            district_id: []
                          });
                          setStateChange((pre) => pre + 1);
                        }}
                        // defaultValue={[top100Films[13]]}
                        // filterSelectedOptions
                        size="small"
                        renderOption={(option, { selected }) => (
                          <li className='flex items-center'>
                            <Checkbox
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckBox fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <Typography
                              variant="subtitle2"
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {option?.name ? option?.name : option}
                            </Typography>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="filterSelectedOptions"
                            variant="outlined"
                            placeholder={crudAction?.create ? "Enter" : ''}
                            className="autoComplete"
                            label='State *'
                            error={touched?.state_id && errors?.state_id}
                            helperText={touched?.state_id && errors?.state_id}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                backgroundColor: 'white',
                                paddingRight: '8px',
                                fontSize: 14
                              }
                            }}
                          />
                        )}
                        disabled={(crudAction.view || crudAction.delete) ? true : false}
                        required
                      />
                    ) : (
                      <AutocompleteComponent
                        required={true}
                        name={"state_id"}
                        options={states?.data}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        value={values?.state_id}
                        errors={errors}
                        multiple={false}
                        reset={{ district_id: "", block_id: "" }}
                        formData={formData}
                        setFormData={setFormData}
                        disable={(crudAction.view || crudAction.delete)}
                        label='State *'
                      />
                    )}
                  </Grid>
                )}
              {["block", "village", "centre", "ngo"].includes(currentPath) && (
                <Grid item xs={12} sx={12} md={12}>
                  {['ngo'].includes(currentPath) ? (
                    <Autocomplete
                      noOptionsText='No Data Found'
                      multiple
                      error={touched.district_id && errors.district_id}
                      helperText={touched.district_id && errors.district_id}
                      onBlur={handleBlur}
                      name="district_id"
                      id="checkboxes-tags-demo"
                      options={districtList || []}
                      className="autoComplete"
                      getOptionLabel={(option) =>
                        option?.name ? option?.name : option
                      }
                      value={
                        districtList?.filter(
                          (val) => val?.id && values.district_id?.includes(val?.id)
                        ) || []
                      }
                      onChange={(e, valuess) => {
                        const value = valuess?.map((val) => val?.id);
                        // handleChange(e);
                        setFieldValue("district_id", value);
                        setFormData({
                          ...formData,
                          district_id: value,
                        });
                      }}
                      // defaultValue={[top100Films[13]]}
                      // filterSelectedOptions
                      size="small"
                      renderOption={(option, { selected }) => (
                        <li>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="filterSelectedOptions"
                          variant="outlined"
                          placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                          className="autoComplete"
                          label='District *'
                          error={touched.center_id && errors.center_id}
                          helperText={touched.center_id && errors.center_id}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              backgroundColor: 'white',
                              paddingRight: '8px',
                              fontSize: 14
                            }
                          }}
                        />
                      )}
                      disabled={crudAction.view || crudAction.delete || values?.state_id === '' ? true : false}
                      required
                    />
                  ) : (
                    <AutocompleteComponent
                      required={true}
                      name={"district_id"}
                      options={districtList}
                      getOptionLabel={(option) =>
                        option?.name ? option?.name : option
                      }
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      touched={touched}
                      value={values?.district_id}
                      errors={errors}
                      multiple={false}
                      reset={{ block_id: "" }}
                      formData={formData}
                      setFormData={setFormData}
                      disable={(!crudAction.create && values.state_id === "") || crudAction.view || crudAction.delete}
                      label='District *'
                    />
                  )}
                </Grid>
              )}
              {["village", "centre"].includes(currentPath) && (
                <Grid item xs={12} sx={12} md={12}>
                  <AutocompleteComponent
                    required={true}
                    name={"block_id"}
                    options={blockList}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    value={values?.block_id}
                    errors={errors}
                    multiple={false}
                    reset=''
                    formData={formData}
                    setFormData={setFormData}
                    disable={
                      (!crudAction.create && values?.district_id === "") ||
                      crudAction.view || crudAction.delete
                    }
                    label='Block *'
                  />
                </Grid>
              )}
              {["centre"].includes(currentPath) && (
                <Grid item xs={12} sx={12} md={12}>
                  <AutocompleteComponent
                    required={true}
                    name={"ngo_id"}
                    options={ngoList}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    value={values?.ngo_id}
                    errors={errors}
                    multiple={false}
                    reset={{}}
                    formData={formData}
                    setFormData={setFormData}
                    disable={
                      (!crudAction.create && values.state_id === "") ||
                      crudAction.view || crudAction.delete
                    }
                    label='NGO *'
                  />
                </Grid>
              )}
              {["purpose_mapper"].includes(currentPath) && (
                <Grid item xs={12} sx={12} md={12}>
                  <AutocompleteComponent
                    required={true}
                    name={"purpose_id"}
                    options={purposeList}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    value={values?.purpose_id}
                    errors={errors}
                    multiple={false}
                    reset={{}}
                    formData={formData}
                    setFormData={setFormData}
                    disable={
                      (!crudAction.create && values.state_id === "") ||
                      crudAction.view || crudAction.delete
                    }
                    label='Purpose *'
                  />
                </Grid>
              )}
              {[
                "state",
                "district",
                "block",
                "village",
                "ngo",
                "centre",
                "purpose",
                "purpose_mapper",
              ].includes(currentPath) && (
                  <Grid item xs={12} sx={12} md={12}>
                    <TextField
                      error={touched.name && errors.name}
                      helperText={touched.name && errors.name}
                      name="name"
                      className="inputRounded"
                      placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                      fullWidth
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={crudAction.view || crudAction.delete ? true : false}
                      required
                      style={{ borderRadius: 10 }}
                      variant="outlined"
                      label='Name'
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          backgroundColor: 'white',
                          paddingRight: '8px',
                          fontSize: 14
                        }
                      }}
                    />
                  </Grid>
                )}

              <Grid item xs={12} sx={12} md={12}>
                {!crudAction.view ? (
                  <Box className="w-full bg-gray-100 mt-10">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="medium"
                      className="w-full "
                    >
                      {crudAction.create ? "CREATE"
                        : crudAction.delete ? "DELETE"
                          : "UPDATE"}
                    </Button>
                  </Box>
                ) : null}
              </Grid>
            </Grid>

          </form>
        );
      }}
    </Formik>
  );
}

export default React.memo(MastersCRUDForm);
