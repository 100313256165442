import {
  TextField,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
} from "@material-ui/core";

import { Formik } from "formik";
import { rest } from "lodash";
import React from "react";
import { useEffect } from "react";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  purposeCreateAction,
  purposeListAction,
} from "src/Redux/Actions/PurposeAction";
import {
  purposeMapperListAction,
  purposeMapperUpdateAction,
} from "src/Redux/Actions/PurposeMapperAction";

function AdminMappingCRUDForm({ crudAction, purposeMapperDetails }) {
  const formDataCreate = {
    name: "",
  };
  const formDataEdit = {
    age: "",
    purpose_id: purposeMapperDetails?.purpose_id || "",
    name: purposeMapperDetails?.name || "",
    state_id: purposeMapperDetails?.state_id || "",
  };

  const statesList = useSelector((state) => state.statesList);
  const { states } = statesList;
  const purposeList = useSelector((state) => state.purposeList);
  const { purposes } = purposeList;
  const purposeMapperList = useSelector((state) => state.purposeMapperList);
  const { purposeMappers } = purposeMapperList;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(stateListAction());
    dispatch(purposeListAction());
    dispatch(purposeMapperListAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return crudAction.create ? (
    <Formik
      initialValues={{
        name: formDataCreate?.name || "",
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        dispatch(purposeCreateAction(values));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        setErrors,
        setStatus,
        setSubmitting,
        values,
      }) => {
        return (
          <form
            className="bg-transparent my-5 "
            onSubmit={handleSubmit}
            {...rest}
          >
            <Grid
              spacing={1}
              container
              className="bg-white rounded-md p-4 shadow"
            >
              <Grid item xs={12} sx={12} md={12}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    Purpose Category<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <TextField
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  name="name"
                  className="inputRounded"
                  placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={crudAction.view ? true : false}
                  required
                  style={{ borderRadius: 10 }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box className="w-full bg-gray-100 mt-10">
              <Button
                className="w-full md:w-2/12"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                CREATE
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  ) : (
    <Formik
      initialValues={{
        purpose_id: formDataEdit?.purpose_id || "",
        state_id: formDataEdit?.state_id || "",
        name: formDataEdit?.name || "",
      }}
      onSubmit={async (valuse, { setErrors, setSubmitting, setStatus }) => {
        dispatch(purposeMapperUpdateAction(valuse, purposeMapperDetails?.id));
      }}
    >
      {({
        errors,
        setErrors,
        touched,
        setStatus,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setSubmitting,
        setFieldTouched,
        isSubmitting,
        values,
      }) => {
        return (
          <form
            className="bg-transparent my-5 "
            onSubmit={handleSubmit}
            {...rest}
          >
            <Grid
              spacing={1}
              container
              className="bg-white rounded-md p-4 shadow"
            >
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    State<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <Select MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                  error={touched.state_id && errors.state_id}
                  helperText={touched.state_id && errors.state_id}
                  name="state_id"
                  className="inputRounded"
                  placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.state_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={crudAction.view ? true : false}
                  required
                  style={{ borderRadius: 10 }}
                  variant="outlined"
                >
                  {states?.data?.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    Purpose<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <Select MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                  value={values.purpose_id}
                  name="purpose_id"
                  onChange={handleChange}
                  variant="outlined"
                  helperText={touched.purpose_id && errors.purpose_id}
                  error={touched.purpose_id && errors.purpose_id}
                  onBlur={handleBlur}
                  fullWidth
                  className="inputRounded"
                  style={{ borderRadius: 10 }}
                  disabled={crudAction.view ? true : false}
                  required
                >
                  {purposes?.data?.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    Scheme<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <Select MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  name="name"
                  className="inputRounded"
                  placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={
                    values.district === "" || crudAction.view ? true : false
                  }
                  required
                  style={{ borderRadius: 10 }}
                  variant="outlined"
                >
                  {purposeMappers?.data?.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {!crudAction.view && (
              <Box className="w-full bg-gray-100 mt-10">
                <Button
                  className="w-full md:w-2/12"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  UPDATE
                </Button>
              </Box>
            )}
          </form>
        );
      }}
    </Formik>
  );
}

export default AdminMappingCRUDForm;
