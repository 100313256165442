const columnsScheme = {
  firstHeader: [
    {
      id: "sno",
      label: "",
    },
    {
      id: "geography",
      label: "",
    },
    {
      id: "linkages",
      label: "Linkages",
    },
  ],
  secoundHeader: [
    { id: "sno", label: "S.No" },
    {
      id: "geography",
      label: "State/District/Block",
    },
    { id: "no_of_linkages", label: "No. of Linkages" },
    {
      id: "no_of_sakhis_providing_linkages",
      label: "No. of Sakhis providing Linkages",
    },
    {
      id: "total_revenue",
      label: "Total Revenue",
    },
    {
      id: "average_revenue_per_sakhi",
      label: "Average Revenue per Sakhi",
    },
    {
      id: "average_revenue_per_linkage",
      label: "Average Revenue per Linkage",
    },
  ],
};

const sakhiColumn = {
  firstHeader: [
    {
      id: "sno",
      label: "",
    },
    {
      id: "data_base",
      label: "",
    },
    {
      id: "onboarded_sakhis",
      label: "Onboarded Sakhis",
    },
    {
      id: "active_sakhis",
      label: "Active Sakhis",
    },
    {
      id: "earning_sakhis",
      label: "Earning Sakhis",
    },
  ],
  secoundHeader: [
    { id: "sno", label: "S.No" },
    {
      id: "geography",
      label: "Geography",
    },
    {
      id: "onboard_no_of_sakhis",
      label: "No. of Sakhis",
    },
    {
      id: "onboard_no_of_transactions",
      label: "No. of Transactions",
    },
    {
      id: "onboard_income_of_sakhis",
      label: "Income of Sakhis",
    },
    {
      id: "active_no_of_sakhis",
      label: "No. of Sakhis",
    },
    {
      id: "active_no_of_transactions",
      label: "No. of Transactions",
    },
    {
      id: "active_income_of_sakhis",
      label: "Income of Sakhis",
    },
    //   {
    //     id: "inactive_no_of_sakhis",
    //     label: "No. of Sakhis",
    //   },
    //   {
    //     id: "inactive_no_of_transactions",
    //     label: "No. of Transactions",
    //   },
    //   {
    //     id: "inactive_income_of_sakhis",
    //     label: "Income of Sakhis",
    //   },
    {
      id: "earning_no_of_sakhis",
      label: "No. of Sakhis",
    },
    {
      id: "earning_no_of_transactions",
      label: "No. of Transactions",
    },
    {
      id: "earning_income_of_sakhis",
      label: "Income of Sakhis",
    },
  ],
};

const montlyReportColumn = (year) => ({
  firstHeader: [
    {
      id: "data_base",
      label: "",
    },
    {
      id: "monthly",
      label: `Monthly (${year})`,
    },
  ],
  secoundHeader: [
    {
      id: "key_matrics",
      label: "Key Metrics",
    },
    {
      id: "jan",
      label: "Jan",
    },
    {
      id: "feb",
      label: "Feb",
    },
    {
      id: "mar",
      label: "Mar",
    },
    {
      id: "apr",
      label: "Apr",
    },
    {
      id: "may",
      label: "May",
    },
    {
      id: "jun",
      label: "Jun",
    },
    {
      id: "jul",
      label: "Jul",
    },
    {
      id: "aug",
      label: "Aug",
    },
    {
      id: "sep",
      label: "Sep",
    },
    {
      id: "oct",
      label: "Oct",
    },
    {
      id: "nov",
      label: "Nov",
    },
    {
      id: "dec",
      label: "Dec",
    },
  ],
});

const tabActiveStyle =
  "px-6 py-2 font-semibold capitalize cursor-pointer  text-black  bg-white  border-b-2 border-primary";
const tabDeActiveStyle =
  "px-6 py-2 font-semibold capitalize cursor-pointer text-black bg-white  border-b-2 border-gray-400";

const activeTypeStyle =
  "py-2 px-10 rounded border border-primary bg-primary text-white cursor-pointer";
const deActiveTypeStyle =
  "py-2 px-10 rounded  hover:border-primary hover:text-primary border bg-white text-gray-500 cursor-pointer ";

function createDataScheme(
  sno,
  geography,
  no_of_linkages,
  no_of_sakhis_providing_linkages,
  total_revenue,
  average_revenue_per_sakhi,
  average_revenue_per_linkage
) {
  return {
    sno,
    geography,
    no_of_linkages,
    no_of_sakhis_providing_linkages,
    total_revenue,
    average_revenue_per_sakhi,
    average_revenue_per_linkage,
  };
}

function createDataSakhi(
  sno,
  geography,
  onboard_no_of_sakhis,
  onboard_no_of_transactions,
  onboard_income_of_sakhis,
  active_no_of_sakhis,
  active_no_of_transactions,
  active_income_of_sakhis,
  // inactive_no_of_sakhis,
  // inactive_no_of_transactions,
  // inactive_income_of_sakhis,
  earning_no_of_sakhis,
  earning_no_of_transactions,
  earning_income_of_sakhis
) {
  return {
    sno,
    geography,
    onboard_no_of_sakhis,
    onboard_no_of_transactions,
    onboard_income_of_sakhis,
    active_no_of_sakhis,
    active_no_of_transactions,
    active_income_of_sakhis,
    //   inactive_no_of_sakhis,
    //   inactive_no_of_transactions,
    //   inactive_income_of_sakhis,
    earning_no_of_sakhis,
    earning_no_of_transactions,
    earning_income_of_sakhis,
  };
}

const staticSchemeReportData = {
  geography: [
    {
      id: 1,
      state_name: "Assam",
      no_of_linkages: 100,
      no_of_sakhis_providing_linkages: 200,
      total_revenue: 300,
      average_revenue_per_sakhi: 100,
      average_revenue_per_linkage: 200,
      scheme: [
        {
          id: 1,
          scheme_name: "Scheme1",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 80,
          average_revenue_per_linkage: 180,
        },
        {
          id: 2,
          scheme_name: "Scheme2",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 80,
          average_revenue_per_linkage: 180,
        },
      ],
    },
    {
      id: 2,
      state_name: "Rajasthan",
      no_of_linkages: 100,
      no_of_sakhis_providing_linkages: 200,
      total_revenue: 300,
      average_revenue_per_sakhi: 100,
      average_revenue_per_linkage: 200,
      scheme: [
        {
          id: 15,
          scheme_name: "Scheme3",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 80,
          average_revenue_per_linkage: 180,
        },
        {
          id: 12,
          scheme_name: "Scheme4",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 80,
          average_revenue_per_linkage: 180,
        },
      ],
    },
  ],
  organisation: [
    {
      id: 3,
      organisation: "Gramin Vikas Trust (GVT)",
      no_of_linkages: 200,
      no_of_sakhis_providing_linkages: 400,
      total_revenue: 600,
      average_revenue_per_sakhi: 200,
      average_revenue_per_linkage: 400,
      scheme: [
        {
          id: 11,
          scheme_name: "Scheme13",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 80,
          average_revenue_per_linkage: 180,
        },
        {
          id: 121,
          scheme_name: "Scheme41",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 80,
          average_revenue_per_linkage: 180,
        },
      ],
    },
    {
      id: 2,
      organisation: "Seven Sisters Development Assistance (SeSTA)",
      no_of_linkages: 200,
      no_of_sakhis_providing_linkages: 400,
      total_revenue: 600,
      average_revenue_per_sakhi: 200,
      average_revenue_per_linkage: 400,
      scheme: [
        {
          id: 5,
          scheme_name: "Scheme5",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 80,
          average_revenue_per_linkage: 180,
        },
        {
          id: 6,
          scheme_name: "Scheme6",
          no_of_linkages: 80,
          no_of_sakhis_providing_linkages: 180,
          total_revenue: 280,
          average_revenue_per_sakhi: 280,
          average_revenue_per_linkage: 180,
        },
      ],
    },
  ],
};

const staticSakhiReportData = {
  geography: [
    {
      id: 1,
      state_name: "Assam",
      onboard_no_of_sakhis: 100,
      onboard_no_of_transaction: 200,
      onboard_income_of_sakhis: 300,
      active_no_of_sakhis: 100,
      active_no_of_transaction: 200,
      active_income_of_sakhis: 300,
      // inactive_no_of_sakhis: 100,
      // inactive_no_of_transaction: 200,
      // inactive_income_of_sakhis: 300,
      earning_no_of_sakhis: 100,
      earning_no_of_transaction: 200,
      earning_income_of_sakhis: 300,
      district: [
        {
          id: 1,
          district_name: "Baksa",
          onboard_no_of_sakhis: 80,
          onboard_no_of_transaction: 180,
          onboard_income_of_sakhis: 280,
          active_no_of_sakhis: 80,
          active_no_of_transaction: 180,
          active_income_of_sakhis: 280,
          // inactive_no_of_sakhis: 80,
          // inactive_no_of_transaction: 180,
          // inactive_income_of_sakhis: 280,
          earning_no_of_sakhis: 80,
          earning_no_of_transaction: 180,
          earning_income_of_sakhis: 280,
          block: [
            {
              id: 5,
              block_name: "Dhamdham",
              onboard_no_of_sakhis: 50,
              onboard_no_of_transaction: 100,
              onboard_income_of_sakhis: 150,
              active_no_of_sakhis: 50,
              active_no_of_transaction: 100,
              active_income_of_sakhis: 150,
              // inactive_no_of_sakhis: 50,
              // inactive_no_of_transaction: 100,
              // inactive_income_of_sakhis: 150,
              earning_no_of_sakhis: 50,
              earning_no_of_transaction: 100,
              earning_income_of_sakhis: 150,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      state_name: "Rajasthan",
      onboard_no_of_sakhis: 100,
      onboard_no_of_transaction: 200,
      onboard_income_of_sakhis: 300,
      active_no_of_sakhis: 100,
      active_no_of_transaction: 200,
      active_income_of_sakhis: 300,
      // inactive_no_of_sakhis: 100,
      // inactive_no_of_transaction: 200,
      // inactive_income_of_sakhis: 300,
      earning_no_of_sakhis: 100,
      earning_no_of_transaction: 200,
      earning_income_of_sakhis: 300,
      district: [
        {
          id: 15,
          district_name: "Alwar",
          onboard_no_of_sakhis: 80,
          onboard_no_of_transaction: 180,
          onboard_income_of_sakhis: 280,
          active_no_of_sakhis: 80,
          active_no_of_transaction: 180,
          active_income_of_sakhis: 280,
          // inactive_no_of_sakhis: 80,
          // inactive_no_of_transaction: 180,
          // inactive_income_of_sakhis: 280,
          earning_no_of_sakhis: 80,
          earning_no_of_transaction: 180,
          earning_income_of_sakhis: 280,
          block: [
            {
              id: 29,
              block_name: "Reni",
              onboard_no_of_sakhis: 50,
              onboard_no_of_transaction: 100,
              onboard_income_of_sakhis: 150,
              active_no_of_sakhis: 50,
              active_no_of_transaction: 100,
              active_income_of_sakhis: 150,
              // inactive_no_of_sakhis: 50,
              // inactive_no_of_transaction: 100,
              // inactive_income_of_sakhis: 150,
              earning_no_of_sakhis: 50,
              earning_no_of_transaction: 100,
              earning_income_of_sakhis: 150,
            },
          ],
        },
      ],
    },
  ],
  organisation: [
    {
      id: 3,
      organisation: "Gramin Vikas Trust (GVT)",
      onboard_no_of_sakhis: 200,
      onboard_no_of_transaction: 400,
      onboard_income_of_sakhis: 600,
      active_no_of_sakhis: 200,
      active_no_of_transaction: 400,
      active_income_of_sakhis: 600,
      // inactive_no_of_sakhis: 200,
      // inactive_no_of_transaction: 400,
      // inactive_income_of_sakhis: 600,
      earning_no_of_sakhis: 200,
      earning_no_of_transaction: 400,
      earning_income_of_sakhis: 600,
      state: [
        {
          id: 2,
          state_name: "Rajasthan",
          onboard_no_of_sakhis: 100,
          onboard_no_of_transaction: 200,
          onboard_income_of_sakhis: 300,
          active_no_of_sakhis: 100,
          active_no_of_transaction: 200,
          active_income_of_sakhis: 300,
          // inactive_no_of_sakhis: 100,
          // inactive_no_of_transaction: 200,
          // inactive_income_of_sakhis: 300,
          earning_no_of_sakhis: 100,
          earning_no_of_transaction: 200,
          earning_income_of_sakhis: 300,
          district: [
            {
              id: 15,
              district_name: "Alwar",
              onboard_no_of_sakhis: 80,
              onboard_no_of_transaction: 180,
              onboard_income_of_sakhis: 280,
              active_no_of_sakhis: 80,
              active_no_of_transaction: 180,
              active_income_of_sakhis: 280,
              // inactive_no_of_sakhis: 80,
              // inactive_no_of_transaction: 180,
              // inactive_income_of_sakhis: 280,
              earning_no_of_sakhis: 80,
              earning_no_of_transaction: 180,
              earning_income_of_sakhis: 280,
              block: [
                {
                  id: 29,
                  block_name: "Reni",
                  onboard_no_of_sakhis: 50,
                  onboard_no_of_transaction: 100,
                  onboard_income_of_sakhis: 150,
                  active_no_of_sakhis: 50,
                  active_no_of_transaction: 100,
                  active_income_of_sakhis: 150,
                  // inactive_no_of_sakhis: 50,
                  // inactive_no_of_transaction: 100,
                  // inactive_income_of_sakhis: 150,
                  earning_no_of_sakhis: 50,
                  earning_no_of_transaction: 100,
                  earning_income_of_sakhis: 150,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      organisation: "Seven Sisters Development Assistance (SeSTA)",
      onboard_no_of_sakhis: 200,
      onboard_no_of_transaction: 400,
      onboard_income_of_sakhis: 600,
      active_no_of_sakhis: 200,
      active_no_of_transaction: 400,
      active_income_of_sakhis: 600,
      // inactive_no_of_sakhis: 200,
      // inactive_no_of_transaction: 400,
      // inactive_income_of_sakhis: 600,
      earning_no_of_sakhis: 200,
      earning_no_of_transaction: 400,
      earning_income_of_sakhis: 600,
      state: [
        {
          id: 1,
          state_name: "Assam",
          onboard_no_of_sakhis: 100,
          onboard_no_of_transaction: 200,
          onboard_income_of_sakhis: 300,
          active_no_of_sakhis: 100,
          active_no_of_transaction: 200,
          active_income_of_sakhis: 300,
          // inactive_no_of_sakhis: 100,
          // inactive_no_of_transaction: 200,
          // inactive_income_of_sakhis: 300,
          earning_no_of_sakhis: 100,
          earning_no_of_transaction: 200,
          earning_income_of_sakhis: 300,
          district: [
            {
              id: 1,
              district_name: "Baksa",
              onboard_no_of_sakhis: 80,
              onboard_no_of_transaction: 180,
              onboard_income_of_sakhis: 280,
              active_no_of_sakhis: 80,
              active_no_of_transaction: 180,
              active_income_of_sakhis: 280,
              // inactive_no_of_sakhis: 80,
              // inactive_no_of_transaction: 180,
              // inactive_income_of_sakhis: 280,
              earning_no_of_sakhis: 80,
              earning_no_of_transaction: 180,
              earning_income_of_sakhis: 280,
              block: [
                {
                  id: 5,
                  block_name: "Dhamdham",
                  onboard_no_of_sakhis: 50,
                  onboard_no_of_transaction: 100,
                  onboard_income_of_sakhis: 150,
                  active_no_of_sakhis: 50,
                  active_no_of_transaction: 100,
                  active_income_of_sakhis: 150,
                  // inactive_no_of_sakhis: 50,
                  // inactive_no_of_transaction: 100,
                  // inactive_income_of_sakhis: 150,
                  earning_no_of_sakhis: 50,
                  earning_no_of_transaction: 100,
                  earning_income_of_sakhis: 150,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
const staticMonthlyReportData = [
  {
    id: 1000,
    key_matrics: "Total Revenue (in INR lakh",
    months: [
      {
        month: 1,
        total: 1.28,
      },
      {
        month: 2,
        total: 2.4,
      },
      {
        month: 3,
        total: 3.8,
      },
      {
        month: 4,
        total: 3.8,
      },
      {
        month: 5,
        total: 3.8,
      },
      {
        month: 6,
        total: 3.8,
      },
      {
        month: 7,
        total: 3.8,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Active Sakhi cadre (#)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 10,
        total: 3658,
      },
      {
        month: 11,
        total: 3658,
      },
      {
        month: 5,
        total: 3658,
      },
      {
        month: 6,
        total: 3658,
      },
      {
        month: 7,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Villages Serviced (#)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 3,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 5,
        total: 3658,
      },
      {
        month: 11,
        total: 3658,
      },
      {
        month: 12,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Earning Sakhi cadre (#)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 3,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 5,
        total: 3658,
      },
      {
        month: 9,
        total: 3658,
      },
      {
        month: 10,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Monthly Average Income (in INR)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 3,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 5,
        total: 3658,
      },
      {
        month: 6,
        total: 3658,
      },
      {
        month: 7,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Monthly Median Income (in INR)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 3,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 5,
        total: 3658,
      },
      {
        month: 6,
        total: 3658,
      },
      {
        month: 7,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Linkages and related service facilitated (#)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 3,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 11,
        total: 3658,
      },
      {
        month: 6,
        total: 3658,
      },
      {
        month: 7,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Schemes & services offered under BoS (#)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 12,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 5,
        total: 3658,
      },
      {
        month: 6,
        total: 3658,
      },
      {
        month: 7,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "SHGs outreached (#)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 3,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 8,
        total: 3658,
      },
      {
        month: 6,
        total: 3658,
      },
      {
        month: 7,
        total: 3658,
      },
    ],
  },
  {
    id: 1001,
    key_matrics: "Unique beneficiary outreached (#)",
    months: [
      {
        month: 1,
        total: 16528,
      },
      {
        month: 2,
        total: 2654,
      },
      {
        month: 3,
        total: 3658,
      },
      {
        month: 4,
        total: 3658,
      },
      {
        month: 8,
        total: 3658,
      },
      {
        month: 6,
        total: 3658,
      },
      {
        month: 7,
        total: 3658,
      },
    ],
  },
];
function monthlyReportCreateData(
  key_matrics,
  jan,
  feb,
  mar,
  apr,
  may,
  jun,
  jul,
  aug,
  sep,
  oct,
  nov,
  dec
) {
  return {
    key_matrics,
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec,
  };
}

export {
  staticMonthlyReportData,
  staticSakhiReportData,
  staticSchemeReportData,
  columnsScheme,
  montlyReportColumn,
  sakhiColumn,
  createDataSakhi,
  createDataScheme,
  monthlyReportCreateData,
  tabActiveStyle,
  tabDeActiveStyle,
  activeTypeStyle,
  deActiveTypeStyle
}