import { Box, Typography } from '@material-ui/core'
import React ,{useEffect,useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AdminMappingCRUDForm from './AdminMappingCRUDForm'
import { useDispatch, useSelector } from 'react-redux'
import { purposeMapperListAction } from 'src/Redux/Actions/PurposeMapperAction'

function AdminMappingCRUDFormView() {
    const [crudAction, setCrudAction] = useState({
        create: false,
        edit: false,
        view: false
    });
    const purposeMapperList = useSelector(state => state.purposeMapperList);
  const {purposeMappers} = purposeMapperList
    const path = window.location.pathname;
    const {id:paramsID} = useParams();
    useEffect(() => {
      if (path === `/app/admin/mapping/create`){
        setCrudAction({
            create: true,
            edit: false,
            view: false
        })
      } else if(path === `/app/admin/mapping/edit/${paramsID}`){
        setCrudAction({
            create: false,
            edit: true,
            view: false
        })
    }else{
        setCrudAction({
            create: false,
            edit: false,
            view: true
        })
    }
    }, [path,paramsID])
    const dispatch = useDispatch()

    useEffect(()=>{
      dispatch(purposeMapperListAction({"id":paramsID}))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])
    
    let purposeMapperDetails = purposeMappers?.data?.find(purpose => purpose.id === Number(paramsID))

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      {/* {loading ? (
        <LoadingBox/>
      ):( */}
      <Box className='w-full h-full'>
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box className="flex justify-center items-center space-x-2">
            <Link to="/app/admin/mapping">
              <ArrowBackIcon />
            </Link>
            <Typography><span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>{crudAction.create && 'Add Purpose'} {crudAction.edit && 'Edit Mapping'} {crudAction.view && 'View Mapping'}</span> </Typography>
          </Box>
        </Box>
        <AdminMappingCRUDForm crudAction={crudAction} purposeMapperDetails={purposeMapperDetails} />
      </Box>
      {/* )} */}
    </Box>
  )
}

export default AdminMappingCRUDFormView