import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CenterDetailsCRUDForm from "./CenterDetailsCRUDForm";
import { useDispatch, useSelector } from "react-redux";
import { centreListAction } from "src/Redux/Actions/CentreAction";
import LoadingBox from "src/components/LoadingBox";
import { fetchWrapper } from "src/services/http_requests";

function CenterDetailsCRUDFormView() {
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const path = window.location.pathname;
  const { id: paramsID } = useParams();
  const { loading, centres } = useSelector((state) => state.centreList);
  useEffect(() => {
    if (path === `/app/centerdetails/create`) {
      setCrudAction({
        create: true,
        edit: false,
        view: false,
        delete: false,
      });
    } else if (path === `/app/centerdetails/edit/${paramsID}`) {
      setCrudAction({
        create: false,
        edit: true,
        view: false,
        delete: false,
      });
    } else if (path === `/app/centerdetails/view/${paramsID}`) {
      setCrudAction({
        create: false,
        edit: false,
        view: true,
        delete: false,
      });
    } else {
      setCrudAction({
        create: false,
        edit: false,
        view: false,
        delete: true,
      });
    }
  }, [path, paramsID]);

  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;

    if (paramsID && mounted) {
      dispatch(centreListAction({ id: paramsID }));
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem('previousUrl',window.location.href)
    return() => (fetchWrapper?.finishPendingRequests())
  },[])

  let updateDetails = centres?.data?.find(
    (data) => Number(data.id) === Number(paramsID)
  );

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      {loading ? (
        <LoadingBox />
      ) : (
        <Box className="w-full h-full">
          <Box
            pb={2}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box className="flex justify-center items-center space-x-2">
              <Link to="/app/centerdetails">
                <ArrowBackIcon />
              </Link>
              <Typography>
                <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                  {crudAction.create && "Create"} {crudAction.edit && "Edit"}{" "}
                  {crudAction.view && "View"} Center
                </span>
              </Typography>
            </Box>
          </Box>
          <CenterDetailsCRUDForm
            crudAction={crudAction}
            updateDetails={updateDetails}
          />
        </Box>
      )}
    </Box>
  );
}

export default CenterDetailsCRUDFormView;
