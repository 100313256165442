export const MOU_LIST_REQUEST = 'MOU_LIST_REQUEST'
export const MOU_LIST_SUCCESS = 'MOU_LIST_SUCCESS'
export const MOU_LIST_FAIL = 'MOU_LIST_FAIL'

export const MOU_CREATE_FAIL = 'MOU_CREATE_FAIL'
export const MOU_CREATE_REQUEST = 'MOU_CREATE_REQUEST'
export const MOU_CREATE_SUCCESS = 'MOU_CREATE_SUCCESS'

export const MOU_UPDATE_REQUEST = 'MOU_UPDATE_REQUEST'
export const MOU_UPDATE_SUCCESS = 'MOU_UPDATE_SUCCESS'
export const MOU_UPDATE_FAIL = 'MOU_UPDATE_FAIL'

export const MOU_DELETE_REQUEST = 'MOU_DELETE_REQUEST'
export const MOU_DELETE_SUCCESS = 'MOU_DELETE_SUCCESS'
export const MOU_DELETE_FAIL = 'MOU_DELETE_FAIL'