export const  DISTRICT_LIST_REQUEST = 'DISTRICT_LIST_REQUEST'
export const DISTRICT_LIST_SUCCESS = 'DISTRICT_LIST_SUCCESS'
export const DISTRICT_LIST_FAIL = 'DISTRICT_LIST_FAIL'

export const DISTRICT_CREATE_FAIL = 'DISTRICT_CREATE_FAIL'
export const DISTRICT_CREATE_REQUEST = 'DISTRICT_CREATE_REQUEST'
export const DISTRICT_CREATE_SUCCESS = 'DISTRICT_CREATE_SUCCESS'

export const DISTRICT_UPDATE_REQUEST = 'DISTRICT_UPDATE_REQUEST'
export const DISTRICT_UPDATE_SUCCESS = 'DISTRICT_UPDATE_SUCCESS'
export const DISTRICT_UPDATE_FAIL = 'DISTRICT_UPDATE_FAIL'

export const DISTRICT_DELETE_REQUEST = 'DISTRICT_DELETE_REQUEST'
export const DISTRICT_DELETE_SUCCESS = 'DISTRICT_DELETE_SUCCESS'
export const DISTRICT_DELETE_FAIL = 'DISTRICT_DELETE_FAIL'