import {
  LIST_REQUEST,
  FETCHED_DATA_SUCCESS,
  GET_FAILURE
} from './actionType';

const initState = {
  isLoading: false,
  redirectToNewPage: false,
  isPasswordChange: true,
  isDeleted: false,
  data: [],
  total: '',
  filters: {
    page: 1
  },
};

export const PAReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        redirectToNewPage: false,
        isDeleted: false,
        filters: payload
      };
    case FETCHED_DATA_SUCCESS:
      let obj = {
        ...state,
        isLoading: false,
        data: payload.data,
        isPasswordChange: payload.isPasswordChange,
        redirectToNewPage: false,
        isDeleted: false,
      };
      if (payload.count !== undefined) {
        obj['total'] = payload.count;
      }
      return obj;
    case GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        redirectToNewPage: false,
        isDeleted: false,
      };
    default:
      return state;
  }
};
