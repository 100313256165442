import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { authReducer } from './Auth/reducer';
import thunk from 'redux-thunk';
import { PAReducer } from './PageAuthentication/Reducer';
import { stateCreateReducer, stateDeleteReducer, stateListReducer, stateUpdateReducer } from './Reducers/StateReducer';
import { purposeCreateReducer, purposeDeleteReducer, purposeListReducer, purposeUpdateReducer } from './Reducers/PurposeReducer';
import { ngoCreateReducer, ngoDeleteReducer, ngoListReducer, ngoUpdateReducer } from './Reducers/NgoReducer';
import { purposeMapperCreateReducer, purposeMapperDeleteReducer, purposeMapperListReducer, purposeMapperUpdateReducer } from './Reducers/PurposeMapperReducer';
import { blockCreateReducer, blockDeleteReducer, blockListReducer, blockUpdateReducer } from './Reducers/BlockReducer';
import { villageCreateReducer, villageDeleteReducer, villageListReducer, villageUpdateReducer } from './Reducers/VillageReducer';
import { centreCreateReducer, centreDeleteReducer, centreListReducer, centreUpdateReducer } from './Reducers/CentreReducer';
import { roleCreateReducer, roleListReducer } from './Reducers/RoleReducer';
import { districtCreateReducer, districtDeleteReducer, districtListReducer, districtUpdateReducer } from './Reducers/DistrictReducer';
import { userCreateReducer, userListReducer } from './Reducers/UserReducer';
import { schemeCreateReducer, schemeDeleteReducer, schemeListReducer, schemeUpdateReducer } from './Reducers/SchemeReducer';
import { sakhiTransactionListReducer } from './Reducers/SakhiTransactionReducer';
import { trainKitCreateReducer, trainKitDeleteReducer, trainKitListReducer, trainKitUpdateReducer } from './Reducers/TrainingKitReducer';
import { milestoneListReducer } from './Reducers/MilestoneReducer';
import { mouListReducer } from './Reducers/MOUReducer';
import { lineItemListReducer } from './Reducers/LineItemReducer';

const rootReducer = combineReducers({
  statesList: stateListReducer,
  stateCreate: stateCreateReducer,
  stateUpdate: stateUpdateReducer,
  stateDelete: stateDeleteReducer,
  purposeList: purposeListReducer,
  purposeCreate: purposeCreateReducer,
  purposeUpdate: purposeUpdateReducer,
  purposeDelete: purposeDeleteReducer,
  ngoList: ngoListReducer,
  ngoCreate: ngoCreateReducer,
  ngoUpdate: ngoUpdateReducer,
  ngoDelete: ngoDeleteReducer,
  purposeMapperList: purposeMapperListReducer,
  purposeMapperCreate: purposeMapperCreateReducer,
  purposeMapperUpdate: purposeMapperUpdateReducer,
  purposeMapperDelete: purposeMapperDeleteReducer,
  blockList: blockListReducer,
  blockCreate: blockCreateReducer,
  blockUpdate: blockUpdateReducer,
  blockDelete: blockDeleteReducer,
  trainKitList: trainKitListReducer,
  trainKitCreate: trainKitCreateReducer,
  trainKitUpdate: trainKitUpdateReducer,
  trainKitDelete: trainKitDeleteReducer,
  schemeList: schemeListReducer,
  schemeCreate: schemeCreateReducer,
  schemeUpdate: schemeUpdateReducer,
  schemeDelete: schemeDeleteReducer,
  villageList: villageListReducer,
  villageCreate: villageCreateReducer,
  villageUpdate: villageUpdateReducer,
  villageDelete: villageDeleteReducer,
  centreList: centreListReducer,
  centreCreate: centreCreateReducer,
  centreUpdate: centreUpdateReducer,
  centreDelete: centreDeleteReducer,
  roleList: roleListReducer,
  roleCreate: roleCreateReducer,
  districtList: districtListReducer,
  districtCreate: districtCreateReducer,
  districtUpdate: districtUpdateReducer,
  districtDelete: districtDeleteReducer,
  userCreate: userCreateReducer,
  userList: userListReducer,
  sakhiTransactionList: sakhiTransactionListReducer,
  auth: authReducer,
  PageAuthentication: PAReducer,
  milestoneList: milestoneListReducer,
  mouList: mouListReducer,
  lineItemList: lineItemListReducer
});
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(rootReducer, enhancer);
