import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"


const { DISTRICT_LIST_REQUEST, DISTRICT_LIST_SUCCESS, DISTRICT_LIST_FAIL, DISTRICT_CREATE_REQUEST, DISTRICT_CREATE_FAIL, DISTRICT_CREATE_SUCCESS, DISTRICT_UPDATE_REQUEST, DISTRICT_UPDATE_FAIL, DISTRICT_DELETE_REQUEST, DISTRICT_DELETE_FAIL, DISTRICT_UPDATE_SUCCESS, DISTRICT_DELETE_SUCCESS } = require("../Conistance/DistrictConistance")

const BASE_URL = process.env.REACT_APP_API_URL

export const districtListAction = (filter) => async (dispatch) => {
    dispatch({ type: DISTRICT_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/district/list${paramsUrl(filter)}`);
        dispatch({ type: DISTRICT_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: DISTRICT_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const districtCreateAction = (body, refetchDataList) => async (dispatch) => {
    dispatch({ type: DISTRICT_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/district/create`, JSON.stringify(body))
        dispatch({ type: DISTRICT_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        console.log('action e', error)
        dispatch({ type: DISTRICT_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const districtUpdateAction = (districtId, body, refetchDataList) => async (dispatch) => {
    dispatch({ type: DISTRICT_UPDATE_REQUEST })
    try {

        const data = await fetchWrapper.put(`${BASE_URL}/district/update/${districtId}`, JSON.stringify(body))
        dispatch({ type: DISTRICT_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: DISTRICT_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const districtDeleteAction = (districtId, refetchDataList) => async (dispatch) => {
    dispatch({ type: DISTRICT_DELETE_REQUEST })
    try {

        const data = await fetchWrapper.put(`${BASE_URL}/district/delete/${districtId}`)
        dispatch({ type: DISTRICT_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: DISTRICT_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}