export const SAKHI_TRANSACTION_CREATE_FAIL = 'SAKHI_TRANSACTION_CREATE_FAIL'
export const SAKHI_TRANSACTION_CREATE_REQUEST = 'SAKHI_TRANSACTION_CREATE_REQUEST'
export const SAKHI_TRANSACTION_CREATE_SUCCESS = 'SAKHI_TRANSACTION_CREATE_SUCCESS'


export const SAKHI_TRANSACTION_LIST_FAIL = 'SAKHI_TRANSACTION_LIST_FAIL'
export const SAKHI_TRANSACTION_LIST_REQUEST = 'SAKHI_TRANSACTION_LIST_REQUEST'
export const SAKHI_TRANSACTION_LIST_SUCCESS = 'SAKHI_TRANSACTION_LIST_SUCCESS'

export const SAKHI_TRANSACTION_UPDATE_FAIL = 'SAKHI_TRANSACTION_UPDATE_FAIL'
export const SAKHI_TRANSACTION_UPDATE_REQUEST = 'SAKHI_TRANSACTION_UPDATE_REQUEST'
export const SAKHI_TRANSACTION_UPDATE_SUCCESS = 'SAKHI_TRANSACTION_UPDATE_SUCCESS'

export const SAKHI_TRANSACTION_DELETE_FAIL = 'SAKHI_TRANSACTION_DELETE_FAIL'
export const SAKHI_TRANSACTION_DELETE_REQUEST = 'SAKHI_TRANSACTION_DELETE_REQUEST'
export const SAKHI_TRANSACTION_DELETE_SUCCESS = 'SAKHI_TRANSACTION_DELETE_SUCCESS'