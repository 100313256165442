export const CENTRE_LIST_REQUEST = 'CENTRE_LIST_REQUEST'
export const CENTRE_LIST_SUCCESS = 'CENTRE_LIST_SUCCESS'
export const CENTRE_LIST_FAIL = 'CENTRE_LIST_FAIL'

export const CENTRE_CREATE_FAIL = 'CENTRE_CREATE_FAIL'
export const CENTRE_CREATE_REQUEST = 'CENTRE_CREATE_REQUEST'
export const CENTRE_CREATE_SUCCESS = 'CENTRE_CREATE_SUCCESS'

export const CENTRE_UPDATE_REQUEST = 'CENTRE_UPDATE_REQUEST'
export const CENTRE_UPDATE_SUCCESS = 'CENTRE_UPDATE_SUCCESS'
export const CENTRE_UPDATE_FAIL = 'CENTRE_UPDATE_FAIL'

export const CENTRE_DELETE_REQUEST = 'CENTRE_DELETE_REQUEST'
export const CENTRE_DELETE_SUCCESS = 'CENTRE_DELETE_SUCCESS'
export const CENTRE_DELETE_FAIL = 'CENTRE_DELETE_FAIL'