import { Box, Typography } from '@material-ui/core'
import React ,{useEffect,useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import FoSakhiMappingCRUDForm from './FoSakhiMappingCRUDForm';

function FoSakhiMappingCRUDFormView() {
    const [crudAction, setCrudAction] = useState({
        create: false,
        edit: false,
        view: false
    });
    const path = window.location.pathname;
    const {id:paramsID} = useParams();
    useEffect(() => {
      if (path === `/app/mapping/fosakhimapping/create`){
        setCrudAction({
            create: true,
            edit: false,
            view: false
        })
      } else if(path === `/app/mapping/fosakhimapping/edit/${paramsID}`){
        setCrudAction({
            create: false,
            edit: true,
            view: false
        })
    }else{
        setCrudAction({
            create: false,
            edit: false,
            view: true
        })
    }
    }, [path,paramsID])

    
  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box className='w-full h-full'>
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box className="flex justify-center items-center space-x-2">
            <Link to="/app/mapping/fosakhimapping">
              <ArrowBackIcon />
            </Link>
            <Typography ><span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>{crudAction.create && 'Create'} {crudAction.edit && 'Edit'} {crudAction.view && 'View'} FO Mapping</span></Typography>
          </Box>
        </Box>
        <FoSakhiMappingCRUDForm crudAction={crudAction} />
      </Box>
    </Box>
  )
}

export default FoSakhiMappingCRUDFormView