import { toast } from "react-toastify";
import { fetchWrapper } from "src/services/http_requests";
import { paramsUrl } from "src/utils/halper";
import { SAKHI_TRANSACTION_LIST_FAIL, SAKHI_TRANSACTION_LIST_REQUEST, SAKHI_TRANSACTION_LIST_SUCCESS } from "../Conistance/SakhiTransactionConistance"

const BASE_URL = process.env.REACT_APP_API_URL

export const sakhiTransationListAction = (filter) => async (dispatch)=>{
    dispatch({type: SAKHI_TRANSACTION_LIST_REQUEST});
    try{
        const data = await fetchWrapper.get(`${BASE_URL}/beneficiary_transaction/list${paramsUrl(filter)}`)
        dispatch({type: SAKHI_TRANSACTION_LIST_SUCCESS, payload: data})
    }catch(error){
        dispatch({type: SAKHI_TRANSACTION_LIST_FAIL, payload: error})
        toast.error(error)
    }
}