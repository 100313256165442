import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import FilterComponent from 'src/components/CommonComponents/FilterComponent'
import { fetchApiDataList, fetchWrapper } from 'src/services/http_requests'
import TargetTable from './targetTable/targetTable'
import { ngoListAction } from 'src/Redux/Actions/NgoAction'
import { Grid } from 'react-feather'

const Target = () => {
    const { ngos } = useSelector((state) => state.ngoList);
    const [filterData, setFilterData] = useState({
        ngo_id: "", po_number: ""
    });
    const [poNumbers, setPoNumbers] = useState(undefined);
    const [targetData, setTargetData] = useState({});
    const [targetLoading, setTargetLoading] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0);
        !ngos && dispatch(ngoListAction({ fetched: "All" }));
    }, []);

    const handleFilterSearch = async () => {
        setTargetLoading(true);
        setTargetData({});
        try {
            fetchApiDataList("mou_target", { ngo_id: filterData?.ngo_id, mou_id: filterData.po_number }, setTargetData);
            setTargetLoading(false);
        } catch (error) {
            setTargetLoading(false);
            throw error;
        }
    };

    const handleRestFilter = () => {
        setFilterData({ mou_id: "", ngo_id: "" });
        setTargetData({});
    };

    const filterInputData = [
        {
            lable: "NGO",
            name: "ngo_id",
            type: "autocompleted",
            multiple: false,
            placeholder: "Enter",
            options: ngos?.data || [],
        },
        {
            lable: "PO Number",
            name: "po_number",
            type: "autocompleted",
            multiple: false,
            placeholder: "Enter",
            options: poNumbers || undefined
        },
    ];

    useEffect(() => {
        if (filterData?.ngo_id) {
            setPoNumbers(undefined);
            const getPoNumberList = async () => {
                try {
                    const { data } = await fetchWrapper.get(
                        `${process.env.REACT_APP_API_URL}/mou/getListPONumber?ngo_id=${filterData?.ngo_id}`
                    );
                    const newData = data?.map(elem => {
                        return elem = { id: elem?.id, name: elem?.po_number };
                    });
                    setPoNumbers(newData);
                }
                catch (error) {
                    toast.error(error);
                    throw (error);
                }
            };
            getPoNumberList();
        } else setPoNumbers(undefined);
    }, [filterData.ngo_id]);

    return (
        <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
            <Box width="100%" height="100%">
                <Box
                    pb={2}
                    width="100%"
                    display="flex"
                    flexdirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>
                        <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                            Masters / Add & Edit Target
                        </span>
                    </Typography>

                </Box>
                <Divider style={{ maxWidth: "100%" }} />
                <FilterComponent
                    handleFilterSearch={handleFilterSearch}
                    handleRestFilter={handleRestFilter}
                    commonFilterData={filterInputData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
                {targetLoading ?
                    <Box className="w-full " sx={{ width: "100%" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="w-full  flex flex-col justify-start items-center">
                                <div className="w-full h-16 flex bg-slate-100 rounded "></div>
                                <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                                <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                                <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                                <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                                <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                                <div className="w-full h-10 flex justify-end items-center  rounded border border-gray-100">
                                    <div className="w-3/12 h-full border border-gray-100 bg-white rounded"></div>
                                </div>
                            </div>
                        </Grid>
                    </Box> :
                    Object.keys(targetData)?.length > 0 ? <TargetTable
                        data={targetData}
                    /> : null
                }
            </Box>
        </Box>
    )
}

export default Target;