import { Button, Divider, Box, Typography, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import StickyHeadTable from "src/components/CommonComponents/Table";
import FilterComponent from "src/components/CommonComponents/FilterComponent";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { adminUsersCommonFilterData } from "src/utils/filterInputData";
import { useSelector } from "react-redux";
import LoadingBox from "src/components/LoadingBox";
import { userListAction } from "src/Redux/Actions/UserAction";
import { fetchApiDataList, fetchWrapper } from "src/services/http_requests";
import { generateSerialNo } from "src/utils";
import { toast } from "react-toastify/dist";
import { filterUserList, paramsUrl } from "src/utils/halper";
import {
  Add,
  DeleteOutlineOutlined,
  EditOutlined,
  FilterList,
  VisibilityOutlined,
} from "@material-ui/icons";
import theme from "src/theme";
import Drawer from "src/components/CommonComponents/Drawer";
import AdminUsersCRUDForm from "./AdminUsersCRUDForm/AdminUsersCRUDForm";
import DialogView from "src/components/CommonComponents/DialogView";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import { fetchFoSakhis } from '../../views/fo_details/fetchAllData';
import { stateListAction } from "src/Redux/Actions/StateAction";

const columns = [
  { id: "sno", label: "S.No" },
  { id: "name", label: "Name" },
  { id: "username", label: "Username" },
  { id: "email", label: "Email", },
  { id: "state", label: "State", },
  { id: "district", label: "District", },
  { id: "block", label: "Block", },
  { id: "ngoName", label: "NGO Name" },
  { id: "foName", label: "FO Name" },
  { id: "sakhiCount", label: "No. of Sakhis", },
  { id: "onBoardingyear", label: "Onboarding Year", },
  { id: "status", label: "Status", },
  { id: "job_profile", label: "Secondary Job", },
  { id: "action", label: "Action", },
];
const sakhiColumns = [
  { id: "sno", label: "S.No" },
  { id: "sakhiName", label: "Sakhi Name" },
  { id: "village", label: "Village", },
];

function createData(
  sno,
  ngoName,
  foName,
  name,
  username,
  email,
  district,
  village,
  state,
  block,
  sakhiCount,
  onBoardingyear,
  action,
  status,
  job_profile
) {
  return {
    sno,
    ngoName,
    foName,
    name,
    username,
    email,
    district,
    village,
    state,
    block,
    sakhiCount,
    onBoardingyear,
    action,
    status,
    job_profile
  };
};

function createSakhiData(sno, sakhiName, village) {
  return { sno, sakhiName, village };
}

function AdminUsersView() {
  const previousUrl = localStorage.getItem("previousUrl");
  const previousSelectedUser = JSON.parse(localStorage.getItem("selectedUser")) || {};
  const pageInfo = localStorage.getItem("pageInfo");
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const [userDetail, setUserDetail] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedUser, setSelectedUser] = useState(previousSelectedUser);
  const [filterData, setFilterData] = useState({
    ngoName: "",
    foName: "",
    name: "",
    state: "",
    district: "",
    block: "",
    year: ""
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(() => pageInfo ? parseInt(pageInfo) : 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usersData, setUsersData] = useState([]);
  const [userFilteredList, setUserFilteredList] = useState([]);
  const [ngoList, setNgoList] = useState([]);
  const [usersFO, setUsersFO] = useState(undefined);
  const [showFilter, setShowFilter] = useState(false);
  const [sakhiCountId, setSakhiCountId] = useState(null);
  const [sakhiPopupOpen, setSakhiPopupOpen] = useState(false);
  const [sakhiListData, setSakhiListData] = useState([]);
  const [sakhiListLength, setSakhiListLength] = useState(0);
  const [sakhiPage, setSakhiPage] = useState(1);
  const [districtList, setDistrictList] = useState(undefined);
  const [blockList, setBlockList] = useState(undefined);

  const handleCloseView = () => {
    setOpenView(false);
    setUserDetail({});
  };
  const { loading, users } = useSelector((state) => state.userList);
  const { ngos } = useSelector((state) => state?.ngoList);
  const { states } = useSelector((state) => state.statesList);

  useEffect(() => { if (ngos) setNgoList(ngos?.data) }, [ngos]);
  useEffect(() => { if (usersData) setUserFilteredList(usersData) }, [usersData]);

  const filterInputData = adminUsersCommonFilterData.map((data) =>
    data?.name === "name"
      ? { ...data, options: userFilteredList }
      : data?.name === "ngoName"
        ? { ...data, options: ngoList }
        : data?.name === "foName"
          ? { ...data, options: usersFO }
          : data?.name === "state"
            ? { ...data, options: states && states?.data }
            : data?.name === "district"
              ? { ...data, options: districtList }
              : data?.name === "block"
                ? { ...data, options: blockList }
                : data
  );

  const fetchFoUsers = async (filter) => {
    try {
      const data = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL}/user/list${paramsUrl(filter)}`
      );
      setUsersFO(data?.data);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFilterSearch = () => {
    fetchWrapper?.finishPendingRequests();
    if (page == 1) {
      let filterDataObject = {
        role_id: selectedUser?.fo ? 3 : selectedUser?.sakhi ? 4 : 2,
        name: usersData.find(elem => elem.id === filterData?.name)?.name,
        ngo_id: filterData?.ngoName,
        fo_id: selectedUser?.sakhi ? filterData?.foName : "",
        state_id: selectedUser?.ngo ? "" : filterData?.state,
        district_id: selectedUser?.ngo ? "" : filterData?.district,
        block_id: selectedUser?.ngo ? "" : filterData?.block,
        year: selectedUser?.sakhi ? filterData.year : "",
        page: 1
      }
      dispatch(userListAction(filterDataObject));
    } else { localStorage.setItem("pageInfo", page); setPage(1); }
  };
  const handleRestFilter = () => {
    setFilterData({
      name: "",
      foName: "",
      ngoName: "",
      state: "",
      district: "",
      block: "",
      year: ""
    });
    if (page == 1) dispatch(userListAction({ role_id: selectedUser?.fo ? 3 : selectedUser?.sakhi ? 4 : 2, page: 1 }));
    else { localStorage.setItem("pageInfo", page); setPage(1); }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userListAction({
      role_id: selectedUser?.ngo
        ? 2
        : selectedUser?.fo
          ? 3
          : selectedUser?.sakhi
            ? 4
            : 1,
      ngo_id: filterData?.ngoName,
      fo_id: filterData?.foName,
      name: filterData.name,
      state_id: filterData.state,
      district_id: filterData.district,
      block_id: filterData.block,
      year: filterData.year,
      page: page
    }));
  }, [page, selectedUser]);

  useEffect(() => {
    if (users?.total) {
      setTotal(users?.total);
      localStorage.setItem("totalData", users?.total);
    } else {
      if (page == 1) {
        setTotal(users?.data?.length);
        localStorage.setItem("totalData", users?.data?.length);
      } else setTotal(localStorage.getItem("totalData"));
    }
  }, [users]);

  useEffect(() => {
    if (sakhiListData?.total) {
      setSakhiListLength(sakhiListData?.total);
    }
  }, [sakhiListData]);

  useEffect(() => {
    if (ngos === undefined || ngos === null) dispatch(ngoListAction({ fetched: "All" }));
    !states && dispatch(stateListAction({ fetched: "All" }));

    // setPage(1);
    window.scrollTo(0, 0);
    return () => {
      // localStorage.setItem('selectedUser', JSON.stringify(selectedUser))
      fetchWrapper?.finishPendingRequests();
    };
  }, []);

  useEffect(() => {
    fetchApiDataList("user", {
      role_id: selectedUser?.ngo ? 2
        : selectedUser?.fo ? 3
          : selectedUser?.sakhi ? 4
            : 2,
      fetched: "All",
    }, setUsersData);
  }, [selectedUser]);

  useEffect(() => {
    if (
      previousUrl
        ?.split("/")
        ?.slice(0, -2)
        ?.join("/") === window.location.href || previousUrl === window.location.href
    ) {
      setPage(parseInt(pageInfo));
    } else {
      setPage(parseInt(1));
      localStorage.setItem("previousUrl", window.location.href);
      localStorage.setItem("pageInfo", 1);
    }
  }, [pageInfo]);

  useEffect(() => () => {
    localStorage.setItem("selectedUser", JSON.stringify({ ngo: true, fo: false, sakhi: false, }));
  }, []);
  const createActiveStatus = (status) => {
    if (parseInt(status) === 0) return "Inactive";
    return "Active";
  };

  const rows = useMemo(() => {
    return users?.data?.map((item, i) => {
      return selectedUser?.ngo
        ? createData(
          generateSerialNo(i, page),
          item?.ngo_name,
          "",
          item?.name,
          item?.username,
          item?.email,
          "",
          "",
          "",
          "",
          "",
          "",
          <Box className="space-x-3 z-50">
            {selectedUser?.ngo ? (
              // <Link to={`/app/admin/users/edit/${item?.id}`}>
              <button
                className="cursor-pointer"
                onClick={(event) => {
                  setUserDetail(item);
                  setCrudAction({
                    create: false,
                    edit: true,
                    view: false,
                    delete: false,
                  });
                  setOpenDrawer(true);
                  event.stopPropagation();
                }}
              >
                <EditOutlined color="primary" />
              </button>
            ) : // </Link>
              null}
            <button
              className="cursor-pointer"
              onClick={(event) => {
                setUserDetail(item);
                setCrudAction({
                  create: false,
                  edit: false,
                  view: true,
                  delete: false,
                });
                // setOpenDrawer(true);
                setOpenView(true);
              }}
            >
              {/* <Link to={`/app/admin/users/view/${item?.id}`}> */}
              <VisibilityOutlined color="primary" />
              {/* </Link> */}
            </button>
            {selectedUser?.ngo ? (
              <button
                className="cursor-pointer"
                onClick={(event) => {
                  setUserDetail(item);
                  setCrudAction({
                    create: false,
                    edit: false,
                    view: false,
                    delete: true,
                  });
                  setOpenDrawer(true);
                  event.stopPropagation();
                }}
              >
                {/* <Link to={`/app/admin/users/delete/${item?.id}`}> */}
                <DeleteOutlineOutlined color="primary" />
                {/* </Link> */}
              </button>
            ) : null}
          </Box>
        ) : selectedUser?.fo
          ? createData(
            generateSerialNo(i, page),
            item?.ngo_name,
            "",
            item?.name,
            "",
            "",
            item?.district_name,
            "",
            item?.state_name,
            item?.block_name,
            <button
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                setSakhiCountId(item?.id);
                setSakhiPopupOpen(true);
              }}
            >
              {item?.sakhi_count}
            </button>,
            "",
            <Box Box className="space-x-3 z-50" >
              {
                selectedUser?.ngo ? (
                  // <Link to={`/app/admin/users/edit/${item?.id}`}>
                  <button
                    className="cursor-pointer"
                    onClick={(event) => {
                      setUserDetail(item);
                      setCrudAction({
                        create: false,
                        edit: true,
                        view: false,
                        delete: false,
                      });
                      setOpenDrawer(true);
                      event.stopPropagation();
                    }
                    }
                  >
                    <EditOutlined color="primary" />
                  </button >
                ) : // </Link>
                  null}
              <button
                className="cursor-pointer"
                onClick={(event) => {
                  setUserDetail(item);
                  setCrudAction({
                    create: false,
                    edit: false,
                    view: true,
                    delete: false,
                  });
                  // setOpenDrawer(true);
                  setOpenView(true);
                }}
              >
                {/* <Link to={`/app/admin/users/view/${item?.id}`}> */}
                <VisibilityOutlined color="primary" />
                {/* </Link> */}
              </button>
              {selectedUser?.ngo ? (
                <button
                  className="cursor-pointer"
                  onClick={(event) => {
                    setUserDetail(item);
                    setCrudAction({
                      create: false,
                      edit: false,
                      view: false,
                      delete: true,
                    });
                    setOpenDrawer(true);
                    event.stopPropagation();
                  }}
                >
                  {/* <Link to={`/app/admin/users/delete/${item?.id}`}> */}
                  <DeleteOutlineOutlined color="primary" />
                  {/* </Link> */}
                </button>
              ) : null}
            </Box>,
            createActiveStatus(item.is_active),
          ) : selectedUser?.sakhi
            ? createData(
              generateSerialNo(i, page),
              item?.ngo_name,
              item?.sakhi_fo_name,
              item?.name,
              "",
              "",
              item?.district_name,
              item?.village_name,
              item?.state_name,
              item?.block_name,
              "",
              new Date(item?.date_of_joining).getFullYear(),
              // item?.role_name,
              <Box className="space-x-3 z-50">
                {selectedUser?.ngo ? (
                  // <Link to={`/app/admin/users/edit/${item?.id}`}>
                  <button
                    className="cursor-pointer"
                    onClick={(event) => {
                      setUserDetail(item);
                      setCrudAction({
                        create: false,
                        edit: true,
                        view: false,
                        delete: false,
                      });
                      setOpenDrawer(true);
                      event.stopPropagation();
                    }}
                  >
                    <EditOutlined color="primary" />
                  </button>
                ) : // </Link>
                  null}
                <button
                  className="cursor-pointer"
                  onClick={(event) => {
                    setUserDetail(item);
                    setCrudAction({
                      create: false,
                      edit: false,
                      view: true,
                      delete: false,
                    });
                    // setOpenDrawer(true);
                    setOpenView(true);
                  }}
                >
                  {/* <Link to={`/app/admin/users/view/${item?.id}`}> */}
                  <VisibilityOutlined color="primary" />
                  {/* </Link> */}
                </button>
                {selectedUser?.ngo ? (
                  <button
                    className="cursor-pointer"
                    onClick={(event) => {
                      setUserDetail(item);
                      setCrudAction({
                        create: false,
                        edit: false,
                        view: false,
                        delete: true,
                      });
                      setOpenDrawer(true);
                      event.stopPropagation();
                    }}
                  >
                    {/* <Link to={`/app/admin/users/delete/${item?.id}`}> */}
                    <DeleteOutlineOutlined color="primary" />
                    {/* </Link> */}
                  </button>
                ) : null}
              </Box>,
              createActiveStatus(item.is_active),
              !item.job_profile ? "No" : JSON.parse(item.job_profile)?.length > 0 ? "Yes" : "No"
            )
            : null;
    });
  }, [users, selectedUser]);

  useEffect(() => {
    if (sakhiCountId) {
      fetchFoSakhis({ role_id: 4, fo_id: sakhiCountId, page: sakhiPage }, setSakhiListData);
    }
  }, [sakhiCountId, sakhiPage]);

  const sakhiRows = sakhiListData?.data?.map((item, i) => {
    return createSakhiData(
      generateSerialNo(i, sakhiPage),
      item?.name,
      item?.village_name
    )
  });

  const refetchUserList = () => {
    handleRestFilter();
    setOpenDrawer(false);
  };

  const handleViewDialog = (id) => {
    setCrudAction({
      create: false,
      edit: false,
      view: true,
      delete: false,
    });
    // setOpenDrawer(true);
    setOpenView(true);
  };

  useEffect(() => {
    if (filterData.state) {
      fetchApiDataList('district', { state_id: filterData.state, fetched: "All" }, setDistrictList);
      setNgoList(filterUserList(ngos?.data, "state_id", filterData.state));
      setFilterData({ ...filterData, ngoName: "" });
      setUserFilteredList(filterUserList(usersData, "state_id", filterData.state));
    } else {
      setDistrictList(undefined);
      setNgoList(ngos?.data);
      setFilterData({ ...filterData, ngoName: "", foName: "" })
      setUserFilteredList(usersData);
    }
  }, [filterData.state]);

  useEffect(() => {
    if (filterData.district) {
      fetchApiDataList('block', { state_id: filterData.state, district_id: filterData.district, fetched: "All" }, setBlockList);
      setNgoList(filterUserList(ngos?.data, "district_id", filterData.district));
      setFilterData({ ...filterData, ngo_id: "" });
      setUserFilteredList(filterUserList(usersData, "district_id", filterData.district));
    } else {
      setBlockList(undefined);
      if (selectedUser.ngo === false) {
        if (filterData.state) {
          setNgoList(filterUserList(ngos?.data, "state_id", filterData.state));
          setFilterData({ ...filterData, ngo_id: "" });
          setUserFilteredList(filterUserList(usersData, "state_id", filterData.state));
        } else setUserFilteredList(usersData);
      };
    };
  }, [filterData.district]);

  useEffect(() => {
    if (filterData.block) {
      setUserFilteredList(filterUserList(usersData, "block_id", filterData.block));
    } else {
      if (filterData.district) setUserFilteredList(filterUserList(usersData, "district_id", filterData.district));
    }
  }, [filterData.block]);

  useEffect(() => {
    if (filterData?.ngoName) {
      if (selectedUser?.sakhi) fetchFoUsers({ role_id: 3, state_id: filterData.state, district_id: filterData.district, block_id: filterData.block, ngo_id: filterData.ngoName, fetched: "All" });

      if (filterData.state === "") setUserFilteredList(filterUserList(usersData, "ngo_id", filterData.ngoName));
      else { fetchApiDataList("user", { role_id: selectedUser?.ngo ? 2 : selectedUser?.fo ? 3 : selectedUser?.sakhi ? 4 : 2, ngo_id: filterData.ngoName, state_id: filterData.state, district_id: filterData.district, block_id: filterData.block, fetched: "All", }, setUserFilteredList); }
    } else {
      if (selectedUser.ngo == false) {
        if (selectedUser.sakhi) setUsersFO(undefined);
        if (filterData.block) setUserFilteredList(filterUserList(usersData, "block_id", filterData.block));
        else if (filterData.district) setUserFilteredList(filterUserList(usersData, "district_id", filterData.district));
        else if (filterData.state) setUserFilteredList(filterUserList(usersData, "state_id", filterData.state));
        else setUserFilteredList(usersData);

      }
    }
  }, [filterData.ngoName]);

  useEffect(() => {
    if (openDrawer == false && userDetail) setUserDetail({});
  }, [openDrawer])

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">

      <Box width="100%" height="100%">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography>
            <span className="capitalize cursor-pointer font-semibold  text-md md:text-lg lg:text-xl text-black">
              User Details
            </span>
          </Typography>
          <Box className="space-x-2 ">
            {/* <Button
                variant="contained"
                size="small"
                // color="primary"
                style={{
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                }}
              >
                <Replay color="primary" />
              </Button> */}
            <Button
              variant="contained"
              size="small"
              startIcon={<FilterList color="primary" />}
              onClick={() => setShowFilter(!showFilter)}
              // color="primary"
              style={{
                backgroundColor: "white",
                color: theme.palette.primary.main,
              }}
            >
              Filter
            </Button>
            {selectedUser?.ngo ? (
              <Button
                variant="contained"
                size="small"
                endIcon={<Add />}
                color="primary"
                onClick={() => {
                  setUserDetail({});
                  setCrudAction({
                    create: true,
                    edit: false,
                    view: false,
                    delete: false,
                  });
                  setOpenDrawer(true);
                }}
              >
                {/* <Link to="/app/admin/users/create">ADD USER</Link> */}
                ADD USER
              </Button>
            ) : null}
          </Box>
        </Box>

        <Box className="w-full flex justify-between items-center">
          <Box className="flex justify-start items-center">
            {[
              { label: "NGO", ngo: true },
              { label: "FO", fo: true },
              { label: "SAKHI", sakhi: true },
            ].map((user) => (
              <Box
                key={user.label}
                variant="contained"
                className={
                  user?.label === "NGO" && selectedUser["ngo"]
                    ? "px-3 py-1.5 font-semibold capitalize cursor-pointer rounded-tl-md text-black  bg-white  border-b-2 border-primary"
                    : user?.label === "FO" && selectedUser["fo"]
                      ? "px-3 py-1.5 font-semibold capitalize cursor-pointer text-black  bg-white  border-b-2 border-primary"
                      : user?.label === "SAKHI" && selectedUser["sakhi"]
                        ? " px-3 py-1.5 font-semibold capitalize cursor-pointer rounded-tr-md text-black  bg-white  border-b-2 border-primary"
                        : user?.label === "NGO"
                          ? "px-3 py-1.5 font-semibold capitalize cursor-pointer rounded-tl-md text-black bg-white  border-b-2"
                          : user?.label === "SAKHI"
                            ? "px-3 py-1.5 font-semibold capitalize cursor-pointer rounded-tr-md text-black bg-white  border-b-2"
                            : "px-3 py-1.5 font-semibold capitalize cursor-pointer text-black bg-white  border-b-2"
                }
                onClick={() => {
                  fetchWrapper?.finishPendingRequests();
                  setSelectedUser({
                    ngo: user?.ngo ? true : false,
                    fo: user?.fo ? true : false,
                    sakhi: user.sakhi ? true : false,
                  });
                  localStorage.setItem(
                    "selectedUser",
                    JSON.stringify({
                      ngo: user?.ngo ? true : false,
                      fo: user?.fo ? true : false,
                      sakhi: user.sakhi ? true : false,
                    })
                  );
                  localStorage.setItem("pageInfo", 1);
                  setFilterData({
                    ngoName: "",
                    foName: "",
                    name: "",
                  })
                  setPage(1);
                }}
              >
                <Typography
                  variant="caption"
                  color={
                    user?.label === "NGO" && selectedUser["ngo"]
                      ? "textPrimary"
                      : user?.label === "FO" && selectedUser["fo"]
                        ? "textPrimary"
                        : user?.label === "SAKHI" && selectedUser["sakhi"]
                          ? " textPrimary"
                          : "textSecondary"
                  }
                >
                  {user.label}
                </Typography>
              </Box>
            ))}
          </Box>
          {/* <Button
              variant="contained"
              size="small"
              startIcon={<Launch color="primary" />}
              endIcon={<ExpandMore color="primary" />}
              // onClick={() => setShowFilter(!showFilter)}
              // color="primary"
              style={{
                backgroundColor: "white",
                color: theme.palette.primary.main,
              }}
            >
              Export
            </Button> */}
        </Box>
        {showFilter ? (
          <FilterComponent
            commonFilterData={filterInputData?.filter((col) =>
              selectedUser?.sakhi ? ['ngoName', 'foName', 'name', 'state', 'district', 'block', 'year']?.includes(col?.name)
                : selectedUser?.fo ? ['ngoName', 'name', 'state', 'district', 'block']?.includes(col?.name)
                  : ['ngoName', 'name']?.includes(col?.name)
            )}
            filterData={filterData}
            setFilterData={setFilterData}
            handleFilterSearch={handleFilterSearch}
            handleRestFilter={handleRestFilter}
          />
        ) : null}
        <Divider style={{ maxWidth: "100%" }} />
        {loading ? (
          <LoadingBox />
        ) : (
          <StickyHeadTable
            handleViewDialog={handleViewDialog}
            columns={columns.filter((col) =>
              selectedUser.ngo
                ? ["sno", "ngoName", "name", "username", "email", "action"]?.includes(col?.id)
                : selectedUser?.fo
                  ? ["sno", "ngoName", "name", "state", "district", "block", "sakhiCount", "status", "action"]?.includes(col?.id)
                  : selectedUser?.sakhi
                    ? ["sno", "ngoName", "foName", "name", "state", "district", "block", "village", "onBoardingyear", "job_profile", "status", "action",]?.includes(col?.id)
                    : col
            )}
            rows={rows}
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>
      <Drawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        title={
          crudAction.create
            ? `Add ${selectedUser?.sakhi
              ? "Sakhi"
              : selectedUser?.fo
                ? "FO"
                : selectedUser?.ngo
                  ? "NGO"
                  : ""
            } User`
            : crudAction.edit
              ? `Update ${selectedUser?.sakhi
                ? "Sakhi"
                : selectedUser?.fo
                  ? "FO"
                  : selectedUser?.ngo
                    ? "NGO"
                    : ""
              } User Details`
              : crudAction.view
                ? `View ${selectedUser?.sakhi
                  ? "Sakhi"
                  : selectedUser?.fo
                    ? "FO"
                    : selectedUser?.ngo
                      ? "NGO"
                      : ""
                } User Details`
                : crudAction.delete &&
                `Delete ${selectedUser?.sakhi
                  ? "Sakhi"
                  : selectedUser?.fo
                    ? "FO"
                    : selectedUser?.ngo
                      ? "NGO"
                      : ""
                } User Details`
        }
      >
        <AdminUsersCRUDForm
          userDetails={userDetail}
          crudAction={crudAction}
          selectedUser={selectedUser}
          refetchUserList={refetchUserList}
          isFormOpen={openDrawer}
        />
      </Drawer>
      <DialogView
        openView={openView}
        handleCloseView={handleCloseView}
        title={
          crudAction.view
            ? `View ${selectedUser?.sakhi
              ? "Sakhi"
              : selectedUser?.fo
                ? "FO"
                : selectedUser?.ngo
                  ? "NGO"
                  : ""
            } User Details`
            : null
        }
      >
        <AdminUsersCRUDForm
          userDetails={userDetail}
          crudAction={crudAction}
          selectedUser={selectedUser}
          refetchUserList={refetchUserList}
          isFormOpen={openView}
        />
      </DialogView>

      <Dialog open={sakhiPopupOpen} onClose={() => {
        setSakhiPopupOpen(false);
        setSakhiPage(1);
      }}>
        <DialogTitle>
          {users?.data.find((val) => val.id === sakhiCountId)?.name}
        </DialogTitle>
        <DialogContent dividers>
          <StickyHeadTable
            columns={sakhiColumns}
            rows={sakhiRows}
            loading={false}
            total={sakhiListLength}
            page={sakhiPage}
            setPage={setSakhiPage}
            rowsPerPage={10}
            // setRowsPerPage={setRowsPerPage}
            isPopupTable={true}
          />

        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default AdminUsersView;
