import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { USER_CREATE_REQUEST, USER_CREATE_FAIL, USER_CREATE_SUCCESS, USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LIST_FAIL, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL, USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAIL, } = require("../Conistance/UserConistance")

const BASE_URL = process.env.REACT_APP_API_URL


export const userCreateAction = (body, refetchUserList) => async (dispatch) => {
    dispatch({ type: USER_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/user/create`, body)
        dispatch({ type: USER_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: USER_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const userListAction = (filter) => async (dispatch) => {
    dispatch({ type: USER_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/user/list${paramsUrl(filter)}`);
        dispatch({ type: USER_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: USER_LIST_FAIL, payload: error })
        toast.error(error)
    }
}
export const userUpdateAction = (bodyId, body, refetchUserList) => async (dispatch) => {
    dispatch({ type: USER_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/user/update/${bodyId}`, body);
        dispatch({ type: USER_UPDATE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: USER_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}
export const userDeleteAction = (bodyId, refetchUserList) => async (dispatch) => {
    dispatch({ type: USER_DELETE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/user/delete/${bodyId}`);
        dispatch({ type: USER_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: USER_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}

