import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Add, CloseOutlined } from '@material-ui/icons';
import { Formik, FieldArray } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { milestoneUpdateAction } from 'src/Redux/Actions/MilestoneAction';
import { milestoneDeleteAction } from 'src/Redux/Actions/MilestoneAction';
import { milestoneCreateAction } from 'src/Redux/Actions/MilestoneAction';
import moment from "moment";
import CustomInputs from 'src/components/CommonComponents/CustomInputs';
import { fetchApiDataList } from 'src/services/http_requests';
import * as Yup from 'yup';
import { mouCreateAction, mouUpdateAction } from 'src/Redux/Actions/MOUAction';


const MouMilestoneCRUDForm = ({ crudAction, prefilledDetails, refetchDataList, currentPath, openDrawer, setOpenDrawer }) => {

    const [ngoList, setNgoList] = useState([]);

    const dispatch = useDispatch();

    const initialValues = {
        ngo_id: "",
        po_number: "",
        file: "",
        tenure: "",
        phaseNumber: "",
        phases: [],
        name: ""
    };

    const validation = useMemo(() => {
        if (currentPath === "target") {
            return Yup.object().shape({
                ngo_id: Yup.string().required("NGO is required"),
                po_number: Yup.string().matches(RegExp("^[a-zA-Z0-9_]*$"), "Alphabets, numbers and underscore are accepted")
                    .required("PO Number is required"),
                file: Yup.mixed().required("File is required"),
                phases: Yup.array().min(1, "Select atleast one phase")
                    .of(
                        Yup.object().shape({
                            start_month: Yup.string().required("Start month is required"),
                            end_month: Yup.string().required("End month is required"),
                        })
                    ),
            });
        } else return Yup.object().shape({
            name: Yup.string().when("id", {
                is: (id) => crudAction.delete,
                then: (schema) => schema,
                otherwise: (schema) => schema.required("Milestone is required")
            })
        })
    }, [currentPath]);

    useEffect(() => {
        if (currentPath === "target" && openDrawer) {
            fetchApiDataList("ngo_partner", { fetched: "All" }, setNgoList);
        }
    }, [currentPath, openDrawer]);

    const formInputList = [
        ...currentPath === "target" ?
            [
                {
                    key: "ngo_id",
                    name: "ngo_id",
                    label: "NGO",
                    inputType: "autoComplete",
                    multiple: false,
                    required: true,
                    disabled: crudAction?.delete || crudAction?.view ? true : false,
                    optionArray: ngoList || [],
                    value: "",
                    placeholder: "Select NGO",
                },
                {
                    key: "po_number",
                    name: "po_number",
                    label: "PO Number",
                    inputType: "text",
                    required: true,
                    disabled: crudAction?.delete || crudAction?.view ? true : false,
                    value: "",
                    placeholder: "Enter PO Number",
                },
                {
                    key: "file",
                    name: "file",
                    label: "Upload MOU",
                    inputType: "file",
                    required: false,
                    disabled: crudAction?.delete || crudAction?.view ? true : false,
                    value: "",
                    placeholder: "Upload File",
                    acceptedFiles: [".pdf", ".csv", ".xlsx"]

                },
                // {
                //     key: "phases",
                //     name: "phases",
                //     label: "Add Phase",
                //     inputType: "appendBtn",
                //     required: false,
                //     disabled: crudAction?.delete || crudAction?.view ? true : false,
                //     value: "",
                //     placeholder: "Add Phase",
                //     children: []
                // },
            ] :
            [{
                key: "name",
                name: "name",
                label: "Milestone",
                inputType: "text",
                required: true,
                disabled: crudAction?.delete || crudAction?.view ? true : false,
                value: "",
                placeholder: "Enter Milestone",
            }]
    ];

    const onSubmitHandler = async (values, { resetForm }) => {
        let formData = { ...values };
        if (currentPath === "milestone") {
            formData = { name: formData?.name };
            crudAction.create ? dispatch(milestoneCreateAction(formData, refetchDataList))
                : crudAction.delete ? dispatch(milestoneDeleteAction(prefilledDetails?.id, refetchDataList))
                    : dispatch(milestoneUpdateAction(prefilledDetails?.id, formData, refetchDataList))

        } else {
            const returnFormattedDate = (date, key) => {
                let formattedDate = "";
                formattedDate = moment(date)?.format("YYYY-MM-DD");
                if (formattedDate?.includes("T")) {
                    return formattedDate?.split("T")[0];
                } else return formattedDate;
            };
            for (let i = 0; i < formData.phases?.length; i++) {
                formData.phases[i]["start_month"] = returnFormattedDate(formData?.phases[i]?.start_month);
                formData.phases[i]["end_month"] = returnFormattedDate(formData?.phases[i]?.end_month);
                if (!formData.phases[i]?.id) {
                    formData.phases[i].id = null;
                }
            };

            let newValues = {
                mou_file: formData.file,
                phases: formData.phases,
                po_number: formData.po_number?.toString(),
                ngo_id: formData.ngo_id,
            };
            if (crudAction.delete) newValues['is_deleted'] = "0";
            crudAction.create ? dispatch(mouCreateAction(newValues, refetchDataList))
                : dispatch(mouUpdateAction(prefilledDetails?.id, newValues, refetchDataList))
        }
    };


    // let initialValues = currentPath === "target" ? targetValue : milestoneValue;

    return (
        <div className={`h-auto  ${crudAction.view ? "pb-4 mx-4" : "pb-16 mx-2"}`}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmitHandler}
            >
                {function Render({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                    touched,
                    setErrors,
                    setStatus,
                    setSubmitting,
                    values,
                    resetForm
                }) {
                    useEffect(() => {
                        if (openDrawer === false) resetForm();
                    }, [openDrawer, currentPath]);


                    useEffect(() => {
                        if (Object.keys(prefilledDetails || {})?.length > 0) {
                            setFieldValue("ngo_id", prefilledDetails?.ngo_partner?.id || "");
                            setFieldValue("po_number", prefilledDetails?.po_number || "");
                            setFieldValue("file", prefilledDetails?.mou_file || "");
                            setFieldValue("name", prefilledDetails?.name || "")
                            setFieldValue("phases", prefilledDetails?.mou_phase_mappers || []);
                        }
                    }, [prefilledDetails]);


                    return (
                        <form onSubmit={handleSubmit} className="bg-transparent overflow-hidden pb-6 ">
                            <Grid container spacing={2} className='bg-white p-4' >
                                {formInputList?.map((elem, index) => (
                                    <CustomInputs
                                        key={elem?.name}
                                        name={elem?.name}
                                        id={elem?.key}
                                        label={elem?.label}
                                        inputType={elem?.inputType}
                                        value={values[elem.name]}
                                        required={elem?.required}
                                        handleBlur={handleBlur}
                                        onClick={() => setFieldTouched(elem.name, true)}
                                        onChange={(event, value) => {
                                            if (["file", "autoComplete"].includes(elem.inputType)) setFieldValue(event, value);
                                            else handleChange(event);
                                        }}
                                        error={errors[elem.name] && touched[elem.name]}
                                        helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                                        placeholder={elem?.placeholder}
                                        disabled={elem?.disabled}
                                        multiple={elem?.multiple}
                                        optionArray={elem?.optionArray}
                                        acceptedFiles={elem?.acceptedFiles}
                                        crudAction={crudAction}
                                        initialValues={initialValues}
                                    />)
                                )}

                                {currentPath === "target" && <FieldArray name="phases">
                                    {(formii) => {
                                        return (
                                            <>
                                                {/* mou phases */}
                                                {values?.phases?.map((phase, index) => {
                                                    return (<Box key={index}>
                                                        {/* heading nd remove btn  */}
                                                        <Box className='flex items-center justify-between mt-4'>
                                                            <Typography>Add New MOU Phase</Typography>
                                                            <IconButton type="button" disabled={crudAction.delete}
                                                                onClickCapture={() => {
                                                                    for (let i = index + 1; i < values?.phases?.length; i++) {
                                                                        setFieldValue(`phases[${i}].phase`, "Phase " + i);

                                                                    }
                                                                }}
                                                                onClick={formii.handleRemove(index)}>
                                                                <CloseOutlined />
                                                            </IconButton>
                                                        </Box>
                                                        <Grid container spacing={2} className='bg-white px-0 py-2' >

                                                            <Grid item xs={12} sx={12} md={12} key={index}>
                                                                <TextField
                                                                    key={values.phases[index]?.phase}
                                                                    type="text"
                                                                    id={values.phases[index]?.phase}
                                                                    name={values.phases[index].phase}
                                                                    className="inputRounded"
                                                                    label="MOU Phase"
                                                                    placeholder="Enter Phase"
                                                                    fullWidth
                                                                    defaultValue={values.phases[index]?.phase || ""}
                                                                    onBlur={handleBlur}
                                                                    disabled={true}
                                                                    style={{ borderRadius: 10 }}
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        style: {
                                                                            backgroundColor: 'white',
                                                                            paddingRight: '8px',
                                                                            fontSize: 14
                                                                        }
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <CustomInputs
                                                                key={`phases[${index}].start_month`}
                                                                name={`phases[${index}].start_month`}
                                                                id={`phases[${index}].start_month`}
                                                                label={`Start Month`}
                                                                inputType={`month`}
                                                                value={values.phases[index]?.start_month}
                                                                required={true}
                                                                handleBlur={(e) => {
                                                                    handleBlur(e.target?.name);
                                                                    !values[e.target?.name] && setFieldError(e.target?.name, "Required Field");
                                                                }}
                                                                onChange={(event, value) => {
                                                                    setFieldValue(event, value);
                                                                    setFieldValue(`phases[${index}].end_month`, "");
                                                                }}
                                                                placeholder={'Select Month'}
                                                                disabled={crudAction.delete || crudAction.view ? true : false}
                                                                error={errors?.phases?.[index]?.start_month && touched?.phases?.[index]?.start_month}
                                                                helperText={errors?.phases?.[index]?.start_month && touched?.phases?.[index]?.start_month ? errors?.phases?.[index]?.start_month : ""}
                                                                crudAction={crudAction}
                                                                initialValues={{}}
                                                                min={index >= 1 ? moment(values.phases[index - 1]?.end_month).add(1, 'M').format() : moment("01/01/1945").format()}
                                                            />
                                                            <CustomInputs
                                                                key={`phases[${index}].end_month`}
                                                                name={`phases[${index}].end_month`}
                                                                id={`phases[${index}].end_month`}
                                                                label={`End Month`}
                                                                inputType={`month`}
                                                                value={values.phases[index]?.end_month}
                                                                required={true}
                                                                handleBlur={(e) => {
                                                                    handleBlur(e.target?.name);
                                                                    !values[e.target?.name] && setFieldError(e.target?.name, "Required Field");
                                                                }}
                                                                onChange={(event, value) => {
                                                                    setFieldValue(event, value);
                                                                    if (values.phases[index + 1]) setFieldValue(`phases[${index + 1}].start_month`, "");
                                                                }}
                                                                error={errors?.phases?.[index]?.end_month && touched?.phases?.[index]?.end_month}
                                                                helperText={errors?.phases?.[index]?.end_month && touched?.phases?.[index]?.end_month ? errors?.phases?.[index]?.end_month : ""}
                                                                placeholder={'Select Month'}
                                                                disabled={crudAction.delete || crudAction.view ? true : values.phases?.[index].start_month ? false : true}
                                                                crudAction={crudAction}
                                                                initialValues={{}}
                                                                min={values.phases?.[index].start_month}
                                                            />
                                                        </Grid>
                                                    </Box>)

                                                })}

                                                {/* add button */}
                                                <Grid item xs={12} sx={12} md={crudAction?.view && Object.keys(initialValues)?.length > 4 ? 6 : 12} key={"phases"}>
                                                    <Box className="w-full flex justify-end ">
                                                        <Button startIcon={<Add />}
                                                            onClickCapture={() => {
                                                                const phaseNumber = values.phases?.length;
                                                                if (phaseNumber >= 1) {
                                                                    if (!values.phases[phaseNumber - 1]?.start_month) {
                                                                        setFieldError(`phases[${phaseNumber - 1}].start_month`, "Start month is required");
                                                                        setFieldTouched(`phases[${phaseNumber - 1}].start_month`, true);
                                                                    }
                                                                    else if (!values.phases[phaseNumber - 1]?.end_month) {
                                                                        setFieldError(`phases[${phaseNumber - 1}].end_month`, "End month is required");
                                                                        setFieldTouched(`phases[${phaseNumber - 1}].end_month`, true);
                                                                    }
                                                                    else formii.push({ phase: `Phase ${phaseNumber + 1}`, start_month: "", end_month: "" });
                                                                } else formii.push({ phase: `Phase ${phaseNumber + 1}`, start_month: "", end_month: "" });
                                                            }}
                                                            name={"phases"}
                                                            disabled={crudAction.delete}
                                                            variant="contained" color="primary" size="small">
                                                            Add Phase
                                                        </Button>
                                                    </Box>
                                                    {(typeof errors?.phases === "string" && errors?.phases && touched?.phases) && <Typography variant='subtitle2' color='error'>{errors?.phases}</Typography>}
                                                </Grid >


                                            </>
                                        )
                                    }}
                                </FieldArray>
                                }

                                {!crudAction.view && <Box className="w-full px-2 bg-gray-100 mt-10"
                                    onClick={() => {
                                        for (let elem in initialValues) setFieldTouched(elem, true);
                                    }} >
                                    <Button type="submit" className="w-full" variant="contained" color='primary' size='large' >{crudAction.create ? 'CREATE' : crudAction.edit ? 'UPDATE' : 'DELETE'}</Button>
                                </Box>}
                            </Grid>

                        </form>
                    )
                }
                }

            </Formik>
        </div>
    )
}

export default MouMilestoneCRUDForm