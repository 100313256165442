export const MILESTONE_LIST_REQUEST = 'MILESTONE_LIST_REQUEST'
export const MILESTONE_LIST_SUCCESS = 'MILESTONE_LIST_SUCCESS'
export const MILESTONE_LIST_FAIL = 'MILESTONE_LIST_FAIL'

export const MILESTONE_CREATE_FAIL = 'MILESTONE_CREATE_FAIL'
export const MILESTONE_CREATE_REQUEST = 'MILESTONE_CREATE_REQUEST'
export const MILESTONE_CREATE_SUCCESS = 'MILESTONE_CREATE_SUCCESS'

export const MILESTONE_UPDATE_REQUEST = 'MILESTONE_UPDATE_REQUEST'
export const MILESTONE_UPDATE_SUCCESS = 'MILESTONE_UPDATE_SUCCESS'
export const MILESTONE_UPDATE_FAIL = 'MILESTONE_UPDATE_FAIL'

export const MILESTONE_DELETE_REQUEST = 'MILESTONE_DELETE_REQUEST'
export const MILESTONE_DELETE_SUCCESS = 'MILESTONE_DELETE_SUCCESS'
export const MILESTONE_DELETE_FAIL = 'MILESTONE_DELETE_FAIL'