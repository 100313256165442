import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { PURPOSE_MAPPER_LIST_REQUEST, PURPOSE_MAPPER_LIST_SUCCESS, PURPOSE_MAPPER_LIST_FAIL, PURPOSE_MAPPER_CREATE_REQUEST, PURPOSE_MAPPER_CREATE_FAIL, PURPOSE_MAPPER_CREATE_SUCCESS, PURPOSE_MAPPER_UPDATE_REQUEST, PURPOSE_MAPPER_UPDATE_FAIL, PURPOSE_MAPPER_DELETE_REQUEST, PURPOSE_MAPPER_DELETE_FAIL, PURPOSE_MAPPER_UPDATE_SUCCESS, PURPOSE_MAPPER_DELETE_SUCCESS } = require("../Conistance/PurposeMapperConistance")

const BASE_URL = process.env.REACT_APP_API_URL

export const purposeMapperListAction = (filter) => async (dispatch) => {
    dispatch({ type: PURPOSE_MAPPER_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/purpose_mapper/list${paramsUrl(filter)}`);

        dispatch({ type: PURPOSE_MAPPER_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {

        dispatch({ type: PURPOSE_MAPPER_LIST_FAIL, payload: error })
        toast.error(error)
    }
}


export const purposeMapperCreateAction = (body, refetchDataList) => async (dispatch) => {
    dispatch({ type: PURPOSE_MAPPER_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/purpose_mapper/create`, body)
        dispatch({ type: PURPOSE_MAPPER_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: PURPOSE_MAPPER_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const purposeMapperUpdateAction = (id, body, refetchDataList) => async (dispatch) => {
    dispatch({ type: PURPOSE_MAPPER_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/purpose_mapper/update/${id}`, JSON.stringify(body))
        dispatch({ type: PURPOSE_MAPPER_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: PURPOSE_MAPPER_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const purposeMapperDeleteAction = (purposeMapperId, refetchDataList) => async (dispatch) => {
    dispatch({ type: PURPOSE_MAPPER_DELETE_REQUEST })
    try {

        const data = await fetchWrapper.put(`${BASE_URL}/purpose_mapper/delete/${purposeMapperId}`)
        dispatch({ type: PURPOSE_MAPPER_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: PURPOSE_MAPPER_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}