export const  PURPOSE_MAPPER_LIST_REQUEST = 'PURPOSE_MAPPER_LIST_REQUEST'
export const PURPOSE_MAPPER_LIST_SUCCESS = 'PURPOSE_MAPPER_LIST_SUCCESS'
export const PURPOSE_MAPPER_LIST_FAIL = 'PURPOSE_MAPPER_LIST_FAIL'

export const PURPOSE_MAPPER_CREATE_FAIL = 'PURPOSE_MAPPER_CREATE_FAIL'
export const PURPOSE_MAPPER_CREATE_REQUEST = 'PURPOSE_MAPPER_CREATE_REQUEST'
export const PURPOSE_MAPPER_CREATE_SUCCESS = 'PURPOSE_MAPPER_CREATE_SUCCESS'

export const PURPOSE_MAPPER_UPDATE_REQUEST = 'PURPOSE_MAPPER_UPDATE_REQUEST'
export const PURPOSE_MAPPER_UPDATE_SUCCESS = 'PURPOSE_MAPPER_UPDATE_SUCCESS'
export const PURPOSE_MAPPER_UPDATE_FAIL = 'PURPOSE_MAPPER_UPDATE_FAIL'

export const PURPOSE_MAPPER_DELETE_REQUEST = 'PURPOSE_MAPPER_DELETE_REQUEST'
export const PURPOSE_MAPPER_DELETE_SUCCESS = 'PURPOSE_MAPPER_DELETE_SUCCESS'
export const PURPOSE_MAPPER_DELETE_FAIL = 'PURPOSE_MAPPER_DELETE_FAIL'