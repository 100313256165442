import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import { ArrowBackOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import axios from 'axios';
import { Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import * as Crypto from "crypto-js";
import { useEffect } from 'react';

const Forgotpassword = ({ setIsResetPass, isResetPass }) => {
    const [showPassword, setShowPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState("");

    const [step, setStep] = useState(1);

    const initialValues = {
        mobileNumber: "",
        otpValue: "",
        password: "",
        confirmPassword: ""
    };
    let phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
    let passwordReg = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$!%#*?&]{8,}$/);
    let numberRegex = RegExp(/^[0-9]*$/);

    const validationOne = Yup.object().shape({
        mobileNumber: Yup.string().matches(phoneRegex, 'Phone number is not valid').required("Mobile Number is required.")
    });
    const validationTwo = Yup.object().shape({
        otpValue: Yup.string().matches(numberRegex, "Numbers only.").required("OTP is required.")
    });
    const validationThree = Yup.object().shape({
        password: Yup.string()
            .matches(passwordReg, "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character")
            .required("Password is required."),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password   must match')
    });

    const sendOtpHandler = async (mobileNumber) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/sendOtp`, { mobile: mobileNumber, type: "web" });
            toast.success(data?.message)
            setStep(step + 1);
        } catch (error) {
            toast.error(error.response?.data?.message)
        }
    };

    const verifyOtp = (values) => {

        axios.post(`${process.env.REACT_APP_API_URL}/user/verifyOtp`, {
            mobile: values.mobileNumber,
            otp: values.otpValue
        })
            .then(response => {
                // console.log(response);
                if (response?.data?.success) {
                    toast.success(response?.data?.message);
                    setStep(step + 1);
                } else toast.error(response?.data?.message);
            })
            .catch(error => {
                console.log(error.response);
                toast.error(error.response?.data?.message || 'Wrong OTP!')
            });
        // try {
        //     const { data } = axios.post(`${process.env.REACT_APP_API_URL}/user/verifyOtp`, {
        //         mobile: values.mobileNumber,
        //         otp: values.otpValue
        //     });
        //     console.log(data);
        //     if (data?.success) {
        //         toast.success(data?.message);
        //         setStep(step + 1);
        //     } else {
        //         toast.error('Wrong OTP');
        //     }
        // } catch (error) {
        //     toast.error(error.response?.data?.message)
        // }

    };

    const onSubmit = async (values, reset) => {
        let passwordHAsh = Crypto.HmacSHA256(
            values.password,
            process.env.REACT_APP_SUPERSECRET
        ).toString();
        let consfirmPasswordHAsh = Crypto.HmacSHA256(
            values.confirmPassword,
            process.env.REACT_APP_SUPERSECRET
        ).toString();
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/user/forgot_password`, {
                new_password: passwordHAsh,
                confirm_password: consfirmPasswordHAsh,
                mobile: values.mobileNumber
            });
            if (data?.success) {
                toast.success(data?.message);
                setTimeout(() => {
                    setStep(1);
                    setIsResetPass(false);
                    reset();
                }, 1000);
            }

        } catch (error) {
            toast.error(error.response?.data?.message);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() =>
                step == 1 ? validationOne
                    : step == 2 ? validationTwo
                        : validationThree
            }
            onSubmit={async (values, { resetForm }) => {
                if (step == 1) sendOtpHandler(values.mobileNumber);
                else if (step == 2) verifyOtp(values);
                else onSubmit(values, resetForm);
            }}
        >
            {function Render({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                resetForm,
            }) {
                useEffect(() => {
                    resetForm();
                }, [isResetPass]);

                return (
                    <form onSubmit={handleSubmit} className='z-50 bg-white w-10/12 md:8/12 lg:w-7/12 xl:w-7/12 mr-10 px-4 py-6  rounded-xl' autoComplete="false">
                        <div className={`${step == 1 ? "block " : "hidden"}`}>
                            <div>
                                <div className=' flex items-center justify-start'>
                                    <ArrowBackOutlined className="mx-2 cursor-pointer" fontSize='large'
                                        onClick={() => { resetForm(); setIsResetPass(false); localStorage.setItem('resetPass', false) }} />
                                    <Typography variant='h4' style={{ fontWeight: 'bold', fontSize: 30 }} >
                                        Forgot Password?
                                    </Typography>
                                </div>
                                <p className='text-[#999999] text-lg text-center mt-3'>Please enter the Mobile Number, we will send you an OTP to reset your password.</p>
                            </div>
                            <div className='mb-[3rem]'>
                                <TextField
                                    name="mobileNumber"
                                    placeholder="Enter Mobile Number"
                                    fullWidth
                                    value={values.mobileNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    style={{ borderRadius: 10, marginTop: "4rem", marginBottom: '0.5rem' }}
                                    variant="outlined"
                                />
                                {errors.mobileNumber && touched.mobileNumber && (
                                    <p className="text-[#FF1E1E] text-xs m-0">
                                        {errors.mobileNumber}
                                    </p>
                                )}
                            </div>
                        </div>
                        {/* step2 */}
                        <div className={`${step == 2 ? "block " : "hidden"}`}>
                            <div>
                                <div className=' flex items-center justify-starts'>
                                    <ArrowBackOutlined className="mx-2 cursor-pointer" fontSize='large'
                                        onClick={() => {
                                            setFieldValue("otpValue", "");
                                            setStep(step - 1);
                                        }} />
                                    <Typography variant='h4' style={{ fontWeight: 'bold', fontSize: 30 }} >
                                        Verify OTP
                                    </Typography>
                                </div>
                            </div>
                            <div className='mb-[3rem]'>
                                <TextField
                                    name="otpValue"
                                    placeholder="Enter OTP"
                                    fullWidth
                                    value={values.otpValue}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    style={{ borderRadius: 10, marginTop: "4rem", marginBottom: "0.5rem" }}
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                />
                                {errors.otpValue && touched.otpValue && (
                                    <p className="text-[#FF1E1E] text-xs m-0">
                                        {errors.otpValue}
                                    </p>
                                )}
                            </div>
                        </div>

                        {/* step3 */}
                        <div className={`${step == 3 ? "block space-y-4" : "hidden"}`}>
                            <div>
                                <div className=' flex items-center justify-start'>
                                    <ArrowBackOutlined className="mx-2 cursor-pointer" fontSize='large'
                                        onClick={() => {
                                            setStep(step - 1);
                                            setFieldValue("password", "");
                                            setFieldValue("confirmPassword", "");
                                        }} />
                                    <Typography variant='h4' style={{ fontWeight: 'bold', fontSize: 30 }} >
                                        Enter Password
                                    </Typography>
                                </div>
                            </div>
                            <div className=''>
                                <div className="relative">
                                    <TextField
                                        name="password"
                                        placeholder="New Password"
                                        fullWidth
                                        type={showPassword ? 'text' : "password"}
                                        value={values.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        style={{ borderRadius: 10, marginBottom: "0.5rem" }}
                                        variant="outlined"
                                        autoComplete="new-password"
                                        inputProps={{
                                            autoComplete: "new-password"
                                        }}
                                    />
                                    <div position="end" className='absolute z-[2] top-0 bottom-0 right-0 margin-auto'>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </div>
                                </div>
                                {errors.password && touched.password && (
                                    <p className="text-[#FF1E1E] text-xs m-0">
                                        {errors.password}
                                    </p>
                                )}
                            </div>
                            <div className='mb-[3rem] mt-[2rem]'>
                                <div className="relative">
                                    <TextField
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        fullWidth
                                        type={showConfirmPassword ? "text" : "password"}
                                        value={values.confirmPassword}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        style={{ borderRadius: 10, marginBottom: "0.5rem" }}
                                        variant="outlined"
                                    />
                                    <div position="end" className='absolute z-[2] top-0 bottom-0 right-0 margin-auto'>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </div>
                                </div>
                                {errors.confirmPassword && touched.confirmPassword && (
                                    <p className="text-[#FF1E1E] text-xs m-0">
                                        {errors.confirmPassword}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className='w-full flex justify-center items-center'>
                            <Button
                                color="primary"
                                // fullWidth
                                className='w-5/12'
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

export default Forgotpassword;


{/* <Formik
    validationSchema={() =>
        step == 1 ? validationOne
            : step == 2 ? validationTwo
                : validationThree
    }
    initialValues={initialValues}
    onSubmit={async (values, helpers) => {
        if (step == 3) onSubmit(values);
        else setStep(step + 1);
    }}
> {({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setFieldValue,
    resetForm,
}) => {
    return (
    
);
}}
</Formik> */}