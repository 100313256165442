import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, colors } from '@material-ui/core'
import React from 'react'
import { useMemo } from 'react';
import moment from "moment";
import { generateSerialNo } from 'src/utils';
import { useState } from 'react';
import BudgetRows from './BudgetRows';
import { fetchWrapper } from 'src/services/http_requests';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { loadData } from 'src/utils/sessionStorage';

const createData = (sno, budget_lineitem, budgetList) => {
    return { sno, budget_lineitem, budgetList };
};

const BudgetTable = ({ data }) => {
    const userData = loadData("loginInfo")?.data;
    const role_id = userData?.role_id;

    const [targetData, setTargetData] = useState({});
    const [superTotal, setSuperTotal] = useState(0);


    const columns = useMemo(() => {
        let firstHeader = [
            { id: "sno", label: "", align: "left" },
            { id: "matrics", label: "", align: "left", minWidth: 200, maxWidth: 250 },
            { id: "budget", label: "", align: "left", minWidth: 200, maxWidth: 200 }
        ];
        let secondHeader = [
            { id: "sno", label: "S.No", align: "left" },
            { id: "matrics", label: "Budget Line Items", align: "left", minWidth: 200, maxWidth: 250 },
            { id: "budget", label: "Overall senctioned Total Budget", align: "left", minWidth: 200, maxWidth: 200 }
        ];

        if (data?.data?.length > 0) {
            data?.data?.[0]?.budgetList?.map((elem, index) => {
                const monthName = moment().month(elem?.month - 1).format('MMM');
                const label = <div className='flex flex-col items-center justify-center'>
                    <span>Projection</span>
                    <span>{monthName} - {data?.financial_year}</span>
                </div>;

                if (role_id === 2) {
                    firstHeader.push({ id: elem?.id || index, label: <span>{monthName} - {data?.financial_year}</span>, padding: 0, colSpan: 2 });

                    secondHeader.push({ id: `budget${index}p`, label: "Projection", colSpan: 1 });
                    secondHeader.push({ id: `budget${index}e`, label: "Expense", colSpan: 1 });
                } else secondHeader.push({ id: elem?.id || index, label: label, padding: 0, maxWidth: 100 });
                return [];
            })
        };

        return { firstHeader, secondHeader };
    }, [data]);

    const rows = useMemo(() => data?.data?.map((elem, index) => {
        return createData(
            generateSerialNo(index),
            elem?.budget_lineitem,
            elem?.budgetList,
        );
    }), [data]);


    const submitTargetHandler = async () => {
        let values = JSON.parse((JSON.stringify(targetData)));

        targetData?.data?.map((elem, index) => {
            let filledBudgets = elem?.budgetList?.map(budget => {
                if (budget?.target) return budget;
            });
            values.data[index].budgetList = filledBudgets?.filter(function (el) {
                return el != null;
            });
            return [];
        });

        try {
            const data = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/budget/create`, values?.data);
            toast.success(data?.message);
        } catch (error) {
            toast.error(error);
            throw error;
        };
    };

    useEffect(() => {
        if (data) {
            setTargetData(data);
        }
    }, [data]);


    return (
        <Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="bg-slate-200">
                            {role_id === 1 ? <TableRow>
                                {columns?.secondHeader?.map((column, index) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align || "center"}
                                        style={
                                            [1].includes(index) ?
                                                { position: "sticky", left: 0, backgroundColor: "#e2e8f0", zIndex: 2, minWidth: column.minWidth, maxWidth: column.maxWidth }
                                                : { zIndex: 1, backgroundColor: "#e2e8f0", minWidth: column.minWidth || 100, maxWidth: column.maxWidth || 100, textAlign: "center" }
                                        }
                                        scope="row"
                                        component='th'
                                    >
                                        <Typography
                                            variant="body2"
                                            className="font-semibold text-xs"
                                        >{column.label}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                                : <>
                                    <TableRow>
                                        {columns?.firstHeader?.map((column, index) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align || "center"}
                                                style={
                                                    [1].includes(index) ?
                                                        { position: "sticky", left: 0, zIndex: 2, minWidth: column.minWidth, maxWidth: column.maxWidth, backgroundColor: "#d6002a", color: "#fff", borderRight: `1px solid ${colors.grey[400]}`, }
                                                        : { zIndex: 1, textAlign: "center", backgroundColor: "#d6002a", color: "#fff", borderRight: `1px solid ${colors.grey[400]}`, }
                                                }
                                                scope="row"
                                                component='th'
                                                colSpan={column?.colSpan || null}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    className="font-semibold text-xs"
                                                >{column.label}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        {columns?.secondHeader?.map((column, index) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align || "center"}
                                                style={
                                                    [1].includes(index) ?
                                                        { position: "sticky", left: 0, backgroundColor: "#e2e8f0", zIndex: 2, minWidth: column.minWidth, maxWidth: column.maxWidth, borderRight: `1px solid ${colors.grey[400]}`, }
                                                        : { zIndex: 1, backgroundColor: "#e2e8f0", minWidth: column.minWidth || null, maxWidth: column.maxWidth || null, textAlign: "center", borderRight: `1px solid ${colors.grey[400]}`, }
                                                }
                                                colSpan={column?.colSpan || null}
                                                scope="row"
                                                component='th'
                                            >
                                                <Typography
                                                    variant="body2"
                                                    className="font-semibold text-xs"
                                                >{column.label}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </>}
                        </TableHead>

                        <TableBody>
                            {rows?.map((elem, index) => {
                                return <BudgetRows
                                    index={index}
                                    elem={elem}
                                    targetData={targetData}
                                    setTargetData={setTargetData}
                                    userRole={role_id}
                                    setSuperTotal={setSuperTotal}
                                />

                            })}
                            <TableRow>
                                <TableCell
                                    style={{ position: "sticky", left: 0, zIndex: 2, background: "#fff" }}
                                    colSpan={2}>Total</TableCell>
                                <TableCell >₹{superTotal}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
            </Paper>
            <Box className="py-6 w-full flex justify-end ">
                <Button variant="contained" color="primary" size="medium" onClick={submitTargetHandler}>Submit</Button>
            </Box>
        </Box >
    )
}

export default React.memo(BudgetTable);