import { toast } from 'react-toastify';
export const downloadCsv = (csv, filename) => {
    if (csv === '') {
        toast.error("Data not found!");
        return;
    }
    //this trick will generate a temp "a" tag
    var link = document.createElement("a");
    link.id = "lnkDwnldLnk";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);

    var blob = new Blob([csv], { type: 'text/csv' });
    var csvUrl = window.webkitURL.createObjectURL(blob);
    filename = (filename || 'data') + '.csv';
    link.setAttribute('download', filename);
    link.setAttribute('href', csvUrl);

    link.click();
    document.body.removeChild(link);
}


