import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ViewForm from "./ViewForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { sakhiTransationListAction } from "src/Redux/Actions/SakhiTransactionAction";
import { useState } from "react";
import { fetchWrapper } from "src/services/http_requests";

function SakhiTransactionView() {
  const { id: paramsID } = useParams();
  const path = window.location.pathname;
  const [crudAction, setCrudAction] = useState({
    edit: false,
    view: true,
  });
  
  const {sakhiTransactions} = useSelector(state => state.sakhiTransactionList)
  const dispatch = useDispatch()
  useEffect(()=>{
    path.includes('view') ? setCrudAction({
      edit: false,
      view: true,
    }): setCrudAction({
      edit: true,
      view: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  useEffect(() => {
    let mounted = true;

    if(paramsID && mounted){
      dispatch(sakhiTransationListAction({"id":paramsID}))
    }
    
    return () => (mounted = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsID])
  
  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem('previousUrl',window.location.href)

    return() => (fetchWrapper?.finishPendingRequests())
  },[])
  

  const sakhiDetails = sakhiTransactions?.data?.find(item => Number(item?.id) === Number(paramsID));
  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box className='w-full h-full'>
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box className="flex justify-center items-center space-x-2">
            <Link to="/app/sakhitransactions">
              <ArrowBackIcon />
            </Link>
            <Typography sx={{ typography: { sm: 'body1', xs: 'body2' } }}><span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>{crudAction.view ? "View" : 'Update'} Transaction</span></Typography>
          </Box>
        </Box>
        <ViewForm sakhiDetails={sakhiDetails} crudAction={crudAction} />
      </Box>
    </Box>
  );
}

export default SakhiTransactionView;
