import {
  TextField,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
  FormControl,
} from "@material-ui/core";
import { Formik } from "formik";
import { rest } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import {
  userCreateAction,
  userDeleteAction,
  userUpdateAction,
} from "src/Redux/Actions/UserAction";
import * as Yup from "yup";
import AutocompleteComponent from "src/components/CommonComponents/AutocompleteComponent";
import CustomInputs from "src/components/CommonComponents/CustomInputs";
import { fetchApiDataList } from "src/services/http_requests";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formStyleView = 'w-96'
const formStyle = 'w-full'

function AdminUsersCRUDForm({ crudAction, userDetails, selectedUser, refetchUserList, isFormOpen }) {
  // eslint-disable-next-line no-unused-vars

  const { ngos } = useSelector((state) => state.ngoList);
  const { states } = useSelector((state) => state.statesList);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlock] = useState([]);
  const [villages, setVillage] = useState([]);

  const dispatch = useDispatch();
  const ngoInitialValues = {
    name: "",
    mobile: "",
    email: "",
    role_id: "",
    ngo_id: "",
  };
  const foInitialValues = {
    name: "",
    mobile: "",
    state_id: "",
    district_id: "",
    block_id: "",
    ngo_id: ""
  }
  const sakhiInitialValues = {
    name: "",
    state_id: "",
    mobile: "",
    district_id: "",
    block_id: "",
    village_id: "",
    ngo_id: "",
    sakhi_fo_name: "",
    date_of_joining: "",
    job_profile: ""
  }

  const formInputList = [
    {
      key: "name",
      name: "name",
      label: "Name *",
      inputType: "text",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Enter Name",
    },
    ...(selectedUser.ngo) ? [
      {
        key: "role_id",
        name: "role_id",
        label: "Level",
        inputType: "autoComplete",
        multiple: false,
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        optionArray: [{ id: 2, name: 'Ngo Partner' }] || [],
        value: "",
        placeholder: "Select State",
      },
      {
        key: "mobile",
        name: "mobile",
        label: "Mobile no. *",
        inputType: "number",
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        value: "",
        placeholder: "Enter Contact number",
      },
      {
        key: "email",
        name: "email",
        label: "Email *",
        inputType: "email",
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        value: "",
        placeholder: "Enter Email",
      },
    ] : [],
    ...(selectedUser.ngo == false) ? [
      {
        key: "mobile",
        name: "mobile",
        label: "Mobile no. *",
        inputType: "number",
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        value: "",
        placeholder: "Enter Contact number",
      },
      {
        key: "state_id",
        name: "state_id",
        label: "State",
        inputType: "autoComplete",
        multiple: false,
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        optionArray: states?.data || [],
        value: "",
        placeholder: "Select State",
      },
      {
        key: "district_id",
        name: "district_id",
        label: "District",
        inputType: "autoComplete",
        multiple: false,
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        optionArray: districts || [],
        value: "",
        placeholder: "Select District",
      },
      {
        key: "block_id",
        name: "block_id",
        label: "Block",
        inputType: "autoComplete",
        multiple: true,
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        optionArray: blocks || [],
        value: "",
        placeholder: "Select Block",
      },
    ] : [],
    ...(selectedUser.sakhi) ? [
      {
        key: "village_id",
        name: "village_id",
        label: "Village",
        inputType: "autoComplete",
        multiple: true,
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        optionArray: villages || [],
        value: "",
        placeholder: "Select Village",
      },
    ] : [],
    {
      key: "ngo_id",
      name: "ngo_id",
      label: "Name of NGO",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: ngos?.data || [],
      value: "",
      placeholder: "Select NGO",
    },
    ...(selectedUser.sakhi) ? [
      {
        key: "sakhi_fo_name",
        name: "sakhi_fo_name",
        label: "Name of FO",
        inputType: "text",
        multiple: false,
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        value: "",
        placeholder: "Select FO",
      },
      {
        key: "date_of_joining",
        name: "date_of_joining",
        label: "Onboarding year",
        inputType: "year",
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        value: "",
        placeholder: "Select Year",
      },
      {
        key: "job_profile",
        name: "job_profile",
        label: "Secondary Job",
        inputType: "text",
        required: true,
        disabled: crudAction?.delete || crudAction?.view ? true : false,
        value: "",
        placeholder: "Secondary Job",
      },
    ] : [],
  ];

  const uuid = uuidv4();
  const onSubmit = (values) => {
    values = {
      ...values,
      txn_id: userDetails.txn_id ? userDetails.txn_id : uuid,
      mobile: typeof values.mobile === 'number' ? values.mobile.toString() : values.mobile,
    }
    crudAction.create
      ? dispatch(userCreateAction(values, refetchUserList))
      : crudAction.edit
        ? dispatch(userUpdateAction(userDetails?.id, values, refetchUserList))
        : crudAction.delete
          ? dispatch(userDeleteAction(userDetails?.id, refetchUserList))
          : console.log("hello");
  }

  const formInitialValues = selectedUser.ngo ? ngoInitialValues : selectedUser.fo ? foInitialValues : sakhiInitialValues
  return (
    <Formik
      // enableReinitialize={crudAction.create ? false : true}
      initialValues={formInitialValues}
      validationSchema={crudAction.delete ? Yup.object().shape({}) : Yup.object().shape({
        mobile: Yup.string()
          .matches(phoneRegExp, "Phone number is not valid")
          .min(10, "to short")
          .max(10, "to long")
          .required("Mobile is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        name: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Name is required"),
        ngo_id: Yup.string().required("Name of NGO is required"),
        role_id: Yup.string().required("Level is required"),
      })}
      onSubmit={async (values, { setErrors, errors, setStatus, setSubmitting }) => {
        onSubmit(values);
      }}
    >
      {function Render({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        setErrors,
        setStatus,
        setSubmitting,
        resetForm,
        values,
      }) {
        useEffect(() => () => {
          if (isFormOpen == false) {
            for (let elem in formInitialValues) {
              setFieldTouched(elem, false);
              setFieldValue(elem, "", false);
            }
          }
        }, []);

        useEffect(() => {
          for (let elem in formInitialValues) {
            setFieldTouched(elem, false);
          };
          if (userDetails) {
            for (let elem in formInitialValues) {
              if (elem == 'role_id') setFieldValue(elem, selectedUser.ngo ? 2 : "");
              else if (elem === "job_profile") setFieldValue(elem, !userDetails[elem] ? "No" : JSON.parse(userDetails[elem])?.length > 0 ? "Yes" : "No");
              else setFieldValue(elem, userDetails[elem]);
            };
          }
        }, [userDetails])

        useEffect(() => {
          if (values.state_id) {
            fetchApiDataList("district", { state_id: values?.state_id, fetched: "All" }, setDistricts);
          } else {
            setFieldValue("district_id", "");
            setDistricts([]);
          }
        }, [values.state_id]);

        useEffect(() => {
          if (values.district_id) {
            fetchApiDataList("block", { state_id: values?.state_id, district_id: values?.district_id, fetched: "All", }, setBlock);
          } else {
            setFieldValue("block_id", "");
            setBlock([]);
          }
        }, [values.district_id]);

        useEffect(() => {
          if (values.block_id) {
            fetchApiDataList("village", { state_id: values?.state_id, district_id: values?.district_id, block_id: values.block_id, fetched: "All", }, setVillage);
          } else {
            setFieldValue("village_id", "");
            setVillage([]);
          }
        }, [values.block_id]);

        return (
          <form
            className='bg-transparent  pb-6 overflow-hidden'
            onSubmit={handleSubmit}
          // {...rest}
          >
            <Grid
              spacing={2}
              container
              className="bg-white  p-4 "
            >
              {formInputList?.map((elem, INDEX) => {
                return (
                  <CustomInputs
                    name={elem?.name}
                    id={elem?.key + INDEX.toString()}
                    label={elem?.label}
                    inputType={elem?.inputType}
                    value={values[elem.name]}
                    required={elem?.required}
                    handleBlur={handleBlur}
                    onClick={() => setFieldTouched(elem.name, true)}
                    onChange={(event, value) => {
                      if (elem.inputType == 'autoComplete' || elem.inputType == 'date') setFieldValue(event, value);
                      else handleChange(event);
                    }}
                    error={errors[elem.name] && touched[elem.name]}
                    helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                    placeholder={elem?.placeholder}
                    disabled={elem?.disabled}
                    multiple={elem?.multiple}
                    optionArray={elem?.optionArray}
                    acceptedFiles={elem?.acceptedFiles}
                    crudAction={crudAction}
                    min={elem?.min}
                    max={elem?.max}
                    initialValues={formInitialValues}
                  />
                )
              })}
              <Grid Grid item xs={12} sx={12} md={12} >
                {!crudAction.view && (
                  <Box className="w-full bg-gray-100 mt-10">
                    <Button
                      className="w-full"
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      {crudAction.create
                        ? "CREATE"
                        : crudAction.edit
                          ? "UPDATE"
                          : "DELETE"}
                    </Button>
                  </Box>
                )
                }
              </Grid>
            </Grid >

          </form >
        );
      }}
    </Formik >
  );
}

export default React.memo(AdminUsersCRUDForm);


{/* <Grid item xs={8} sx={12} md={12}>
<TextField
  labelId='label'
  error={touched.name && errors.name}
  helperText={touched.name && errors.name}
  name="name"
  className="inputRounded"
  label='Name'
  placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
  fullWidth
  value={values.name}
  onBlur={handleBlur}
  onChange={handleChange}
  disabled={(crudAction.view || crudAction.delete) ? true : false}
  required
  style={{ borderRadius: 10 }}
  variant="outlined"
  InputLabelProps={{
    shrink: true,
    style: {
      backgroundColor: 'white',
      paddingRight: '8px',
      fontSize: 14
    }
  }}
/>
</Grid>
<Grid item xs={12} sx={12} md={12}>
<AutocompleteComponent
  required={true}
  name={"role_id"}
  options={selectedUser?.ngo ? [{ id: 2, name: 'Ngo Partner' }] : selectedUser?.fo ? [{ id: 3, name: 'FO' }] : selectedUser?.sakhi ? [{ id: 4, name: 'Sakhi' }] : undefined}
  setFieldValue={setFieldValue}
  handleBlur={handleBlur}
  touched={touched}
  value={values?.role_id}
  errors={errors}
  multiple={false}
  reset={{}}
  formData={formData}
  setFormData={setFormData}
  disable={(crudAction.view || crudAction.delete)}
  label='Level *'
/>
</Grid>
<Grid item xs={12} sx={12} md={12}>
<TextField
  error={touched.mobile && errors.mobile}
  helperText={touched.mobile && errors.mobile}
  name="mobile"
  className="inputRounded"
  label='Mobile no'
  placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
  fullWidth
  value={values.mobile}
  onBlur={handleBlur}
  onChange={handleChange}
  disabled={crudAction.view || crudAction.edit || crudAction?.delete ? true : false}
  required
  style={{ borderRadius: 10 }}
  variant="outlined"
  InputLabelProps={{
    shrink: true,
    style: {
      backgroundColor: 'white',
      paddingRight: '8px',
      fontSize: 14
    }
  }}
/>
</Grid>
<Grid item xs={12} sx={12} md={12}>
<TextField
  error={touched.email && errors.email}
  helperText={touched.email && errors.email}
  name="email"
  label='Email'
  className="inputRounded"
  placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
  fullWidth
  value={values.email}
  onBlur={handleBlur}
  onChange={handleChange}
  disabled={(crudAction.view || crudAction.delete) ? true : false}
  required
  style={{ borderRadius: 10 }}
  variant="outlined"
  InputLabelProps={{
    shrink: true,
    style: {
      backgroundColor: 'white',
      paddingRight: '8px',
      fontSize: 14
    }
  }}
/>
</Grid>
<Grid item xs={12} sx={12} md={12}>
<AutocompleteComponent
  required={true}
  label='Name of NGO *'
  name={"ngo_id"}
  options={ngos?.data}
  setFieldValue={setFieldValue}
  handleBlur={handleBlur}
  touched={touched}
  value={values?.ngo_id}
  errors={errors}
  multiple={false}
  reset={{}}
  formData={formData}
  setFormData={setFormData}
  disable={(crudAction.view || crudAction.delete)}
/>
</Grid> */}