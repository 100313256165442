import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { MOU_LIST_REQUEST, MOU_LIST_SUCCESS, MOU_LIST_FAIL, MOU_CREATE_REQUEST, MOU_CREATE_FAIL, MOU_CREATE_SUCCESS, MOU_UPDATE_REQUEST, MOU_UPDATE_FAIL, MOU_DELETE_REQUEST, MOU_DELETE_FAIL, MOU_UPDATE_SUCCESS, MOU_DELETE_SUCCESS } = require("../Conistance/MOUConistance")

const BASE_URL = process.env.REACT_APP_API_URL

export const mouListAction = (filter) => async (dispatch) => {
    dispatch({ type: MOU_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/mou/get_all_mou${paramsUrl(filter)}`)

        dispatch({ type: MOU_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: MOU_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const mouCreateAction = (body, refetchUserList) => async (dispatch) => {
    dispatch({ type: MOU_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/mou/create`, body)
        dispatch({ type: MOU_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: MOU_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const mouUpdateAction = (bodyId, body, refetchUserList) => async (dispatch) => {
    dispatch({ type: MOU_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/mou/update/${bodyId}`, body)
        dispatch({ type: MOU_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: MOU_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const mouDeleteAction = (mouId, refetchUserList) => async (dispatch) => {
    dispatch({ type: MOU_DELETE_REQUEST })
    try {

        const data = await fetchWrapper.put(`${BASE_URL}/mou/delete/${mouId}`)
        dispatch({ type: MOU_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: MOU_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}