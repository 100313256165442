export const  PURPOSE_LIST_REQUEST = 'PURPOSE_LIST_REQUEST'
export const PURPOSE_LIST_SUCCESS = 'PURPOSE_LIST_SUCCESS'
export const PURPOSE_LIST_FAIL = 'PURPOSE_LIST_FAIL'

export const PURPOSE_CREATE_FAIL = 'PURPOSE_CREATE_FAIL'
export const PURPOSE_CREATE_REQUEST = 'PURPOSE_CREATE_REQUEST'
export const PURPOSE_CREATE_SUCCESS = 'PURPOSE_CREATE_SUCCESS'

export const PURPOSE_UPDATE_REQUEST = 'PURPOSE_UPDATE_REQUEST'
export const PURPOSE_UPDATE_SUCCESS = 'PURPOSE_UPDATE_SUCCESS'
export const PURPOSE_UPDATE_FAIL = 'PURPOSE_UPDATE_FAIL'

export const PURPOSE_DELETE_REQUEST = 'PURPOSE_DELETE_REQUEST'
export const PURPOSE_DELETE_SUCCESS = 'PURPOSE_DELETE_SUCCESS'
export const PURPOSE_DELETE_FAIL = 'PURPOSE_DELETE_FAIL'