export const commonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "District",
    name: "district",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Block",
    name: "block",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Centre",
    name: "centre",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },

  // {
  //   lable: "Centre",
  //   name: "centre",
  //   type: "select",
  //   multiple: false,
  //   placeholder: "Select",
  //   items: [
  //     {
  //       id: "admin",
  //       name: "Admin",
  //     },
  //   ],
  // },
];
export const commonReportFilterData = [
  {
    lable: "NGO Name",
    name: "ngoName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Purpose",
    name: "purpose",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "District",
    name: "district",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Block",
    name: "block",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },

  {
    lable: "Year",
    name: "year",
    type: "date",
    multiple: false,
    placeholder: "Select Year",
    // options: [],
  },

  // {
  //   lable: "Centre",
  //   name: "centre",
  //   type: "select",
  //   multiple: false,
  //   placeholder: "Select",
  //   items: [
  //     {
  //       id: "admin",
  //       name: "Admin",
  //     },
  //   ],
  // },
];
export const traningKitCommonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Purpose",
    name: "purpose",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Scheme",
    name: "scheme",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },

  {
    lable: "File Name",
    name: "name",
    type: "text",
    multiple: false,
    placeholder: "Enter",
  },
];
export const bannerFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "File Name",
    name: "name",
    type: "text",
    multiple: false,
    placeholder: "Enter",
  },
]
export const foCommonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "District",
    name: "district",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Block",
    name: "block",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },

  {
    lable: "Centre",
    name: "centre",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Fo Name",
    name: "foName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
];
export const sakhiCommonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "District",
    name: "district",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Block",
    name: "block",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },

  {
    lable: "Village",
    name: "village",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Fo Name",
    name: "foName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Sakhi Name",
    name: "sakhiName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
];

export const adminUsersCommonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "District",
    name: "district",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Block",
    name: "block",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: 'NGO Name',
    name: 'ngoName',
    type: 'autocompleted',
    multiple: false,
    placeholder: 'Enter',
    options: []
  },
  {
    lable: 'FO Name',
    name: 'foName',
    type: 'autocompleted',
    multiple: false,
    placeholder: 'Enter',
    options: []
  },
  {
    lable: "User's Name",
    name: "name",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Onboarding Year",
    name: "year",
    type: "date",
    multiple: false,
    placeholder: "Select Year",
    // options: [],
  },
  // {
  //   lable: "Username",
  //   name: "username",
  //   type: "autocompleted",
  //   multiple: false,
  //   placeholder: "Enter",
  //   options: [],
  // },
  // {
  //   lable: "Email",
  //   name: "email",
  //   type: "autocompleted",
  //   multiple: false,
  //   placeholder: "Enter",
  //   options: [],
  // },
  // {
  //   lable: "Level",
  //   name: "level",
  //   type: "autocompleted",
  //   multiple: false,
  //   placeholder: "Enter",
  //   options: ["Ngo Partner",'FO',"Sakhi"],
  // },
  // {
  //   lable: "Mobile",
  //   name: "mobile",
  //   type: "autocompleted",
  //   multiple: false,
  //   placeholder: "Enter",
  //   options: [],
  // },
];
export const adminCommonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "select",
    multiple: false,
    placeholder: "Select",
    items: [
      {
        id: "admin",
        name: "Admin",
      },
    ],
  },
  {
    lable: "Purpose",
    name: "purpose",
    type: "select",
    multiple: false,
    placeholder: "Select",
    items: [
      {
        id: "admin",
        name: "Admin",
      },
    ],
  },
  {
    lable: "Scheme",
    type: "select",
    name: "scheme",
    multiple: false,
    placeholder: "Select",
    items: [
      {
        id: "admin",
        name: "Admin",
      },
    ],
  },
];
export const masterCommonFilterData = [
  {
    lable: "State",
    name: "state_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "District",
    name: "district_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Block",
    name: "block_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Village",
    name: "village_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Ngo",
    name: "ngo_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "PO Number",
    name: "po_number",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Centre",
    name: "centre_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Purpose",
    name: "purpose_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Scheme",
    name: "scheme_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
];
export const feedbackCommonFilterData = [
  {
    lable: "State",
    name: "state_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "District",
    name: "district_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "Block",
    name: "block_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
  {
    lable: "NGO Name",
    name: "ngo_id",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: [],
  },
]
