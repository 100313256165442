import { Box, Typography } from "@material-ui/core";
import React from 'react'
import { useEffect } from "react";
import { useState } from "react";
import lockSvg from 'src/assets/images/lock.svg';
import login1Png from 'src/assets/images/login1.png';
import login2Png from 'src/assets/images/login2.png';
import login3Png from 'src/assets/images/login3.png';

function Slider({ isResetPass }) {

  const [selected, setSelected] = useState(1)
  const [selecting, setSelecting] = useState(false)
  const handleClick = (e) => {
    setSelecting(true)
    let value = e.target.getAttribute("data-value");
    if (value !== null) {
      setSelected(value)
    }
  };
  const style =
    selected >= 1
      ? { transform: `translateX(-${selected * 40}rem)` }
      : {};
  const renderIndicator = [];
  for (let i = 0; i < 3; i++) {
    renderIndicator.push(
      <li
        data-value={i}
        key={i}
        className={selected == i ? "active" : "bg-red-400"}
      ></li>
    );
  }



  useEffect(() => {
    if (!selecting) {
      setTimeout(() => {
        if (selected < 2) {
          setSelected(pre => pre + 1)
        } else {
          setSelected(0)
        }
      }, 2000);
    }
  }, [selected, selecting])

  // setInterval(() => {
  //   setSelecting(false)
  // }, 5000);

  return (
    <div className="w-[40rem]   overflow-hidden  z-50  hidden space-y-2 sm:block ">
      {/* <div className="rounded-xl overflow-hidden h-full"> */}
      {isResetPass ? (
        <div className=" w-full flex justify-center items-center h-full p-2 ">
          <img className="w-full h-full" src={lockSvg} />
        </div>
      ) : null}
      {isResetPass ? null : (
        <div className="slide-items w-[120rem]  " style={style}>
          <div className="item w-4/12">
            <Box className=" w-full   h-full ">
              <img
                className="w-full h-full object-fit "
                src={login2Png}
                alt="logo"
              />
            </Box>
            <Box className=" w-9/12 text-center flex justify-center items-center pr-2">
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Impacts: We identify the women by connecting with Self Help
                Groups NGOs and local leaders
              </Typography>
            </Box>
          </div>
          <div className="item w-4/12">
            <Box className=" w-full  h-full  ">
              <img
                className="w-full h-full object-fit"
                src={login1Png}
                alt="logo"
              />
            </Box>
            <Box className=" w-9/12 text-center flex justify-center items-center pr-2">
              <Typography variant="h6" >
                Women Empowerment and Financial Inclusion
              </Typography>
            </Box>
          </div>
          <div className="item w-4/12  ">
            <Box className=" w-full  h-full">
              <img
                className="w-full h-full object-fit"
                src={login3Png}
                alt="logo"
              />
            </Box>
            <Box className=" w-9/12 text-center flex justify-center items-center pr-2">
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Empowering financially excluded and under privileged women
                in rural india
              </Typography>
            </Box>
          </div>
        </div>
      )}
      {/* </div> */}
      {!isResetPass && (
        <ul onClick={handleClick} className="indicators">
          {renderIndicator}
        </ul>
      )}
    </div>
  )
}

export default Slider
