import { Box, Button, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReportTable from "./Table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { stateListAction } from "src/Redux/Actions/StateAction";
import LoadingBox from "src/components/LoadingBox";
import { useMemo } from "react";
import { fetchWrapper } from "src/services/http_requests";
import { generateSerialNo } from "src/utils";
import { ArrowBack, CloudDownload, FilterList } from "@material-ui/icons";
import theme from "src/theme";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import {
  activeTypeStyle,
  columnsScheme,
  createDataSakhi,
  createDataScheme,
  deActiveTypeStyle,
  monthlyReportCreateData,
  montlyReportColumn,
  sakhiColumn,
  tabActiveStyle,
  tabDeActiveStyle,
} from "./utils";
import { getToken, loadData } from "src/utils/sessionStorage";
import { paramsUrl } from "src/utils/halper";
import moment from "moment";
import { toast } from "react-toastify";
import CommonFilter2 from "src/components/CommonComponents/CommonFilter2";
import {
  gvtAlDsaArchivedData,
  gvtBnsArchivedData,
  rbksArchivedData,
  rgbnArchivedData,
  sestaArchivedData,
} from "./jsonData/archievedMilestone";
import { parseInt } from "lodash";
import archievedData from "./jsonData/archievedData.json";

function ReportsView() {
  const userData = loadData("loginInfo")?.data;
  const role_id = userData?.role_id;
  const pageInfo = localStorage.getItem("pageInfo");
  const monthlyArchievedRef = useRef(archievedData);
  const [filterData, setFilterData] = useState({
    state_id: "",
    district_id: "",
    block_id: "",
    ngo_id: "",
    fo_id: "",
    year: "",
    purpose_id: "",
    from: "",
    to: "",
  });

  const [showFilter, setShowFilter] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    sakhis: true,
    schemes: false,
    monthlyReport: false,
    milestoneReport: false,
    archievedMilestoneReport: false,
    budgetReport: false,
    archievedMonthly: false,
  });
  const [reportType, setReportType] = useState({
    geography: true,
    organisation: false,
  });

  const [selectedNgo, setSelectedNgo] = useState({
    n1: true,
    n2: false,
    n3: false,
    n4: false,
    n5: false,
  });
  const [archievedYear, setArchievedYear] = useState("2022");

  const [loading, setLoading] = useState(true);
  const [rowsLoading, setRowsLoading] = useState(false);
  const [sakhiGeographyData, setSakhiGeographyData] = useState([]);
  const [schemesGeographyData, setSchemesGeographyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [milestoneData, setMilestoneData] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  const [monthlyReportYear, setMonthlyReportYear] = useState(() =>
    moment().year()
  );

  const statesList = useSelector((state) => state.statesList);
  const { states } = statesList;

  const ngoList = useSelector((state) => state?.ngoList);
  const { ngos } = ngoList;
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(() =>
    pageInfo ? parseInt(pageInfo) : 1
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const downloadFileHandler = (url, query) => {
    let link = document.createElement("a");
    link.setAttribute(
      "href",
      `${process.env.REACT_APP_API_URL}${url}${paramsUrl(
        query
      )}&token=${getToken()}`
    );
    link.setAttribute("target", "_blank");
    link.click();
  };
  const getSakhiGeographyData = async (query) => {
    try {
      const data = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL
        }/report/sakhi_transaction_report${paramsUrl(query)}`
      );
      setSakhiGeographyData(data?.data);
      if (data?.total) {
        setTotal(data?.total);
        localStorage.setItem("totalData", data?.total);
      } else {
        if (parseInt(page) === 1) {
          setTotal(data?.data?.length);
          localStorage.setItem("totalData", data?.data?.length);
        } else setTotal(localStorage.getItem("totalData"));
      }
    } catch (error) {
      if (error) {
        toast.error(error);
      }
    } finally {
      setLoading(false);
      setRowsLoading(false);
    }
  };
  const getSchemesGeographyData = async (query) => {
    try {
      const data = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL}/report/scheme_report${paramsUrl(
          query
        )}`
      );
      setSchemesGeographyData(data?.data);
      if (data?.total) {
        setTotal(data?.total);
        localStorage.setItem("totalData", data?.total);
      } else {
        if (page === 1) setTotal(data?.data?.length);
        else setTotal(localStorage.getItem("totalData"));
      }
    } catch (error) {
      if (error) {
        toast.error(error);
      }
    } finally {
      setLoading(false);
      setRowsLoading(false);
    }
  };
  const getMonthlyData = async (query) => {
    let params = { ...query };

    const selectedyear = moment(params.from).year();
    setMonthlyReportYear(selectedyear);
    if (filterData.from && !filterData.to) {
      setLoading(false);
      return;
    }

    try {
      const { data } = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL}/report/monthly${paramsUrl(params)}`
      );
      setMonthlyData(data);
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        toast.error(error);
      }
    } finally {
      setRowsLoading(false);
    }
  };
  const getMilestoneData = async () => {
    try {
      const data = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL}/report/milestone${paramsUrl({
          state_id: filterData?.state_id,
          district_id: filterData?.district_id,
          ngo_id: filterData?.ngo_id,
          id: filterData.po_number,
        })}`
      );
      setMilestoneData(data);
    } catch (error) {
      if (error) {
        toast.error(error);
      }
    } finally {
      setLoading(false);
      setRowsLoading(false);
    }
  };
  const getArchievedMilestoneData = async () => {
    try {
      const data = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL}/report/milestone${paramsUrl({
          ngo_id: filterData?.ngo_id,
          id: filterData.po_number,
        })}`
      );
      setMilestoneData(data);
    } catch (error) {
      if (error) {
        toast.error(error);
      }
    } finally {
      setLoading(false);
      setRowsLoading(false);
    }
  };
  const getBudgetData = async () => {
    try {
      const data = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL}/budget/list${paramsUrl({
          ngo_id: filterData?.ngo_id,
          po_number: filterData.po_number,
        })}`
      );
      setBudgetData(data);
    } catch (error) {
      if (error) {
        toast.error(error);
      }
    } finally {
      setLoading(false);
      setRowsLoading(false);
    }
  };

  const fetchAllApis = () => {
    const year = filterData?.year;
    if (selectedReport?.sakhis) {
      let param = {};
      if (reportType?.geography) {
        if (!filterData?.state_id) {
          param = { level: "state", page: page, year };
        } else if (filterData?.state_id && filterData?.district_id === "") {
          param = {
            level: "district",
            state_id: filterData?.state_id,
            page: page,
            year,
          };
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id === ""
        ) {
          param = {
            level: "block",
            district_id: filterData?.district_id,
            page: page,
            year,
          };
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id
        ) {
          param = {
            level: "block",
            block_id: filterData?.block_id,
            page: page,
            year,
          };
        }
      } else if (reportType?.organisation) {
        if (!filterData?.ngo_id) {
          param = { level: "ngo", page: page, year };
        } else if (filterData?.ngo_id && !filterData?.state_id) {
          param = {
            level: "state",
            ngo_id: filterData?.ngo_id,
            page: page,
            year,
          };
        } else if (
          filterData?.ngo_id &&
          filterData?.state_id &&
          filterData?.district_id === ""
        ) {
          param = {
            level: "district",
            ngo_id: filterData?.ngo_id,
            state_id: filterData?.state_id,
            page: page,
            year,
          };
        } else if (
          filterData?.ngo_id &&
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id === ""
        ) {
          param = {
            level: "block",
            ngo_id: filterData?.ngo_id,
            district_id: filterData?.district_id,
            page: page,
            year,
          };
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id
        ) {
          param = {
            level: "block",
            ngo_id: filterData?.ngo_id,
            block_id: filterData?.block_id,
            page: page,
            year,
          };
        }
      }
      getSakhiGeographyData(param);
    } else if (selectedReport?.schemes) {
      let param = {};
      if (reportType?.geography) {
        if (filterData?.state_id === "") {
          param = { level: "state", page: page, year };
        } else if (filterData?.state_id && !filterData?.purpose_id) {
          param = {
            level: "scheme",
            state_id: filterData?.state_id,
            page: page,
            year,
          };
        } else if (filterData?.state_id && filterData?.purpose_id) {
          param = {
            level: "purpose_mapper",
            state_id: filterData?.state_id,
            scheme_id: filterData?.purpose_id,
            page: page,
            year,
          };
        } else return;
      } else if (reportType?.organisation) {
        if (!filterData?.ngo_id && !filterData?.state_id) {
          param = { level: "ngo", page: page, year };
        } else if (filterData?.ngo_id && !filterData?.purpose_id) {
          param = {
            level: "scheme",
            ngo_id: filterData?.ngo_id,
            page: page,
            year,
          };
        } else if (filterData?.ngo_id && filterData?.purpose_id) {
          param = {
            level: "purpose_mapper",
            ngo_id: filterData?.ngo_id,
            scheme_id: filterData?.purpose_id,
            page: page,
            year,
          };
        } else return;
      }
      getSchemesGeographyData(param);
    } else if (selectedReport?.monthlyReport) {
      getMonthlyData({
        state_id: filterData?.state_id,
        district_id: filterData?.district_id,
        block_id: filterData?.block_id,
        ngo_id: filterData?.ngo_id,
        fo_id: filterData?.fo_id,
        year: filterData?.year,
        from: filterData?.from
          ? moment(filterData?.from).format("YYYY-MM-DD")
          : moment(`${moment().year()}-01-01`).format("YYYY-MM-DD"),
        to: filterData?.to
          ? moment(filterData?.to).format("YYYY-MM-DD")
          : moment(`${moment().year()}-12-31`).format("YYYY-MM-DD"),
      });
    } else {
      setLoading(false);
      setRowsLoading(false);
    };
  };

  useEffect(() => {
    fetchAllApis();
    return () => {
      fetchWrapper?.finishPendingRequests();
    };
  }, [reportType, filterData, selectedReport, page]);

  const milestoneColumns = useMemo(() => {
    if (Object.keys(milestoneData?.data || {}).length > 0) {
      let firstHeader = [
        { id: "sno", label: "", align: "left" },
        {
          id: "matrics",
          label: "",
          align: "left",
          minWidth: 200,
          maxWidth: 250,
        },
      ];
      let secoundHeader = [
        { id: "sno", label: "S.No" },
        { id: "matrics", label: "Key Metrics", minWidth: 200, maxWidth: 250 },
      ];

      const firstKey = Object.keys(milestoneData?.data)[0];

      milestoneData?.data?.[firstKey]?.phaseList?.map((item, index) => {
        firstHeader.push({
          id: `phase`,
          label: `MOU P${index + 1} (${moment(item?.start_month).format(
            "MMM, YYYY"
          )} - ${moment(item?.end_month).format("MMM, YYYY")})`,
        });
        secoundHeader.push({ id: `target${index}`, label: `Target` });
        secoundHeader.push({ id: `achieved${index}`, label: `Achieved` });
        return [];
      });
      return { firstHeader, secoundHeader };
    } else return [];
  }, [milestoneData]);

  const milestoneRows = useMemo(() => {
    if (Object.keys(milestoneData?.data || {})?.length > 0) {
      const createTargetAcheived = (target, achieved) => {
        let targetAchieved = [0, "0", null].includes(target)
          ? "0"
          : [0, "0", null].includes(achieved)
            ? "0"
            : ((parseInt(achieved) / parseInt(target)) * 100).toFixed(2);
        return (
          <div className="flex justify-between">
            <span className="py-1">{achieved || "-"}</span>
            <span
              className={`px-2 py-1 rounded-md text-xs font-medium
          ${targetAchieved >= 75
                  ? "bg-[#ECFDF5] text-[#064E3B]"
                  : targetAchieved >= 33 && targetAchieved < 75
                    ? "text-[#997411] bg-[#FFF4D8]"
                    : "bg-[#FAD4D4] text-[#E11531]"
                }
            `}
            >
              {targetAchieved}%
            </span>
          </div>
        );
      };
      let i = -1;
      let row = [];
      for (let item in milestoneData?.data) {
        let phases = {};
        milestoneData?.data?.[item]?.phaseList?.map((phase, i) => {
          phases[`target${i}`] = phase.target || "-";
          phases[`achieved${i}`] = createTargetAcheived(
            phase.target,
            phase?.achieved
          );
          return [];
        });

        i++;
        row.push({
          sno: generateSerialNo(i, page),
          matrics: milestoneData?.data?.[item]?.milestone,
          ...phases,
        });
      }
      return row;
    } else return [];
  }, [milestoneData]);

  const budgetColumns = useMemo(() => {
    if (Object.keys(budgetData?.data || {})?.length > 0) {
      let firstHeader = [
        { id: "sno", label: "", align: "left" },
        {
          id: "matrics",
          label: "",
          align: "left",
          minWidth: 200,
          maxWidth: 250,
        },
        {
          id: "budget",
          label: "",
          align: "left",
          minWidth: 200,
          maxWidth: 200,
          colSpan: 2,
        },
      ];
      let secoundHeader = [
        { id: "sno", label: "S.No", align: "left" },
        {
          id: "matrics",
          label: "Budget Line Items",
          align: "left",
          minWidth: 200,
          maxWidth: 250,
        },
        {
          id: "target",
          label: "Overall senctioned Total Budget",
          align: "left",
          minWidth: 200,
          maxWidth: 200,
          colSpan: 1,
        },
        {
          id: "achieved",
          label: "As per UC total spent",
          align: "left",
          minWidth: 200,
          maxWidth: 200,
          colSpan: 1,
        },
      ];

      if (budgetData?.data?.data?.length > 0) {
        budgetData?.data?.data?.[0]?.budgetList?.map((elem, index) => {
          const monthName = moment()
            .month(elem?.month - 1)
            .format("MMM");

          firstHeader.push({
            id: elem?.id || index,
            label: (
              <span>
                {monthName} - {budgetData?.data?.financial_year}
              </span>
            ),
            padding: 0,
            colSpan: 2,
          });

          secoundHeader.push({
            id: `target${index}`,
            label: "Projection",
            colSpan: 1,
          });
          secoundHeader.push({
            id: `achieved${index}`,
            label: "Expense",
            colSpan: 1,
          });

          return [];
        });
      }
      return { firstHeader, secoundHeader };
    } else return [];
  }, [budgetData]);

  const budgetRows = useMemo(() => {
    if (Object.keys(budgetData?.data || {})?.length > 0) {
      let i = -1;
      let row = [];
      const createTargetAcheived = (target, achieved) => {
        let targetAchieved = [0, "0", null].includes(target)
          ? "0"
          : [0, "0", null].includes(achieved)
            ? "0"
            : ((parseInt(achieved) / parseInt(target)) * 100).toFixed(2);

        return (
          <div className="flex justify-between">
            <span
              className={`px-2 py-1 font-medium
          ${targetAchieved >= 75
                  ? "text-[#E11531]"
                  : targetAchieved >= 33 && targetAchieved < 75
                    ? "text-[#997411]"
                    : "text-[#064E3B]"
                }
            `}
            >
              ₹{achieved}
            </span>
          </div>
        );
      };

      let total = {
        target: 0,
        achieved: 0,
        monthlytarget: {},
        monthlyAchieved: {},
        allBudgets: {},
      };

      budgetData?.data?.data?.map((item) => {
        let budgets = {};
        let totalAchieved = 0;
        item?.budgetList?.map((budget, i) => {
          budgets[`target${i}`] = budget.target ? "₹" + budget.target : "-";
          budgets[`achieved${i}`] = budget?.achieved
            ? createTargetAcheived(budget?.target, budget?.achieved)
            : "-";
          totalAchieved += budget.achieved ? parseInt(budget.achieved) : 0;

          total.monthlytarget[`target${i}`] = !total.monthlytarget[`target${i}`]
            ? budget.target
              ? budget.target
              : 0
            : total.monthlytarget[`target${i}`] + (budget.target || 0);

          total.monthlyAchieved[`achieved${i}`] = !total.monthlyAchieved[
            `achieved${i}`
          ]
            ? budget.achieved
              ? budget.achieved
              : 0
            : total.monthlyAchieved[`achieved${i}`] + (budget.achieved || 0);
          return [];
        });
        i++;

        total = {
          ...total,
          target: item?.amount ? total.target + item?.amount : total.target,
          achieved: totalAchieved
            ? total.achieved + totalAchieved
            : total.achieved,
          allBudgets: { ...total.monthlyAchieved, ...total.monthlytarget },
        };

        row.push({
          sno: generateSerialNo(i, page),
          matrics: item?.budget_lineitem || "-",
          target: item?.amount ? "₹" + item?.amount : "-",
          achieved: totalAchieved ? "₹" + totalAchieved : "-",
          ...budgets,
        });
        return [];
      });

      total.target &&
        row.push({
          sno: "",
          matrics: "Total",
          target: total?.target,
          achieved: total?.achieved,
          ...total.allBudgets,
        });

      return row;
    } else return [];
  }, [budgetData]);

  const filterToDisplay = selectedReport?.monthlyReport
    ? ["state_id", "district_id", "block_id", "from", "to", "ngo_id", "fo_id", "year"]
    : selectedReport?.milestoneReport
      ? ["state_id", "district_id", "ngo_id", "mou_po_number"]
      : selectedReport?.budgetReport
        ? ["ngo_id", "budget_po_number"]
        : selectedReport?.archievedMilestoneReport
          ? ["ngo_id"]
          : selectedReport?.sakhis && reportType?.organisation
            ? ["ngo_id", "state_id", "district_id", "block_id", "year"]
            : selectedReport?.schemes && reportType?.organisation
              ? ["ngo_id", "state_id", "district_id", "block_id", "year"]
              : selectedReport?.schemes && reportType?.geography
                ? filterData?.state_id
                  ? ["state_id", "purpose_id", "year"]
                  : ["state_id", "year"]
                : ["state_id", "district_id", "block_id", "year"];

  const handleDownloadClick = () => {
    let monthlyDataUrl = "/report/monthly";
    let milestoneDataUrl = "/report/milestone";
    let budgerDataUrl = "/budget/list";
    let sakhiGeoDataUrl = "/report/sakhi_transaction_report";
    let schemeGeoDataUrl = "/report/scheme_report";

    if (selectedReport?.monthlyReport) {
      downloadFileHandler(monthlyDataUrl, {
        ngo_id: filterData?.ngo_id,
        fo_id: filterData?.fo_id,
        year: filterData?.year,
        state_id: filterData?.state_id,
        district_id: filterData?.district_id,
        block_id: filterData?.block_id,
        from: filterData?.from
          ? moment(filterData?.from).format("YYYY-MM-DD")
          : moment(`${moment().year()}-01-01`).format("YYYY-MM-DD"),
        to: filterData?.to
          ? moment(filterData?.to).format("YYYY-MM-DD")
          : moment(`${moment().year()}-12-31`).format("YYYY-MM-DD"),
        isCsv: true,
      });
    } else if (selectedReport?.sakhis) {
      if (reportType?.geography) {
        if (filterData?.state_id === "") {
          downloadFileHandler(sakhiGeoDataUrl, { level: "state", isCsv: true });
        } else if (filterData?.state_id && filterData?.district_id === "") {
          downloadFileHandler(sakhiGeoDataUrl, {
            level: "district",
            state_id: filterData?.state_id,
            isCsv: true,
          });
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id === ""
        ) {
          downloadFileHandler(sakhiGeoDataUrl, {
            level: "block",
            district_id: filterData?.district_id,
            isCsv: true,
          });
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id
        ) {
          downloadFileHandler(sakhiGeoDataUrl, {
            level: "block",
            block_id: filterData?.block_id,
            isCsv: true,
          });
        } else return;
      } else if (reportType?.organisation) {
        if (filterData?.ngo_id === "") {
          downloadFileHandler(sakhiGeoDataUrl, { level: "ngo", isCsv: true });
        } else if (filterData?.ngo_id) {
          downloadFileHandler(sakhiGeoDataUrl, {
            level: "ngo",
            ngo_id: filterData?.ngo_id,
            isCsv: true,
          });
        }
      } else return;
    } else if (selectedReport?.schemes) {
      if (reportType?.geography) {
        if (filterData?.state_id === "") {
          downloadFileHandler(schemeGeoDataUrl, {
            level: "state",
            isCsv: true,
          });
        } else if (filterData?.state_id && !filterData?.purpose_id) {
          downloadFileHandler(schemeGeoDataUrl, {
            level: "scheme",
            state_id: filterData?.state_id,
            isCsv: true,
          });
        } else if (filterData?.state_id && filterData?.purpose_id) {
          downloadFileHandler(schemeGeoDataUrl, {
            level: "purpose_mapper",
            state_id: filterData?.state_id,
            scheme_id: filterData?.purpose_id,
            isCsv: true,
          });
        }
      } else if (reportType?.organisation) {
        if (!filterData?.ngo_id) {
          downloadFileHandler(schemeGeoDataUrl, {
            level: "ngo",
            state_id: filterData?.state_id,
            district_id: filterData?.district_id,
            block_id: filterData?.block_id,
            isCsv: true,
          });
        } else if (filterData?.ngo_id && filterData?.purpose_id === "") {
          downloadFileHandler(schemeGeoDataUrl, {
            level: "scheme",
            ngo_id: filterData?.ngo_id,
            isCsv: true,
          });
        } else if (filterData?.ngo_id && filterData?.purpose_id) {
          downloadFileHandler(schemeGeoDataUrl, {
            level: "purpose_mapper",
            ngo_id: filterData?.ngo_id,
            scheme_id: filterData?.purpose_id,
            isCsv: true,
          });
        }
      }
    } else if (selectedReport?.milestoneReport) {
      if (!filterData?.ngo_id && !filterData?.po_number) {
        toast.error("NGO and PO Number is Required!");
        return;
      }
      downloadFileHandler(milestoneDataUrl, {
        ngo_id: filterData?.ngo_id,
        id: filterData.po_number,
        state_id: filterData?.state_id,
        district_id: filterData?.district_id,
        isCsv: true,
      });
      // not asked by client
      // } else if (selectedReport?.budgetReport) {
      //   if (!filterData?.ngo_id && !filterData?.po_number) {
      //     toast.error("NGO and PO Number is Required!");
      //     return;
      //   }
      //   downloadFileHandler(budgerDataUrl, {
      //     ngo_id: filterData?.ngo_id,
      //     po_number: filterData.po_number,
      //     isCsv: true,
      //   });
    } else return;
  };
  const handleFilterSearch = () => {
    setRowsLoading(true);
    fetchWrapper?.finishPendingRequests();
    const year = filterData?.year;
    if (selectedReport?.monthlyReport) {
      if (filterData.from && !filterData.to) {
        setLoading(false);
        toast.error("Please select meeting to date with from date");
        return;
      }
      getMonthlyData({
        state_id: filterData?.state_id,
        district_id: filterData?.district_id,
        block_id: filterData?.block_id,
        ngo_id: filterData?.ngo_id,
        fo_id: filterData?.fo_id,
        year: filterData?.year,
        from: filterData?.from
          ? moment(filterData?.from).format("YYYY-MM-DD")
          : moment(`${moment().year()}-01-01`).format("YYYY-MM-DD"),
        to: filterData?.to
          ? moment(filterData?.to).format("YYYY-MM-DD")
          : moment(`${moment().year()}-12-31`).format("YYYY-MM-DD"),
      });
    } else if (selectedReport?.sakhis) {
      let param = {};
      if (reportType?.geography) {
        if (!filterData?.state_id) {
          param = { level: "state", year };
        } else if (filterData?.state_id && filterData?.district_id === "") {
          param = { level: "district", state_id: filterData?.state_id, year };
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id === ""
        ) {
          param = {
            level: "block",
            district_id: filterData?.district_id,
            year,
          };
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id
        ) {
          param = { level: "block", block_id: filterData?.block_id, year };
        }
      } else if (reportType?.organisation) {
        if (!filterData?.ngo_id) {
          param = { level: "ngo", page: 1, year };
        } else if (filterData?.ngo_id && !filterData?.state_id) {
          param = { level: "state", ngo_id: filterData?.ngo_id, page: 1, year };
        } else if (
          filterData?.ngo_id &&
          filterData?.state_id &&
          filterData?.district_id === ""
        ) {
          param = {
            level: "district",
            ngo_id: filterData?.ngo_id,
            state_id: filterData?.state_id,
            page: 1,
            year,
          };
        } else if (
          filterData?.ngo_id &&
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id === ""
        ) {
          param = {
            level: "block",
            ngo_id: filterData?.ngo_id,
            district_id: filterData?.district_id,
            page: 1,
            year,
          };
        } else if (
          filterData?.state_id &&
          filterData?.district_id &&
          filterData?.block_id
        ) {
          param = {
            level: "block",
            ngo_id: filterData?.ngo_id,
            block_id: filterData?.block_id,
            page: 1,
            year,
          };
        }
      }
      getSakhiGeographyData(param);
    } else if (selectedReport?.schemes) {
      let param = {};
      if (reportType?.geography) {
        if (!filterData?.state_id) {
          param = { level: "state", year };
        } else if (filterData?.state_id && !filterData?.purpose_id) {
          param = { level: "scheme", state_id: filterData?.state_id, year };
        } else if (filterData?.state_id && filterData?.purpose_id) {
          param = {
            level: "purpose_mapper",
            state_id: filterData?.state_id,
            scheme_id: filterData?.purpose_id,
            year,
          };
        }
      } else if (reportType?.organisation) {
        if (!filterData?.ngo_id) {
          param = {
            level: "ngo",
            state_id: filterData?.state_id,
            district_id: filterData?.district_id,
            block_id: filterData?.block_id,
            year,
          };
        } else if (filterData?.ngo_id && !filterData?.purpose_id) {
          param = { level: "scheme", ngo_id: filterData?.ngo_id, year };
        } else if (filterData?.ngo_id && filterData?.purpose_id) {
          param = {
            level: "purpose_mapper",
            ngo_id: filterData?.ngo_id,
            scheme_id: filterData?.purpose_id,
            year,
          };
        }
      }
      getSchemesGeographyData(param);
    } else if (selectedReport?.milestoneReport) getMilestoneData();
    else if (selectedReport?.archievedMilestoneReport)
      getArchievedMilestoneData();
    else if (selectedReport?.budgetReport) getBudgetData();
    else return;
  };
  const handleRestFilter = () => {
    setLoading(true);
    setFilterData({
      state_id: "",
      district_id: "",
      block_id: "",
      year: "",
      ngo_id: "",
      fo_id: "",
      from: "",
      to: "",
    });

    // if (selectedReport?.monthlyReport) getMonthlyData()
    // else if (selectedReport?.schemes) {
    //   if (reportType?.geography) getSchemesGeographyData({ level: 'state' })
    //   else getSchemesGeographyData({ level: 'ngo' })
    // } else
    if (selectedReport.milestoneReport) setMilestoneData([]);
    else if (selectedReport.archievedMilestoneReport) setMilestoneData([]);
    else if (selectedReport.budgetReport) setBudgetData([]);
    else return;
  };

  useEffect(() => {
    !states && dispatch(stateListAction({ fetched: "All" }));
    !ngos && dispatch(ngoListAction({ fetched: "All" }));
    window.scrollTo(0, 0);
    return () => fetchWrapper?.finishPendingRequests();
  }, [dispatch]);

  const convertInINRFormat = (number) => {
    let inrValue =
      number !== "-" ? new Intl.NumberFormat("en-IN").format(number) : "-";
    return inrValue;
  };

  const monthlyReportRows = monthlyData?.map((item, i) => {
    return monthlyReportCreateData(
      item?.title,
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 1)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 2)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 3)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 4)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 5)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 6)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 7)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 8)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 9)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 10)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 11)?.value || "-"
      ),
      convertInINRFormat(
        item?.data?.find((val) => parseInt(val.month) === 12)?.value || "-"
      )
    );
  });

  const schemeReportRows = schemesGeographyData?.map((item, i) => {
    return createDataScheme(
      generateSerialNo(i, page),
      <Box
        className="text-blue-500 cursor-pointer"
        onClick={() => {
          const param = reportType?.geography
            ? filterData?.state_id === ""
              ? { ...filterData, state_id: item?.id }
              : filterData?.state_id && !filterData?.purpose_id
                ? { ...filterData, purpose_id: item?.id }
                : {}
            : reportType?.organisation
              ? filterData?.ngo_id === ""
                ? { ...filterData, ngo_id: item?.id }
                : filterData?.ngo_id && !filterData?.purpose_id
                  ? { ...filterData, purpose_id: item?.id }
                  : {}
              : {};
          if (Object.keys(param).length > 0) {
            setRowsLoading(true);
            setFilterData(param);
          }
        }}
      >
        {item?.name}
      </Box>,
      item?.total_transaction,
      item?.no_of_linkages_sakhi,
      item?.total_income_sakhi,
      item?.avgRevenuPerSakhi,
      item?.avgRevenuPerLinkage
    );
  });

  const sakhiReportRows = sakhiGeographyData?.map((item, i) => {
    return createDataSakhi(
      generateSerialNo(i, page),
      <Box
        onClick={() => {
          const param = reportType?.geography
            ? filterData?.state_id === ""
              ? { ...filterData, state_id: item?.location_id }
              : filterData?.state_id && !filterData?.district_id
                ? { ...filterData, district_id: item?.location_id }
                : filterData?.district_id && !filterData?.block_id
                  ? { ...filterData, block_id: item?.location_id }
                  : {}
            : reportType?.organisation
              ? filterData?.ngo_id === ""
                ? { ...filterData, ngo_id: item?.location_id }
                : filterData?.ngo_id && !filterData?.state_id
                  ? { ...filterData, state_id: item?.location_id }
                  : filterData?.state_id && !filterData?.district_id
                    ? { ...filterData, district_id: item?.location_id }
                    : filterData?.district_id && !filterData?.block_id
                      ? { ...filterData, block_id: item?.location_id }
                      : ""
              : {};
          if (Object.keys(param).length > 0) {
            setRowsLoading(true);
            setFilterData(param);
          }
        }}
        className="text-blue-500 cursor-pointer"
      >
        {item?.name}
      </Box>,
      item?.total_onboarded_sakhi,
      item?.total_onboarded_sakhi_transaction,
      item?.total_onboarded_sakhi_income,
      item?.total_active_sakhi,
      item?.active_sakhi_total_transaction,
      item?.total_income_sakhi,
      // item?.inactive_no_of_transaction,
      // item?.inactive_no_of_transaction,
      // item?.inactive_income_of_sakhis,
      item?.earning_number_of_sakhi,
      item?.earning_number_of_transaction,
      item?.earning_income_of_sakhi
    );
  });

  const goToPreviousTable = () => {
    setRowsLoading(true);
    if (Object.values(filterData)?.every((elem) => elem === "")) return;
    else {
      let filter = {
        ngo_id: filterData?.ngo_id,
        state_id: filterData?.state_id,
        district_id: filterData?.district_id,
        block_id: filterData?.block_id,
        purpose_id: filterData?.purpose_id,
        year: filterData?.year,
      };
      let filledFilters = {};
      let emptyFiltes = {};
      let i = Object.values(filter)?.filter((elem) => elem !== "")?.length;
      for (let item in filter) {
        if (filter[item]) {
          filledFilters = {
            ...filledFilters,
            [item]: i > 1 ? filter[item] : "",
          };
          i--;
        } else {
          emptyFiltes = {
            ...emptyFiltes,
            [item]: "",
          };
        }
      }
      let newFilters = { ...filledFilters, ...emptyFiltes };
      setPage(1);
      setFilterData(newFilters);
    }
  };

  const createMonthlyArchievedData = (year, ngoName) => {
    const newData = monthlyArchievedRef?.current?.[year][ngoName]?.map(
      (elem, index) => {
        return {
          key: elem?.key,
          title: elem["Key Metrics"],
          data: [
            { value: elem["Jan"] || 0, month: "01" },
            { value: elem["Feb"] || 0, month: "02" },
            { value: elem["Mar"] || 0, month: "03" },
            { value: elem["Apr"] || 0, month: "04" },
            { value: elem["May"] || 0, month: "05" },
            { value: elem["Jun"] || 0, month: "06" },
            { value: elem["Jul"] || 0, month: "07" },
            { value: elem["Aug"] || 0, month: "08" },
            { value: elem["Sept"] || 0, month: "09" },
            { value: elem["Oct"] || 0, month: "10" },
            { value: elem["Nov"] || 0, month: "11" },
            { value: elem["Dec"] || 0, month: "12" },
          ],
        };
      }
    );
    setMonthlyData(newData);
  };

  useEffect(() => {
    if (selectedNgo && selectedReport?.archievedMilestoneReport) {
      if (selectedNgo?.n1) setMilestoneData(rgbnArchivedData);
      else if (selectedNgo?.n2) setMilestoneData(sestaArchivedData);
      else if (selectedNgo?.n3) setMilestoneData(gvtAlDsaArchivedData);
      else if (selectedNgo?.n4) setMilestoneData(gvtBnsArchivedData);
      else if (selectedNgo?.n5) setMilestoneData(rbksArchivedData);
    } else if (selectedReport.archievedMonthly) {
      if (selectedNgo?.n1)
        createMonthlyArchievedData(
          archievedYear,
          archievedYear === "2021" ? "RGVN_226" : "RGVN"
        );
      else if (selectedNgo?.n2)
        createMonthlyArchievedData(archievedYear, "SeSTA");
      else if (selectedNgo?.n3)
        createMonthlyArchievedData(archievedYear, "GVT");
      else if (selectedNgo?.n4)
        createMonthlyArchievedData(
          archievedYear,
          archievedYear === "2021" ? "RGVN_591" : "GVT_BNS"
        );
      else if (selectedNgo?.n5)
        createMonthlyArchievedData(archievedYear, "RBKS");
    } else return;
  }, [
    selectedNgo,
    selectedReport?.archievedMilestoneReport,
    selectedReport.archievedMonthly,
  ]);

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box width="100%" height="100%">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography>
            <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
              Reports
            </span>
          </Typography>
          {/* download button */}
          {selectedReport?.archievedMilestoneReport ||
            selectedReport.archievedMonthly || selectedReport.budgetReport ? null : (
            <Box className="space-x-2 ">
              <Button
                variant="contained"
                size="small"
                endIcon={<CloudDownload />}
                onClick={() => handleDownloadClick()}
                color="primary"
              >
                Download Report
              </Button>
            </Box>
          )}
        </Box>
        <Divider style={{ width: "100%" }} />
        <Box className="w-full flex justify-between items-center">
          {/* main tabs */}
          <Box className="flex justify-start items-center">
            {[
              { label: "Sakhi", sakhis: true },
              { label: "Schemes", schemes: true },
              { label: "Monthly Reports", monthlyReport: true },
              { label: "Milestone Reports", milestoneReport: true },
              { label: "Budget Reports", budgetReport: true },
              ...(role_id === 1
                ? [
                  {
                    label: "Archieved Milestone",
                    archievedMilestoneReport: true,
                  },
                  {
                    label: "Archieved Monthly Report",
                    archievedMonthly: true,
                  },
                ]
                : []),
            ].map((user) => (
              <Box
                key={user?.label}
                variant="contained"
                className={
                  user?.label === "Monthly Reports" &&
                    selectedReport["monthlyReport"]
                    ? tabActiveStyle
                    : user?.label === "Milestone Reports" &&
                      selectedReport["milestoneReport"]
                      ? tabActiveStyle
                      : user?.label === "Budget Reports" &&
                        selectedReport["budgetReport"]
                        ? tabActiveStyle
                        : user?.label === "Archieved Milestone" &&
                          selectedReport["archievedMilestoneReport"]
                          ? tabActiveStyle
                          : user?.label === "Archieved Monthly Report" &&
                            selectedReport["archievedMonthly"]
                            ? tabActiveStyle
                            : user?.label === "Schemes" && selectedReport["schemes"]
                              ? tabActiveStyle
                              : user?.label === "Sakhi" && selectedReport["sakhis"]
                                ? tabActiveStyle
                                : tabDeActiveStyle
                }
                onClick={() => {
                  setLoading(true);
                  setSelectedReport({
                    monthlyReport: user?.monthlyReport ? true : false,
                    milestoneReport: user?.milestoneReport ? true : false,
                    budgetReport: user?.budgetReport ? true : false,
                    archievedMonthly: user.archievedMonthly ? true : false,
                    archievedMilestoneReport: user.archievedMilestoneReport
                      ? true
                      : false,
                    schemes: user?.schemes ? true : false,
                    sakhis: user.sakhis ? true : false,
                  });
                  localStorage.setItem(
                    "selectedReport",
                    JSON.stringify({
                      monthlyReport: user?.monthlyReport ? true : false,
                      milestoneReport: user?.milestoneReport ? true : false,
                      budgetReport: user?.budgetReport ? true : false,
                      archievedMilestoneReport: user?.archievedMilestoneReport
                        ? true
                        : false,
                      schemes: user?.schemes ? true : false,
                      sakhis: user.sakhis ? true : false,
                    })
                  );
                  setFilterData({
                    state_id: "",
                    district_id: "",
                    block_id: "",
                    year: "",
                    ngo_id: "",
                    fo_id: "",
                    from: "",
                    to: "",
                  });
                  setReportType({
                    geography: true,
                    organisation: false,
                  });
                  setSelectedNgo({
                    n1: true,
                    n2: false,
                    n3: false,
                    n4: false,
                    n5: false,
                  });
                  setMilestoneData([]);
                  setBudgetData([]);
                  localStorage.setItem("pageInfo", 1);
                  setPage(1);
                  (user?.milestoneReport || user?.budgetReport) &&
                    setShowFilter(true);
                  (user?.archievedMilestoneReport || user?.archievedMonthly) &&
                    setShowFilter(false);
                  user.archievedMonthly && setMonthlyReportYear(archievedYear);
                  setArchievedYear("2022");
                }}
              >
                <Typography variant="caption">{user.label}</Typography>
              </Box>
            ))}
          </Box>
          {/* filter button */}
          {selectedReport?.archievedMilestoneReport ||
            selectedReport.archievedMonthly ? null : (
            <Button
              variant="contained"
              size="small"
              startIcon={<FilterList color="primary" />}
              onClick={() => setShowFilter(!showFilter)}
              style={{
                backgroundColor: "white",
                color: theme.palette.primary.main,
              }}
            >
              Filter
            </Button>
          )}
        </Box>

        {/* year tabs for archieved monthly report */}
        {selectedReport?.archievedMonthly ? (
          <Box className="w-full flex justify-start items-center space-x-4 pt-5 pb-0">
            {[
              { label: "2022", yearA: true },
              { label: "2021", yearB: true },
            ].map((val) => (
              <Box
                key={val?.label}
                onClick={() => {
                  setMonthlyReportYear(val.label);
                  setArchievedYear(val.label);
                  setSelectedNgo({
                    n1: true,
                    n2: false,
                    n3: false,
                    n4: false,
                    n5: false,
                  });
                }}
                className={
                  archievedYear === val.label
                    ? activeTypeStyle
                    : archievedYear === val.label
                      ? activeTypeStyle
                      : deActiveTypeStyle
                }
              >
                <Typography variant="caption">{val?.label}</Typography>
              </Box>
            ))}
          </Box>
        ) : null}

        {/* sub tabs---> ngos/geo-org/year */}
        {selectedReport?.sakhis || selectedReport?.schemes ? (
          <Box className="w-full flex justify-start items-center  space-x-4 py-5">
            {(role_id === 1
              ? [
                { label: "Geography", geography: true },
                { label: "Organisation", organisation: true },
              ]
              : [{ label: "Geography", geography: true }]
            ).map((val) => (
              <Box
                key={val?.label}
                onClick={() => {
                  setRowsLoading(true);
                  setReportType({
                    geography: val?.geography ? true : false,
                    organisation: val?.organisation ? true : false,
                  });
                  setFilterData({
                    state_id: "",
                    district_id: "",
                    block_id: "",
                    ngo_id: "",
                    fo_id: "",
                    from: "",
                    to: "",
                    year: "",
                  });
                }}
                className={
                  val.geography && reportType["geography"]
                    ? activeTypeStyle
                    : val.organisation && reportType["organisation"]
                      ? activeTypeStyle
                      : deActiveTypeStyle
                }
              >
                <Typography variant="caption">{val?.label}</Typography>
              </Box>
            ))}
          </Box>
        ) : selectedReport?.archievedMilestoneReport ||
          selectedReport.archievedMonthly ? (
          <Box className="w-full flex justify-start items-center  space-x-4 py-5">
            {[
              {
                label: selectedReport.archievedMonthly
                  ? archievedYear === "2021"
                    ? "RGVN_226"
                    : "RGVN"
                  : "RGVN",
                n1: true,
              },
              { label: "Sesta", n2: true },
              {
                label: selectedReport.archievedMonthly ? "GVT" : "GVT(AL+DSA)",
                n3: true,
              },
              {
                label: selectedReport.archievedMonthly
                  ? archievedYear === "2021"
                    ? "RGVN_591"
                    : "GVT(BNS)"
                  : "GVT(BNS)",
                n4: true,
              },
              ...(archievedYear === "2021" && selectedReport.archievedMonthly
                ? []
                : [{ label: "RBKS", n5: true }]),
            ].map((val) => (
              <Box
                key={val?.label}
                onClick={() => {
                  setSelectedNgo({
                    n1: val?.n1 ? true : false,
                    n2: val?.n2 ? true : false,
                    n3: val?.n3 ? true : false,
                    n4: val?.n4 ? true : false,
                    n5: val?.n5 ? true : false,
                  });
                }}
                className={
                  val.n1 && selectedNgo["n1"]
                    ? activeTypeStyle
                    : val.n2 && selectedNgo["n2"]
                      ? activeTypeStyle
                      : val.n3 && selectedNgo["n3"]
                        ? activeTypeStyle
                        : val.n4 && selectedNgo["n4"]
                          ? activeTypeStyle
                          : val.n5 && selectedNgo["n5"]
                            ? activeTypeStyle
                            : deActiveTypeStyle
                }
              >
                <Typography variant="caption">{val?.label}</Typography>
              </Box>
            ))}
          </Box>
        ) : null}

        {/* filters */}
        {showFilter ? (
          <CommonFilter2
            handleFilterSearch={handleFilterSearch}
            handleRestFilter={handleRestFilter}
            filtersToDisplay={filterToDisplay}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        ) : null}
        {/* arrow back to previous table */}
        {Object.values(filterData)?.some((elem) => elem !== "") &&
          (selectedReport?.sakhis || selectedReport?.schemes) ? (
          <Button
            variant="contained"
            size="medium"
            startIcon={<ArrowBack />}
            style={{
              backgroundColor: "white",
              color: theme.palette.primary.main,
            }}
            className="h-9"
            onClick={goToPreviousTable}
          >
            Go Back
          </Button>
        ) : null}
        {/* loader and table */}
        {loading ? (
          <LoadingBox type="table" />
        ) : (
          <ReportTable
            columns={
              selectedReport?.monthlyReport || selectedReport?.archievedMonthly
                ? montlyReportColumn(monthlyReportYear)
                : selectedReport?.budgetReport
                  ? budgetColumns
                  : selectedReport?.milestoneReport ||
                    selectedReport?.archievedMilestoneReport
                    ? milestoneColumns
                    : selectedReport?.schemes
                      ? {
                        ...columnsScheme,
                        secoundHeader: columnsScheme["secoundHeader"]?.map((item) =>
                          item?.id === "geography"
                            ? {
                              ...item,
                              label:
                                reportType?.geography && filterData?.state_id
                                  ? "Schemes"
                                  : reportType?.organisation &&
                                    filterData?.ngo_id === ""
                                    ? "NGO"
                                    : reportType?.organisation && filterData?.ngo_id
                                      ? "Schemes"
                                      : item?.label,
                            }
                            : item
                        ),
                      }
                      : {
                        ...sakhiColumn,
                        secoundHeader: sakhiColumn["secoundHeader"]?.map((item) =>
                          item?.id === "geography"
                            ? {
                              ...item,
                              label:
                                reportType?.organisation &&
                                  filterData?.ngo_id === ""
                                  ? "NGO"
                                  : item?.label,
                            }
                            : item
                        ),
                      }
            }
            rows={
              selectedReport?.monthlyReport || selectedReport?.archievedMonthly
                ? monthlyReportRows
                : selectedReport?.budgetReport
                  ? budgetRows
                  : selectedReport?.schemes
                    ? schemeReportRows
                    : selectedReport?.milestoneReport ||
                      selectedReport?.archievedMilestoneReport
                      ? milestoneRows
                      : sakhiReportRows
            }
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsLoading={rowsLoading} />
        )}
      </Box>
    </Box>
  );
}

export default ReportsView;