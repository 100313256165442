import { Box, Dialog, Slide, Typography, makeStyles } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import React from 'react';

const useStyles = makeStyles({
  bottomLeft: {
    position: 'absolute',
    right: 10,
    bottom: 10
  },
});



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogView({ children, title, subTitle, openView, handleCloseView, position }) {
  const classes = useStyles();
  return (
    <Dialog open={openView} onClose={handleCloseView} TransitionComponent={Transition}
      classes={{
        paper: position ? classes[position] : {}
      }}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth='xs'
      PaperProps={{
        style: {
          minHeight: '16%',
          maxHeight: '90%',
        }
      }}
    >
      {/* <DialogTitle> */}

      <Box className="flex justify-between px-3 pt-4 py-3 items-center space-x-2">
        <Box className='flex flex-col'>
          <Typography ><span className='capitalize text-md md:text-lg lg:text-xl text-black'>{title}</span></Typography>
          <Typography ><span className='capitalize text-sm text-black'>{subTitle}</span></Typography>
        </Box>
        <Box onClick={handleCloseView}>
          <CloseOutlined className='hover:text-primary' />
        </Box>
      </Box>
      {/* </DialogTitle> */}
      <Box className={`${position ? "" : 'flex justify-center items-center'}`}>
        {children}
      </Box>
    </Dialog>
  )
}

export default DialogView