import { Button, Divider, Box, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineOutlined from "@material-ui/icons/Delete"
import { Link } from "react-router-dom";
import StickyHeadTable from 'src/components/CommonComponents/Table';
import FilterComponent from 'src/components/CommonComponents/FilterComponent';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { adminCommonFilterData } from 'src/utils/filterInputData';
import { useSelector } from 'react-redux';
import { stateListAction } from 'src/Redux/Actions/StateAction';
import { purposeMapperListAction } from 'src/Redux/Actions/PurposeMapperAction';
import { purposeListAction } from 'src/Redux/Actions/PurposeAction';
import LoadingBox from 'src/components/LoadingBox';

const columns = [
  { id: 'state', label: 'STATE' },
  { id: 'purpose', label: 'PURPOSE', },
  { id: 'scheme', label: 'Scheme', },
  { id: 'action', label: 'Action', },
];

function createData(state, purpose, scheme, action) {
  return { state, purpose, scheme, action };
}



function AdminMappingView() {
  const [filterData, setFilterData] = useState({
    state: "",
    purpose: "",
    scheme: "",
  });
  const statesList = useSelector(state => state.statesList);
  const { states } = statesList;
  const purposeMapperList = useSelector(state => state.purposeMapperList);
  const { loading, purposeMappers } = purposeMapperList
  const purposeList = useSelector(state => state.purposeList)
  const { purposes } = purposeList;


  const filterInputData = adminCommonFilterData.map(data => (
    data.name === 'state' ? { ...data, items: states?.data } :
      data.name === 'purpose' ? { ...data, items: purposes?.data } :
        data.name === 'scheme' ? { ...data, items: purposeMappers?.data.filter(data => Number(data.purpose_id) === filterData.purpose) } :
          data
  ))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(stateListAction())
    dispatch(purposeMapperListAction())
    dispatch(purposeListAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let rows = purposeMappers?.data?.map(item => {
    const state = states?.data?.find(state => state.id === Number(item.state_id))
    const purpose = purposes?.data?.find(purpose => purpose.id === item.purpose_id)
    return createData(state?.name, purpose?.name, item.name, <Box className='space-x-3'><Link to={`/app/admin/mapping/edit/${item.id}`}><EditIcon color='primary' /></Link><Link to={`/app/admin/mapping/view/${item.id}`}><VisibilityIcon color='primary' /></Link><Link to={`/app/admin/mapping/delete/${item.id}`}><DeleteOutlineOutlined color='primary' /></Link></Box>)
  })

  return (
    <Box className='w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10'>
      {loading ? (
        <LoadingBox />
      ) : (
        <Box width='100%' height='100%'>
          <Box pb={2} width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography><span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>Mapping</span></Typography>
            <Button variant='contained' color='primary' size='small' ><Link to='/app/admin/mapping/create'>ADD PURPOSE</Link></Button>
          </Box>
          <Divider style={{ width: "100%" }} />
          <FilterComponent commonFilterData={filterInputData} filterData={filterData} setFilterData={setFilterData} />
          <StickyHeadTable columns={columns} rows={rows} loading={loading} />
        </Box>
      )}
    </Box>
  )
}

export default AdminMappingView