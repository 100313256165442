const { TRAIN_KIT_CREATE_REQUEST, TRAIN_KIT_CREATE_SUCCESS, TRAIN_KIT_CREATE_FAIL, TRAIN_KIT_LIST_REQUEST, TRAIN_KIT_LIST_SUCCESS, TRAIN_KIT_LIST_FAIL, TRAIN_KIT_DELETE_REQUEST, TRAIN_KIT_UPDATE_SUCCESS, TRAIN_KIT_UPDATE_REQUEST, TRAIN_KIT_UPDATE_FAIL, TRAIN_KIT_DELETE_SUCCESS, TRAIN_KIT_DELETE_FAIL } = require("../Conistance/TrainingKitConistance");

export const trainKitCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case TRAIN_KIT_CREATE_REQUEST:
            return { loading: true };
        case TRAIN_KIT_CREATE_SUCCESS:
            return { loading: false, success: true, trainKit: action.payload }
        case TRAIN_KIT_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const trainKitListReducer = (state = [], action) => {
    switch (action.type) {
        case TRAIN_KIT_LIST_REQUEST:
            return { loading: true }
        case TRAIN_KIT_LIST_SUCCESS:
            return { loading: false, trainKits: action.payload }
        case TRAIN_KIT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const trainKitUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case TRAIN_KIT_UPDATE_REQUEST:
            return { loading: true };
        case TRAIN_KIT_UPDATE_SUCCESS:
            return { loading: false, trainKit: action.payload }
        case TRAIN_KIT_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const trainKitDeleteReducer = (success = '', action) => {
    switch (action.type) {
        case TRAIN_KIT_DELETE_REQUEST:
            return { loading: true }
        case TRAIN_KIT_DELETE_SUCCESS:
            return { loading: false, success: true }
        case TRAIN_KIT_DELETE_FAIL:
            return { loading: false, success: false };
        default:
            return success
    }
}