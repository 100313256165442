import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import "./Top.css";
import { useNavigate } from "react-router-dom";
import { paramsUrl } from "src/utils/halper";
import { fetchWrapper } from "./../services/http_requests";

const baseUrl = process.env.REACT_APP_API_URL;
const Page = forwardRef((props, ref) => {
  const {
    children,
    title = "",
    href,
    btnTitle,
    csvTitle = "",
    downloadUrl,
    csvFilters,
  } = props;
  const navigate = useNavigate();

  const handleBackBtn = () => {
    navigate(href, { replace: true });
  };

  const handleCSVDownload = async () => {
    let url = `${baseUrl + downloadUrl + paramsUrl(csvFilters)}`;
    fetchWrapper.exportCsv(url, csvTitle);
  };
  const handleAdd = () => {
    navigate(href, { state: { title } });
  };

  return (
    <div className="layoutPage" ref={ref}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {(btnTitle || csvTitle) && (
        <div className="topBarWrapper">
          <h2>{title}</h2>

          <div className="topBarRightBtnsWrapper">
            {csvTitle && (
              <button className="csvBtn" onClick={handleCSVDownload}>
                CSV Download
              </button>
            )}
            {btnTitle && (
              <button className="csvBtn" onClick={handleAdd}>
                {btnTitle}
              </button>
            )}
          </div>
        </div>
      )}

      {!btnTitle && !csvTitle && (
        <div className="topBarWrapper">
          <div className="topBarInner">
            <div className="tobBarBackBtn" onClick={handleBackBtn}>
              <KeyboardBackspaceIcon />
            </div>
            <h2>{title}</h2>
          </div>
        </div>
      )}
      <div className="commonPage">{children}</div>
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
