import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import theme from 'src/theme'
import { numberWithCommas } from 'src/utils'
import { activeIndicatorStyle, deactiveIndicatorStyle } from './Conistance'

function IndicatorsComponent({ detail, i, indicatorData, chartLoading, activeIndicator, setActiveIndicator, classN }) {
  return (
    <Grid item xs={6} sm={6} md={6} lg={6}
      className={classN ? `py-0.5 px-0.5 transform  translate-y-0 z-0 transition duration-500 delay-${i === 0 ? 100 : i === 1 ? 150 : i === 2 ? 200 : i === 3 ? 300 : i === 4 ? 500 : i === 5 ? 700 : i === 6 ? 1000 : i === 7 ? `[1500ms]` : i === 8 ? `[2000ms]` : i === 9 ? `[2500ms]` : i === 10 ? `[3000ms]` : i === 11 ? `[3500ms]` : i === 12 ? `[4000ms]` : `[5000ms]`}  ease-in-out` : `py-0.5 px-0.5  transform  translate-y-full z-0 transition duration-500 ease-in-out opacity-0 `}
      key={detail?.label}
    >
      {chartLoading ? (
        <Box className="w-full h-28 animate-pulse rounded-lg bg-gray-100 shadow-lg p-2 px-3 flex flex-col justify-between items-start">
          <Box className="w-full flex justify-start items-center space-x-2 animate-pulse">
            <Box className="w-10 h-10 p-1 bg-red-100 rounded-full flex justify-center items-center animate-pulse">
              {detail.icon}
            </Box>
            <Typography
              variant="caption"
              style={{
                color: theme.palette.grey[900],
                fontSize: "16px",
              }}
            >
              {detail.label}
            </Typography>
          </Box>
          <Typography
            varitan="h1"
            style={{ fontSize: "24px", fontWeight: "bold" }}
          ></Typography>
        </Box>
      ) : (
        <Box className={activeIndicator === detail?.label
          ? activeIndicatorStyle : deactiveIndicatorStyle}
          onClick={() => setActiveIndicator(detail?.label)}
        >
          <Box className="w-full flex justify-start items-center space-x-2">
            <Box className="w-16 h-16 p-1 bg-red-100 rounded-full flex justify-center items-center">
              {detail.icon}
            </Box>
            <Box>
              <Typography
                variant="caption"
                style={{
                  color: theme.palette.grey[900],
                  fontSize: "16px",
                }}
              >
                {detail.label}
              </Typography>
              <Typography
                varitan="h1"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {[4, 5, 6]?.includes(i) && <span>&#x20B9;</span>}
                {["Total Active Sakhis"]?.includes(detail?.label)
                  ? numberWithCommas(Number(indicatorData?.total_active_sakhi))
                  : ["Total Onboarded Sakhis"]?.includes(detail?.label)
                    ? numberWithCommas(indicatorData?.total_onboarded
                      ? Number(indicatorData?.total_onboarded) : 0) || 0
                    : ["Total Schemes"]?.includes(detail?.label)
                      ? numberWithCommas(Number(indicatorData?.scheme_count))
                      : ["Total Transactions"]?.includes(detail?.label)
                        ? numberWithCommas(Number(indicatorData?.total_application))
                        : ["Total Inactive Sakhis"]?.includes(detail?.label)
                          ? numberWithCommas(Number(indicatorData?.total_inactive_sakhi))
                          : ["Total Earning Sakhis"]?.includes(detail?.label)
                            ? numberWithCommas(Number(indicatorData?.total_earning_sakhi))
                            : ["Total Income of Sakhis"]?.includes(detail?.label)
                              ? numberWithCommas(Number(indicatorData?.total_income_sakhi))
                              : ["Average Income of Sakhis"]?.includes(detail?.label)
                                ? numberWithCommas(Number(indicatorData?.avg_income))
                                : ["Median Income of Sakhis"]?.includes(detail?.label)
                                  ? numberWithCommas(Number(indicatorData?.median))
                                  : ["Total Applications"]?.includes(detail?.label)
                                    ? numberWithCommas(Number(indicatorData?.total_application))
                                    : ["Total Services"]?.includes(detail?.label)
                                      ? numberWithCommas(Number(indicatorData?.services_count))
                                      : ["Total Awareness"]?.includes(detail?.label)
                                        ? numberWithCommas(Number(indicatorData?.awareness_count))
                                        : ["Unique Beneficiaries Reached"]?.includes(detail?.label)
                                          ? numberWithCommas(Number(indicatorData?.beneficiary_count))
                                          : ["Total Villages Reached"]?.includes(detail?.label)
                                            ? numberWithCommas(Number(indicatorData?.village_count))
                                            : ["Total SHGs Covered"]?.includes(detail?.label)
                                              ? numberWithCommas(Number(indicatorData?.total_shg))
                                              : 0}
                <br />
                <span className='text-sm font-normal break-normal'>
                  {["Unique Beneficiaries Reached"]?.includes(detail?.label) ?
                    ` (M: ${numberWithCommas(Number(indicatorData?.male_count))}, F:${numberWithCommas(Number(indicatorData?.female_count))}, T:${numberWithCommas(Number(indicatorData?.transgender_count))})`
                    : ["Total Schemes"]?.includes(detail?.label) ? ` (Scheme: ${numberWithCommas(Number(indicatorData?.scheme))} )`
                      : ["Total Services"]?.includes(detail?.label) ? ` (Services: ${numberWithCommas(Number(indicatorData?.services))} )`
                        : ["Total Awareness"]?.includes(detail?.label) ? ` (Awareness: ${numberWithCommas(Number(indicatorData?.awareness))} )`
                          : ["Total Onboarded Sakhis"]?.includes(detail?.label) ? ` (Exit Sakhi: ${numberWithCommas(Number(indicatorData?.total_removed_sakhi))} )`
                            : ""

                  }
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  )
}

export default IndicatorsComponent