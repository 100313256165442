import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TrainingKitCRUDForm from "./TrainingKitCRUDForm";
import { useDispatch, useSelector } from "react-redux";
import LoadingBox from "src/components/LoadingBox";
import { fetchWrapper } from "src/services/http_requests";
import { trainKitListAction } from "src/Redux/Actions/TrainingKitAction";

function TrainingKitCRUDFormView() {
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const path = window.location.pathname;
  const { id: paramsID } = useParams();
  const { loading, trainKits } = useSelector((state) => state.trainKitList);
  useEffect(() => {
    if (path === `/app/admin/trainingkit/create`) {
      setCrudAction({
        create: true,
        edit: false,
        view: false,
        delete: false,
      });
    } else if (path === `/app/admin/trainingkit/edit/${paramsID}`) {
      setCrudAction({
        create: false,
        edit: true,
        view: false,
        delete: false,
      });
    } else if (path === `/app/admin/trainingkit/view/${paramsID}`) {
      setCrudAction({
        create: false,
        edit: false,
        view: true,
        delete: false,
      });
    } else {
      setCrudAction({
        create: false,
        edit: false,
        view: false,
        delete: true,
      });
    }
  }, [path, paramsID]);

  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;

    if (paramsID && mounted) {
      dispatch(trainKitListAction());
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem('previousUrl',window.location.href)
    return() => (fetchWrapper?.finishPendingRequests())
  },[])

  let updateDetails = trainKits?.data?.find(
    (data) => Number(data.id) === Number(paramsID)
  );

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      {loading ? (
        <LoadingBox />
      ) : (
        <Box className="w-full h-full">
          <Box
            pb={2}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box className="flex justify-center items-center space-x-2">
              <Link to="/app/admin/trainingkit">
                <ArrowBackIcon />
              </Link>
              <Typography>
                <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                  {crudAction.create && "Create"} {crudAction.edit && "Edit"}{" "}
                  {crudAction.view && "View"} Training Kit
                </span>
              </Typography>
            </Box>
          </Box>
          <TrainingKitCRUDForm
            crudAction={crudAction}
            updateDetails={updateDetails}
          />
        </Box>
      )}
    </Box>
  );
}

export default TrainingKitCRUDFormView;
