import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@material-ui/core';
import TopBar from './TopBar';
import TabsView from './New';
import Drawer from 'src/components/CommonComponents/Drawer';
import { Close } from '@material-ui/icons';

const DashboardLayout = () => {
  const [classN,setClass] = useState(false)
  const [openNav,setOpenNav] = useState(false)

  setTimeout(() => {
    setClass(true)
  }, 500);
  return (
    <div className='w-full min-h-screen flex flex-col justify-start items-start overflow-hidden '>
      <TopBar setOpenNav={setOpenNav} openNav={openNav} />
          <Box className='w-full h-full mt-20 flex justify-end items-start '>
            <TabsView setClass={setClass} openNav={openNav} setOpenNav={setOpenNav}/>
            {/* {classN ? null :<div className='w-10/12 h-screen bg-hero-pattern bg-white  animate-pulse bg-no-repeat bg-center bg-auto'></div>} */}
            {/* <div 
  className={classN ? "transform translate-x-10/12 z-0 transition duration-500 ease-in-out w-10/12 min-h-screen bg-gray-100" : '  transform -translate-x-full z-0 transition duration-500 ease-in-out w-10/12 min-h-screen bg-gray-100'}> */}
            <div className='w-full md:w-10/12 min-h-screen '>

            <Outlet />
            </div>
            {/* </div> */}
          </Box>
    </div>
  );
};

export default DashboardLayout;
