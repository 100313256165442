import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import GlobalStyles from 'src/components/GlobalStyles';
import routes from 'src/routes';
import theme from 'src/theme';
import { deleteData } from './utils/sessionStorage';

const App = () => {
  window.addEventListener("storage", (e) => {
    if (["token", "loginInfo"].includes(e.key) && e.newValue !== e.oldValue) {
      toast.error("Your session has expired. Pleasee login again");
      deleteData("loginInfo");
    }
  });

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routes()}
      </ThemeProvider>
    </div>
  );
};

export default App;
