import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
// import "./styles/tailwind.css"
import "./index.css";
import "@fontsource/roboto";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

// import fetchIntercept from 'fetch-intercept';
import { deleteData } from "./utils/sessionStorage";
axios.interceptors.request.use(
  (request) => {
    let sessionStr = window.sessionStorage.getItem("session");
    if (sessionStr) {
      let session = JSON.parse(sessionStr);
      request.headers["session_id"] = session.id;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.response?.data?.logout
      || (err?.message === "Network Error" && window.location.pathname.includes('/app'))
      || [401, 403].includes(err?.response?.status)
    ) {
      deleteData("loginInfo");
      window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);
// const unregister = fetchIntercept.register({
//   request: function (url, config) {
//     // Modify the url or config here
//     let sessionStr = window.sessionStorage.getItem('session');
//     if (sessionStr) {
//       let session = JSON.parse(sessionStr);
//       config.headers['session_id'] = session.id;
//     }
//     return [url, config];
//   },

//   requestError: function (error) {
//     // Called when an error occured during another 'request' interceptor call
//     return Promise.reject(error);
//   },

//   response: function (response) {
//     // Modify the reponse object
//     return response;
//   },

//   responseError: function (err) {
//     // Handle an fetch error
//     console.log("ResponseError[fetch]", err);
//     if (err.response !== undefined && err.response.status === 401 && err.response.data.logout) {
//       deleteData("loginInfo");
//       window.location.href = "/login";
//     }
//     return Promise.reject(err);
//   }
// });
// unregister()
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.unregister();
