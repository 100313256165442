import { Box, Button, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilterComponent from "src/components/CommonComponents/FilterComponent";
import StickyHeadTable from "src/components/CommonComponents/Table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { centreListAction } from "src/Redux/Actions/CentreAction";
import { commonFilterData } from "src/utils/filterInputData";
import LoadingBox from "src/components/LoadingBox";
import { fetchApiDataList, fetchWrapper } from "src/services/http_requests";
import { generateSerialNo } from "src/utils";
import Drawer from "src/components/CommonComponents/Drawer";
import CenterDetailsCRUDForm from "./CenterDetailsCRUDFrom/CenterDetailsCRUDForm";
import { DeleteOutlineOutlined, EditOutlined, FilterList, VisibilityOutlined } from "@material-ui/icons";
import theme from "src/theme";
import DialogView from "src/components/CommonComponents/DialogView";

const columns = [
  { id: 'sno', label: 'S.No' },
  {
    id: "centre",
    label: "Centre",
  },
  { id: "state", label: "State" },
  {
    id: "district",
    label: "District",
  },
  {
    id: "block",
    label: "Block",
  },
  {
    id: "action",
    label: "Action",
  },
];

function createData(sno, centre, state, district, block, action) {
  return { sno, centre, state, district, block, action };
}

function CenterDetailsView() {
  const pageInfo = localStorage.getItem('pageInfo')
  const [filterData, setFilterData] = useState({
    state: "",
    district: "",
    block: "",
    centre: "",
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const [centerDetails, setCenterDetails] = useState({});
  const [showFilter, setShowFilter] = useState(false);

  const statesList = useSelector((state) => state.statesList);
  const { states, loading } = statesList;
  const [districts, setDistricts] = useState(undefined);
  const [blocks, setBlocks] = useState(undefined);
  const [centresList, setCentresList] = useState(undefined);
  const centreList = useSelector((state) => state.centreList);
  const { centres } = centreList;

  const filterInputData = commonFilterData.map((data) =>
    data.name === "state"
      ? { ...data, options: states?.data }
      : data.name === "district"
        ? { ...data, options: districts }
        : data.name === "block"
          ? { ...data, options: blocks }
          : data.name === "centre"
            ? { ...data, options: centresList }
            : data
  );


  const handleFilterSearch = () => {
    dispatch(
      centreListAction({
        page: "1",
        state_id: filterData.state,
        district_id: filterData?.district,
        block_id: filterData?.block,
        center_id: filterData?.centre,
      })
    );
  };
  const handleRestFilter = () => {
    dispatch(centreListAction({ page: 1 }));
    setFilterData({
      state: "",
      district: "",
      block: "",
      centre: "",
    });
  };
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(() => pageInfo ? parseInt(pageInfo) : 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();
  useEffect(() => {
    !states && dispatch(stateListAction({ fetched: "All" }));
  }, [dispatch]);
  useEffect(() => {
    if (centres?.total) {
      setTotal(centres?.total);
      localStorage.setItem("totalData", centres?.total);
    } else {
      if (parseInt(page) === 1) setTotal(centres?.data?.length);
      else setTotal(localStorage.getItem("totalData"));
    }
  }, [centres]);
  useEffect(() => {
    if (page) dispatch(
      centreListAction({
        state_id: filterData.state,
        district_id: filterData?.district,
        block_id: filterData?.block,
        center_id: filterData?.centre,
        page: page,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (filterData.state) {
      fetchApiDataList("district", { state_id: filterData?.state, fetched: "All" }, setDistricts);
    } else {
      setDistricts(undefined);
      setFilterData({ ...filterData, district: "" });
    }
  }, [filterData.state]);

  useEffect(() => {
    if (filterData.district) {
      fetchApiDataList("block", { state_id: filterData?.state, district_id: filterData?.district, fetched: "All" }, setBlocks);
    } else {
      setBlocks(undefined);
      setFilterData({ ...filterData, block: "" });
    }
  }, [filterData.district]);

  useEffect(() => {
    if (filterData.block) {
      fetchApiDataList("centre", { state_id: filterData?.state, district_id: filterData?.district, block_id: filterData?.block, fetched: "All" }, setCentresList)
    } else {
      setCentresList(undefined);
      setFilterData({ ...filterData, center: "" });

    }
  }, [filterData.block]);

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => (fetchWrapper?.finishPendingRequests())
  }, []);


  useEffect(() => {
    if (openDrawer == false) setCenterDetails({});
  }, [openDrawer]);

  const rows = centres?.data?.map((item, i) => {
    return createData(
      generateSerialNo(i, page),
      item?.name,
      item?.state_name,
      item?.district_name,
      item?.block_name,
      <Box className="space-x-3">
        <button
          className="cursor-pointer"
          onClick={() => {
            setCenterDetails(item);
            setCrudAction({
              create: false,
              edit: true,
              view: false,
              delete: false,
            });
            setOpenDrawer(true);
          }}
        >
          {/* <Link to={`/app/admin/masters/state/edit/${item.id}`}> */}
          <EditOutlined color="primary" />
          {/* </Link> */}
        </button>
        <button
          className="cursor-pointer"
          onClick={() => {
            setCenterDetails(item);
            setCrudAction({
              create: false,
              edit: false,
              view: true,
              delete: false,
            });
            setOpenView(true);
          }}
        >
          {/* <Link to={`/app/admin/masters/state/view/${item.id}`}> */}
          <VisibilityOutlined color="primary" />
          {/* </Link> */}
        </button>
        <button
          className="cursor-pointer"
          onClick={() => {
            setCenterDetails(item);
            setCrudAction({
              create: false,
              edit: false,
              view: false,
              delete: true,
            });
            setOpenDrawer(true);
          }}
        >
          {/* <Link to={`/app/admin/masters/state/delete/${item.id}`}> */}
          <DeleteOutlineOutlined color="primary" />
          {/* </Link> */}
        </button>
      </Box>
    );
  });

  const refetchUserList = () => {
    handleRestFilter();
    setOpenDrawer(false);
  }

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      {loading ? (
        <LoadingBox />
      ) : (
        <Box width="100%" height="100%">
          <Box
            pb={2}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography>
              <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                Centre Details
              </span>
            </Typography>
            <Box className="space-x-2 ">
              {/* <Button
                variant="contained"
                size="small"
                // color="primary"
                style={{
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                }}
              >
                <Replay color="primary" />
              </Button> */}
              <Button
                variant="contained"
                size="small"
                startIcon={<FilterList color="primary" />}
                onClick={() => setShowFilter(!showFilter)}
                // color="primary"
                style={{
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                }}
              >
                Filter
              </Button>
              <Button variant="contained" color="primary" size="small" onClick={() => {
                setCrudAction({
                  create: true,
                  edit: false,
                  view: false,
                  delete: false,
                }); setOpenDrawer(true)
              }}>
                {/* <Link to="/app/centerdetails/create"> */}
                CREATE CENTRE
                {/* </Link> */}
              </Button>
            </Box>
          </Box>
          <Divider style={{ maxWidth: "100%" }} />
          {showFilter ? <FilterComponent
            handleFilterSearch={handleFilterSearch}
            handleRestFilter={handleRestFilter}
            commonFilterData={filterInputData}
            filterData={filterData}
            setFilterData={setFilterData}
          /> : null}
          <StickyHeadTable
            columns={columns}
            rows={rows}
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      )}
      <Drawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        title={
          <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
            {crudAction.create && "Create"} {crudAction.edit && "Edit"}{" "}
            {crudAction.view && "View"} Center
          </span>
        }
      >
        <CenterDetailsCRUDForm
          updateDetails={centerDetails}
          crudAction={crudAction}
          refetchUserList={refetchUserList}
          isFormOpen={openDrawer}
        />
      </Drawer>
      <DialogView
        openView={openView}
        handleCloseView={() => {
          setOpenView(false);
          setCenterDetails({});
        }}
        title={<span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
          {crudAction.create && "Create"} {crudAction.edit && "Edit"}{" "}
          {crudAction.view && "View"} Center
        </span>}
      >
        <CenterDetailsCRUDForm
          updateDetails={centerDetails}
          crudAction={crudAction} refetchUserList={refetchUserList}
          isFormOpen={openView} />
      </DialogView>
    </Box>
  );
}

export default CenterDetailsView;
