import { Grid, Box, Button } from "@material-ui/core";
import { Formik } from "formik";
import { rest } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { districtListAction } from "src/Redux/Actions/DistrictAction";
import { blockListAction } from "src/Redux/Actions/BlockAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import { userCreateAction, userDeleteAction, userUpdateAction } from "src/Redux/Actions/UserAction";
import { v4 as uuidv4 } from "uuid";
import * as Yup from 'yup';
import { villageListAction } from "src/Redux/Actions/VillageAction";
import CustomInputs from "src/components/CommonComponents/CustomInputs";
import moment from "moment";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
const ageRegExp = /^[1-9]\d{1}/;

const validation = Yup.object().shape({
  mobile: Yup.string().required('Mobile no is required').matches(phoneRegExp, 'Phone number is not valid').min(10, 'Too short'),
  // email: Yup.string().email('Invalid email').required('Email is required'),
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required("Name is required"),
  ngo_id: Yup.string().required("Name of NGO is required"),
  // role_id: Yup.string().required("Level is required"),
  age: Yup.string().required("Age is required").matches(ageRegExp, 'Age is not valid').max(2, "The maximum age is 99 year's old"),
  state_id: Yup.string().required('State is required'),
  district_id: Yup.string().required('District is required'),
  block_id: Yup.string().required('Block is required'),
  village_id: Yup.string().required('Centre is required'),
  date_of_joining: Yup.date().required('Date of joining is required'),
  batch_year: Yup.date().required('Batch year is required'),
  // date_of_leaving: Yup.date(),
  is_active: Yup.string().required("Status is required"),
  gse_enrolled_status: "",
  gse_enrolled_date: Yup.string().when("gse_enrolled_status", (gse_enrolled_status, scheme) => {
    if (gse_enrolled_status === "1") return scheme.required("Enroll date is required");
  }),
  exam_certified_status: Yup.string().when("gse_enrolled_status", (gse_enrolled_status, scheme) => {
    if (gse_enrolled_status === "1") return scheme.required("Exam status is required")
  }),
  exam_certified_date: Yup.string().when("exam_certified_status", (exam_certified_status, scheme) => {
    if (exam_certified_status === "1") return scheme.required("Exam date is required")
  })
});

function SakhiDetailsCRUDForm({ crudAction, userDetails, fetchUserData, openDrawer }) {
  const dispatch = useDispatch();

  const [minLeavingDate, setMinLeavingDate] = useState("");

  const [isSakhiEnrolled, setIsSakhiEnrolled] = useState(false);
  const [examStatus, setExamStatus] = useState(false);

  const { states } = useSelector(state => state.statesList);
  const { districts } = useSelector(state => state.districtList);
  const { blocks } = useSelector(state => state.blockList);
  const { villages } = useSelector(state => state.villageList);
  const { ngos } = useSelector(state => state.ngoList)


  const initialValues = {
    txn_id: uuidv4(),
    role_id: 4 || "",
    name: "",
    sakhi_fo_name: "",
    age: "",
    mobile: "",
    batch_year: "",
    state_id: "",
    district_id: "",
    block_id: [],
    village_id: [],
    ngo_id: "",
    date_of_joining: "",
    date_of_leaving: "",
    is_active: "",
    gse_enrolled_status: "",
    gse_enrolled_date: "",
    exam_certified_status: "",
    exam_certified_date: ""
  };


  useEffect(() => {
    !states && dispatch(stateListAction({ "fetched": 'All' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  const sakhiFormInputs = [
    {
      key: "name",
      name: "name",
      label: "Sakhi Name",
      inputType: "text",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: crudAction?.view || crudAction?.delete ? "" : "Enter",
    },
    {
      key: "sakhi_fo_name",
      name: "sakhi_fo_name",
      label: "FO Name",
      inputType: "text",
      required: true,
      disabled: true,
      value: "",
      placeholder: crudAction?.view || crudAction?.delete ? "" : "Enter",
    },
    {
      key: "age",
      name: "age",
      label: "Age",
      inputType: "text",
      required: true,
      disabled: crudAction?.view ? true : false,
      value: "",
      placeholder: crudAction?.view || crudAction?.delete ? "" : "Enter",
    },
    {
      key: "mobile",
      name: "mobile",
      label: "Contact Number",
      inputType: "tel",
      required: true,
      disabled: crudAction?.create || crudAction.edit ? false : true,
      value: "",
      placeholder: crudAction?.view || crudAction?.delete ? "" : "Enter",
    },
    {
      key: "state_id",
      name: "state_id",
      label: "State",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: true,
      optionArray: states?.data || [],
      value: "",
      placeholder: "Select State",
    },
    {
      key: "district_id",
      name: "district_id",
      label: "District",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: true,
      optionArray: districts?.data || [],
      value: "",
      placeholder: "Select District",
    },
    {
      key: "block_id",
      name: "block_id",
      label: "Block",
      inputType: "autoComplete",
      multiple: true,
      required: true,
      disabled: true,
      optionArray: blocks?.data || [],
      value: "",
      placeholder: "Select Block",
    },
    {
      key: "village_id",
      name: "village_id",
      label: "Village",
      inputType: "autoComplete",
      multiple: true,
      required: true,
      disabled: crudAction.edit ? false : true,
      optionArray: villages?.data || [],
      value: "",
      placeholder: "Select Village",
    },
    {
      key: "ngo_id",
      name: "ngo_id",
      label: "NGO",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction.edit ? false : true,
      optionArray: ngos?.data || [],
      value: "",
      placeholder: "Select NGO",
    },
    {
      key: "date_of_joining",
      name: "date_of_joining",
      label: "Date of Joining",
      inputType: "date",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Select Date",
      max: new Date(),
    },
    {
      key: "date_of_leaving",
      name: "date_of_leaving",
      label: "Date of Leaving",
      inputType: "date",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Select Date",
      min: minLeavingDate,
    },
    {
      key: "batch_year",
      name: "batch_year",
      label: "Batch Year",
      inputType: "year",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Select Year",
      max: new Date(),
    },
    {
      key: "is_active",
      name: "is_active",
      label: "Status",
      inputType: "radio",
      required: true,
      disabled: crudAction?.edit ? false : true,
      value: "",
      optionArray: [{ id: 1, label: "Active", value: "1" }, { id: 0, label: "InActive", value: "0" }]
    },
    {
      key: "gse_enrolled_status",
      name: "gse_enrolled_status",
      label: "Sakhi Enrollement(GSE)",
      inputType: "radio",
      required: true,
      disabled: crudAction?.edit ? false : true,
      value: "",
      optionArray: [{ id: 1, label: "Yes", value: "1" }, { id: 2, label: "No", value: "2" }]
    },
    ...isSakhiEnrolled ? [
      {
        key: "gse_enrolled_date",
        name: "gse_enrolled_date",
        label: "GSE Enrollement Date",
        inputType: "date",
        required: true,
        disabled: crudAction?.edit ? false : true,
        value: "",
        placeholder: "Select Date",
        max: new Date(),
      },
      {
        key: "exam_certified_status",
        name: "exam_certified_status",
        label: "Exam Certificate Status",
        inputType: "radio",
        required: true,
        disabled: crudAction?.edit ? false : true,
        value: "",
        optionArray: [{ id: 1, label: "Yes", value: "1" }, { id: 2, label: "No", value: "2" }]
      },
      ...examStatus ? [
        {
          key: "exam_certified_date",
          name: "exam_certified_date",
          label: "Exam Certificate Date",
          inputType: "date",
          required: true,
          disabled: crudAction?.edit ? false : true,
          value: "",
          placeholder: "Select Date",
        },
      ] : []
    ] : []
  ]


  return (
    <div className={`h-auto  ${crudAction.view ? "pb-4 mx-4" : "pb-16 mx-2"}`}>
      {/* <ScrollBar > */}
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

          let data = {
            ...values,
            role_id: 4,
            batch_year: values?.batch_year ? moment(values?.batch_year).format("YYYY") : "",
            village_id: values?.village_id?.map(item => villages?.data?.map(val => val.id)?.includes(item) && item)?.filter(Boolean)?.join(','),
            date_of_leaving: values?.date_of_leaving === '' ? null : values?.date_of_leaving,
            gse_enrolled_date: values?.gse_enrolled_date === '' ? null : values?.gse_enrolled_date,
            exam_certified_date: values?.exam_certified_date === '' ? null : values?.exam_certified_date,
          };
          crudAction?.create ?
            dispatch(userCreateAction(data, fetchUserData))
            : crudAction?.edit ?
              dispatch(userUpdateAction(userDetails?.id, data, fetchUserData))
              : dispatch(userDeleteAction(userDetails?.id, fetchUserData))
        }}
      >
        {function Render({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          touched,
          values,
          resetForm
        }) {

          useEffect(() => {
            if (openDrawer === false) {
              resetForm();
            }
            return () => resetForm();
          }, [openDrawer]);

          useEffect(() => {
            if (Object.keys(userDetails || {})?.length > 0 && openDrawer) {
              for (let elem in initialValues) {
                if (["block_id", "village_id"].includes(elem)) {
                  setFieldValue(elem, userDetails[elem]?.split(',')?.map(item => parseInt(item)) || []);
                } else setFieldValue(elem, userDetails[elem] || "")
              };
            }
          }, [userDetails]);


          useEffect(() => {
            if (values?.state_id) {
              dispatch(districtListAction({ fetched: 'All', state_id: values?.state_id }))
              dispatch(ngoListAction({ fetched: 'All', state_id: values?.state_id }))
            }
          }, [values.state_id]);
          useEffect(() => {
            if (values?.district_id) {
              dispatch(blockListAction({ fetched: 'All', state_id: values?.state_id, district_id: values?.district_id }))
            }
          }, [values.district_id]);
          useEffect(() => {
            if (values?.block_id) {
              dispatch(villageListAction({ fetched: 'All', state_id: values?.state_id, district_id: values?.district_id, block_id: values?.block_id }))
            }
          }, [values.block_id]);

          useEffect(() => {
            if (values.date_of_joining) {
              setMinLeavingDate(values.date_of_joining);
            } else setMinLeavingDate("");
          }, [values.date_of_joining]);

          useEffect(() => {
            if (values?.gse_enrolled_status == "1") setIsSakhiEnrolled(true);
            else {
              setFieldValue("gse_enrolled_date", "");
              setFieldValue("exam_certified_status", "");
              setFieldValue("exam_certified_date", "");
              setIsSakhiEnrolled(false);
            }
          }, [values?.gse_enrolled_status]);

          useEffect(() => {
            if (values?.exam_certified_status == "1") setExamStatus(true);
            else { setExamStatus(false); setFieldValue("exam_certified_date", ""); }
          }, [values?.exam_certified_status]);

          return (
            <form className="bg-transparent" onSubmit={handleSubmit} {...rest}>
              <Grid
                spacing={2}
                container
                className="bg-white rounded-md py-2 shadow"
              >
                {sakhiFormInputs?.map((elem, INDEX) => {
                  return (
                    <CustomInputs
                      name={elem?.name}
                      id={elem?.key + INDEX.toString()}
                      label={elem?.label}
                      inputType={elem?.inputType}
                      value={values[elem.name]}
                      required={elem?.required}
                      handleBlur={handleBlur}
                      onClick={() => setFieldTouched(elem.name, true)}
                      onChange={(event, value) => {
                        if (['autoComplete', 'date', "radio", "year"].includes(elem.inputType)) setFieldValue(event, value);
                        else handleChange(event);
                      }}
                      error={(errors[elem.name] && touched[elem.name])}
                      helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                      placeholder={elem?.placeholder}
                      disabled={elem?.disabled}
                      multiple={elem?.multiple}
                      optionArray={elem?.optionArray}
                      acceptedFiles={elem?.acceptedFiles}
                      crudAction={crudAction}
                      min={elem.name === "exam_certified_date" ? values?.gse_enrolled_date : elem?.min}
                      max={elem?.max}
                      initialValues={initialValues}
                    />
                  )
                })}
              </Grid>
              {!crudAction.view && <Box className="w-full px-2 bg-gray-100 mt-10" >
                <Button type='submit' className="w-full" variant="contained" color='primary' size='large' >{crudAction.create ? 'CREATE' : crudAction.edit ? 'UPDATE' : 'DELETE'}</Button>
              </Box>}
            </form>
          );
        }}
      </Formik>
      {/* </ScrollBar> */}
    </div >
  );
}

export default React.memo(SakhiDetailsCRUDForm);
