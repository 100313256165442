import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@material-ui/core';
import Logo from 'src/components/Logo';

// const useStyles = makeStyles({
//     root: {},
//     toolbar: {
//         height: 64,
//     },
//     AppBar: {
//         boxShadow: 'none',
//     },
// });

const TopBar = ({ className, ...rest }) => {

    return (
        <AppBar position="static" color='white'>
            <Toolbar >
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
};

export default TopBar;
