const { toast } = require("react-toastify/dist");
const { fetchWrapper } = require("src/services/http_requests");
const { paramsUrl } = require("src/utils/halper");
const Base_url = process.env.REACT_APP_API_URL;


const fetchFoSakhis = async (filter,setFoSakhis) => {
    try{
        const data = await fetchWrapper.get(`${Base_url}/user/list${paramsUrl(filter)}`)
        setFoSakhis(data)
    }catch(error){
        toast.error(error)
    }
}



export {fetchFoSakhis}