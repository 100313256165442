import { SAKHI_TRANSACTION_LIST_FAIL, SAKHI_TRANSACTION_LIST_REQUEST, SAKHI_TRANSACTION_LIST_SUCCESS } from "../Conistance/SakhiTransactionConistance";

export const sakhiTransactionListReducer = (state = [], action) => {
    switch(action.type){
        case SAKHI_TRANSACTION_LIST_REQUEST:
            return {loading: true};
        case SAKHI_TRANSACTION_LIST_SUCCESS:
            return {loading: false, sakhiTransactions: action.payload}
        case SAKHI_TRANSACTION_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state;
    }
}