import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { PURPOSE_LIST_REQUEST, PURPOSE_LIST_SUCCESS, PURPOSE_LIST_FAIL, PURPOSE_CREATE_REQUEST, PURPOSE_CREATE_FAIL, PURPOSE_CREATE_SUCCESS, PURPOSE_UPDATE_REQUEST, PURPOSE_UPDATE_FAIL, PURPOSE_DELETE_REQUEST, PURPOSE_DELETE_FAIL, PURPOSE_UPDATE_SUCCESS, PURPOSE_DELETE_SUCCESS } = require("../Conistance/PurposeConistance")

const BASE_URL = process.env.REACT_APP_API_URL

export const purposeListAction = (filterData) => async (dispatch) => {
    dispatch({ type: PURPOSE_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/purpose/list${paramsUrl(filterData)}`);

        dispatch({ type: PURPOSE_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {

        dispatch({ type: PURPOSE_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const purposeCreateAction = (stateData, refetchDataList) => async (dispatch) => {

    dispatch({ type: PURPOSE_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/purpose/create`, stateData)
        dispatch({ type: PURPOSE_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: PURPOSE_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const purposeUpdateAction = (id, body, refetchDataList) => async (dispatch) => {
    dispatch({ type: PURPOSE_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/purpose/update/${id}`, body)
        dispatch({ type: PURPOSE_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: PURPOSE_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const purposeDeleteAction = (stateId, refetchDataList) => async (dispatch) => {
    dispatch({ type: PURPOSE_DELETE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/purpose/delete/${stateId}`)
        dispatch({ type: PURPOSE_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: PURPOSE_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}