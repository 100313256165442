import { toast } from "react-toastify";
import { fetchWrapper } from "src/services/http_requests";
import { paramsUrl } from "src/utils/halper";

const { BLOCK_LIST_REQUEST, BLOCK_LIST_SUCCESS, BLOCK_LIST_FAIL, BLOCK_CREATE_REQUEST, BLOCK_CREATE_FAIL, BLOCK_CREATE_SUCCESS, BLOCK_UPDATE_REQUEST, BLOCK_UPDATE_FAIL, BLOCK_DELETE_REQUEST, BLOCK_DELETE_FAIL, BLOCK_UPDATE_SUCCESS, BLOCK_DELETE_SUCCESS } = require("../Conistance/BlockConistance")

const BASE_URL = process.env.REACT_APP_API_URL;

export const blockListAction = (filter) => async (dispatch) => {
    dispatch({ type: BLOCK_LIST_REQUEST })
    try {
        let data = await fetchWrapper.get(`${BASE_URL}/block/list${paramsUrl(filter)}`);

        dispatch({ type: BLOCK_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: BLOCK_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const blockCreateAction = (body, refetchDataList) => async (dispatch) => {
    dispatch({ type: BLOCK_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/block/create`, JSON.stringify(body))
        dispatch({ type: BLOCK_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: BLOCK_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const blockUpdateAction = (blockId, body, refetchDataList) => async (dispatch) => {
    dispatch({ type: BLOCK_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/block/update/${blockId}`, JSON.stringify(body))
        dispatch({ type: BLOCK_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: BLOCK_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const blockDeleteAction = (blockId, refetchDataList) => async (dispatch) => {
    dispatch({ type: BLOCK_DELETE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/block/delete/${blockId}`)
        dispatch({ type: BLOCK_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: BLOCK_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}