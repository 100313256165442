const { VILLAGE_CREATE_REQUEST, VILLAGE_CREATE_SUCCESS, VILLAGE_CREATE_FAIL, VILLAGE_LIST_REQUEST, VILLAGE_LIST_SUCCESS, VILLAGE_LIST_FAIL, VILLAGE_DELETE_REQUEST, VILLAGE_UPDATE_SUCCESS, VILLAGE_UPDATE_REQUEST, VILLAGE_UPDATE_FAIL, VILLAGE_DELETE_SUCCESS, VILLAGE_DELETE_FAIL } = require("../Conistance/VillageConistance");

export const villageCreateReducer = (state = {}, action) => {
    switch(action.type){
        case VILLAGE_CREATE_REQUEST:
            return {loading: true};
        case VILLAGE_CREATE_SUCCESS:
            return { loading: false, success: true, village: action.payload}
        case VILLAGE_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const villageListReducer = (state = [], action) => {
    switch(action.type){
        case VILLAGE_LIST_REQUEST:
            return {loading: true}
        case VILLAGE_LIST_SUCCESS:
            return { loading: false, villages: action.payload}
        case VILLAGE_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const villageUpdateReducer = (state = {},action) => {
    switch(action.type){
        case VILLAGE_UPDATE_REQUEST:
            return {loading: true};
        case VILLAGE_UPDATE_SUCCESS:
            return {loading: false , village: action.payload}
        case VILLAGE_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const villageDeleteReducer = (success = '', action) => {
    switch(action.type){
        case VILLAGE_DELETE_REQUEST:
            return {loading: true}
        case VILLAGE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case VILLAGE_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}