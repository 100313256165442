import { Box, Button, Divider, Typography } from "@material-ui/core";
import React, { useState } from "react";
import StickyHeadTable from "src/components/CommonComponents/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { sakhiTransationListAction } from "src/Redux/Actions/SakhiTransactionAction";
import moment from "moment";
import { purposeListAction } from "src/Redux/Actions/PurposeAction";
import LoadingBox from "src/components/LoadingBox";
import { fetchWrapper } from "src/services/http_requests";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { generateSerialNo } from "src/utils";
import theme from "src/theme";
import { FilterList, VisibilityOutlined } from "@material-ui/icons";
import ViewForm from "./sakhi_transactions_view/ViewForm";
import { ngoListAction } from "src/Redux/Actions/NgoAction";
import DialogView from "src/components/CommonComponents/DialogView";
import CommonFilter2 from "src/components/CommonComponents/CommonFilter2";

const columns = [
  { id: "sno", label: "S.No" },
  { id: "id", label: "ID" },
  { id: "purpose", label: "Purpose" },
  { id: "beneficiary", label: "Beneficiary", },
  { id: "fo", label: "FO Name", },
  { id: "sakhi", label: "Sakhi", },
  { id: "meeting", label: "Meeting Date", minWidth: 10, },
  { id: "block", label: "Block", },
  { id: "village", label: "Village", },
  { id: "schema", label: "Scheme", },
  { id: "status", label: "Status", },
  { id: "action", label: "Action", },
];

function createData(
  sno,
  id,
  purpose,
  beneficiary,
  fo,
  sakhi,
  meeting,
  block,
  village,
  schema,
  status,
  action
) {
  return {
    sno,
    id,
    purpose,
    beneficiary,
    fo,
    sakhi,
    meeting,
    block,
    village,
    schema,
    status,
    action,
  };
}

function SakhiTransaction() {
  const pageInfo = localStorage.getItem("pageInfo");
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo")) || {};
  const loginUserRole = loginInfo?.data?.role_id;

  const [openView, setOpenView] = useState(false)
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const [paramsId, setParamsId] = useState(null);
  const [filterData, setFilterData] = useState({
    state_id: "",
    block_id: "",
    village_id: "",
    purpose_id: "",
    district_id: "",
    sakhi_id: "",
    status_id: "",
    from: "",
    to: "",
    year: "",
    ngo_id: '',
    fo_id: "",
    scheme_id: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const { states } = useSelector((state) => state.statesList);
  const { purposes } = useSelector((state) => state.purposeList);
  const { loading, sakhiTransactions } = useSelector((state) => state.sakhiTransactionList);

  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(() => pageInfo ? parseInt(pageInfo) : 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const handleFilterSearch = () => {
    if (page == 1) {
      dispatch(
        sakhiTransationListAction({
          state_id: filterData?.state_id,
          district_id: filterData?.district_id,
          block_id: filterData?.block_id,
          village_id: filterData?.village_id,
          purpose_id: filterData?.purpose_id,
          scheme_id: filterData?.scheme_id,
          ngo_id: filterData?.ngo_id,
          fo_id: filterData?.fo_id,
          sakhi_id: filterData?.sakhi_id,
          status_id: filterData?.status_id,
          from: filterData.from ? moment(filterData.from).format("YYYY-MM-DD") : "",
          to: filterData.to ? moment(filterData.to).format("YYYY-MM-DD") : "",
          year: filterData?.year,
          page: 1
        })
      );
    } else {
      localStorage.setItem("pageInfo", 1);
      setPage(1);
    }
  };
  const handleRestFilter = () => {
    setFilterData({
      state_id: "",
      block_id: "",
      village_id: "",
      purpose_id: "",
      district_id: "",
      sakhi_id: "",
      status_id: "",
      from: "",
      to: "",
      year: "",
      ngo_id: '',
      fo_id: "",
      scheme_id: "",
    });
    if (page == 1) dispatch(sakhiTransationListAction({ page: page }));
    else {
      localStorage.setItem("pageInfo", 1);
      setPage(1);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (purposes === undefined || purposes === null) dispatch(purposeListAction({ fetched: "All" }));
      if (states === undefined || states === null) dispatch(stateListAction({ fetched: "All" }));
      loginUserRole == 1 && dispatch(ngoListAction({ fetched: 'All' }))
    }
    window.scrollTo(0, 0);
    return () => {
      mounted = false;
      fetchWrapper?.finishPendingRequests();

    }
  }, []);


  useEffect(() => {
    if (sakhiTransactions?.total) {
      setTotal(sakhiTransactions?.total);
      localStorage.setItem("totalData", sakhiTransactions?.total);
    } else {
      if (parseInt(page) === 1) {
        setTotal(sakhiTransactions?.data?.length);
        localStorage.setItem("totalData", sakhiTransactions?.data?.length);
      } else setTotal(localStorage.getItem("totalData"));
    }
  }, [sakhiTransactions]);

  useEffect(() => {
    let mounted = true;
    if (page) {
      dispatch(
        sakhiTransationListAction({
          state_id: filterData?.state_id,
          district_id: filterData?.district_id,
          block_id: filterData?.block_id,
          village_id: filterData?.village_id,
          purpose_id: filterData?.purpose_id,
          scheme_id: filterData?.scheme_id,
          ngo_id: filterData?.ngo_id,
          fo_id: filterData?.fo_id,
          sakhi_id: filterData?.sakhi_id,
          from: filterData.from ? moment(filterData.from).format("YYYY-MM-DD") : "",
          to: filterData.to ? moment(filterData.to).format("YYYY-MM-DD") : "",
          year: filterData?.year,
          status_id: filterData?.status_id,
          page: page,
        })
      );
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);


  const createStatus = (foStatus, ngoStatus) => {
    let status = "";
    let approve = "bg-green-200 text-green-500";
    let pending = "bg-yellow-200 text-yellow-500";
    let rejected = "bg-red-200 text-red-500";

    let statusColor = "";
    if (parseInt(loginUserRole) === 1) {
      if (parseInt(foStatus) === 1) {
        status = "Pending by FO";
      } else if (parseInt(foStatus) === 2 && parseInt(ngoStatus) === 1) {
        status = "Pending by NGO";
      } else if (parseInt(ngoStatus) === 2) {
        status = "Approved by NGO"
      } else if (parseInt(foStatus) === 3) {
        status = "Rejected by FO"
      } else if (parseInt(ngoStatus) === 3) {
        status = "Rejected by NGO"
      } else return;
    } else {
      if (parseInt(foStatus) === 1) status = "Pending by FO";
      else if (parseInt(foStatus) === 2 && parseInt(ngoStatus) === 1) status = "Pending";
      else if (parseInt(foStatus) === 2 && parseInt(ngoStatus) === 2) status = "Approved";
      else if (parseInt(foStatus) === 3 && parseInt(ngoStatus) === 1) status = "Rejected by FO";
      else status = "Rejected";
    }
    statusColor = status.includes("Approved") ? approve : status.includes("Pending") ? pending : rejected

    return <Box className={`p-1 rounded py-1.5 ${statusColor}`}>
      {status}
    </Box>
  }
  let rows = sakhiTransactions?.data?.map((item, i) => {
    return createData(
      generateSerialNo(i, page),
      item?.id,
      item?.purpose_name,
      item?.ben_name,
      item?.fo_name,
      item?.sakhi_name,
      moment(item?.meeting_date).format("DD/MM/YYYY"),
      item?.block,
      item?.village,
      item?.scheme,
      createStatus(item.is_approve_status_fo, item.is_approve_status_ngo),
      <Box className="space-x-3">
        <button
          className="cursor-pointer"
          onClick={() => {
            setParamsId(item?.id);
            setCrudAction({
              create: false,
              edit: false,
              view: true,
              delete: false,
            });
            setOpenView(true)
          }}
        >
          <VisibilityOutlined color="primary" />
        </button>
      </Box>
    );
  });

  const refetchUserList = () => {
    const filters = {
      ...filterData, page: pageInfo,
      from: filterData.from ? moment(filterData.from).format("YYYY-MM-DD") : "",
      to: filterData.to ? moment(filterData.to).format("YYYY-MM-DD") : "",
    }
    dispatch(sakhiTransationListAction(filters));
    setOpenView(false);
  };

  let filtersToDisplay = ["state_id", "district_id", "block_id", "village_id", "purpose_id", "scheme_id", "status_id", "from", "to", "year", "fo_id", "sakhi_id"];

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">

      <Box width="100%" height="100%">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography>
            <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
              Sakhi Transaction
            </span>
          </Typography>
          <Box className="space-x-2 ">
            <Button
              variant="contained"
              size="small"
              startIcon={<FilterList color="primary" />}
              onClick={() => setShowFilter(!showFilter)}
              // color="primary"
              style={{
                backgroundColor: "white",
                color: theme.palette.primary.main,
              }}
            >
              Filter
            </Button>
          </Box>
        </Box>
        <Divider style={{ maxWidth: "100%" }} />
        {showFilter ? <CommonFilter2
          filterData={filterData}
          showFilter={showFilter}
          setFilterData={setFilterData}
          handleFilterSearch={handleFilterSearch}
          handleRestFilter={handleRestFilter}
          filtersToDisplay={loginUserRole == 1 ? [...filtersToDisplay, "ngo_id"] : filtersToDisplay}
        /> : null}
        {loading ? (
          <LoadingBox type={showFilter ? 'table' : ""} />
        ) : (
          <StickyHeadTable
            columns={columns}
            rows={rows}
            loading={loading}
            total={total}
            page={page && page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>
      <DialogView openView={openView} handleCloseView={() => setOpenView(false)}
        title={`${crudAction.view ? "View" : 'Update'} Transaction`} >
        <ViewForm sakhiDetails={sakhiTransactions?.data?.find(item => Number(item?.id) === Number(paramsId))}
          crudAction={crudAction} refetchUserList={refetchUserList} />
      </DialogView>
    </Box>
  );
}
export default SakhiTransaction;
