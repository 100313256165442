const { BLOCK_CREATE_REQUEST, BLOCK_CREATE_SUCCESS, BLOCK_CREATE_FAIL, BLOCK_LIST_REQUEST, BLOCK_LIST_SUCCESS, BLOCK_LIST_FAIL, BLOCK_DELETE_REQUEST, BLOCK_UPDATE_SUCCESS, BLOCK_UPDATE_REQUEST, BLOCK_UPDATE_FAIL, BLOCK_DELETE_SUCCESS, BLOCK_DELETE_FAIL } = require("../Conistance/BlockConistance");

export const blockCreateReducer = (state = {}, action) => {
    switch(action.type){
        case BLOCK_CREATE_REQUEST:
            return {loading: true};
        case BLOCK_CREATE_SUCCESS:
            return { loading: false, success: true, block: action.payload}
        case BLOCK_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const blockListReducer = (state = [], action) => {
    switch(action.type){
        case BLOCK_LIST_REQUEST:
            return {loading: true}
        case BLOCK_LIST_SUCCESS:
            return { loading: false, blocks: action.payload}
        case BLOCK_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const blockUpdateReducer = (state = {},action) => {
    switch(action.type){
        case BLOCK_UPDATE_REQUEST:
            return {loading: true};
        case BLOCK_UPDATE_SUCCESS:
            return {loading: false , block: action.payload}
        case BLOCK_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const blockDeleteReducer = (success = '', action) => {
    switch(action.type){
        case BLOCK_DELETE_REQUEST:
            return {loading: true}
        case BLOCK_DELETE_SUCCESS:
            return {loading: false, success: true}
        case BLOCK_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}