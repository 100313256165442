import { Button, Divider, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchWrapper } from "src/services/http_requests";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { userListAction } from "src/Redux/Actions/UserAction";
import { generateSerialNo } from "src/utils";
import LoadingBox from "src/components/LoadingBox";
import StickyHeadTable from "src/components/CommonComponents/Table";
import theme from "src/theme";
import { EditOutlined, FilterList, VisibilityOutlined } from "@material-ui/icons";
import DialogView from "src/components/CommonComponents/DialogView";
import SakhiDetailsCRUDForm from "./SakhiDetailsCRUDForm/SakhiDetailsCRUDForm";
import Drawer from "src/components/CommonComponents/Drawer";
import CommonFilter2 from "src/components/CommonComponents/CommonFilter2";


const columns = [
  { id: 'sno', label: 'S.No' },
  { id: "foName", label: "FO Name" },
  { id: "sakhiName", label: "Sakhi Name" },
  { id: "state", label: "State" },
  {
    id: "district",
    label: "District",
  },
  {
    id: "block",
    label: "Block",
  },
  {
    id: "village",
    label: "Village",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];

function createData(sno, foName, sakhiName, state, district, block, village, status, action) {
  return { sno, foName, sakhiName, state, district, block, village, status, action };
}

function SakhiDetailsView() {
  const pageInfo = localStorage.getItem('pageInfo');
  const [filterData, setFilterData] = useState({
    state_id: "",
    district_id: "",
    block_id: "",
    village_id: "",
    fo_id: '',
    sakhi_id: '',
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openView, setOpenView] = useState(false)
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const [userDetails, setUserDetails] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(() => pageInfo ? parseInt(pageInfo) : 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const statesList = useSelector((state) => state.statesList);
  const { states } = statesList;
  const { loading, users } = useSelector((state) => state.userList);

  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      !states && dispatch(stateListAction({ fetched: "All" }));
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (users?.total) {
      setTotal(users?.total);
      localStorage.setItem("totalData", users?.total);
    } else {
      if (page == 1) setTotal(users?.data?.length);
      else setTotal(localStorage.getItem("totalData"));
    }
  }, [users]);

  useEffect(() => {
    // if (page > 1) {
    let mounted = true;
    if (mounted) {
      dispatch(
        userListAction({
          role_id: 4,
          state_id: filterData.state_id,
          district_id: filterData?.district_id,
          block_id: filterData?.block_id,
          village_id: filterData?.village_id,
          fo_id: filterData?.fo_id,
          sakhi_id: filterData?.sakhi_id,
          page: page,
        })
      );
    }
    return () => (mounted = false)
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => fetchWrapper?.finishPendingRequests();
  }, []);

  const createActiveStatus = (status) => {
    if (parseInt(status) === 0) return "Inactive";
    return "Active";
  };
  const rows = users?.data?.map((item, i) => {
    return createData(
      generateSerialNo(i, page),
      item?.sakhi_fo_name,
      item?.name,
      item?.state_name,
      item?.district_name,
      item?.block_name,
      item?.village_name,
      createActiveStatus(item?.is_active),
      <Box className="space-x-3">
        <button
          className="cursor-pointer"
          onClick={(event) => {
            setUserDetails(item);
            setCrudAction({
              create: false,
              edit: true,
              view: false,
              delete: false,
            });
            setOpenDrawer(true);
            event.stopPropagation();
          }}
        >
          <EditOutlined color="primary" />
        </button>
        <button
          className="cursor-pointer"
          onClick={() => {
            setUserDetails(item);
            setCrudAction({
              create: false,
              edit: false,
              view: true,
              delete: false,
            });
            setOpenView(true);
          }}
        >
          <VisibilityOutlined color="primary" />
        </button>
      </Box>
    );
  });

  const handleFilterSearch = () => {
    if (parseInt(page) === 1) {
      dispatch(
        userListAction({
          ...filterData,
          role_id: 4,
          page: 1,
        })
      );
    } else {
      localStorage.setItem("pageInfo", 1);
      setPage(1);
    }
  };
  const handleRestFilter = () => {
    setFilterData({
      state_id: "",
      district_id: "",
      block_id: "",
      village_id: "",
      fo_id: '',
      sakhi_id: '',
    });
    if (page == 1) dispatch(userListAction({ role_id: 4, page: page }));
    else setPage(1);
  };

  const fetchUserData = () => {
    dispatch(userListAction({ role_id: 4, page: page }));
    setOpenDrawer(false);
  };

  let filtersToDisplay = ["state_id", "district_id", "block_id", "village_id", "fo_id", "sakhi_id"]

  useEffect(() => {
    if (openDrawer === false && Object.keys(userDetails)?.length) setUserDetails({});
  }, [openDrawer]);

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">

      <Box width="100%" height="100%">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography>
            <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
              Sakhi Details
            </span>
          </Typography>
          <Box className="space-x-2 ">
            <Button
              variant="contained"
              size="small"
              startIcon={<FilterList color="primary" />}
              onClick={() => setShowFilter(!showFilter)}
              // color="primary"
              style={{
                backgroundColor: "white",
                color: theme.palette.primary.main,
              }}
            >
              Filter
            </Button>
          </Box>
        </Box>
        <Divider style={{ maxWidth: "100%" }} />
        {showFilter ? <CommonFilter2
          filterData={filterData}
          showFilter={showFilter}
          setFilterData={setFilterData}
          handleFilterSearch={handleFilterSearch}
          handleRestFilter={handleRestFilter}
          filtersToDisplay={filtersToDisplay}
        /> : null}
        {loading ? (
          <LoadingBox type="table" />
        ) : (
          <StickyHeadTable
            columns={columns}
            rows={rows}
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />)}
      </Box>

      <Drawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        title={`${crudAction.view ? "View" : 'Update'} Sakhi Details`}>
        <SakhiDetailsCRUDForm
          crudAction={crudAction}
          userDetails={userDetails}
          fetchUserData={fetchUserData}
          openDrawer={openDrawer}
        />
      </Drawer>

      <DialogView
        openView={openView}
        handleCloseView={() => setOpenView(false)}
        title={`View Sakhi Details`}
      >
        <SakhiDetailsCRUDForm crudAction={crudAction} userDetails={userDetails} openDrawer={openView} />
      </DialogView>
    </Box>
  );
}

export default SakhiDetailsView;
