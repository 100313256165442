import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import BudgetTable from './BudgetTable.jsx';
import noDataSvg from 'src/assets/images/no_data.svg';

const BudgetCreatonForm = ({ data }) => {

    return (
        <>
            {!data ?
                <Box className="w-full " sx={{ width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="w-full  flex flex-col justify-start items-center">
                            <div className="w-full h-16 flex bg-slate-100 rounded "></div>
                            <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                            <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                            <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                            <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                            <div className="w-full h-14 flex  bg-white rounded border border-gray-100"></div>
                            <div className="w-full h-10 flex justify-end items-center  rounded border border-gray-100">
                                <div className="w-3/12 h-full border border-gray-100 bg-white rounded"></div>
                            </div>
                        </div>
                    </Grid>
                </Box> :
                Object.keys(data)?.length > 0 ? <BudgetTable data={data} />
                    : <Box className="w-full h-80 flex flex-col justify-center items-center space-y-2">
                        <Box className="w-56 h-56 flex justify-center items-center  ">
                            <img
                                src={noDataSvg}
                                alt="log"
                                className="w-full h-full object-contain"
                            />
                        </Box>
                        <Typography variant="h5" style={{ fontWeight: 600 }}>
                            No Data Found
                        </Typography>
                    </Box>
            }
        </>
    )
}

export default React.memo(BudgetCreatonForm);