const { ROLE_CREATE_REQUEST, ROLE_CREATE_SUCCESS, ROLE_CREATE_FAIL, ROLE_LIST_REQUEST, ROLE_LIST_SUCCESS, ROLE_LIST_FAIL} = require("../Conistance/RoleConistance");

export const roleCreateReducer = (state = {}, action) => {
    switch(action.type){
        case ROLE_CREATE_REQUEST:
            return {loading: true};
        case ROLE_CREATE_SUCCESS:
            return { loading: false, success: true, role: action.payload}
        case ROLE_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const roleListReducer = (state = [], action) => {
    switch(action.type){
        case ROLE_LIST_REQUEST:
            return {loading: true}
        case ROLE_LIST_SUCCESS:
            return { loading: false, roles: action.payload}
        case ROLE_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}
