import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { MILESTONE_LIST_REQUEST, MILESTONE_LIST_SUCCESS, MILESTONE_LIST_FAIL, MILESTONE_CREATE_REQUEST, MILESTONE_CREATE_FAIL, MILESTONE_CREATE_SUCCESS, MILESTONE_UPDATE_REQUEST, MILESTONE_UPDATE_FAIL, MILESTONE_DELETE_REQUEST, MILESTONE_DELETE_FAIL, MILESTONE_UPDATE_SUCCESS, MILESTONE_DELETE_SUCCESS } = require("../Conistance/MilestoneConistance")

const BASE_URL = process.env.REACT_APP_API_URL

export const milestoneListAction = (filter) => async (dispatch) => {
    dispatch({ type: MILESTONE_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/milestone/get_all${paramsUrl(filter)}`)

        dispatch({ type: MILESTONE_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: MILESTONE_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const milestoneCreateAction = (body, refetchUserList) => async (dispatch) => {
    dispatch({ type: MILESTONE_CREATE_REQUEST })
    try {

        const data = await fetchWrapper.post(`${BASE_URL}/milestone/create`, body)
        dispatch({ type: MILESTONE_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: MILESTONE_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const milestoneUpdateAction = (bodyId, body, refetchUserList) => async (dispatch) => {
    dispatch({ type: MILESTONE_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/milestone/update/${bodyId}`, body)
        dispatch({ type: MILESTONE_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: MILESTONE_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const milestoneDeleteAction = (milestoneId, refetchUserList) => async (dispatch) => {
    dispatch({ type: MILESTONE_DELETE_REQUEST })
    try {

        const data = await fetchWrapper.put(`${BASE_URL}/milestone/delete/${milestoneId}`)
        dispatch({ type: MILESTONE_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: MILESTONE_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}