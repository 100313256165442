import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"



const { STATE_LIST_REQUEST, STATE_LIST_SUCCESS, STATE_LIST_FAIL, STATE_CREATE_REQUEST, STATE_CREATE_FAIL, STATE_CREATE_SUCCESS, STATE_UPDATE_REQUEST, STATE_UPDATE_FAIL, STATE_DELETE_REQUEST, STATE_DELETE_FAIL, STATE_DELETE_SUCCESS, STATE_UPDATE_SUCCESS } = require("../Conistance/StateConistance")

const BASE_URL = process.env.REACT_APP_API_URL

// let cancelToken = null;
export const stateListAction = (filter) => async (dispatch) => {
    dispatch({ type: STATE_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/state/list${paramsUrl(filter)}`);
        dispatch({ type: STATE_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: STATE_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const stateCreateAction = (stateData, refetchDataList) => async (dispatch) => {
    dispatch({ type: STATE_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/state/create`, JSON.stringify(stateData))
        dispatch({ type: STATE_CREATE_SUCCESS, payload: data })
        toast.success(data?.message);
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }


    } catch (error) {
        dispatch({ type: STATE_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const stateUpdateAction = (stateId, stateDateForUpdate, refetchDataList) => async (dispatch) => {
    dispatch({ type: STATE_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/state/update/${stateId}`, JSON.stringify(stateDateForUpdate))
        dispatch({ type: STATE_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message);
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }

    } catch (error) {
        dispatch({ type: STATE_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const stateDeleteAction = (stateId, refetchDataList) => async (dispatch) => {
    dispatch({ type: STATE_DELETE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/state/delete/${stateId}`)
        dispatch({ type: STATE_DELETE_SUCCESS, payload: data })
        toast.success(data?.message);
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }

    } catch (error) {
        dispatch({ type: STATE_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}