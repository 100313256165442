import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { CENTRE_LIST_REQUEST, CENTRE_LIST_SUCCESS, CENTRE_LIST_FAIL, CENTRE_CREATE_REQUEST, CENTRE_CREATE_FAIL, CENTRE_CREATE_SUCCESS, CENTRE_UPDATE_REQUEST, CENTRE_UPDATE_FAIL, CENTRE_DELETE_REQUEST, CENTRE_DELETE_FAIL, CENTRE_UPDATE_SUCCESS, CENTRE_DELETE_SUCCESS } = require("../Conistance/CentreConistance")

const BASE_URL = process.env.REACT_APP_API_URL

export const centreListAction = (filter) => async (dispatch) => {
    dispatch({type: CENTRE_LIST_REQUEST})
    try{
        const data = await fetchWrapper.get(`${BASE_URL}/centre/list${paramsUrl(filter)}`)

        dispatch({type: CENTRE_LIST_SUCCESS,success: true, payload: data})
    }catch(error){
        dispatch({type: CENTRE_LIST_FAIL, payload: error})
        toast.error(error)
    }
}

export const centreCreateAction = (body,refetchUserList) => async (dispatch) => {
    dispatch({type: CENTRE_CREATE_REQUEST})
    try{
        
        const data = await fetchWrapper.post(`${BASE_URL}/centre/create`,body)
        dispatch({type: CENTRE_CREATE_SUCCESS,payload: data})
        toast.success(data?.message)
        if(data?.success){
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    }catch(error){
        dispatch({type: CENTRE_CREATE_FAIL, payload: error})
        toast.error(error)
    }
}

export const centreUpdateAction = (bodyId,body,refetchUserList) => async (dispatch)=>{
    dispatch({type: CENTRE_UPDATE_REQUEST})
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/centre/update/${bodyId}`,body)
        dispatch({type: CENTRE_UPDATE_SUCCESS,payload: data})
        toast.success(data?.message)
        if(data?.success){
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    }catch(error){
        dispatch({type: CENTRE_UPDATE_FAIL, payload: error})
        toast.error(error)
    }
}

export const centreDeleteAction = (centreId,refetchUserList) => async (dispatch) => {
    dispatch({type: CENTRE_DELETE_REQUEST})
    try {
        
        const data = await fetchWrapper.put(`${BASE_URL}/centre/delete/${centreId}`)
        dispatch({type: CENTRE_DELETE_SUCCESS, success: true,payload: data})
        toast.success(data?.message)
        if(data?.success){
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error){
        dispatch({type: CENTRE_DELETE_FAIL, payload: error})
        toast.error(error)
    }
}