import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Add, CloseOutlined } from '@material-ui/icons';
import { FieldArray, Formik } from 'formik';
import React from 'react'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import CustomInputs from 'src/components/CommonComponents/CustomInputs';
import { fetchApiDataList } from 'src/services/http_requests';
import * as Yup from "yup";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { lineItemCreateAction, lineItemUpdateAction } from 'src/Redux/Actions/LineItemAction';

const LineItemForm = ({ crudAction, openDrawer, refetchDataList, prefilledDetails }) => {
    const [ngoList, setNgoList] = useState([]);

    const dispatch = useDispatch();

    const initialValues = {
        ngo_id: "",
        po_number: "",
        lineItemList: [],
        financial_year: ""
    };

    const validation = useMemo(() => {
        return Yup.object().shape({
            ngo_id: Yup.string().required("NGO is required"),
            po_number: Yup.string().matches(RegExp("^[a-zA-Z0-9_]*$"), "Alphabets, numbers and underscore are accepted"),
            financial_year: Yup.string().required("Financial year is required"),
            lineItemList: Yup.array().min(1, "Select atleast one line ittem")
                .of(Yup.object().shape({
                    name: Yup.string().required("Line name is required"),
                })),
        })
    }, []);



    const formInputList = [
        {
            key: "ngo_id",
            name: "ngo_id",
            label: "NGO",
            inputType: "autoComplete",
            multiple: false,
            required: true,
            disabled: crudAction?.delete || crudAction?.view ? true : false,
            optionArray: ngoList || [],
            value: "",
            placeholder: "Select NGO",
        },
        {
            key: "financial_year",
            name: "financial_year",
            label: "Financial year",
            inputType: "year",
            disabled: crudAction?.delete || crudAction?.view ? true : false,
            value: "",
            placeholder: "Financial year",
            children: [],
            max: moment().year().toString()
        },
        {
            key: "po_number",
            name: "po_number",
            label: "PO Number",
            inputType: "text",
            required: true,
            disabled: crudAction?.delete || crudAction?.view ? true : false,
            value: "",
            placeholder: "Enter PO Number",
        },
    ];

    useEffect(() => {
        if (openDrawer) {
            fetchApiDataList("ngo_partner", { fetched: "All" }, setNgoList);
        }
    }, [openDrawer]);

    const onSubmitHandler = (values) => {
        let formData = { ...values };
        for (let i = 0; i < formData.lineItemList?.length; i++) {
            if (!formData.lineItemList[i]?.id) {
                formData.lineItemList[i].id = null;
                formData.lineItemList[i].budget_lineitem_id = prefilledDetails?.id;
                delete formData.lineItemList[i]._id;
            }
        };

        const newValues = {
            ...formData,
            financial_year: moment(formData?.financial_year).year()?.toString(),
            po_number: formData?.po_number?.toString()
        }

        if (crudAction.delete) newValues['is_deleted'] = "0";
        crudAction.create ? dispatch(lineItemCreateAction(newValues, refetchDataList))
            : dispatch(lineItemUpdateAction(prefilledDetails?.id, newValues, refetchDataList))

    }

    return (
        <div className={`h-auto  ${crudAction.view ? "pb-4 mx-4" : "pb-16 mx-2"}`}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmitHandler}
            >
                {function Render({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                    touched,
                    setErrors,
                    setStatus,
                    setSubmitting,
                    values,
                    resetForm
                }) {
                    useEffect(() => {
                        if (openDrawer === false) resetForm();
                    }, [openDrawer]);


                    useEffect(() => {
                        if (Object.keys(prefilledDetails || {})?.length > 0) {
                            setFieldValue("ngo_id", prefilledDetails?.ngo_partner?.id || "");
                            setFieldValue("po_number", prefilledDetails?.po_number || "");
                            setFieldValue("financial_year", prefilledDetails?.financial_year || "")
                            setFieldValue("lineItemList", prefilledDetails?.budget_lineitem_mappers || []);
                        }
                    }, [prefilledDetails]);
                    return (
                        <form onSubmit={handleSubmit} className="bg-transparent overflow-hidden pb-6 ">
                            <Grid container spacing={2} className='bg-white p-4' >
                                {formInputList?.map((elem, index) => (
                                    <CustomInputs
                                        key={elem?.name}
                                        name={elem?.name}
                                        id={elem?.key}
                                        label={elem?.label}
                                        inputType={elem?.inputType}
                                        value={values[elem.name]}
                                        required={elem?.required}
                                        handleBlur={handleBlur}
                                        onClick={() => setFieldTouched(elem.name, true)}
                                        onChange={(event, value) => {
                                            if (["autoComplete", "year"].includes(elem.inputType)) setFieldValue(event, value);
                                            else handleChange(event);
                                        }}
                                        error={errors[elem.name] && touched[elem.name]}
                                        helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                                        placeholder={elem?.placeholder}
                                        disabled={elem?.disabled}
                                        multiple={elem?.multiple}
                                        optionArray={elem?.optionArray}
                                        acceptedFiles={elem?.acceptedFiles}
                                        crudAction={crudAction}
                                        initialValues={initialValues}
                                        max={elem?.max}
                                    />)
                                )}

                                <FieldArray name="lineItemList">
                                    {(formii) => {
                                        return (
                                            <>
                                                {/* lineItemList */}
                                                {values?.lineItemList?.map((item, index) => {
                                                    return (<Box key={index}>
                                                        {/* heading nd remove btn  */}
                                                        <Box className='flex items-center justify-between mt-4'>
                                                            <Typography>Add Line Item {index + 1}</Typography>
                                                            <IconButton type="button" disabled={crudAction.delete} onClick={formii.handleRemove(index)}>
                                                                <CloseOutlined />
                                                            </IconButton>
                                                        </Box>

                                                        <Grid container spacing={2} className='bg-white px-0 py-2' >
                                                            <CustomInputs
                                                                key={`lineItemList[${index}].name`}
                                                                name={`lineItemList[${index}].name`}
                                                                id={`lineItemList[${index}].name`}
                                                                label={`Line name ${index + 1}`}
                                                                inputType={`text`}
                                                                value={values.lineItemList[index]?.name}
                                                                required={true}
                                                                onChange={handleChange}
                                                                error={errors?.lineItemList?.[index]?.name && touched?.lineItemList?.[index]?.name}
                                                                helperText={errors?.lineItemList?.[index]?.name && touched?.lineItemList?.[index]?.name ? errors?.lineItemList?.[index]?.name : ""}
                                                                placeholder={`Enter line name`}
                                                                disabled={crudAction.delete || crudAction.view ? true : false}
                                                                crudAction={crudAction}
                                                                initialValues={{}}
                                                            />
                                                        </Grid>
                                                    </Box>)

                                                })}

                                                {/* add button */}
                                                <Grid item xs={12} md={crudAction?.view && Object.keys(initialValues)?.length > 4 ? 6 : 12} key={"lineItemList"}>
                                                    <Box className="w-full flex justify-end ">
                                                        <Button startIcon={<Add />}
                                                            onClickCapture={() => {
                                                                const itemNumber = values?.lineItemList?.length;
                                                                if (itemNumber >= 1) {
                                                                    if (!values.lineItemList[itemNumber - 1]?.name) {
                                                                        setFieldError(`lineItemList[${itemNumber - 1}].name`, "Line item is required");
                                                                        setFieldTouched(`lineItemList[${itemNumber - 1}].name`, true);
                                                                    } else formii.push({ _id: `${itemNumber + 1}`, name: "" });
                                                                } else formii.push({ _id: `${itemNumber + 1}`, name: "" });
                                                            }}
                                                            name={"lineItemList"}
                                                            disabled={crudAction.delete}
                                                            variant="contained" color="primary" size="small">
                                                            Add Line Item
                                                        </Button>
                                                    </Box>
                                                    {(typeof errors?.lineItemList === "string" && errors?.lineItemList && touched?.lineItemList) && <Typography variant='subtitle2' color='error'>{errors?.lineItemList}</Typography>}
                                                </Grid >
                                            </>
                                        )
                                    }}
                                </FieldArray>


                                {!crudAction.view && <Box className="w-full px-2 bg-gray-100 mt-10"
                                    onClick={() => {
                                        for (let elem in initialValues) setFieldTouched(elem, true);
                                    }} >
                                    <Button type="submit" className="w-full" variant="contained" color='primary' size='large' >{crudAction.create ? 'CREATE' : crudAction.edit ? 'UPDATE' : 'DELETE'}</Button>
                                </Box>}
                            </Grid>

                        </form>
                    )
                }
                }

            </Formik>
        </div>
    )
}

export default LineItemForm;