import { Button, Divider, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineOutlined from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import StickyHeadTable from "src/components/CommonComponents/Table";
import FilterComponent from "src/components/CommonComponents/FilterComponent";
import { useSelector } from "react-redux";
import { commonFilterData } from "src/utils/filterInputData";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { districtListAction } from "src/Redux/Actions/DistrictAction";
import { blockListAction } from "src/Redux/Actions/BlockAction";
import { centreListAction } from "src/Redux/Actions/CentreAction";
import LoadingBox from "src/components/LoadingBox";

const columns = [
  { id: "state", label: "State" },
  {
    id: "district",
    label: "District",
  },
  {
    id: "block",
    label: "Block",
  },
  {
    id: "center",
    label: "Center",
  },
  { id: "fo", label: "FO" },
  {
    id: "action",
    label: "Action",
  },
];

function createData(state, district, block, center, fo, action) {
  return { state, district, block, center, fo, action };
}

const rows = [
  createData(
    "Utter Pardesh",
    "Agra",
    "Achnera",
    "Center 1",
    "FO 1",
    <Box className="space-x-3">
      <Link to="/app/mapping/centerfomapping/edit/7678">
        <EditIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/view/7678">
        <VisibilityIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/delete/7678">
        <DeleteOutlineOutlined color="primary" />
      </Link>
    </Box>
  ),
  createData(
    "Utter Pardesh",
    "Agra",
    "Achnera",
    "Center 1",
    "FO 1",
    <Box className="space-x-3">
      <Link to="/app/mapping/centerfomapping/edit/7678">
        <EditIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/view/7678">
        <VisibilityIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/delete/7678">
        <DeleteOutlineOutlined color="primary" />
      </Link>
    </Box>
  ),
  createData(
    "Utter Pardesh",
    "Agra",
    "Achnera",
    "Center 1",
    "FO 1",
    <Box className="space-x-3">
      <Link to="/app/mapping/centerfomapping/edit/7678">
        <EditIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/view/7678">
        <VisibilityIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/delete/7678">
        <DeleteOutlineOutlined color="primary" />
      </Link>
    </Box>
  ),
  createData(
    "Utter Pardesh",
    "Agra",
    "Achnera",
    "Center 1",
    "FO 1",
    <Box className="space-x-3">
      <Link to="/app/mapping/centerfomapping/edit/7678">
        <EditIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/view/7678">
        <VisibilityIcon color="primary" />
      </Link>
      <Link to="/app/mapping/centerfomapping/delete/7678">
        <DeleteOutlineOutlined color="primary" />
      </Link>
    </Box>
  ),
];

function CenterFoMappingView() {
  const [filterData, setFilterData] = useState({
    state: "",
    district: "",
    block: "",
    center: "",
  });
  const statesList = useSelector((state) => state.statesList);
  const { states, loading } = statesList;
  const districtList = useSelector((state) => state.districtList);
  const { districts } = districtList;
  const blockList = useSelector((state) => state.blockList);
  const { blocks } = blockList;
  const centreList = useSelector((state) => state.centreList);
  const { centres } = centreList;

  const filterInputData = commonFilterData.map((data) =>
    data.name === "state"
      ? { ...data, items: states?.data }
      : data.name === "district"
        ? {
          ...data,
          items: districts?.data?.filter(
            (item) => item.state_id === filterData?.state
          ),
        }
        : data.name === "block"
          ? {
            ...data,
            items: blocks?.data?.filter(
              (item) => item.district_id === filterData?.district
            ),
          }
          : data.name === "center"
            ? {
              ...data,
              items: centres?.data?.filter(
                (item) => item.state_id === filterData?.state
              ),
            } : data
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(stateListAction());
    dispatch(districtListAction());
    dispatch(blockListAction());
    dispatch(centreListAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box className="w-full min-h-screen bg-gray-100 px-2 lg:px-32 py-24 sm:py-10">
      {loading ? (
        <LoadingBox />
      ) : (
        <Box width="100%" height="100%">
          <Box
            pb={2}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography ><span className='capitalize font-semibold  text-md md:text-lg lg:text-xl text-black'>Center - FO Mapping</span></Typography>
            <Button variant="contained" color="primary" size="small">
              <Link to="/app/mapping/centerfomapping/create">
                CREATE FO MAPPING
              </Link>
            </Button>
          </Box>
          <Divider style={{ maxWidth: "100%" }} />
          <FilterComponent
            commonFilterData={filterInputData}
            filterData={filterData}
            setFilterData={setFilterData}
          />
          <StickyHeadTable columns={columns} rows={rows} />
        </Box>
      )}
    </Box>
  );
}

export default CenterFoMappingView;
