import {
  TextField,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
} from "@material-ui/core";
import { Formik } from "formik";
import { rest } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { blockListAction } from "src/Redux/Actions/BlockAction";
import { districtListAction } from "src/Redux/Actions/DistrictAction";
import { stateListAction } from "src/Redux/Actions/StateAction";

function CenterFoMappingCRUDForm({crudAction}) {
  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState({
    centre_name: "",
    state: "",
    district: "",
    block: "",
    fo_name: ''
  });

  const statesList = useSelector(state => state.statesList);
  const {states} = statesList;
  const districtList = useSelector(state => state.districtList);
  const {districts} = districtList;
  const blockList = useSelector(state => state.blockList);
  const {blocks} = blockList;

  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(stateListAction())
    dispatch(districtListAction())
    dispatch(blockListAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <Formik
    initialValues={{
        centre_name: formData?.centre_name || "",
        state: formData?.state || "",
        district: formData?.district || "",
        block: formData?.block || "",
        fo_name: formData?.fo_name || "",
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {}}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        setErrors,
        setStatus,
        setSubmitting,
        values,
      }) => {
        return (
          <form className="bg-transparent my-5 " onSubmit={handleSubmit} {...rest}>
            <Grid
              spacing={1}
              container
              className="bg-white rounded-md p-4 shadow"
            >
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    Centre Name<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <TextField
                  error={touched.centre_name && errors.centre_name}
                  helperText={touched.centre_name && errors.centre_name}
                  name="centre_name"
                  className="inputRounded"
                 placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.centre_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={crudAction.view ? true : false}
                  required
                  style= {{ borderRadius: 10}}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    State<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <Select MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                  error={touched.state && errors.state}
                  helperText={touched.state && errors.state}
                  name="state"
                  className="inputRounded"
                 placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={crudAction.view ? true : false}
                  required
                  style= {{ borderRadius: 10}}
                  variant="outlined"
                >
                  {states?.data?.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    District<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <Select MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                  error={touched.district && errors.district}
                  helperText={touched.district && errors.district}
                  name="district"
                  className="inputRounded"
                 placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.district}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={values.state === "" || crudAction.view ? true : false}
                  required
                  style= {{ borderRadius: 10}}
                  variant="outlined"
                >
                  {districts?.data?.filter(data => data.state_id === values.state)?.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    Block<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <Select MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                  error={touched.block && errors.block}
                  helperText={touched.block && errors.block}
                  name="block"
                  className="inputRounded"
                 placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.block}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={values.district === "" || crudAction.view ? true : false}
                  required
                  style= {{ borderRadius: 10}}
                  variant="outlined"
                >
                  {blocks?.data?.filter(data => data.district_id === values.district)?.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sx={4} md={4}>
                <InputLabel className="mb-2">
                  <Typography variant="caption" style={{ color: "black" }}>
                    FO Name<span className="text-red-500">*</span>
                  </Typography>
                </InputLabel>
                <Select MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                  error={touched.fo_name && errors.fo_name}
                  helperText={touched.fo_name && errors.fo_name}
                  name="fo_name"
                  className="inputRounded"
                 placeholder={crudAction?.view || crudAction?.delete ? "" : "Enter"}
                  fullWidth
                  value={values.fo_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={crudAction.view ? true : false}
                  required
                  style= {{ borderRadius: 10}}
                  variant="outlined"
                >
                  <MenuItem value={"state"}>Select</MenuItem>
                </Select>
              </Grid>
            </Grid> 
            {(crudAction?.create || crudAction?.edit)  && <Box className="w-full bg-gray-100 mt-10" >
                <Button className="w-full md:w-2/12"  variant="contained" color='primary' size='large' > {crudAction.create ? 'CREATE' : 'UPDATE'}</Button>
            </Box>}
          </form>
        );
      }}
    </Formik>
  );
}

export default CenterFoMappingCRUDForm;
