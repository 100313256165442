import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { VILLAGE_LIST_REQUEST, VILLAGE_LIST_SUCCESS, VILLAGE_LIST_FAIL, VILLAGE_CREATE_REQUEST, VILLAGE_CREATE_FAIL, VILLAGE_CREATE_SUCCESS, VILLAGE_UPDATE_REQUEST, VILLAGE_UPDATE_FAIL, VILLAGE_DELETE_REQUEST, VILLAGE_DELETE_FAIL, VILLAGE_UPDATE_SUCCESS, VILLAGE_DELETE_SUCCESS } = require("../Conistance/VillageConistance")

const BASE_URL = process.env.REACT_APP_API_URL

export const villageListAction = (filter) => async (dispatch) => {
    dispatch({ type: VILLAGE_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/village/list${paramsUrl(filter)}`);

        dispatch({ type: VILLAGE_LIST_SUCCESS, success: true, payload: data });
    } catch (error) {
        dispatch({ type: VILLAGE_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const villageCreateAction = (body, refetchDataList) => async (dispatch) => {
    dispatch({ type: VILLAGE_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/village/create`, JSON.stringify(body))
        dispatch({ type: VILLAGE_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: VILLAGE_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const villageUpdateAction = (villageId, body, refetchDataList) => async (dispatch) => {
    dispatch({ type: VILLAGE_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/village/update/${villageId}`, JSON.stringify(body))
        dispatch({ type: VILLAGE_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: VILLAGE_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const villageDeleteAction = (villageId, refetchDataList) => async (dispatch) => {
    dispatch({ type: VILLAGE_DELETE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/village/delete/${villageId}`)
        dispatch({ type: VILLAGE_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchDataList && refetchDataList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: VILLAGE_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}