import { useEffect, useState } from "react";
import { Navigate } from "react-router";
// import { Link } from "react-router-dom";
import * as Crypto from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/Redux/Auth/action";
import LoginForm from "./LoginView/LoginForm";
import { Box } from "@material-ui/core";
import axios from "axios";
import Forgotpassword from "./forgotpassword";
import Slider from "./Slider";
import logo from 'src/assets/images/logo.png'
import crisilImg from 'src/assets/images/crisil.png'
// import groupSvg from 'src/assets/images/Group.svg'

const classes = {
  root: "w-full h-screen relative overflow-hidden ",
  boxContainer:
    "w-full h-full  flex flex-col-reverse sm:flex-row justify-start items-center   overflow-hidden ",
  boxContainerLeft:
    " w-full overflow-hidden md:w-6/12 sm:h-full  flex  justify-center  items-center",
  boxContainerRight:
    "w-0 md:w-6/12 h-full flex justify-end items-center px-2",
  firstDesign: "fixed w-[0rem] h-[0rem] sm:w-[450rem] sm:h-[60rem] md:w-[50rem] md:h-[50rem] lg:w-[55rem] lg:h-[55rem] xl:w-[62rem] xl:h-[62rem] 2xl:w-[70rem] 2xl:h-[70rem] 3xl:w-[75rem] 3xl:h-[75rem] 4xl:w-[80rem] 3xl:h-[80rem] 5xl:w-[85rem] 5xl:h-[85rem] 6xl:w-[90rem] 6xl:h-[90rem]   sm:rounded-[2rem] md:rounded-[3rem] lg:rounded-[4rem] xl:rounded-[5rem]   -translate-x-1/3 -left-10 -translate-y-20 bg-red-400 z-10 rotate-45 rounded-[7rem]   ",
  // firstDesign: "fixed w-[65rem] h-[100vh]   bg-red-400 z-10 rotate-45 rounded-[7rem] -translate-y-1/4 sm:-translate-y-[20rem] xl:-translate-y-2/3 translate-x-2/4 sm:-translate-x-2/4 md:-translate-x-2/4 lg:-translate-x-2/4 xl:-translate-x-2/4  2xl:-translate-x-2/4 3xl:-translate-x-2/4",
  secoundDesign: "fixed w-[0rem] h-[0rem] sm:w-[450rem] sm:h-[60rem] md:w-[50rem] md:h-[50rem] lg:w-[55rem] lg:h-[55rem] xl:w-[62rem] xl:h-[62rem] 2xl:w-[70rem] 2xl:h-[70rem] 3xl:w-[75rem] 3xl:h-[75rem] 4xl:w-[80rem] 3xl:h-[80rem] 5xl:w-[85rem] 5xl:h-[85rem] 6xl:w-[90rem] 6xl:h-[90rem]  sm:rounded-[2rem] md:rounded-[3rem] lg:rounded-[6rem] xl:rounded-[7rem]  -translate-x-1/3   bg-primary z-20 rotate-45 rounded-[7rem]   ",
  // secoundDesign: "fixed w-[65rem] h-[100vh]  bg-primary z-20 rotate-45 rounded-[7rem] -translate-y-2/4 sm:-translate-y- xl:-translate-y-[20rem] translate-x-2/4 sm:-translate-x-2/4 md:-translate-x-2/4 lg:-translate-x-2/4 xl:-translate-x-1/3 2xl:-translate-x-2/4 3xl:-translate-x-2/4",
  thirdDesign: "fixed w-[0rem] h-[0rem] sm:w-[450rem] sm:h-[60rem] md:w-[50rem] md:h-[50rem] lg:w-[55rem] lg:h-[55rem] xl:w-[62rem] xl:h-[62rem] 2xl:w-[70rem] 2xl:h-[70rem] 3xl:w-[75rem] 3xl:h-[75rem] 4xl:w-[80rem] 3xl:h-[80rem] 5xl:w-[85rem] 5xl:h-[85rem] 6xl:w-[90rem] 6xl:h-[90rem]  sm:rounded-[2rem] md:rounded-[3rem] lg:rounded-[4rem] xl:rounded-[5rem]  -translate-x-1/3 translate-y-28   bg-red-400 z-10 rotate-45 rounded-[7rem]   "
  // thirdDesign: "fixed w-[80rem] h-[100vh]   bg-red-400 z-10 rotate-45 rounded-[7rem] -translate-y-[20rem] sm:-translate-y-1/4 xl:-translate-y-[10rem] translate-x-2/4 sm:-translate-x-2/4 md:-translate-x-2/4 lg:-translate-x-2/4 xl:-translate-x-2/4 2xl:-translate-x-2/4 3xl:-translate-x-2/4"
};

const Login = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isError = useSelector((state) => state.auth.isError);
  // eslint-disable-next-line no-unused-vars
  const storageRestPass = JSON.parse(localStorage.getItem('resetPass'))
  const [submitting, setSubmitting] = useState(false);
  const [captchaId, setCaptchaId] = useState(null);
  const [isResetPass, setIsResetPass] = useState(storageRestPass);


  const fetchCaptchaId = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/session/create`
      );
      setCaptchaId(data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleLogin = (username, password, captcha) => {
    // e.preventDefault();
    // const { username, password } = formData;
    if (username && password) {
      setSubmitting(true);
      let passwordHAsh = Crypto.HmacSHA256(
        password,
        process.env.REACT_APP_SUPERSECRET
      ).toString();
      let passwordHAshUuid = Crypto.HmacSHA256(
        passwordHAsh,
        captchaId?.uuid
      ).toString();
      dispatch(
        loginUser({
          username: username,
          // password: passwordHAsh,
          password: passwordHAshUuid,
          session_id: captchaId?.id,
          captchaText: captcha,
          // random: random.toString(),
        }, handleCaptchaId)
      );
    }
  };

  const handleCaptchaId = () => {
    fetchCaptchaId();
  };

  useEffect(() => {
    fetchCaptchaId();
  }, []);
  useEffect(() => {
    if (isError) setSubmitting(false);
  }, [isError]);

  if (isAuth) {
    return <Navigate to="/" />;
  }

  // console.log('resetPass', storageRestPass, isResetPass)
  return (
    <Box className={classes["root"]}>
      <Box className="absolute w-full flex justify-between items-center px-2 z-50">
        <Box className="w-28 h-20 ml-8 z-50 bg-white rounded-xl z-50">
          <img
            className="w-full h-full object-contain"
            src={logo}
            alt="hello"
          />
        </Box>
        <Box className=" w-44  ">
          <img
            className="w-full h-full object-contain"
            src={crisilImg}
            alt="crisil-foundation"
          />
        </Box>
      </Box>
      <Box className={classes["boxContainer"]}>
        <Box className={classes["firstDesign"]}></Box>
        <Box className={classes['secoundDesign']}></Box>
        <Box className={classes['thirdDesign']}></Box>
        <Box className={classes["boxContainerLeft"]}>
          {isResetPass ? (
            <Forgotpassword
              setIsResetPass={setIsResetPass}
              isResetPass={isResetPass}
            />
          ) : (
            <LoginForm
              handleLogin={handleLogin}
              captchaId={captchaId}
              handleCaptchaId={handleCaptchaId}
              setIsResetPass={setIsResetPass}
              isResetPass={isResetPass}
            />
          )}
          {/* <span className="capitalize font-semibold text-red-500 text-3xl sm:text-lg md:text-xl lg:text-3xl text-black">Welcome Back</span>
          <Box className="w-8/12 sm:w-4/12 h-full sm:h-2/6">
            <img
              className="w-full h-full object-contain"
              src={logo}
              alt="hello"
            />
          </Box>
          <Box className="hidden sm:block w-7/12 h-4/6">
            <img
              className="w-full h-full object-contain"
              src={groupSvg}
              alt="logo"
            />
          </Box> */}
        </Box>
        <Box className={classes.boxContainerRight}>
          <Slider isResetPass={isResetPass} />
        </Box>
      </Box>
    </Box>
  );
};
export default Login;
