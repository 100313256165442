import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SakhiDetailsCRUDForm from "./SakhiDetailsCRUDForm";
import { useDispatch, useSelector } from "react-redux";
import { userListAction } from "src/Redux/Actions/UserAction";
import LoadingBox from "src/components/LoadingBox";
import { fetchWrapper } from "src/services/http_requests";

function SakhiDetailsCRUDFormView() {
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const path = window.location.pathname;
  const { id: paramsID } = useParams();
  const { loading, users } = useSelector((state) => state.userList);

  const dispatch = useDispatch();
  useEffect(() => {
    if (path === `/app/fodetails/create`) {
      setCrudAction({
        create: true,
        edit: false,
        view: false,
        delete: false,
      });
    } else if (path === `/app/fodetails/edit/${paramsID}`) {
      setCrudAction({
        create: false,
        edit: true,
        view: false,
        delete: false,
      });
    } else if (path === `/app/sakhidetails/view/${paramsID}`) {
      setCrudAction({
        create: false,
        edit: false,
        view: true,
        delete: false,
      });
    } else {
      setCrudAction({
        create: false,
        edit: false,
        view: false,
        delete: true,
      });
    }
  }, [path, paramsID]);
  useEffect(() => {
    let mounted = true;

    if (mounted && paramsID) {
      dispatch(userListAction({ role_id:4,id: paramsID }));
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem('previousUrl',window.location.href)
    return() => (fetchWrapper?.finishPendingRequests())
  },[])

  const userDetails = users?.data?.find((item) => item.id === Number(paramsID));
  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Box className="w-full h-full">
        <Box
          pb={2}
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box className="flex justify-center items-center space-x-2">
            <Link to="/app/sakhidetails">
              <ArrowBackIcon />
            </Link>
            <Typography>
              <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                {crudAction.create && "Create"} {crudAction.edit && "Edit"}{" "}
                {crudAction.view && "View"} Sakhi
              </span>
            </Typography>
          </Box>
        </Box>
        {loading ? (
          <LoadingBox />
        ) : (
          <SakhiDetailsCRUDForm
            crudAction={crudAction}
            userDetails={userDetails}
          />
        )}
      </Box>
    </Box>
  );
}

export default SakhiDetailsCRUDFormView;
