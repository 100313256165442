import { AccountTreeOutlined, EmojiObjectsOutlined, GroupWorkOutlined, HearingOutlined, HomeOutlined, MultilineChart, ScatterPlotOutlined, SettingsInputAntenna, VerifiedUserOutlined } from "@material-ui/icons";

const genderGroupLegend = ["Male", "Female", "Transgender"];
const shgGroupLegend = ["SHG", "Non SHG"];

const activeIndicatorStyle =
  "w-full h-28 rounded-lg border border-primary bg-white p-2 px-3 flex  justify-center items-center";
const deactiveIndicatorStyle =
  "w-full h-28 rounded-lg hover:border hover:border-primary bg-white p-2 px-3 flex  justify-center items-center";

  const FoChartLabels = [
    "Overall Transaction Status",
    "Remuneration Till Date",
    "Average Transactions Per Month",
    "Beneficiaries Reached By Month",
  ];
  
  const AdminChartLabels = [
    "Overall Transaction Status",
    "Remuneration Till Date",
    "Average Transactions Per Month",
    "Evidence Per Month",
    "Comparison",
  
    // "Average. Evidence Revenue Per Month",
    "5 Performing Blocks",
    "Average Sakhi Revenue Per Month",
    "5 Best Performing Sakhis",
    "Sakhis Providing Evidences Per Month",
  ];
  
  const legendActiveColor =
    "px-4 py-1 bg-primary text-white rounded-xl hover:bg-red-100 hover:text-primary";
  const legendDeactiveColor =
    "px-4 py-1 bg-red-100 text-primary rounded-xl hover:bg-primary hover:text-white";
  
  const columns = [
    {
      id: "ranking",
      label: "Ranking",
    },
    {
      id: "sakhi",
      label: "Sakhi",
    },
    {
      id: "block",
      label: "Block",
    },
    {
      id: "scoring",
      label: "Scoring",
    },
  ];
  
  const AdminDetailsBox = [
    {
      icon: (
        <ScatterPlotOutlined
        
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Onboarded Sakhis",
      number: 1234,
    },
    {
      icon: (
        <ScatterPlotOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Earning Sakhis",
      number: 1234,
    },
    {
      icon: (
        <VerifiedUserOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Active Sakhis",
      number: 1234,
    },
    {
      icon: (
        <HearingOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Inactive Sakhis",
      number: 1234,
    },
    {
      icon: (
        <MultilineChart
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Income of Sakhis",
      number: 65146464.0,
    },
    {
      icon: (
        <MultilineChart
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Average Income of Sakhis",
      number: 3456.0,
    },
    {
      icon: (
        <GroupWorkOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Median Income of Sakhis",
      number: 3456.0,
    },
    {
      icon: (
        <HomeOutlined color="primary" style={{ width: "40px", height: "40px" }} />
      ),
      label: "Total Villages Reached",
      number: 8845,
    },
    {
      icon: (
        <SettingsInputAntenna
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total SHGs Covered",
      number: 4,
    },
    {
      icon: (
        <EmojiObjectsOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Unique Beneficiaries Reached",
      number: 5234,
    },
    {
      icon: (
        <SettingsInputAntenna
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Transactions",
      number: 4845,
    },
    {
      icon: (
        <AccountTreeOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Schemes",
      number: 4845,
    },
    {
      icon: (
        <AccountTreeOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Services",
      number: 4845,
    },
    {
      icon: (
        <AccountTreeOutlined
          color="primary"
          style={{ width: "40px", height: "40px" }}
        />
      ),
      label: "Total Awareness",
      number: 5845,
    },
  ];
  

  const titleButton = [
    "Beneficiaries Reached By Month",
    "Remuneration Till Date",
    "Average Transactions Per Month",
    "Evidence Per Month",
    // "Average. Evidence Revenue Per Month",
    "Comparison",
    "Average Sakhi Revenue Per Month",
    "Sakhis Providing Evidences Per Month",
  ]

const titleLeftButtonActiveStyle = "border px-1 rounded-l-md bg-white hover:bg-gray-100 text-gray-500 ";
const titleRightButtonActiveStyle = "border px-1 rounded-r-md bg-white hover:bg-gray-100 text-gray-500 ";
const titleLeftButtonDeactiveStyle ="border px-1 rounded-l-md bg-gray-100 hover:bg-white  text-gray-500"
const titleRightButtonDeactiveStyle ="border px-1 rounded-r-md bg-gray-100 hover:bg-white hover:text-gray-500 text-gray-500"

export {
    genderGroupLegend,
    shgGroupLegend,
    activeIndicatorStyle,
    deactiveIndicatorStyle,
    FoChartLabels,
    legendActiveColor,
    legendDeactiveColor,
    AdminDetailsBox,
    AdminChartLabels,
    columns,
    titleButton,
    titleLeftButtonActiveStyle,
    titleRightButtonActiveStyle,
    titleLeftButtonDeactiveStyle,
    titleRightButtonDeactiveStyle
}