export const  BLOCK_LIST_REQUEST = 'BLOCK_LIST_REQUEST'
export const BLOCK_LIST_SUCCESS = 'BLOCK_LIST_SUCCESS'
export const BLOCK_LIST_FAIL = 'BLOCK_LIST_FAIL'

export const BLOCK_CREATE_FAIL = 'BLOCK_CREATE_FAIL'
export const BLOCK_CREATE_REQUEST = 'BLOCK_CREATE_REQUEST'
export const BLOCK_CREATE_SUCCESS = 'BLOCK_CREATE_SUCCESS'

export const BLOCK_UPDATE_REQUEST = 'BLOCK_UPDATE_REQUEST'
export const BLOCK_UPDATE_SUCCESS = 'BLOCK_UPDATE_SUCCESS'
export const BLOCK_UPDATE_FAIL = 'BLOCK_UPDATE_FAIL'

export const BLOCK_DELETE_REQUEST = 'BLOCK_DELETE_REQUEST'
export const BLOCK_DELETE_SUCCESS = 'BLOCK_DELETE_SUCCESS'
export const BLOCK_DELETE_FAIL = 'BLOCK_DELETE_FAIL'