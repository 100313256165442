import React from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";
import {
  AppBar,
  Box,
  Toolbar,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import "./Top.css";
import { Menu } from "@material-ui/icons";
import logo from 'src/assets/images/logo.png';
import crisil from 'src/assets/images/crisil.png';


const TopBar = ({ className, setOpenNav, openNav, ...rest }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  var lastScrollTop = 0;
  $(window).scroll(function () {
    var st = $(this).scrollTop();
    var logo__right = $(".logo__right");
    setTimeout(function () {
      if (st > lastScrollTop) {
        logo__right.addClass("hide");
      } else {
        logo__right.removeClass("hide");
      }
      lastScrollTop = st;
    }, 100);
  });
  return (
    <AppBar className="w-full bg-white px-0 py-1 md:px-8 " style={{ backgroundColor: 'white', color: 'black', zIndex: 1100 }} >
      <Toolbar className="w-full h-20 flex justify-between items-center  py-1 bg-white">
        <Box className="w-24 h-24 md:w-28 md:h-28 flex jusitfy-center items-center">
          <Box className="md:hidden p-1 hover:text-primary hover:bg-red-100 rounded-[20rem]" onClick={() => setOpenNav(!openNav)}>
            <Menu />
          </Box>
          <Link
            style={{ color: "black" }}
            to="/app/dashboard"
          // className="w-16 h-16 md:w-28 md:h-28"
          >
            <img
              src={logo}
              alt="log"
              className="w-full h-full object-contain"
            />
          </Link>
        </Box>
        <Box className="relative flex justify-center items-center space-x-1">
          {/* <span className='absolute w-full right-10 md:right-4  capitalize text-xs md:text-md lg:text-md text-black md:font-semibold'>Development by</span> */}
          <Box className="relative -right-6 md:-right-10 w-28 md:w-44  ">
            <img className="w-full h-full object-contain" src={crisil} alt='crisil-foundation' />
          </Box>
        </Box>
      </Toolbar>
      {/* <Divider light /> */}
      {/* <TabsView /> */}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
