import { TableCell, TableRow, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';

const BudgetRows = ({ index, elem, targetData, setTargetData, userRole, setSuperTotal }) => {
    const [maxAmount, setMaxAmount] = useState(0);

    const setAmountValueinData = (name, value) => {
        let copiedData = { ...targetData };
        eval(`copiedData.data${name}`).target = parseInt(value);
        setTargetData(copiedData);
        let sum = 0;

        const calSumOfAlltarget = () => elem?.budgetList?.map(item => {
            let itemTarget = item?.target ? parseInt(item?.target) : 0;
            sum += itemTarget;
            return [];
        });

        calSumOfAlltarget();
        if (sum > maxAmount) {
            toast.error("Amount should be less than total Budget")
            eval(`copiedData.data${name}`).target = "";
        } else return;
    };

    const setExpense = (name, value) => {
        let copiedData = { ...targetData };
        eval(`copiedData.data${name}`).achieved = parseInt(value);
        setTargetData(copiedData);
        let sum = 0;

        const calSumOfAllachieved = () => elem?.budgetList?.map(item => {
            let itemTarget = item?.achieved ? parseInt(item?.achieved) : 0;
            sum += itemTarget;
            return [];
        });

        calSumOfAllachieved();
        if (sum > maxAmount) {
            toast.error("Achieved amount should be less than total Budget")
            eval(`copiedData.data${name}`).achieved = "";
        } else return;

    }

    useEffect(() => {
        if (targetData?.data) {
            setMaxAmount(targetData?.data?.[index]?.amount);
        }
    }, [targetData]);

    useEffect(() => {
        let sTotal = 0;
        targetData?.data?.map(elem => {
            sTotal += parseInt(elem?.amount || 0);
            return [];
        });
        setSuperTotal(parseInt(sTotal));
    }, [maxAmount]);


    return (
        <TableRow key={index} >
            <TableCell style={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 1 }}>
                {elem.sno}</TableCell>
            <TableCell style={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 1 }}>
                {elem.budget_lineitem}</TableCell>
            <TableCell style={{ position: "sticky", left: 0, backgroundColor: "white", minWidth: 200, maxWidth: 200 }}>
                <TextField
                    key={`[${index}].amount`}
                    type={"number"}
                    id={`[${index}]`}
                    name={`[${index}].amount`}
                    className="inputRounded"
                    label={""}
                    placeholder={""}
                    fullWidth
                    value={targetData?.data?.[index]?.amount || ""}
                    onKeyDown={(e) => { if (["-", "+", "e", "."].includes(e.key)) e.preventDefault() }}
                    onChange={(e) => {
                        let copiedData = { ...targetData };
                        let sumOfTargets = 0;
                        copiedData?.data?.[index]?.budgetList?.map(budget => {
                            sumOfTargets += budget.target ? budget.target : 0;
                            return [];
                        });
                        if (e.target.value < sumOfTargets) {
                            toast.error(`Budget must be greater than target.`)
                            copiedData.data[index]?.budgetList?.map((targ, i) => {
                                eval(`copiedData.data[${[index]}].budgetList[${i}]`).target = "";
                                return [];
                            })
                        }
                        eval(`copiedData.data[${[index]}]`).amount = parseInt(e?.target?.value);
                        setTargetData(copiedData);
                        setMaxAmount(parseInt(e?.target?.value));
                    }}
                    disabled={userRole === 2}
                    style={{ borderRadius: 10 }}
                    variant="outlined"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            backgroundColor: 'white',
                            paddingRight: '8px',
                            fontSize: 14
                        }
                    }}
                    onWheel={(e) => e.target.blur()}
                />
            </TableCell>

            {elem?.budgetList?.map((budget, budgetIndex) =>
                <>
                    <TableCell style={{ maxWidth: 200, minWidth: 130 }}>
                        <TextField
                            key={`budgetProposed${budgetIndex}`}
                            type={"number"}
                            id={""}
                            name={`[${index}].budgetList[${budgetIndex}]`}
                            className="inputRounded"
                            label={""}
                            placeholder={""}
                            fullWidth
                            value={budget?.target || ""}
                            onKeyDown={(e) => { if (["-", "+", "e", "."].includes(e.key)) e.preventDefault() }}
                            onChange={(e) => setAmountValueinData(e.target.name, e.target.value)}
                            disabled={userRole === 2 ? true : !targetData.data?.[index]?.amount}
                            style={{ borderRadius: 10 }}
                            variant="outlined"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    backgroundColor: 'white',
                                    paddingRight: '8px',
                                    fontSize: 14
                                }
                            }}
                            onWheel={(e) => e.target.blur()}

                        />
                    </TableCell>
                    {userRole === 2 && <TableCell style={{ maxWidth: 200, minWidth: 130 }} >
                        <TextField
                            key={`budgetAchieved${budgetIndex}`}
                            type={"number"}
                            id={""}
                            name={`[${index}].budgetList[${budgetIndex}]`}
                            className="inputRounded"
                            label={""}
                            placeholder={""}
                            fullWidth
                            value={budget?.achieved || ""}
                            onKeyDown={(e) => { if (["-", "+", "e", "."].includes(e.key)) e.preventDefault() }}
                            onChange={(e) => setExpense(e.target.name, e.target.value)}
                            disabled={targetData?.data?.[index]?.amount && budget?.target ? false : true}
                            style={{ borderRadius: 10 }}
                            variant="outlined"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    backgroundColor: 'white',
                                    paddingRight: '8px',
                                    fontSize: 14
                                }
                            }}
                            onWheel={(e) => e.target.blur()}
                        />
                    </TableCell >}
                </>
            )}
        </TableRow>
    )
}

export default React.memo(BudgetRows);