import { Box, CircularProgress, IconButton, TextField } from '@material-ui/core';
import { CachedRounded, SendRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { fetchWrapper } from 'src/services/http_requests';
import { paramsUrl } from 'src/utils/halper';
import { getToken } from 'src/utils/sessionStorage';
import { v4 as uuid } from 'uuid';

const FeedbackChat = ({ feedbackData, loginUserData }) => {
    const [reply, setReply] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [sending, setSending] = useState(false);
    const [fetchingMssg, setFetchingMssg] = useState(false);
    const [fetchingMoreMssg, setFetchingMoreMssg] = useState(false);
    const [replyTo, setReplyTo] = useState(0);
    const windowRef = useRef(null);
    const [page, setPage] = useState(1);
    const [showLoadBtn, setShowLoadBtn] = useState(false);

    const dataPerPage = 100;

    const scrollToBottom = () => {
        windowRef?.current?.scrollIntoView({ behaviour: "smooth" });
    }

    const getFeedbackMessages = async (page) => {
        try {
            const { data } = await fetchWrapper.get(`
            ${process.env.REACT_APP_API_URL}/feedback/feedbackMsg${paramsUrl({ feedbackId: feedbackData?.id, page: page, limit: dataPerPage })}`);
            const messageData = data?.map(elem => {
                return {
                    feebackTxnId: elem?.feebackTxnId,
                    message: elem?.message,
                    by: elem?.by,     //user type id,
                    createdAt: elem?.createdAt
                }
            });
            messageData.reverse();
            setShowLoadBtn(data?.length == dataPerPage);
            setFetchingMssg(false);

            const repToObj = messageData.find(mssg => mssg.by !== loginUserData?.id);
            setReplyTo(repToObj);
            setMessageList(prev => [...messageData, ...prev]);
            page === 1 && scrollToBottom();
        } catch (error) {
            setFetchingMssg(false);
            throw (error);
        } finally {
            setFetchingMoreMssg(false);
        }
    };
    const createReply = async () => {
        setSending(true);
        try {
            const mssgObj = {
                "feebackTxnId": uuid(),
                "message": reply,
                "by": loginUserData?.id,
                "replyId": replyTo?.by,
                "createdAt": moment().format()
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/feedback/create`,
                {
                    "txnId": feedbackData?.txnid,
                    "userId": loginUserData?.id,
                    "feedbackMsg": [mssgObj]
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": getToken(),
                    },
                }
            )
            scrollToBottom();
            setMessageList(prev => [...prev, mssgObj]);
            setReply("");

        } catch (error) {
            throw (error.message);
        } finally {
            setSending(false);
        }
    };

    useEffect(() => {
        if (Object.keys(feedbackData || {}).length > 0) {
            setFetchingMssg(true);
            getFeedbackMessages(page);
        }
        return () => {
            setMessageList([]);
            setReply("");
            setPage(1);
            setShowLoadBtn(false)
        }
    }, [feedbackData]);

    useEffect(() => {
        if (page > 1) {
            setFetchingMoreMssg(true);
            getFeedbackMessages(page);
        }
    }, [page]);


    useEffect(() => {
        page === 1 && scrollToBottom();
    }, [messageList]);

    return (
        <div className=' border-t-[1px] h-full mx-4 pb-4'>
            {fetchingMssg ?
                <div>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((elem, i) => <Skeleton key={i} animation="wave" variant="rect" width={"100%"} height={"5vh"} style={{ margin: 2 }} />)}
                </div> : <>
                    <Box className={`h-[60vh] w-full overflow-auto p-2 m-0 bg-white`} >
                        {showLoadBtn && <div className="flex justify-center items-center">
                            {fetchingMoreMssg && <CircularProgress size="1rem" />} <span className='text-center cursor-pointer px-auto border-2 px-2 py-1 rounded-md mr-4' onClick={() => setPage(page + 1)}>Load more</span>
                        </div>}
                        {messageList?.map((elem) => {
                            return (<li key={elem?.feebackTxnId}
                                className={`flex flex-col  ${loginUserData?.id === elem?.by ? "items-end" : "items-start"}`} >
                                <p className={`max-w-[70%] text-sm px-4 py-2 rounded-2xl border-2
                                    ${loginUserData?.id === elem?.by ?
                                        "my-1 bg-[#D6002A] border-[#D6002A] text-white rounded-br-sm"
                                        : "rounded-bl-sm my-1 bg-white border-[#D6002A] text-black"}`}
                                >{elem.message}</p>
                                <span className='text-[8px]'>{moment(elem?.createdAt).format("DD/MM/YYYY h:mm a")}</span>
                            </li>)
                        }
                        )}
                        <div ref={windowRef} />
                    </Box>
                </>}



            <Box className='flex items-center justify-between mt-2'>
                <TextField
                    id={"reply"}
                    className="inputRounded"
                    fullWidth
                    value={reply}
                    style={{ borderRadius: 10 }}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: false,
                        style: {
                            backgroundColor: 'white',
                            fontSize: 14,
                        }
                    }}
                    onChange={e => setReply(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === "Enter" && reply.length) createReply();
                    }}
                />
                <Box className='active:bg-[#fcacbc] p-2 flex items-center justify-center 
                cursor-pointer rounded-md ml-4 bg-[#D6002A]' onClick={() => { if (reply.length) createReply() }}>
                    <SendRounded fontSize='medium' className='p-0 text-white translate-x-[2px]' />
                </Box>
            </Box>
        </div>
    )
}

export default FeedbackChat;