import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import theme from 'src/theme'

function BlockComponent({ config, clicktopFiveBlockSakhi, clicktopFiveBlockTransaction, topFiveBlockSakhi, topFiveBlockTransaction, worstFiveBlockTransaction, worstFiveSakhiTransaction }) {
  const [afterLoadClass1, setAfterLoadClass1] = useState(false)
  const [afterLoadClass2, setAfterLoadClass2] = useState(false)
  useEffect(() => {
    setAfterLoadClass1(false)
    setTimeout(() => {
      setAfterLoadClass1(true)
    }, 100);

  }, [topFiveBlockTransaction, worstFiveBlockTransaction, clicktopFiveBlockTransaction])
  useEffect(() => {
    setAfterLoadClass2(false)
    setTimeout(() => {
      setAfterLoadClass2(true)
    }, 100);

  }, [topFiveBlockSakhi, worstFiveSakhiTransaction, clicktopFiveBlockSakhi])
  return (
    <Box className="w-11/12 h-full  h-72  space-y-4  overflow-hidden" key={config.title || Math.random()}>
      {["5 Performing Blocks"]?.includes(config?.title)
        ? (clicktopFiveBlockTransaction === "top"
          ? topFiveBlockTransaction
          : worstFiveBlockTransaction
        )?.map((row, i) => (
          <Box key={i} className="w-full flex justify-between items-center ">
            <Box className="w-2/12 border-r-2 bg-white z-50 break-words">
              <Typography
                variant="body1"
                style={{ color: theme?.palette?.grey[500] }}
              >
                {row.block_name}
              </Typography>
            </Box>
            <Box className={afterLoadClass1 ? `w-8/12 h-5 transition translate-x-0 tranform delay-${i === 0 ? 100 : i === 1 ? 200 : i === 2 ? 300 : i === 3 ? 500 : i === 4 ? 700 : 1000} duration-500 ease-in-out opacity-100 z-0` : "w-0 h-5 transition -translate-x-full opactiy-0 z-0"}>
              <Box
                className="h-full rounded-r-xl"
                style={{
                  width: `${((100 * row.total_transaction) /
                    Math.max(
                      ...(clicktopFiveBlockTransaction ===
                        "top"
                        ? topFiveBlockTransaction
                        : worstFiveBlockTransaction
                      ).map((val) =>
                        parseInt(val.total_transaction)
                      )
                    )) || 0}%`,
                  backgroundColor: [0, 2, 4].includes(i)
                    ? clicktopFiveBlockTransaction ===
                      "top"
                      ? "#1a3e77"
                      : "#a8b918"
                    : clicktopFiveBlockTransaction ===
                      "top"
                      ? "#00b0f0"
                      : "#29858c",
                }}
              ></Box>
            </Box>
            <Box className="w-2/12 px-2">
              <Typography
                variant="body1"
                style={{ fontWeight: 600 }}
              >
                {row.total_transaction}
              </Typography>
            </Box>
          </Box>
        ))
        : null}
      {["5 Best Performing Sakhis"]?.includes(config?.title)
        ? (clicktopFiveBlockSakhi === "top"
          ? topFiveBlockSakhi
          : worstFiveSakhiTransaction
        )?.map((row, i) => (
          <Box key={i} className="w-full flex justify-between items-center ">
            <Box className="w-3/12 border-r-2 z-50 bg-white  break-words">
              <Typography
                variant="body1"
                style={{ color: theme?.palette?.grey[500], textAlign: 'center' }}
              >
                {clicktopFiveBlockSakhi === "top"
                  ? row.sakhi_name
                  : row.sakhi_name}
              </Typography>
            </Box>
            <Box className={afterLoadClass2 ? `w-7/12 h-5 transition translate-x-0 tranform delay-${i === 0 ? 100 : i === 1 ? 200 : i === 2 ? 300 : i === 3 ? 500 : i === 4 ? 700 : 1000} duration-1000   ease-in-out opacity-100 z-0 overflow-hidden` : "w-0 h-5 transition -translate-x-full opactiy-0 z-0 overflow-hidden"}>
              <Box
                className="h-full rounded-r-xl"
                style={{
                  width: `${(100 *
                    (clicktopFiveBlockSakhi === "top"
                      ? row.total_sakhi
                      : row.total_sakhi)) /
                    Math.max(
                      ...(clicktopFiveBlockSakhi ===
                        "top"
                        ? topFiveBlockSakhi
                        : worstFiveSakhiTransaction
                      ).map((val) =>
                        parseInt(val.total_sakhi)
                      )
                    )}%`,
                  backgroundColor: [0, 2, 4].includes(i)
                    ? clicktopFiveBlockSakhi === "top"
                      ? "#1a3e77"
                      : "#a8b918"
                    : clicktopFiveBlockSakhi === "top"
                      ? "#00b0f0"
                      : "#29858c",
                }}
              ></Box>
            </Box>
            <Box className="w-2/12 px-2">
              <Typography
                variant="body1"
                style={{ fontWeight: 600 }}
              >
                {clicktopFiveBlockSakhi === "top"
                  ? row.total_sakhi
                  : row.total_sakhi}
              </Typography>
            </Box>
          </Box>
        ))
        : null}
    </Box>
  )
}

export default BlockComponent