import { Box, Button, Grid, MuiThemeProvider, TextField } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons';
import { Formik } from 'formik'
import { DropzoneDialog } from 'material-ui-dropzone';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AutocompleteComponent from 'src/components/CommonComponents/AutocompleteComponent';
import CustomInputs from 'src/components/CommonComponents/CustomInputs';
import { fetchWrapper } from 'src/services/http_requests';
import { getToken } from 'src/utils/sessionStorage';
import * as Yup from 'yup';
import { v4 as uuidv4 } from "uuid";
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_API_URL;

const URL = RegExp(/^((http?s?):\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/);
const validation = Yup.object().shape({
    title: Yup.string().required("Title is required."),
    state_id: Yup.string().required("State is required"),
    file: Yup.mixed().required("File is required."),
    redirect_url: Yup.string()
        .matches(
            URL,
            'Enter correct url!'
        )
        .required('Please provide link'),
})

const BannerCRUDForm = ({ setOpenDrawer, refetchDataList, openDrawer, crudAction, userDetails }) => {
    const { states } = useSelector((state) => state.statesList);

    const initialValues = {
        title: "",
        state_id: "",
        file: "",
        txn_id: "",
        redirect_url: ""
    };

    const onSubmit = async (values) => {
        delete values.state_name;
        values.txn_id = values.txn_id ? values.txn_id : uuidv4();
        values.state_id = Array.isArray(values?.state_id) ? values?.state_id?.join(",") : values.state_id;
        let bannerAction = 'update';
        try {
            let data = {};
            if (crudAction.create) {
                data = await fetchWrapper.post(`${BASE_URL}/banner/create`, values);
            }
            else {
                if (crudAction.delete) bannerAction = 'delete';
                else if (crudAction.edit) bannerAction = 'update'
                else return;
                data = await fetchWrapper.put(`${BASE_URL}/banner/${bannerAction}/${values.id}`, values);
            }
            toast.success(data?.message);
            refetchDataList({ page: 1 });
            setTimeout(() => setOpenDrawer(false), 300);
        } catch (error) {
            toast.error(error.message);
            throw (error);
        }
    };

    const bannerFormInput = [

        {
            key: "state_id",
            name: "state_id",
            label: "State",
            inputType: "autoComplete",
            multiple: true,
            required: true,
            disabled: crudAction?.delete || crudAction?.view ? true : false,
            optionArray: states?.data || [],
            value: "",
            placeholder: "Select State",
        },
        {
            key: "title",
            name: "title",
            label: "Title",
            inputType: "text",
            required: true,
            disabled: crudAction?.delete || crudAction?.view ? true : false,
            value: "",
            placeholder: "Enter Title",
        },
        {
            key: "redirect_url",
            name: "redirect_url",
            label: "URL",
            inputType: "url",
            required: true,
            disabled: crudAction?.delete || crudAction?.view ? true : false,
            value: "",
            placeholder: "Enter URL",
        },
        {
            key: "file",
            name: "file",
            label: "Upload Image",
            inputType: "file",
            required: false,
            disabled: crudAction?.delete || crudAction?.view ? true : false,
            value: "",
            placeholder: "Upload Image",
            acceptedFiles: [".png", ".jpg", ".jpeg"]

        },
    ];

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {function Render({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    touched,
                    setErrors,
                    setStatus,
                    setSubmitting,
                    values,
                    resetForm
                }) {
                    useEffect(() => {
                        if (openDrawer == false) {
                            resetForm();
                        }
                    }, [openDrawer]);
                    useEffect(() => {
                        if (userDetails) {
                            for (let elem in userDetails) setFieldValue(elem, userDetails[elem]);
                        }
                    }, [userDetails]);

                    return (
                        <form onSubmit={handleSubmit} className="bg-transparent overflow-hidden pb-6 ">
                            <Grid container spacing={2} className='bg-white p-4' >
                                {bannerFormInput?.map((elem, index) => {
                                    return (
                                        <CustomInputs
                                            name={elem?.name}
                                            id={elem?.key}
                                            label={elem?.label}
                                            inputType={elem?.inputType}
                                            value={values[elem.name]}
                                            required={elem?.required}
                                            handleBlur={handleBlur}
                                            onClick={() => setFieldTouched(elem.name, true)}
                                            onChange={(event, value) => {
                                                if (["file", "autoComplete"].includes(elem.inputType)) setFieldValue(event, value);
                                                else handleChange(event);
                                            }}
                                            error={errors[elem.name] && touched[elem.name]}
                                            helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                                            placeholder={elem?.placeholder}
                                            disabled={elem?.disabled}
                                            multiple={elem?.multiple}
                                            optionArray={elem?.optionArray}
                                            acceptedFiles={elem?.acceptedFiles}
                                            crudAction={crudAction}
                                            initialValues={initialValues}
                                        />
                                    )
                                })}

                                {crudAction.view ? null : <Grid item xs={12} sx={12} md={12}>
                                    <Box className="w-full py-5">
                                        <Button
                                            type="submit"
                                            className="w-full"
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                        >
                                            {crudAction.create ? "CREATE"
                                                : crudAction.edit ? "EDIT"
                                                    : crudAction.delete ? "DELETE"
                                                        : "SUBMIT"}
                                        </Button>
                                    </Box>
                                </Grid>}
                            </Grid>
                        </form>
                    )
                }}

            </Formik>
        </div >
    )
}

export default BannerCRUDForm