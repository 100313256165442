export const  TRAIN_KIT_LIST_REQUEST = 'TRAIN_KIT_LIST_REQUEST'
export const TRAIN_KIT_LIST_SUCCESS = 'TRAIN_KIT_LIST_SUCCESS'
export const TRAIN_KIT_LIST_FAIL = 'TRAIN_KIT_LIST_FAIL'

export const TRAIN_KIT_CREATE_FAIL = 'TRAIN_KIT_CREATE_FAIL'
export const TRAIN_KIT_CREATE_REQUEST = 'TRAIN_KIT_CREATE_REQUEST'
export const TRAIN_KIT_CREATE_SUCCESS = 'TRAIN_KIT_CREATE_SUCCESS'

export const TRAIN_KIT_UPDATE_REQUEST = 'TRAIN_KIT_UPDATE_REQUEST'
export const TRAIN_KIT_UPDATE_SUCCESS = 'TRAIN_KIT_UPDATE_SUCCESS'
export const TRAIN_KIT_UPDATE_FAIL = 'TRAIN_KIT_UPDATE_FAIL'

export const TRAIN_KIT_DELETE_REQUEST = 'TRAIN_KIT_DELETE_REQUEST'
export const TRAIN_KIT_DELETE_SUCCESS = 'TRAIN_KIT_DELETE_SUCCESS'
export const TRAIN_KIT_DELETE_FAIL = 'TRAIN_KIT_DELETE_FAIL'