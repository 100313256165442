import React from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  makeStyles,
  CardHeader,
  Divider,
  CardContent,
  Card,
  InputAdornment,
  Typography,
  colors,
} from "@material-ui/core";
import Page from "src/components/Page";
import { fetchWrapper } from "src/services/http_requests";
import { toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useDispatch } from "react-redux";
import { PageAuthenticationList } from "src/Redux/PageAuthentication/action";
import { loadData } from "src/utils/sessionStorage";
import * as Crypto from "crypto-js";
import theme from "src/theme";
import { CheckBox, CheckCircle } from "@material-ui/icons";
const baseUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

var rgularExp = {
  contains_alphaNumeric : /^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/,
  containsNumber : /\d+/,
  containsAlphabet : /[A-Z]/,

  onlyLetters : /^[A-Za-z]+$/,
  containsSpecialCharacter : /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  minimumMixOfAlphaNumeric : /^[A-Za-z\d@$!%*#?&]{8,}$/
}

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {data} = loadData('loginInfo')

  const { id } = useParams();
  const [currentPasswordShown, setCurrentPasswordShown] = React.useState(false);
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [configPasswordShown, setConfigPasswordShown] = React.useState(false);

  const toggleCurrentPasswordVisiblity = () => {
    setCurrentPasswordShown(currentPasswordShown ? false : true);
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfigPasswordVisiblity = () => {
    setConfigPasswordShown(configPasswordShown ? false : true);
  };
  // const signPassword = (pwd) => {
  //   return jwt.sign(Crypto.HmacSHA256(pwd, JJMSECKEY).toString(), JJMSECKEY);
  // };
  // console.log('loginInfo',data?.mobile)
  return (
          <Formik
            initialValues={{
              old_password: '',
              new_password: "",
              confirm_password: "",
              mobile: data?.mobile
            }}
            validationSchema={Yup.object().shape({
              old_password: Yup.string().required("Current password is required"),
              new_password: Yup.string()
                .required("New password is required")
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  "Password must have atleast 8 characters, one digit (0-9), one uppercase letter (A-Z), one special character except ( <>!%^()+;~` )"
                ),
              confirm_password: Yup.string()
                .required("Confirm password is required")
                .oneOf([Yup.ref("new_password"), null], "Confirm Password should be same as new Password"),
            })}
            onSubmit={async (data, { resetForm }) => {
              let cp = data.new_password;
              let np = data.confirm_password;
              // console.log('data')
              let passwordHAsh = (password) =>  Crypto.HmacSHA256(
                password,
                process.env.REACT_APP_SUPERSECRET
              ).toString();
              // data["new_password"] = signPassword(data.new_password);
              // data["confirm_password"] = signPassword(data.confirm_password);
              try {
                let url = `${baseUrl}/user/change_password`;
                let response = await fetchWrapper.put(url, {...data,old_password: passwordHAsh(data?.old_password), new_password : passwordHAsh(data?.new_password), confirm_password: passwordHAsh(data?.confirm_password)});
                if (response.success) {
                  toast.success("Password change successfully");
                  // dispatch(PageAuthenticationList({ page: 1 }));
                  resetForm();
                } else {
                  toast.error(response.message);
                }
              } catch (error) {
                data["new_password"] = cp;
                data["confirm_password"] = np;
                toast.error(error);
                console.log("error", error);
              }
            }}
          >
            {function Render({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) {
              return (
                <form className=" p-2" onSubmit={handleSubmit}>
                    {/* <CardHeader title="Change password" /> */}
                    <Box className="py-4">
                      <Typography variant="h5" style={{fontWeight: 600}}>Set your Password</Typography>
                      <Typography variant="subtitle2" style={{color: theme?.palette?.grey[500], marginTop: 4}}>Please enter your current password to change into new password </Typography>
                    </Box>
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12} className='flex justify-start items-center  border-t'>
                          <Grid md={3} className='py-3'>
                          <Typography variant="h6" style={{fontSize: 11, fontWeight: 'bold'}}>Current Password</Typography>
                          </Grid>
                          <Grid md={6}  className='py-3'>
                          <TextField
                            error={Boolean(
                              touched.old_password && errors.old_password
                            )}
                            fullWidth
                            helperText={
                              touched.old_password && errors.old_password
                            }
                            placeholder="Current password"
                            name="old_password"
                  className="inputRounded"
                            onChange={handleChange}
                            type={currentPasswordShown ? "text" : "password"}
                            onBlur={handleBlur}
                            value={values.old_password}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={toggleCurrentPasswordVisiblity}
                                  position="end"
                                >
                                  {currentPasswordShown ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                          </Grid>
                        </Grid>
                        <Grid item md={12} xs={12} className='flex justify-start items-start  border-t '>
                          <Grid md={3} className='py-4 pt-6'>
                          <Typography variant="h6" style={{fontSize: 11, fontWeight: 'bold'}}>New Password</Typography>
                          </Grid>
                          <Grid md={6} className='py-4'>
                          <TextField
                            error={Boolean(
                              touched.new_password && errors.new_password
                            )}
                            fullWidth
                            helperText={
                              touched.new_password && errors.new_password
                            }
                            placeholder="New password"
                            name="new_password"
                            onChange={handleChange}
                            type={passwordShown ? "text" : "password"}
                            onBlur={handleBlur}
                            value={values.new_password}
                            variant="outlined"
                  className="inputRounded"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={togglePasswordVisiblity}
                                  position="end"
                                >
                                  {passwordShown ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Grid container md={12} spacing={1} className='py-4'>
                            <Grid item md={5}>
                              <Box className="flex justify-start items-center space-x-2">
                                <CheckCircle style={{color: rgularExp['minimumMixOfAlphaNumeric'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}} />
                                <Typography variant="caption" style={{color: rgularExp['minimumMixOfAlphaNumeric'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}}>8 Character minimum</Typography>
                              </Box>
                            </Grid>
                            <Grid item md={7}>
                            <Box className="flex justify-start items-center space-x-2">
                                <CheckCircle style={{color: rgularExp['containsSpecialCharacter'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}} />
                                <Typography style={{color: rgularExp['containsSpecialCharacter'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}} variant="caption">1 allowed special character (! @ # $ % & + -)</Typography>
                              </Box>
                            </Grid>
                            <Grid item md={5}>
                            <Box className="flex justify-start items-center space-x-2">
                                <CheckCircle style={{color: rgularExp['containsAlphabet'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}} />
                                <Typography style={{color: rgularExp['containsAlphabet'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}} variant="caption">1 Uppercase letter</Typography>
                              </Box>
                            </Grid>
                            <Grid item md={7}>
                            <Box className="flex justify-start items-center space-x-2">
                                <CheckCircle style={{color: rgularExp['containsNumber'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}} />
                                <Typography style={{color: rgularExp['containsNumber'].test(values?.new_password) ? colors.green[500] : colors.grey[500]}} variant="caption">1 number</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={12} xs={12} className='flex justify-start items-center  border-y'>
                        <Grid md={3} className='py-3'>
                          <Typography variant="h6" style={{fontSize: 11, fontWeight: 'bold'}}>Confirm Password</Typography>
                          </Grid>
                        <Grid md={6} className='py-3'>
                          <TextField
                            error={Boolean(
                              touched.confirm_password &&
                                errors.confirm_password
                            )}
                            fullWidth
                            helperText={
                              touched.confirm_password &&
                              errors.confirm_password
                            }
                            placeholder="Confirm password"
                  className="inputRounded"

                            name="confirm_password"
                            onChange={handleChange}
                            type={configPasswordShown ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={toggleConfigPasswordVisiblity}
                                  position="end"
                                >
                                  {configPasswordShown ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            // pattern="/^[6-9]\d{9}$/"
                            onBlur={handleBlur}
                            value={values.confirm_password}
                            variant="outlined"
                          />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" className="space-x-2" p={2}>
                      <Link
                      to='/'
                      >
                      <Button
                        // style={{
                        //   background: "#3396d3",
                        //   marginRight: "8px",
                        //   color: "white",
                        // }}
                        // disabled={isLoading ? true : false}
                        size="medium"
                        // type="submit"
                        // onClick={() => {setFieldValue('old_password',''); setFieldValue('new_password',''); setFieldValue('confirm_password','')}}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                      </Link>
                      <Button
                        // style={{
                        //   background: "#3396d3",
                        //   marginRight: "8px",
                        //   color: "white",
                        // }}
                        color="primary"
                        // disabled={isLoading ? true : false}
                        size="medium"
                        type="submit"
                        variant="contained"
                      >
                        Update Password
                      </Button>
                    </Box>
                </form>
              );
            }}
          </Formik>
        
  );
};
export default ChangePassword;
