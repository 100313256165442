const { USER_CREATE_REQUEST, USER_CREATE_SUCCESS, USER_CREATE_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LIST_FAIL,  } = require("../Conistance/UserConistance");

export const userCreateReducer = (state = {}, action) => {
    switch(action.type){
        case USER_CREATE_REQUEST:
            return {loading: true};
        case USER_CREATE_SUCCESS:
            return { loading: false, success: true, user: action.payload}
        case USER_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        case 'USER_CREATE_DEFAULT':
            return {loading: false, success: false}
        default:
            return state;
    }
}

export const userListReducer = (state = [], action) => {
    switch(action.type){
        case USER_LIST_REQUEST:
            return  {loading : true};
        case USER_LIST_SUCCESS:
            return {loading : false, users: action.payload};
        case USER_LIST_FAIL:
            return {loading: false, error: action.payload};
        default: 
            return state;
    }
}

