import {
  Box,
  Button,
  colors,
  createTheme,
  Grid,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DateRangePicker,
  DateRangeDelimiter,
} from "@material-ui/pickers";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { loadData } from "src/utils/sessionStorage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const theme = createTheme({
  overrides: {
    layoutPadding: "52px 39px",
    layoutPaddingTop: "52px",
    MuiButton: {
      root: {
        borderRadius: 2,
        width: "100%",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: `1px solid white`,
        },
      },
      input: {
        height: 14,
        borderRadius: "2px",
      },
    },
    MuiOutlinedInput: {
      marginDense: {
        padding: `0px !important`,
      },
    },
    MuiTextField: {
      root: {
        verticalAlign: "middle",
        fontSize: "12px",
        height: "36px",
        padding: 0,
        margin: 0,
        // width: 150,
        "& fieldset": {
          borderRadius: "4px",
          height: "41px",
          padding: 0,
          // border: "solid 2px #0070D8"
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "white",
      },
    },
  },
  palette: {
    action: {
      active: "#6b778c",
    },
    mode: "dark",
    background: {
      default: "#f4f5f7",
      paper: "#ffffff",
    },
    error: {
      contrastText: "#ffffff",
      main: "#f44336",
    },
    secondary: {
      main: colors.red[800],
    },
    primary: {
      contrastText: "#ffffff",
      main: "#D6002A",
    },
    success: {
      contrastText: "#ffffff",
      main: "#4caf50",
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
    },
    warning: {
      contrastText: "#ffffff",
      main: "#ff9800",
    },
  },
});

function FilterComponent({
  commonFilterData,
  filterData,
  setFilterData,
  handleFilterSearch,
  handleRestFilter,
}) {
  // const [value, setValue] = React.useState([null, null]);
  const [selectedDate, setDate] = useState(null);
  let object = {};
  commonFilterData
    ?.filter((data) => data?.type === "date")
    ?.map((data) => (object[data.name] = false));
  let warp =
    (6 -
      (commonFilterData?.length >= 6
        ? commonFilterData?.length - 6
        : commonFilterData?.length)) *
    2;

  const [isOpen, setIsOpen] = useState(object);
  const userInfo = loadData("loginInfo")?.data;
  const [classN, setClass] = useState(false)

  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const role_id = userInfo?.role_id;
  const onDateChange = (date, value, data) => {
    if (data === "from" && filterData?.to !== "") {
      if (date <= filterData?.to) {
        setFilterData({
          ...filterData,
          [data]: date,
        });
        setDate(date);
      } else {
        setFilterData({
          ...filterData,
          [data]: date,
          to: "",
        });
        setDate(null);
      }
    } else if (data === "to" && filterData?.from !== "") {
      if (filterData?.from <= date) {
        setFilterData({
          ...filterData,
          [data]: date,
        });
        setDate(date);
      } else {
        setFilterData({
          ...filterData,
          [data]: date,
          from: "",
        });
        setDate(null);
      }
    } else if (data === 'meetingDateFrom' && filterData?.meetingDateTo !== "") {
      if (date <= filterData?.meetingDateTo) {
        setFilterData({
          ...filterData,
          [data]: date,
        });
        setDate(date);
      } else {
        setFilterData({
          ...filterData,
          [data]: date,
          meetingDateTo: "",
        });
        setDate(null);
      }
    } else if (data === 'meetingDateTo' && filterData?.meetingDateFrom !== "") {
      if (filterData?.meetingDateFrom <= date) {
        setFilterData({
          ...filterData,
          [data]: date,
        });
        setDate(date);
      } else {
        setFilterData({
          ...filterData,
          [data]: date,
          meetingDateFrom: "",
        });
        setDate(null);
      }
    } else {
      setFilterData({
        ...filterData,
        [data]: date,
      });
      setDate(date);
    }
  };


  const onChange = (e, data) => {
    if (typeof e.target.value === "object") {
      let value = e?.target?.value?.id;
      if (data === "state") {
        Object.keys(filterData).includes("centre")
          ? setFilterData({
            ...filterData,
            [data]: value,
            district: "",
            block: "",
            centre: "",
            village: "",
          })
          : Object.keys(filterData).includes("village")
            ? setFilterData({
              ...filterData,
              [data]: value,
              district: "",
              block: "",
              village: "",
              centre: "",
            })
            : Object.keys(filterData).includes("block")
              ? setFilterData({
                ...filterData,
                [data]: value,
                district: "",
                block: "",
              })
              : Object.keys(filterData).includes("district")
                ? setFilterData({
                  ...filterData,
                  [data]: value,
                  district: "",
                  block: "",
                })
                : setFilterData({
                  ...filterData,
                  [data]: value,
                  district: "",
                });
      } else if (data === "district") {
        Object.keys(filterData).includes("block")
          ? setFilterData({
            ...filterData,
            [data]: value,
            block: "",
          })
          : setFilterData({
            ...filterData,
            [data]: value,
          });
      } else if (data === "block") {
        Object.keys(filterData).includes("centre")
          ? setFilterData({
            ...filterData,
            [data]: value,
            centre: "",
          })
          : Object.keys(filterData).includes("village")
            ? setFilterData({
              ...filterData,
              [data]: value,
              village: "",
            })
            : setFilterData({
              ...filterData,
              [data]: value,
            });
      } else if (data === "centre") {
        setFilterData({
          ...filterData,
          [data]: value,
        });
      } else if (data === "village") {
        setFilterData({
          ...filterData,
          [data]: value,
        });
      } else if (data === "ngoName") {
        Object.keys(filterData).includes("foName")
          ? Object.keys(filterData).includes("sakhiName")
            ? setFilterData({
              ...filterData,
              [data]: value,
              foName: "",
              sakhiName: "",
            })
            : setFilterData({
              ...filterData,
              [data]: value,
              foName: "",
              sakhiName: "",
            })
          : setFilterData({
            ...filterData,
            [data]: value,
          });
      } else if (data === "foName") {
        Object.keys(filterData).includes("sakhiName")
          ? setFilterData({
            ...filterData,
            [data]: value,
            sakhiName: "",
          })
          : setFilterData({
            ...filterData,
            [data]: value,
          });
      } else {
        setFilterData({
          ...filterData,
          [data]: value,
        });
      }
    } else {
      if (data === "state") {
        Object.keys(filterData).includes("centre")
          ? setFilterData({
            ...filterData,
            [data]: e.target.value,
            district: "",
            block: "",
            centre: "",
            village: "",
          })
          : Object.keys(filterData).includes("village")
            ? setFilterData({
              ...filterData,
              [data]: e.target.value,
              district: "",
              block: "",
              village: "",
              centre: "",
            })
            : Object.keys(filterData).includes("block")
              ? setFilterData({
                ...filterData,
                [data]: e.target.value,
                district: "",
                block: "",
              })
              : Object.keys(filterData).includes("district")
                ? setFilterData({
                  ...filterData,
                  [data]: e.target.value,
                  district: "",
                })
                : setFilterData({
                  ...filterData,
                  [data]: e.target.value,
                });
      } else if (data === "district") {
        Object.keys(filterData).includes("centre")
          ? setFilterData({
            ...filterData,
            [data]: value,
            district: "",
            block: "",
            centre: "",
            village: "",
          })
          : Object.keys(filterData).includes("village")
            ? setFilterData({
              ...filterData,
              [data]: value,
              district: "",
              block: "",
              village: "",
              centre: "",
            })
            : Object.keys(filterData).includes("block")
              ? setFilterData({
                ...filterData,
                [data]: e.target.value,
                block: "",
              })
              : setFilterData({
                ...filterData,
                [data]: e.target.value,
              });
      } else if (data === "block") {
        Object.keys(filterData).includes("centre")
          ? setFilterData({
            ...filterData,
            [data]: e.target.value,
            centre: "",
            village: "",
          })
          : Object.keys(filterData).includes("village")
            ? setFilterData({
              ...filterData,
              [data]: e.target.value,
              village: "",
              centre: "",
            })
            : setFilterData({
              ...filterData,
              [data]: e.target.value,
            });
      } else if (data === "centre") {
        setFilterData({
          ...filterData,
          [data]: e.target.value,
        });
      } else if (data === "village") {
        setFilterData({
          ...filterData,
          [data]: e.target.value,
        });
      } else if (data === "ngoName") {
        Object.keys(filterData).includes("foName")
          ? Object.keys(filterData).includes("sakhiName")
            ? setFilterData({
              ...filterData,
              [data]: e.target.value,
              foName: "",
              sakhiName: "",
            })
            : setFilterData({
              ...filterData,
              [data]: e.target.value,
              foName: "",
              sakhiName: "",
            })
          : setFilterData({
            ...filterData,
            [data]: e.target.value,
          });
      } else if (data === "foName") {
        Object.keys(filterData).includes("sakhiName")
          ? setFilterData({
            ...filterData,
            [data]: e.target.value,
            sakhiName: "",
          })
          : setFilterData({
            ...filterData,
            [data]: e.target.value,
          });
      } else {
        setFilterData({
          ...filterData,
          [data]: e.target.value,
        });
      }
    }
  };
  useEffect(() => {
    const div = document.querySelector("fieldset");
    div?.classList.remove("PrivateNotchedOutline-root-24");
  }, []);

  setTimeout(() => {
    setClass(true)
  }, 50);


  return (
    commonFilterData?.length > 0 && <Box className="w-full py-5 flex flex-col justify-center items-center ">
      <Grid
        spacing={3}
        container
      // className=" bg-green-500 "
      >
        {commonFilterData.map((data, i) => (
          <Grid key={i} item xs={6} sm={4} md={2} className={classN ? `transform translate-y-10/12 opacity-100 z-0 transition duration-500 ease-in-out ` : `transform -translate-y-full opacity-0 z-0 transition duration-500 ease-in-out `}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ marginBottom: 1, marginLeft: "4px" }}
            >
              <Typography
                variant="caption"
                color="textPrimary"
                style={{ fontWeight: 500 }}
              >
                {data?.lable}
              </Typography>
            </InputLabel>
            {data.type === "select" ? (
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                className="bg-white w-full h-10"
                name={data?.name}
                onChange={(e) => {
                  onChange(e, data["name"]);
                }}
                value={filterData[data.name] === "" ? 0 : filterData[data.name]}
                variant="outlined"
                multiple={data.multiple}
                style={{ borderRadius: 10, fontSize: 15 }}
                disabled={
                  data.name === "district" && filterData?.state === ""
                    ? true
                    : false ||
                      (["block"].includes(data.name) &&
                        filterData?.district === "")
                      ? true
                      : false ||
                        (["centre", "village"].includes(data.name) &&
                          filterData?.block === "")
                        ? true
                        : false ||
                          (["block"].includes(data.name) &&
                            filterData?.district === "")
                          ? true
                          : false
                }
                input={<OutlinedInput className="w-full" />}
                inputProps={{
                  style: { fontSize: 15 },
                }}
                renderValue={(selected) => {
                  if (selected === 0) {
                    return (
                      <MenuItem value={0} disabled>
                        Select
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem value={selected}>
                      {data?.items?.find((val) => val.id === selected).name}
                    </MenuItem>
                  );
                }}
              >
                <MenuItem style={{ fontSize: 12 }} value="" disabled>
                  Select
                </MenuItem>
                {data?.items?.map((item, i) => (
                  <MenuItem
                    // style={{fontSize: 12}}
                    key={i}
                    value={item?.id ? item?.id : item?.beneficiary_id}
                  >
                    {item[data.name]
                      ? item[data.name]
                      : item?.name
                        ? item?.name
                        : item?.sakhi_name}
                  </MenuItem>
                ))}
              </Select>
            ) : data?.type === "text" ? (
              <TextField
                className="inputRounded h-10"
                // minHeight={10}
                onChange={(e) => {
                  onChange(e, data["name"]);
                }}
                value={filterData[data.name]}
                name={data.name}
                placeholder={data.placeholder}
                fullWidth
                variant="outlined"
                style={{ borderRadius: 10, fontSize: 15, minHeight: 10 }}
                inputProps={{
                  style: { height: "13px", fontSize: 15 },
                }}
              />
            ) : data?.type === "date" ? (
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider minHeight={12} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size='small'
                    disableFuture
                    views={['year']?.includes(data?.name) ? ['year'] : ["year", "month", "date"]}
                    onYearChange={(date) => date}
                    shouldDisableDate={(date) =>
                      data.name === "from" || data.name === "meetingDateFrom"
                        ? null
                        : moment(date).add(1440, "m") < moment(selectedDate)
                    }
                    color="primary"
                    className="inputRounded bg-white"
                    autoOk={true}
                    onClick={() => setIsOpen({ ...isOpen, [data.name]: true })}
                    // open={isOpen[data.name]}
                    onClose={() => setIsOpen({ ...isOpen, [data.name]: false })}
                    okLabel=""
                    variant="st"
                    placeholder="-Select date-"
                    format={['year']?.includes(data?.name) ? "yyyy" : "dd/MM/yy"}
                    inputProps={{
                      readOnly: true,
                      style: {
                        // height: "12px",
                        color: "black",
                      },
                    }}
                    onChange={(date, value) =>
                      onDateChange(date, value, data["name"])
                    }
                    value={
                      filterData[data.name] ?
                        filterData[data.name] : null
                    }
                    inputVariant="outlined"
                    style={{ borderRadius: 10 }}
                  />
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            ) : data?.type === "autocompleted" ? (
              <Autocomplete
                noOptionsText='No Data Found'
                size="small"
                // disablePortal
                id="size-small-filled"
                value={
                  data?.options?.find((value) =>
                    value?.id
                      ? value.id === filterData[data?.name]
                      : value === filterData[data?.name]
                  ) || null
                }
                disableClearable={
                  filterData[data?.name] === "" ||
                    filterData[data?.name] === undefined ||
                    filterData[data?.name] === null
                    ? true
                    : false
                }
                // defaultValue={data?.options?.find(item => item?.id == filterData[data?.name])?.name}
                options={data?.options}
                onChange={(event, value) => {
                  const e = {
                    target: {
                      value: value || "",
                    },
                  };
                  onChange(e, data["name"]);
                }}
                disabled={
                  data?.options === undefined || data?.options === ""
                    ? true
                    : false
                }
                className="autoComplete hero-banner"
                getOptionLabel={(option) =>
                  option?.name ? option?.name : option
                }
                // disabled ={data.name === 'foName' && (role_id == 1 && filterData?.ngoName === '' ) ? true : false || data.name === 'sakhiName' && filterData?.foName === '' ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // InputProps={{ ...params.InputProps, style: { fontSize: 15,textOverflow: 'ellipsis',whiteSpace: "nowrap"} }}
                    variant="outlined"
                    placeholder="Enter"
                    className="h-0 "
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                    <Typography
                      variant="subtitle2"
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {option?.name ? option?.name : option}
                    </Typography>
                  </React.Fragment>
                )}
              />
            ) : (
              ""
            )}
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          className={classN ? `w-full flex flex-col justify-end items-end transform translate-y-10/12 opacity-100 z-0 transition duration-500 ease-in-out ` : `transform -translate-y-full opacity-0 z-0 transition duration-500 ease-in-out `}

        >
          <InputLabel className="mb-[1.3rem]"></InputLabel>
          <Box className="w-full flex justify-end item-end space-x-2 pb-2 ">
            <Button
              variant="contained"
              size="medium"
              // color="white"
              className=" h-9"
              style={{ backgroundColor: 'white', color: theme.palette.primary.main, height: '34px' }}
              // style={{paddingLeft:27,paddingRight:27}}
              onClick={() => handleFilterSearch()}
            >
              SEARCH
            </Button>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: 'white', color: theme.palette.primary.main, height: '34px' }}
              className="h-9"
              // style={{paddingLeft:27,paddingRight:27}}
              onClick={() => handleRestFilter()}
            >
              RESET
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(FilterComponent);
