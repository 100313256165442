const { MILESTONE_CREATE_REQUEST, MILESTONE_CREATE_SUCCESS, MILESTONE_CREATE_FAIL, MILESTONE_LIST_REQUEST, MILESTONE_LIST_SUCCESS, MILESTONE_LIST_FAIL, MILESTONE_DELETE_REQUEST, MILESTONE_UPDATE_SUCCESS, MILESTONE_UPDATE_REQUEST, MILESTONE_UPDATE_FAIL, MILESTONE_DELETE_SUCCESS, MILESTONE_DELETE_FAIL } = require("../Conistance/MilestoneConistance");

export const milestoneCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case MILESTONE_CREATE_REQUEST:
            return { loading: true };
        case MILESTONE_CREATE_SUCCESS:
            return { loading: false, success: true, milestone: action.payload }
        case MILESTONE_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const milestoneListReducer = (state = [], action) => {
    switch (action.type) {
        case MILESTONE_LIST_REQUEST:
            return { loading: true }
        case MILESTONE_LIST_SUCCESS:
            return { loading: false, milestone: action.payload }
        case MILESTONE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const milestoneUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case MILESTONE_UPDATE_REQUEST:
            return { loading: true };
        case MILESTONE_UPDATE_SUCCESS:
            return { loading: false, milestone: action.payload }
        case MILESTONE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const milestoneDeleteReducer = (success = '', action) => {
    switch (action.type) {
        case MILESTONE_DELETE_REQUEST:
            return { loading: true }
        case MILESTONE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case MILESTONE_DELETE_FAIL:
            return { loading: false, success: false };
        default:
            return success
    }
}