export const  VILLAGE_LIST_REQUEST = 'VILLAGE_LIST_REQUEST'
export const VILLAGE_LIST_SUCCESS = 'VILLAGE_LIST_SUCCESS'
export const VILLAGE_LIST_FAIL = 'VILLAGE_LIST_FAIL'

export const VILLAGE_CREATE_FAIL = 'VILLAGE_CREATE_FAIL'
export const VILLAGE_CREATE_REQUEST = 'VILLAGE_CREATE_REQUEST'
export const VILLAGE_CREATE_SUCCESS = 'VILLAGE_CREATE_SUCCESS'

export const VILLAGE_UPDATE_REQUEST = 'VILLAGE_UPDATE_REQUEST'
export const VILLAGE_UPDATE_SUCCESS = 'VILLAGE_UPDATE_SUCCESS'
export const VILLAGE_UPDATE_FAIL = 'VILLAGE_UPDATE_FAIL'

export const VILLAGE_DELETE_REQUEST = 'VILLAGE_DELETE_REQUEST'
export const VILLAGE_DELETE_SUCCESS = 'VILLAGE_DELETE_SUCCESS'
export const VILLAGE_DELETE_FAIL = 'VILLAGE_DELETE_FAIL'