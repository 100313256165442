import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core';
import { Add, DeleteOutlineOutlined, EditOutlined } from '@material-ui/icons';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Drawer from 'src/components/CommonComponents/Drawer';
import FilterComponent from 'src/components/CommonComponents/FilterComponent';
import { fetchApiDataList, fetchWrapper } from 'src/services/http_requests';
import StickyHeadTable from "src/components/CommonComponents/Table";
import LineItemForm from './lineItemForm';
import { lineItemListAction } from 'src/Redux/Actions/LineItemAction';
import { generateSerialNo } from 'src/utils';
import { toast } from 'react-toastify';
import { ngoListAction } from 'src/Redux/Actions/NgoAction';
import BudgetCreatonForm from './budgetCreationForm';
import { loadData } from 'src/utils/sessionStorage';

const lineItemColumn = [
    { id: 'sno', label: 'S.No' },
    { id: "ngo", label: "NGO" },
    { id: "financial_year", label: "Financial Year" },
    { id: "po_number", label: "PO Number" },
    { id: "budget", label: "Overall Sectioned Total Budget" },
    { id: "action", label: "Action" },
];

const createData = (sno, ngo, year, po_number, budget, action) => {
    return { sno, ngo, financial_year: year, po_number, budget, action }
}


const Budget = () => {
    const pageInfo = localStorage.getItem("pageInfo");
    const totalData = localStorage.getItem("totalData");
    const userData = loadData("loginInfo")?.data;
    const role_id = userData?.role_id;


    const { loading, lineItem } = useSelector((state) => state.lineItemList)
    const { ngos } = useSelector((state) => state.ngoList);

    const [page, setPage] = React.useState(() => pageInfo ? parseInt(pageInfo) : 1);
    const [total, setTotal] = useState(() => totalData ? totalData : 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const selectedRoute = localStorage.getItem("selectedRoute") || "lineItem";
    const [selectedAction, setSelectedAction] = useState(role_id === 2 ? "budget" : selectedRoute);
    const [filterData, setFilterData] = useState({
        ngo_id: "",
        po_number: "",
    });
    const [individualDetails, setIndividualDetails] = useState({});
    const [crudAction, setCrudAction] = useState({
        create: false,
        edit: false,
        view: false,
        delete: false,
    });
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState({ status: false, details: null });
    const [budgetData, setBudgetData] = useState({});
    const [poNumbers, setPoNumbers] = useState(undefined);

    const dispatch = useDispatch()


    const handleFilterSearch = () => {
        if (parseInt(page) === 1) {
            if (selectedAction === 'lineItem') dispatch(lineItemListAction({ page: page, ngo_id: filterData?.ngo_id }))
            else {
                setBudgetData({});
                fetchApiDataList("budget", filterData, setBudgetData);
            }
        } else {
            localStorage.setItem("pageInfo", 1);
            setPage(1);
        }
    };

    const handleRestFilter = () => {
        setFilterData({ ngo_id: "", po_number: "" });
        if (parseInt(page) === 1) {
            if (selectedAction === 'lineItem') dispatch(lineItemListAction({ page: 1 }))
            else setBudgetData({});
        } else {
            localStorage.setItem("pageInfo", 1);
            setPage(1);
        }
    };

    const refetchDataList = () => {
        handleRestFilter();
        setOpenDrawer(false);
    };

    const filterInputData = [
        {
            lable: "NGO",
            name: "ngo_id",
            type: "autocompleted",
            multiple: false,
            placeholder: "Enter",
            options: ngos?.data || [],
        },
        ...(selectedAction !== "lineItem") ? [
            {
                lable: "PO Number",
                name: "po_number",
                type: "autocompleted",
                multiple: false,
                placeholder: "Enter",
                options: poNumbers || undefined
            }] : []
    ];

    useEffect(() => {
        if (page) {
            if (selectedAction === 'lineItem') dispatch(lineItemListAction({ page: page, ngo_id: filterData?.ngo_id }));
        }
    }, [page, selectedAction]);

    useEffect(() => {
        if (selectedAction === 'lineItem') {
            if (lineItem?.total) {
                setTotal(lineItem?.total);
                localStorage.setItem("totalData", lineItem?.total);
            }
        } else {
            setTotal(1);
            localStorage.setItem("totalData", 1);
        }
    }, [lineItem]);

    useEffect(() => {
        !ngos && dispatch(ngoListAction({ fetched: "All" }));
        window.scrollTo(0, 0);
        return () => {
            fetchWrapper?.finishPendingRequests();
            localStorage.removeItem("selectedRoute");
            localStorage.setItem("totalData", 0);
            localStorage.setItem("pageInfo", 1);
        }
    }, []);

    useEffect(() => {
        if (filterData?.ngo_id && selectedAction !== "lineItem") {
            setPoNumbers(undefined);
            const getPoNumberList = async () => {
                try {
                    const { data } = await fetchWrapper.get(
                        `${process.env.REACT_APP_API_URL}/budget_lineitem/poNumberList?ngo_id=${filterData?.ngo_id}`
                    );
                    const newData = data?.map(elem => {
                        return elem = { id: elem?.po_number, name: elem?.po_number };
                    });
                    setPoNumbers(newData);
                }
                catch (error) {
                    toast.error(error);
                    throw (error);
                }
            };
            getPoNumberList();
        } else setPoNumbers(undefined);
    }, [filterData.ngo_id]);

    const lineItemRows = lineItem?.data?.map((item, index) => {
        return createData(
            generateSerialNo(index, page),
            item?.ngo_partner?.name,
            item?.financial_year,
            item?.po_number,
            <button className="text-blue-500 cursor-pointer"
                onClick={() => setIsViewOpen({ status: true, details: item })}>
                {item?.budget_lineitem_mappers?.length}
            </button>,
            <Box className="space-x-3">
                <button
                    className="cursor-pointer"
                    onClick={() => {
                        setCrudAction({
                            create: false,
                            edit: true,
                            view: false,
                            delete: false,
                        });
                        setOpenDrawer(true);
                        setIndividualDetails(item);
                    }}
                >
                    <EditOutlined color="primary" />
                </button>
                <button
                    className="cursor-pointer"
                    onClick={() => {
                        setCrudAction({
                            create: false,
                            edit: false,
                            view: false,
                            delete: true,
                        });
                        setOpenDrawer(true);
                        setIndividualDetails(item);
                    }}
                >
                    <DeleteOutlineOutlined color="primary" />
                </button>
            </Box>
        );
    });

    const phaseRows = () => isViewOpen?.details?.budget_lineitem_mappers?.map((elem, index) => {
        return {
            sno: generateSerialNo(index, 1),
            name: elem?.name
        }
    });

    useEffect(() => {
        if (openDrawer === false && Object.keys(individualDetails)) setIndividualDetails({});
    }, [openDrawer]);


    return (
        <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
            <Box width="100%" height="100%">
                <Box
                    pb={2}
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Typography>
                        <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                            {role_id === 1 ? "Budget" : "Add & Edit Response"}
                        </span>
                    </Typography>
                    {(role_id === 1 && selectedAction === "lineItem") && <Box className="space-x-2 ">
                        <Button
                            startIcon={<Add />}
                            onClick={() => {
                                setIndividualDetails({});
                                setCrudAction({
                                    create: true,
                                    edit: false,
                                    view: false,
                                    delete: false,
                                });
                                setOpenDrawer(true);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Add Line Item
                        </Button>
                    </Box>}
                </Box>

                <Divider style={{ maxWidth: "100%" }} />

                {role_id === 1 && <Box className="w-full flex justify-between items-center">
                    <Box className="flex justify-start items-center">
                        {[
                            { label: "Line Item", name: "lineItem" },
                            { label: "Budget", name: "budget" }
                        ].map(action => (
                            <Box
                                key={action.label}
                                variant="contained"
                                className={selectedAction === action.name
                                    ? "px-3 py-1.5 font-semibold capitalize cursor-pointer  text-black  bg-white  border-b-2 border-primary"
                                    : "px-3 py-1.5 font-semibold capitalize cursor-pointer text-black bg-white  border-b-2"
                                }
                                onClick={() => {
                                    setTotal(0);
                                    localStorage.setItem("selectedRoute", action.name);
                                    localStorage.setItem("pageInfo", 1);
                                    setSelectedAction(action.name);
                                    setPage(1);
                                    setBudgetData({});
                                    setFilterData({ ngo_id: "", po_number: "" });
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    color={
                                        selectedAction === action.name
                                            ? "primary"
                                            : "textSecondary"
                                    }
                                >
                                    {action.label}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>}

                <FilterComponent
                    handleFilterSearch={handleFilterSearch}
                    handleRestFilter={handleRestFilter}
                    commonFilterData={filterInputData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />

                {selectedAction === "lineItem" ? <StickyHeadTable
                    columns={lineItemColumn}
                    rows={selectedAction === 'lineItem' ? lineItemRows : []}
                    loading={loading}
                    total={total}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                /> : <BudgetCreatonForm data={budgetData} />
                }
            </Box>

            {role_id === 1 &&
                <>
                    <Drawer
                        isOpen={openDrawer}
                        setIsOpen={setOpenDrawer}
                        title={
                            <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                                {crudAction.create ? "Create" : crudAction.edit ? "Edit" : crudAction.view ? "View" : "Delete"} {selectedAction == 'training' ? "Training Kit" : "Banner"}
                            </span>
                        }
                    >
                        <LineItemForm
                            crudAction={crudAction} openDrawer={openDrawer}
                            refetchDataList={refetchDataList}
                            prefilledDetails={individualDetails}
                        />
                    </Drawer>

                    <Dialog
                        open={isViewOpen.status}
                        onClose={() => setIsViewOpen({ status: false, details: null })}
                        fullWidth={true}
                        maxWidth={"sm"}>
                        <>
                            <DialogTitle>
                                {isViewOpen?.details?.ngo_partner?.name || ""}
                            </DialogTitle>
                            <DialogContent dividers>
                                <StickyHeadTable
                                    columns={[
                                        { id: "sno", label: "S.No" },
                                        { id: "name", label: "Line Item" },
                                    ]}
                                    rows={phaseRows()}
                                />
                            </DialogContent>

                        </>
                    </Dialog>
                </>}
        </Box>
    )
}

export default Budget;