import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useMemo } from 'react';
import moment from "moment";
import { generateSerialNo } from 'src/utils';
import Rows from './tableRow';
import { useState } from 'react';
import { Button } from "@material-ui/core";
import { fetchWrapper } from 'src/services/http_requests';
import { toast } from 'react-toastify';

const createData = (sno, milestone, phaseList) => {
    return { sno, milestone, phaseList };
};

const TargetTable = ({ data }) => {
    const [targetData, setTargetData] = useState(data);
    const [superTotal, setSuperTotal] = useState(0);

    const columns = useMemo(() => {
        let tableCols = [
            { id: "sno", label: "S.No", align: "left" },
            { id: "matrics", label: "Key Metrics", align: "left", minWidth: 200, maxWidth: 250 }
        ];
        if (data?.data?.length > 0) {
            data?.data[0]?.phaseList?.map((elem, phaseIndex) => {
                const phasePeriod = `(${moment(elem?.start_month).format("MMM, YYYY")} - ${moment(elem?.end_month).format("MMM, YYYY")})`;
                const label = <div className='flex flex-col items-center justify-center'>
                    <p>MOU P{phaseIndex + 1}</p>
                    <p>{phasePeriod}</p>
                </div>
                tableCols.push({ id: elem?.mou_phase_id, label: label, padding: 0, maxWidth: 100 });
                return [];
            }
            );
            tableCols.push({ id: "total", label: "Total" });
        }
        return tableCols;
    }, [data]);

    const rows = data?.data?.map((elem, index) => {
        return createData(
            generateSerialNo(index),
            elem?.milestone,
            elem?.phaseList,

        );
    });

    const submitTargetHandler = async () => {
        let phaseList = [];

        targetData?.data?.map((elem, index) => {
            elem?.phaseList?.map(phase => {
                if (phase?.target) {
                    let shellObj = {
                        milestone_id: elem?.milestone_id,
                        mou_phase_id: phase?.mou_phase_id,
                        start_month: phase?.start_month,
                        end_month: phase?.end_month,
                        target: phase?.target
                    };
                    phaseList.push(shellObj);
                }
                return [];
            });
            return [];
        });

        const value = { ngo_id: targetData?.ngo_id, mou_id: targetData?.mou_id, phaseList: phaseList };
        try {
            const data = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/mou_target/create`, value);
            toast.success(data?.message);
        } catch (error) {
            toast.error(error.message);
            throw error;
        }
    };

    useEffect(() => {
        if (data) setTargetData(data);
    }, [data]);

    return (
        <Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="bg-slate-200">
                            <TableRow>
                                {columns?.map((column, index) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align || "center"}
                                        style={
                                            [1].includes(index) ?
                                                { position: "sticky", left: 0, backgroundColor: "#e2e8f0", zIndex: 2, minWidth: column.minWidth, maxWidth: column.maxWidth }
                                                : index === columns?.length - 1 ? { minWidth: 100, position: "sticky", right: 0, backgroundColor: "#e2e8f0" }
                                                    : { zIndex: 1, backgroundColor: "#e2e8f0" }
                                        }
                                        scope="row"
                                        component='th'
                                    >
                                        <Typography
                                            variant="body2"
                                            className="font-semibold text-xs"
                                        >{column.label}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows?.map((elem, index) => {
                                return <Rows
                                    setSuperTotal={setSuperTotal}
                                    key={index}
                                    index={index}
                                    elem={elem}
                                    targetData={targetData}
                                    setTargetData={setTargetData}
                                    superTotal={superTotal}
                                />

                            })}
                            <TableRow>
                                <TableCell
                                    style={{ position: "sticky", left: 0, zIndex: 2 }}
                                    colSpan={columns?.length - 1}>Total</TableCell>
                                <TableCell style={{ minWidth: 100, right: 0, position: "sticky", background: "white" }} align='center'>{superTotal}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </TableContainer>
            </Paper>
            <Box className="py-6 w-full flex justify-end ">
                <Button variant="contained" color="primary" size="medium" onClick={submitTargetHandler}>Submit</Button>
            </Box>
        </Box >
    )
}

export default React.memo(TargetTable);