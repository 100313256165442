import { Box, Button, Dialog, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilterComponent from "src/components/CommonComponents/FilterComponent";
import StickyHeadTable from "src/components/CommonComponents/Table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { bannerFilterData, traningKitCommonFilterData } from "src/utils/filterInputData";
import LoadingBox from "src/components/LoadingBox";
import { useMemo } from "react";
import { fetchWrapper } from "src/services/http_requests";
import { generateSerialNo } from "src/utils";
import { purposeListAction } from "src/Redux/Actions/PurposeAction";
import { trainKitListAction } from "src/Redux/Actions/TrainingKitAction";
import { purposeMapperListAction } from "src/Redux/Actions/PurposeMapperAction";
import {
  Add,
  DeleteOutlineOutlined,
  EditOutlined,
  FilterList,
  PhotoOutlined,
  PictureAsPdf,
  VisibilityOutlined,
} from "@material-ui/icons";
import theme from "src/theme";
import Drawer from "src/components/CommonComponents/Drawer";
import TrainingKitCRUDForm from "./TrainingKitCRUDFrom/TrainingKitCRUDForm";
import DialogView from "src/components/CommonComponents/DialogView";
import BannerCRUDForm from "./bannerCRUDForm";
import { getToken } from "src/utils/sessionStorage";
import axios from "axios";
import { toast } from "react-toastify";
import { paramsUrl } from "src/utils/halper";

const BASE_URL = process.env.REACT_APP_API_URL;

const columns = (route) => [
  { id: "sno", label: "S.No" },
  { id: "state", label: "State" },
  ...(route == 'training') ?
    [
      { id: "purpose", label: "Purpose" },
      { id: "scheme", label: "Scheme" }
    ] : [],
  { id: "title", label: "Title" },
  { id: "file", label: "File" },
  { id: "action", label: "Action" },
];

function createData(sno, state, purpose, scheme, title, file, action) {
  return { sno, state, purpose, scheme, title, file, action };
}

function AdminTrainingKitView() {
  const previousUrl = localStorage.getItem("previousUrl");
  const pageInfo = localStorage.getItem("pageInfo");
  const [filterData, setFilterData] = useState({
    state: "",
    purpose: "",
    scheme: "",
    name: "",
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [attechedFile, setAttechedFile] = useState("");
  const statesList = useSelector((state) => state.statesList);
  const { states, loading } = statesList;
  const purposeList = useSelector((state) => state.purposeList);
  const { purposes } = purposeList;
  const purposeMapperList = useSelector((state) => state.purposeMapperList);
  const { purposeMappers } = purposeMapperList;
  const trainKitList = useSelector((state) => state.trainKitList);
  const { trainKits } = trainKitList;
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(() => pageInfo ? parseInt(pageInfo) : 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const selectedRoute = localStorage.getItem("selectedAction") || "training";
  const [selectedAction, setSelectedAction] = useState(selectedRoute);
  const [bannerListData, setBannerListData] = useState([]);
  const [individualUserDetails, setIndividualUserDetails] = useState({});


  const filterInputData = (selectedAction == "training" ? traningKitCommonFilterData : bannerFilterData)?.map((data) =>
    data.name === "state"
      ? { ...data, options: states?.data }
      : data.name === "purpose"
        ? { ...data, options: purposes?.data }
        : data.name === "scheme"
          ? { ...data, options: purposeMappers?.data }
          : data
  );

  const getBannerList = async (filterData) => {
    axios.get(`${BASE_URL}/banner/list${paramsUrl(filterData)}`, { headers: { "x-access-token": getToken() } })
      .then(res => {
        if (res?.data?.success) {
          setBannerListData(res?.data)
        }
      })
      .catch(err => toast.error(err?.message));
  };

  const handleFilterSearch = () => {
    fetchWrapper?.finishPendingRequests();
    if (page == 1) {
      if (selectedAction == 'training') dispatch(
        trainKitListAction({
          state_id: filterData.state,
          purpose_id: filterData?.purpose,
          scheme_id: filterData?.scheme,
          name: filterData?.name,
        })
      ); else getBannerList({ page: 1, state_id: filterData?.state })
    } else {
      localStorage.setItem("pageInfo", 1);
      setPage(1);
    }
  };
  const handleRestFilter = () => {
    setFilterData({
      state: "",
      purpose: "",
      scheme: "",
      name: "",
    });
    if (page == 1) {
      if (selectedAction == 'training') dispatch(trainKitListAction({ page: 1 }));
      else getBannerList({ page: 1 });
    } else {
      localStorage.setItem("pageInfo", 1);
      setPage(1);
    }

  };
  const dispatch = useDispatch();


  useEffect(() => {
    if (states == undefined || states == null) dispatch(stateListAction({ fetched: "All" }));
    if (purposes == undefined || purposes == null) dispatch(purposeListAction({ fetched: "All" }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedAction == 'training') {
      if (trainKits?.total) {
        setTotal(trainKits?.total);
        localStorage.setItem("totalData", trainKits?.total);
      }
    } else {
      if (bannerListData?.total) {
        localStorage.setItem("totalData", bannerListData?.total);
        setTotal(bannerListData?.total);
      } else {
        if (page == 1) {
          setTotal(bannerListData?.data?.length);
          localStorage.setItem("totalData", bannerListData?.data?.length);
        } else setTotal(localStorage.getItem("totalData"));
      }
    }
  }, [trainKits, bannerListData]);

  useEffect(() => {
    if (page) {
      if (selectedAction == 'training') {
        dispatch(
          trainKitListAction({
            state_id: filterData.state,
            purpose_id: filterData?.purpose,
            scheme_id: filterData?.scheme,
            name: filterData?.name,
            page: page,
          })
        );
      } else {
        getBannerList({ page: page, state_id: filterData?.state });
      }
    }
  }, [page, selectedAction]);

  // eslint-disable-next-line no-unused-vars
  const loadFilterData = useMemo(() => {
    if (filterData?.state !== "" && filterData?.purpose !== "") {
      dispatch(
        purposeMapperListAction({
          state_id: filterData?.state,
          purpose_id: filterData?.purpose,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.purpose, filterData.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      fetchWrapper?.finishPendingRequests();
      localStorage.removeItem("selectedAction");
      localStorage.setItem("totalData", 0);
      localStorage.setItem("pageInfo", 1);
    }
  }, []);

  useEffect(() => {
    if (
      previousUrl
        ?.split("/")
        ?.slice(0, -2)
        ?.join("/") === window.location.href || previousUrl === window.location.href
    ) {
      setPage(parseInt(pageInfo));
      setSelectedAction(selectedRoute);
    } else {
      setPage(parseInt(1));
      localStorage.setItem("previousUrl", window.location.href);
      localStorage.setItem("pageInfo", 1);
    }
  }, [pageInfo]);

  const createActionButtons = (item) => {
    return <Box className="space-x-3">
      <button
        className="cursor-pointer"
        onClick={() => {
          setCrudAction({
            create: false,
            edit: true,
            view: false,
            delete: false,
          });
          setOpenDrawer(true);
          setIndividualUserDetails(item);
        }}
      >
        {/* <Link to={`/app/admin/masters/state/edit/${item.id}`}> */}
        <EditOutlined color="primary" />
        {/* </Link> */}
      </button>
      <button
        className="cursor-pointer"
        onClick={() => {
          setCrudAction({
            create: false,
            edit: false,
            view: true,
            delete: false,
          });
          setOpenView(true);
          setIndividualUserDetails(item);
        }}
      >
        <VisibilityOutlined color="primary" />
      </button>
      <button
        className="cursor-pointer"
        onClick={() => {
          setCrudAction({
            create: false,
            edit: false,
            view: false,
            delete: true,
          });
          setOpenDrawer(true);
          setIndividualUserDetails(item);
        }}
      >
        {/* <Link to={`/app/admin/masters/state/delete/${item.id}`}> */}
        <DeleteOutlineOutlined color="primary" />
        {/* </Link> */}
      </button>
    </Box>
  }

  const trainingKitRows = trainKits?.data?.map((item, i) => {
    return createData(
      generateSerialNo(i, page),
      item?.state,
      item?.purpose,
      item?.scheme,
      item?.name,
      ["jpg", "jpeg", "png"].includes(item?.file?.split(".")?.slice(-1)?.[0]) ? (
        <PhotoOutlined
          className="cursor-pointer"
          color="primary"
          onClick={() => {
            setAttechedFile(item?.file);
            setOpen(true);
          }}
        />
      ) : (
        <PictureAsPdf
          className="cursor-pointer"
          color="primary"
          onClick={() => {
            setAttechedFile(item?.file);
            setOpen(true);
          }}
        />
      ),
      // <a href={item?.file} target='blank'><PictureAsPdf color='primary'/>{item?.file?.split("").slice(-4).join('')}</a> ,
      createActionButtons(item)
    );
  });
  const bannerRows = bannerListData?.data?.map((item, i) => {
    return createData(
      generateSerialNo(i, page),
      item?.state_name,
      "", "",
      <a
        href={item?.redirect_url}
        className="text-blue-500"
        target="_blank"
        rel="noreferrer"
      >
        {item?.title}
      </a>,
      ["jpg", "jpeg", "png"].includes(item?.file?.split(".")?.slice(-1)?.[0]) ? (
        <PhotoOutlined
          className="cursor-pointer"
          color="primary"
          onClick={() => {
            setAttechedFile(item?.file);
            setOpen(true);
          }}
        />
      ) : (
        <PictureAsPdf
          className="cursor-pointer"
          color="primary"
          onClick={() => {
            setAttechedFile(item?.file);
            setOpen(true);
          }}
        />
      ),
      createActionButtons(item)
    )
  })

  const refetchUserList = () => {
    handleRestFilter();
    setOpenDrawer(false);
  };
  useEffect(() => {
    if (openDrawer == false && Object.keys(individualUserDetails)?.length) setIndividualUserDetails({});
  }, [openDrawer]);
  useEffect(() => {
    if (openView == false && Object.keys(individualUserDetails)?.length) setIndividualUserDetails({});
  }, [openView]);

  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth={"md"}
      >
        {["jpg", "jpeg", "png"].includes(
          attechedFile?.split(".").slice(-1)[0]
        ) ? (
          <img
            className="w-full object-content "
            src={attechedFile}
            alt="attechedFile"
          />
        ) : (
          <iframe src={attechedFile} className="w-full" height={1000}></iframe>
        )}
      </Dialog>
      {loading ? (
        <LoadingBox />
      ) : (
        <Box width="100%" height="100%">
          <Box
            pb={2}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography>
              <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                {selectedAction == 'training' ? "Training Kit" : "Banner"}
              </span>
            </Typography>
            <Box className="space-x-2 ">
              <Button
                variant="contained"
                size="small"
                startIcon={<FilterList color="primary" />}
                onClick={() => setShowFilter(!showFilter)}
                // color="primary"
                style={{
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                }}
              >
                Filter
              </Button>
              <Button
                startIcon={<Add />}
                onClick={() => {
                  setIndividualUserDetails({});
                  setCrudAction({
                    create: true,
                    edit: false,
                    view: false,
                    delete: false,
                  });
                  setOpenDrawer(true);
                }}
                variant="contained"
                color="primary"
                size="small"
              >
                {`ADD ${selectedAction == 'training' ? "TRAINING KIT" : "BANNER"}`}
              </Button>
            </Box>
          </Box>

          <Divider style={{ maxWidth: "100%" }} />

          <Box className="w-full flex justify-between items-center">
            <Box className="flex justify-start items-center">
              {[
                { label: "Traning Kit", name: "training" },
                { label: "Banner", name: "banner" }
              ].map(action => (
                <Box
                  variant="contained"
                  className={selectedAction == action.name
                    ? "px-3 py-1.5 font-semibold capitalize cursor-pointer  text-black  bg-white  border-b-2 border-primary"
                    : "px-3 py-1.5 font-semibold capitalize cursor-pointer text-black bg-white  border-b-2"
                  }
                  onClick={() => {
                    fetchWrapper?.finishPendingRequests();
                    setTotal(0);
                    localStorage.setItem("selectedAction", action.name);
                    localStorage.setItem("pageInfo", 1);
                    setSelectedAction(action.name);
                    setPage(1);
                    setShowFilter(false);
                    setFilterData({
                      state: "",
                      purpose: "",
                      scheme: "",
                      name: "",
                    });
                  }}
                >
                  <Typography
                    variant="caption"
                    color={
                      selectedAction == action.name
                        ? " textPrimary"
                        : "textSecondary"
                    }
                  >
                    {action.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          {showFilter ? (
            <FilterComponent
              handleFilterSearch={handleFilterSearch}
              handleRestFilter={handleRestFilter}
              commonFilterData={filterInputData}
              filterData={filterData}
              setFilterData={setFilterData}
            />
          ) : null}
          <StickyHeadTable
            columns={columns(selectedAction)}
            rows={selectedAction == 'training' ? trainingKitRows : bannerRows}
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      )}
      <Drawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        title={
          <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
            {crudAction.create ? "Create" : crudAction.edit ? "Edit" : crudAction.view ? "View" : "Delete"} {selectedAction == 'training' ? "Training Kit" : "Banner"}
          </span>
        }
      >
        {selectedAction === 'training' ?
          <TrainingKitCRUDForm
            updateDetails={individualUserDetails}
            crudAction={crudAction}
            refetchUserList={refetchUserList}
            selectedRoute={selectedAction}
            openDrawer={openDrawer}
          /> :
          <BannerCRUDForm
            userDetails={individualUserDetails}
            crudAction={crudAction}
            setOpenDrawer={setOpenDrawer}
            refetchDataList={getBannerList}
            openDrawer={openDrawer}
          />
        }
      </Drawer>
      <DialogView
        openView={openView}
        handleCloseView={() => setOpenView(false)}
        title={
          <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
            {crudAction.create ? "Create" : crudAction.edit ? "Edit" : crudAction.view ? "View" : "Delete"} {selectedAction == 'training' ? "Training Kit" : "Banner"}
          </span>
        }
      >
        {selectedAction == 'training' ?
          <TrainingKitCRUDForm
            updateDetails={individualUserDetails}
            crudAction={crudAction}
            refetchUserList={refetchUserList}
            selectedRoute={selectedAction}
            openDrawer={openView}
          />
          : <BannerCRUDForm
            userDetails={individualUserDetails}
            crudAction={crudAction}
            setOpenDrawer={setOpenDrawer}
            refetchDataList={getBannerList}
            openDrawer={openDrawer}
          />}
      </DialogView>
    </Box>
  );
}

export default AdminTrainingKitView;
