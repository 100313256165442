import { toast } from "react-toastify";
import { fetchWrapper } from "src/services/http_requests";
import { paramsUrl } from "src/utils/halper";

const { TRAIN_KIT_LIST_REQUEST, TRAIN_KIT_LIST_SUCCESS, TRAIN_KIT_LIST_FAIL, TRAIN_KIT_CREATE_REQUEST, TRAIN_KIT_CREATE_FAIL, TRAIN_KIT_CREATE_SUCCESS, TRAIN_KIT_UPDATE_REQUEST, TRAIN_KIT_UPDATE_FAIL, TRAIN_KIT_DELETE_REQUEST, TRAIN_KIT_DELETE_FAIL, TRAIN_KIT_UPDATE_SUCCESS, TRAIN_KIT_DELETE_SUCCESS } = require("../Conistance/TrainingKitConistance")

const BASE_URL = process.env.REACT_APP_API_URL;

export const trainKitListAction = (filter) => async (dispatch) => {
    dispatch({ type: TRAIN_KIT_LIST_REQUEST })
    try {
        let data = await fetchWrapper.get(`${BASE_URL}/training_kit/list${paramsUrl(filter)}`);
        dispatch({ type: TRAIN_KIT_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: TRAIN_KIT_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const trainKitCreateAction = (body, refetchUserList) => async (dispatch) => {
    dispatch({ type: TRAIN_KIT_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/training_kit/create`, JSON.stringify(body))
        dispatch({ type: TRAIN_KIT_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: TRAIN_KIT_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const trainKitUpdateAction = (trainKitId, body, refetchUserList) => async (dispatch) => {
    dispatch({ type: TRAIN_KIT_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/training_kit/update/${trainKitId}`, JSON.stringify(body))
        dispatch({ type: TRAIN_KIT_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: TRAIN_KIT_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const trainKitDeleteAction = (trainKitId, refetchUserList) => async (dispatch) => {
    dispatch({ type: TRAIN_KIT_DELETE_REQUEST })
    try {
        const data = await fetchWrapper.put(`${BASE_URL}/training_kit/delete/${trainKitId}`)
        dispatch({ type: TRAIN_KIT_DELETE_SUCCESS, success: true, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: TRAIN_KIT_DELETE_FAIL, payload: error })
        toast.error(error)
    }
}