import "chartjs-plugin-datalabels";
export let commonFilterData = [
  {
    lable: "State",
    name: "state",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "District",
    name: "district",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Block",
    name: "block",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },

  {
    lable: "Village",
    name: "village",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Purpose",
    name: "category",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "NGO Name",
    name: "ngoName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "FO Name",
    name: "foName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Sakhi Name",
    name: "sakhiName",
    type: "autocompleted",
    multiple: false,
    placeholder: "Enter",
    options: []
  },
  {
    lable: "Meeting Date From",
    name: "from",
    type: "date",
    multiple: false,
    placeholder: "Select",
  },
  {
    lable: "Meeting Date To",
    name: "to",
    type: "date",
    multiple: false,
    placeholder: "Select",
  },
  {
    lable: "Onboarding Year",
    name: "year",
    type: "date",
    multiple: false,
    placeholder: "Select",
  },
];



export const ChartConfig = [
  {
    title: "Overall Transaction Status",
    logic: "Total number of transactions till date under this NGO.",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: ["Scheme", "Services", "Awareness"],
      datasets: [
        {
          label: "My First Dataset",
          data: [300, 50, 100],
          backgroundColor: ["#1A3E77", "#95B3CC", "#D53814"],
          //   hoverOffset: 4s
        },
      ],
    },
    type: "pie",
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            try {
              let label = ' ' + data.labels[tooltipItem.index] || '';

              if (label) {
                label += ': ';
              }

              const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                return accumulator + curValue;
              });
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              // label += Number((value / sum) * 100).toFixed(2) + '%';
              label += value;
              return label;
            } catch (error) {
              console.log(error);
            }
          }
        }
      },
      // plugins: {
      //   datalabels: {
      //     color: '#fff',
      //     display: true, 
      //     formatter: function (value, ctx) {
      //       return ((value * 100) / 1000).toFixed(2) + '%'; 
      //     },
      //   },
      // },
      plugins: {
        datalabels: {
          formatter: (value, categories) => {
            let sum = 0;
            let dataArr = categories.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
              return data;
            });
            let percentage = (Math.round((value * 100) / sum)) + "%";
            return percentage;
          },
          color: "#fff",
        },
      },
    },
  },
  {
    title: "Remuneration Till Date",
    logic: "Total of remuneration purpose wise, month wise under this NGO",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
      ],
    },
    type: "line",
    options: {
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
          },
          // label: function(tooltipItem, data) {
          //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

          //   if (label) {
          //     label += ": ";
          //   }
          //   label += Math.round(tooltipItem.yLabel * 100) / 100;
          //   return label;
          // },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Value",
            },
            ticks: {
              min: 0,


              fontSize: 8,
            },
          },
        ],
        xAxes: [
          {
            offset: true,
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
            ticks: {
              fontSize: 8,
            },
          },
        ],
      },
    },
  },
  {
    title: "Average Transactions Per Month",
    logic: "Total transactions per month/total number of sakhis under this NGO",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
      ],
    },
    type: "line",
    options: {
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
          },
          // label: function(tooltipItem, data) {
          //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

          //   if (label) {
          //     label += ": ";
          //   }
          //   label += Math.round(tooltipItem.yLabel * 100) / 100;
          //   return label;
          // },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Value",
            },
            ticks: {
              min: 0,


              fontSize: 8,
            },
          },
        ],
        xAxes: [
          {
            offset: true,
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
            ticks: {
              fontSize: 8,
            },
          },
        ],
      },
    },
  },
  {
    title: "Evidence Per Month",
    logic: "Count of  transactions which have evidence and are approved by NGO/Admin",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
      ],
    },
    type: "line",
    options: {
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
          },
          // label: function(tooltipItem, data) {
          //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

          //   if (label) {
          //     label += ": ";
          //   }
          //   label += Math.round(tooltipItem.yLabel * 100) / 100;
          //   return label;
          // },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Value",
            },
            ticks: {
              min: 0,


              fontSize: 8,
            },
          },
        ],
        xAxes: [
          {
            offset: true,
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
            ticks: {
              fontSize: 8,
            },
          },
        ],
      },
    },
  },
  {
    title: "Beneficiaries Reached By Month",
    logic: "Total unique beneficiaries under this NGO - Purpose, Gender, SHG/Non-SHG",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
      ],
    },
    type: "line",
    options: {
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
          },
          // label: function(tooltipItem, data) {
          //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

          //   if (label) {
          //     label += ": ";
          //   }
          //   label += Math.round(tooltipItem.yLabel * 100) / 100;
          //   return label;
          // },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Value",
            },
            ticks: {
              min: 0,


              fontSize: 8,
            },
          },
        ],
        xAxes: [
          {
            offset: true,
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
            ticks: {
              fontSize: 8,
            },
          },
        ],
      },
    },
  },
  // {
  //   title: "Top 5 Worst Performing Blocks",
  //   // legend: [
  //   //   {
  //   //     name: "Block 1",
  //   //     color: "#00b0f0",
  //   //   },
  //   //   {
  //   //     name: "Block 2",
  //   //     color: "#cd9735",
  //   //   },
  //   //   {
  //   //     name: "Block 3",
  //   //     color: "#95B3CC",
  //   //   },
  //   //   {
  //   //     name: "Block 4",
  //   //     color: "#29858c",
  //   //   },
  //   //   {
  //   //     name: "Block 5",
  //   //     color: "#7b2129",
  //   //   },
  //   // ],
  //   // data: {
  //   //   labels: ["Red", "Blue", "Yellow"],
  //   //   datasets: [
  //   //     {
  //   //       label: "My First Dataset",
  //   //       data: [300, 250, 150, 200,200],
  //   //       backgroundColor: ["#00b0f0","#cd9735", "#95B3CC","#29858c", "#7b2129"],
  //   //       //   hoverOffset: 4s
  //   //     },
  //   //   ],
  //   // },
  //   // type: "pie",
  //   // options: {
  //   //   legend: {
  //   //     display: false,
  //   //   },
  //   //   tooltips: {
  //   //     callbacks: {
  //   //       title:function(tooltipItem, data) {
  //   //         // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
  //   //       },
  //   //       // label: function(tooltipItem, data) {
  //   //       //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

  //   //       //   if (label) {
  //   //       //     label += ": ";
  //   //       //   }
  //   //       //   label += Math.round(tooltipItem.yLabel * 100) / 100;
  //   //       //   return label;
  //   //       // },
  //   //     },
  //   //   },
  //   //   plugins: {
  //   //     datalabels: {
  //   //       formatter: (value, categories) => {
  //   //         let sum = 0;
  //   //         let dataArr = categories.chart.data.datasets[0].data;
  //   //         dataArr.map((data) => {
  //   //           sum += data;
  //   //         });
  //   //         let percentage = ((value * 100) / sum).toFixed(2) + "%";
  //   //         return percentage;
  //   //       },
  //   //       color: "#fff",
  //   //     },
  //   //   },
  //   // },
  // },
  {
    title: "Comparison",
    logic: "",
    legend: [
      {
        name: "Rajasthan",
        color: "#D53814",
      },
      {
        name: "Assam",
        color: "#1A3E77",
      },
    ],
    data: {
      labels: ['Rajasthan', 'Assam'],
      datasets: [
        {
          label: "My First Dataset",
          data: [50, 300],
          backgroundColor: ["#D53814", "#1A3E77"],
          //   hoverOffset: 4s
        },
      ],
    },
    type: "pie",
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            try {
              let label = ' ' + data.labels[tooltipItem.index] || '';

              if (label) {
                label += ': ';
              }

              const sum = data.datasets[0].data.reduce((accumulator, curValue) => {
                return accumulator + curValue;
              });
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              // label += Number((value / sum) * 100).toFixed(2) + '%';
              label += value;
              return label;
            } catch (error) {
              console.log(error);
            }
          }
        }
      },
      // plugins: {
      //   datalabels: {
      //     color: '#fff',
      //     display: true, 
      //     formatter: function (value, ctx) {
      //       return ((value * 100) / 1000).toFixed(2) + '%'; 
      //     },
      //   },
      // },
      plugins: {
        datalabels: {
          formatter: (value, categories) => {
            let sum = 0;
            let dataArr = categories.chart.data.datasets[0].data;
            dataArr.map((data) => {
              sum += data;
              return data;
            });
            let percentage = (Math.round((value * 100) / sum)) + "%";
            return percentage;
          },
          color: "#fff",
        },
      },
    },
  },


  // {
  //   title: "Sakhis Providing Evidences Per Month",
  //   legend: [
  //     {
  //       name: "Scheme",
  //       color: "#1A3E77",
  //     },
  //     {
  //       name: "Services",
  //       color: "#95B3CC",
  //     },
  //     {
  //       name: "Awareness",
  //       color: "#D53814",
  //     },
  //   ],
  //   data: {
  //     labels: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sept",
  //       "Oct",
  //     ],
  //     datasets: [
  //       {
  //         data: [86, 114, 106, 106, 107, 111, 133],
  //         label: "Scheme",
  //         borderColor: "#1A3E77",
  //         backgroundColor: "#1A3E77",
  //         fill: false,
  //         borderWidth: 1.5,
  //         borderRadius: Number.MAX_VALUE,
  //         borderSkipped: false,
  //       },
  //       {
  //         data: [70, 90, 44, 60, 83, 90, 100],
  //         label: "Services",
  //         borderColor: "#95B3CC",
  //         backgroundColor: "#95B3CC",
  //         fill: false,
  //         borderWidth: 1.5,
  //         borderRadius: Number.MAX_VALUE,
  //         borderSkipped: false,
  //       },
  //       {
  //         data: [10, 21, 60, 44, 17, 21, 17],
  //         label: "Awareness",
  //         borderColor: "#D53814",
  //         backgroundColor: "#D53814",
  //         fill: false,
  //         borderWidth: 1.5,
  //         borderRadius: Number.MAX_VALUE,
  //         borderSkipped: false,
  //       },
  //     ],
  //   },
  //   type: "line",
  //   options: {
  //     legend: {
  //       display: false,
  //     },
  //     plugins : {
  //       datalabels: {
  //         display:false
  //       },
  //     },
  //     tooltips: {
  //       callbacks: {
  //         title:function(tooltipItem, data) {
  //           // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
  //         },
  //         // label: function(tooltipItem, data) {
  //         //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

  //         //   if (label) {
  //         //     label += ": ";
  //         //   }
  //         //   label += Math.round(tooltipItem.yLabel * 100) / 100;
  //         //   return label;
  //         // },
  //       },
  //     },
  //     scales: {
  //       yAxes: [
  //         {
  //           scaleLabel: {
  //             display: true,
  //             labelString: "Value",
  //           },
  //           ticks: {
  //             min: 0,


  //             fontSize: 8,
  //           },
  //         },
  //       ],
  //       xAxes: [
  //         {
  //           offset: true,
  //           gridLines: {
  //             display: false,
  //           },
  //           scaleLabel: {
  //             display: true,
  //             labelString: "Month",
  //           },
  //           ticks: {
  //             fontSize: 8,
  //           },
  //         },
  //       ],
  //     },
  //   },
  // },
  {
    title: "Average Sakhi Revenue Per Month",
    logic: "Total of remuneration where evidence was provided/count of sakhis providing evidence and approved by NGO/Admin.",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
      ],
    },
    type: "line",
    options: {
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
          },
          // label: function(tooltipItem, data) {
          //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

          //   if (label) {
          //     label += ": ";
          //   }
          //   label += Math.round(tooltipItem.yLabel * 100) / 100;
          //   return label;
          // },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Value",
            },
            ticks: {
              min: 0,


              fontSize: 8,
            },
          },
        ],
        xAxes: [
          {
            offset: true,
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
            ticks: {
              fontSize: 8,
            },
          },
        ],
      },
    },
  },
  {
    title: "5 Performing Blocks",
    logic: "Total income, total schemes, services (Block wise)",
    //   // legend: [
    //   //   {
    //   //     name: "Block 1",
    //   //     color: "#00b0f0",
    //   //   },
    //   //   {
    //   //     name: "Block 2",
    //   //     color: "#cd9735",
    //   //   },
    //   //   {
    //   //     name: "Block 3",
    //   //     color: "#95B3CC",
    //   //   },
    //   //   {
    //   //     name: "Block 4",
    //   //     color: "#29858c",
    //   //   },
    //   //   {
    //   //     name: "Block 5",
    //   //     color: "#7b2129",
    //   //   },
    //   // ],
    //   // data: {
    //   //   labels: ["Red", "Blue", "Yellow"],
    //   //   datasets: [
    //   //     {
    //   //       label: "My First Dataset",
    //   //       data: [300, 250, 150, 200,200],
    //   //       backgroundColor: ["#00b0f0","#cd9735", "#95B3CC","#29858c", "#7b2129"],
    //   //       //   hoverOffset: 4s
    //   //     },
    //   //   ],
    //   // },
    //   // type: "pie",
    //   // options: {
    //   //   legend: {
    //   //     display: false,
    //   //   },
    //   //   tooltips: {
    //   //     callbacks: {
    //   //       title:function(tooltipItem, data) {
    //   //         // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
    //   //       },
    //   //       // label: function(tooltipItem, data) {
    //   //       //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

    //   //       //   if (label) {
    //   //       //     label += ": ";
    //   //       //   }
    //   //       //   label += Math.round(tooltipItem.yLabel * 100) / 100;
    //   //       //   return label;
    //   //       // },
    //   //     },
    //   //   },

    //   //   plugins: {
    //   //     datalabels: {
    //   //       formatter: (value, categories) => {
    //   //         let sum = 0;
    //   //         let dataArr = categories.chart.data.datasets[0].data;
    //   //         dataArr.map((data) => {
    //   //           sum += data;
    //   //         });
    //   //         let percentage = ((value * 100) / sum).toFixed(2) + "%";
    //   //         return percentage;
    //   //       },
    //   //       color: "#fff",
    //   //     },
    //   //   },
    //   // },
  },
  {
    title: "5 Best Performing Sakhis",
    logic: "Total income, total schemes, services (Sakhi wise)",
    // legend: [
    //   {
    //     name: "Scheme",
    //     color: "#1A3E77",
    //   },
    //   {
    //     name: "Services",
    //     color: "#95B3CC",
    //   },
    //   {
    //     name: "Awareness",
    //     color: "#D53814",
    //   },
    // ],
    // data: {
    //   labels: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //   ],
    //   datasets: [

    //     {
    //       yAxisId: 'data',
    //       data: [86, 114, 106, 106, 107, ],
    //       label: "Scheme",
    //       backgroundColor: [

    //         'rgba(201, 203, 207, 0.2)'
    //       ],
    //       borderColor: [
    //         'rgb(201, 203, 207)'
    //       ],
    //       borderWidth: 1,
    //       borderRadius: 10,
    //       borderSkipped: false,
    //     }
    //   ],
    // },
    // type: "horizontalBar",
    // options: {
    //   indexAxis: 'y',
    //   legend: {
    //     display: false,
    //   },
    //   plugins : {
    //     datalabels: {
    //       display:false
    //     },
    //   },
    //   tooltips: {
    //     callbacks: {
    //       title:function(tooltipItem, data) {
    //         // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
    //       },
    //       // label: function(tooltipItem, data) {
    //       //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

    //       //   if (label) {
    //       //     label += ": ";
    //       //   }
    //       //   label += Math.round(tooltipItem.yLabel * 100) / 100;
    //       //   return label;
    //       // },
    //     },
    //   },
    //   scales: {
    //    data: {
    //     type: 'linear',
    //     position: 'right'
    //    }
    //   },
    // },
  },
  {
    title: "Average. Evidence Revenue Per Month",
    logic: "Total of remuneration where evidence was provided/count of sakhis providing evidence and approved by NGO/Admin",
    legend: [
      {
        name: "Scheme",
        color: "#1A3E77",
      },
      {
        name: "Services",
        color: "#95B3CC",
      },
      {
        name: "Awareness",
        color: "#D53814",
      },
    ],
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
      ],
      datasets: [
        {
          data: [86, 114, 106, 106, 107, 111, 133],
          label: "Scheme",
          borderColor: "#1A3E77",
          backgroundColor: "#1A3E77",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [70, 90, 44, 60, 83, 90, 100],
          label: "Services",
          borderColor: "#95B3CC",
          backgroundColor: "#95B3CC",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
        {
          data: [10, 21, 60, 44, 17, 21, 17],
          label: "Awareness",
          borderColor: "#D53814",
          backgroundColor: "#D53814",
          fill: false,
          borderWidth: 1.5,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false,
        },
      ],
    },
    type: "line",
    options: {
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false
        },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            // return ["Monthly", "Quarterly", "Half Yearly"].includes(filterData.Periodicity) ? data.datasets.map(item => item.data).flat().filter(item => item.y === Number(tooltipItem[0]?.value))[0]?.x : tooltipItem[0]?.label
          },
          // label: function(tooltipItem, data) {
          //   var label = data.datasets[tooltipItem.datasetIndex].label || "";

          //   if (label) {
          //     label += ": ";
          //   }
          //   label += Math.round(tooltipItem.yLabel * 100) / 100;
          //   return label;
          // },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Value",
            },
            ticks: {
              min: 0,


              fontSize: 8,
            },
          },
        ],
        xAxes: [
          {
            offset: true,
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
              labelString: "Month",
            },
            ticks: {
              fontSize: 8,
            },
          },
        ],
      },
    },
  },


];