import { Grid, Box, Button } from "@material-ui/core";
import { Formik } from "formik";
import { rest } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { purposeMapperListAction } from "src/Redux/Actions/PurposeMapperAction";
import {
  trainKitCreateAction,
  trainKitDeleteAction,
  trainKitUpdateAction,
} from "src/Redux/Actions/TrainingKitAction";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import CustomInputs from "src/components/CommonComponents/CustomInputs";
import { fetchApiDataList } from "src/services/http_requests";
import { useState } from "react";


function TrainingKitCRUDForm({ crudAction, updateDetails, refetchUserList, openDrawer }) {
  const { states } = useSelector((state) => state.statesList);
  const { purposes } = useSelector((state) => state.purposeList);
  const [schemeList, setSchemeList] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    txn_id: uuidv4(),
    name: "",
    state_id: "",
    purpose_id: "",
    scheme_id: "",
    file: "",
  };

  const dispatch = useDispatch();

  const trainingFormInputs = [
    {
      key: "state_id",
      name: "state_id",
      label: "State",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: states?.data || [],
      value: "",
      placeholder: "Select State",
    },
    {
      key: "purpose_id",
      name: "purpose_id",
      label: "Purpose",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: purposes?.data || [],
      value: "",
      placeholder: "Select Purpose",
    },
    {
      key: "scheme_id",
      name: "scheme_id",
      label: "Scheme",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: schemeList,
      value: "",
      placeholder: "Select Scheme",
    },
    {
      key: "name",
      name: "name",
      label: "Title",
      inputType: "text",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Enter Title",
    },
    {
      key: "file",
      name: "file",
      label: "Upload File",
      inputType: "file",
      required: false,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Upload File",
      acceptedFiles: [".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values", '.pdf', '.jpg', '.jpeg', '.png']
    },
  ];

  return (
    <div>
      <Formik
        // enableReinitialize={crudAction.create ? false : true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Name is required"),
          state_id: Yup.string().required("State is required"),
          purpose_id: Yup.string().required("Purpose is required"),
          scheme_id: Yup.string().required("Scheme is required"),
          file: Yup.string().required("File is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setIsSubmitting(true);
          values.txn_id = updateDetails?.txn_id ? updateDetails?.txn_id : uuidv4();
          crudAction.create
            ? dispatch(trainKitCreateAction({ ...values }, refetchUserList))
            : crudAction.edit
              ? dispatch(trainKitUpdateAction(updateDetails?.id, { ...values }, refetchUserList))
              : dispatch(trainKitDeleteAction(updateDetails?.id, refetchUserList));
          // }
        }}
      >
        {function Render({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          touched,
          values,
          resetForm
        }) {

          useEffect(() => {
            if (openDrawer === false) {
              resetForm();
              setIsSubmitting(false);
            }
          }, [openDrawer]);
          useEffect(() => {
            if (updateDetails) {
              for (let elem in initialValues) {
                setFieldValue(elem, updateDetails[elem]);
              }
            }
          }, [updateDetails]);

          useEffect(() => {
            if (values.purpose_id && values.state_id) {
              fetchApiDataList("purpose_mapper", {
                fetched: "All",
                state_id: values?.state_id,
                purpose_id: values?.purpose_id,
              }, setSchemeList);
            } else {
              setFieldValue("scheme_id", "");
              setSchemeList(null)
            }
          }, [values.purpose_id, values.state_id]);

          return (
            <form
              className="bg-transparent overflow-hidden pb-6 "
              onSubmit={handleSubmit}
              {...rest}
            >
              <Grid container spacing={2} className='bg-white p-4' >
                {trainingFormInputs?.map((elem, index) => {
                  return (
                    <CustomInputs
                      name={elem?.name}
                      id={elem?.key}
                      label={elem?.label}
                      inputType={elem?.inputType}
                      value={values[elem.name]}
                      required={elem?.required}
                      handleBlur={handleBlur}
                      onClick={() => setFieldTouched(elem.name, true)}
                      onChange={(event, value) => {
                        if (['autoComplete', 'file'].includes(elem.inputType)) setFieldValue(event, value);
                        else handleChange(event);
                      }}
                      error={errors[elem.name] && touched[elem.name]}
                      helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                      placeholder={elem?.placeholder}
                      disabled={elem?.disabled}
                      multiple={elem?.multiple}
                      optionArray={elem?.optionArray}
                      acceptedFiles={elem?.acceptedFiles}
                      crudAction={crudAction}
                      initialValues={initialValues}
                    />
                  )
                })}

                {crudAction.view ? null : <Grid item xs={12} sx={12} md={12}>
                  <Box className={`w-full py-5 ${isSubmitting ? "pointer-events-none" : "pointer-events-auto"}`}>
                    <Button
                      type="submit"
                      className="w-full"
                      variant="contained"
                      color="primary"
                      size="medium"
                    >
                      {crudAction.create ? "CREATE"
                        : crudAction.edit ? "EDIT"
                          : crudAction.delete ? "DELETE"
                            : "SUBMIT"}
                    </Button>
                  </Box>
                </Grid>}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default React.memo(TrainingKitCRUDForm);
