import { Box, Typography } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";

export default function Drawer({ children, isOpen, setIsOpen, title }) {

  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflowY = "auto";
  }, [isOpen])
  return (
    <main
      // className="w-64 fixed inset-0 bg-pink-200 z-50 h-screen"
      style={{ zIndex: 1100 }}
      className={
        " fixed inset-0 z-50 min-h-screen overflow-x-hidden overflow-y-auto  bg-gray-900 bg-opacity-25  transform ease-in-out duration-700 " +
        (isOpen
          ? " transition-opacity opacity-100 translate-x-0  "
          : " transition-all opacity-0 translate-x-full")
      }
    >
      <div onClick={() => { setIsOpen(false); document.body.style.overflow = 'auto'; }} className="absolute right-72 w-8 h-8  z-50 flex justify-center items-center cursor-pointer">
        <CloseOutlined className="w-full mt-4 h-full " style={{ color: 'white' }} />
      </div>
      <section
        className={
          " w-72 max-w-md overflow-y-auto  right-0 absolute bg-white h-screen shadow-xl duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative overflow-y-auto w-72 max-w-md pb-10 flex flex-col justify-start items-start space-y-4 px-3  h-full">
          <Box className="flex justify-start px-3 pt-4 items-center space-x-2">
            <Typography ><span className='capitalize   text-md md:text-lg lg:text-xl text-black'>{title}</span></Typography>
          </Box>
          {children}
        </article>
      </section>
      <section
        className="w-screen h-screen cursor-pointer "
        onClick={() => { setIsOpen(false); document.body.style.overflow = 'auto'; }}
      ></section>
    </main>
  );
}
