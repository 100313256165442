import React from "react";
import { MapContainer, ZoomControl, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useState } from "react";
import TopoJSON from "./TopoJSON.js";
import theme from "src/theme/index.js";
import { Box, Button, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { Undo } from "@material-ui/icons";
import { ChangeView } from "./ChangeView.js";
var country_geo = require("./AssamNew.json");
var country_geos = require("./Assam.json");

const MapView = ({ mapData, selectedState, setSelectedState }) => {
  const [position, setPosition] = useState({
    lat: 22.98563880920415,
    lng: 82.012077331542969,
  });
  const [zoom, setZoom] = useState(4);
  const [indiaJson, setIndiaJson] = useState([]);

  const mapStyle = {
    height: "100%",
    width: "100%",
    // margin: "0 auto",
    backgroundColor: "rgb(249 250 251)",
    // border: `1px solid black`,
    // borderRadius: '0%',
    zIndex: 1,
    // boxShadow: '0px 1px 2px 1px #B10932'
  };
  // const feature = country_geo?.objects?.districts?.geometries.map((feature) => {
  //   return feature;
  // });

  useEffect(() => {
    let mounted = true;
    // handleRestFilter()s
    if (mounted) {
      if (selectedState) {
        setIndiaJson(country_geos);
        // country_geos?.features?.
        setPosition(
          selectedState === "Assam"
            ? { lat: 24.98563880920415, lng: 93.012077331542969 }
            : { lat: 26.98563880920415, lng: 74.012077331542969 }
        );
        setZoom(6.2);
        // setPosition({ lat: 26.985638809204150, lng: 74.012077331542969 })
      } else {
        setIndiaJson(country_geo);
        setPosition({ lat: 22.98563880920415, lng: 82.012077331542969 });
        setZoom(4);
      }
    }
    return () => (mounted = false);
  }, [selectedState]);


  return (
    <MapContainer
      minZoom={4}
      maxZoom={7}
      zoomControl={false}
      attributionControl={false}
      style={mapStyle}
      key={Math.random()}
      center={position}
      zoom={zoom}
      scrollWheelZoom={false}
    >
      {/* {position && zoom && <ChangeView center={position} zoom={zoom} />} */}
      <Box
        className="absolute top-0 left-0 z-75 h-20 w-20 p-2"
        style={{ zIndex: 9999 }}
      >
        {mapData?.data?.map((data, index) => (
          <Box className="flex flex-col justify-start items-start" key={index}>
            {mapData?.showPercentage ? (
              <Typography variant="h6" style={{ fontWeight: 600 }}>
                {data?.total > 0 ? Math.round((100 * data?.total) / mapData?.total) : 0}%
              </Typography>
            ) : null}
            <Box className="flex justify-start items-center space-x-1">
              <span
                className="p-1 rounded-full"
                style={{ backgroundColor: data?.color }}
              ></span>{" "}
              <Typography variant="caption">{data?.state_name}</Typography>
            </Box>
          </Box>
        ))}

      </Box>
      {selectedState ? (
        <Box
          className="absolute top-0 left-12 z-75 h-20 w-20 p-2"
          style={{ zIndex: 9999 }}
        >
          <Button onClick={() => setSelectedState("")}>
            <Undo />
          </Button>
        </Box>
      ) : null}
      <TopoJSON
        data={indiaJson}
        mapData={mapData}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />
      <ZoomControl position="bottomleft" zoomInText="+" zoomOutText="-" />
    </MapContainer>
  );
};

export default MapView;
