function paramsUrl(obj) {
    let params = "";
    if (obj !== undefined) {
        if (Object.keys(obj).length === 0 && obj.constructor === Object) {
            return params;
        } else {
            let keys = Object.keys(obj);
            let i = 0;
            for (const key of keys) {
                if (obj[key]) {
                    if (i === 0) {
                        params = `?${key}=${obj[key]}`;
                    } else {
                        if (obj[key]?.length || typeof obj[key] === "number" || typeof obj[key] === "boolean") {
                            params += `&${key}=${obj[key]}`;
                        } else {
                        }
                    }
                    i++;
                }
            }
        }
    }
    return params;
}

function selectedLableValueMultiselect(selected, dataArr, field, label) {
    let arr = [];
    if (selected.length) {
        for (const pf of selected) {
            let d = dataArr.length ? dataArr.find(e => e[field] === pf) : {};
            if (d) {
                arr.push(d[label]);
            }
        }
    }
    return arr;
}

function rowsPerPageOptions() {
    return [10, 25, 50, 100, 500];
}
function isPageAuth(data, key) {
    if (data !== undefined && data.actions !== undefined) {
        let d = data.actions.field(e => e.key === key);
        if (d) {
            return d.access;
        } else {
            return false;
        }
    } else {
        return true;
    }
}
function getLabelToId(data, labels) {
    let mainArr = [];
    if (data?.length) {
        for (let i = 0; i < labels.length; i++) {
            let obj = data.find(e => e.name === labels[i]);
            if (obj) {
                mainArr.push(obj.id);
            }
        }
    }
    return mainArr;
}
const filterUserList = (list, key, value) => {
    const filteredList = list?.filter(elem => {
        if (typeof elem[key] !== 'number' && elem[key]?.includes(",")) {
            let valueArray = elem[key]?.split(",");
            if (Array.isArray(value)) {
                return value.some(val => valueArray.includes(val.toString()));
            } else return valueArray.includes(value.toString());
        } return parseInt(elem[key]) === parseInt(value);
    });
    return filteredList;
};
export { paramsUrl, selectedLableValueMultiselect, rowsPerPageOptions, isPageAuth, getLabelToId, filterUserList };