export const  STATE_LIST_REQUEST = 'STATE_LIST_REQUEST'
export const STATE_LIST_SUCCESS = 'STATE_LIST_SUCCESS'
export const STATE_LIST_FAIL = 'STATE_LIST_FAIL'

export const STATE_CREATE_FAIL = 'STATE_CREATE_FAIL'
export const STATE_CREATE_REQUEST = 'STATE_CREATE_REQUEST'
export const STATE_CREATE_SUCCESS = 'STATE_CREATE_SUCCESS'

export const STATE_UPDATE_REQUEST = 'STATE_UPDATE_REQUEST'
export const STATE_UPDATE_SUCCESS = 'STATE_UPDATE_SUCCESS'
export const STATE_UPDATE_FAIL = 'STATE_UPDATE_FAIL'

export const STATE_DELETE_REQUEST = 'STATE_DELETE_REQUEST'
export const STATE_DELETE_SUCCESS = 'STATE_DELETE_SUCCESS'
export const STATE_DELETE_FAIL = 'STATE_DELETE_FAIL'