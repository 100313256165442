import { toast } from "react-toastify"
import { fetchWrapper } from "src/services/http_requests"
import { paramsUrl } from "src/utils/halper"

const { LINE_ITEM_LIST_REQUEST, LINE_ITEM_LIST_SUCCESS, LINE_ITEM_LIST_FAIL, LINE_ITEM_CREATE_REQUEST, LINE_ITEM_CREATE_FAIL, LINE_ITEM_CREATE_SUCCESS, LINE_ITEM_UPDATE_REQUEST, LINE_ITEM_UPDATE_FAIL, LINE_ITEM_UPDATE_SUCCESS } = require("../Conistance/LineItemConistance");

const BASE_URL = process.env.REACT_APP_API_URL

export const lineItemListAction = (filter) => async (dispatch) => {
    dispatch({ type: LINE_ITEM_LIST_REQUEST })
    try {
        const data = await fetchWrapper.get(`${BASE_URL}/budget_lineitem/list${paramsUrl(filter)}`)
        dispatch({ type: LINE_ITEM_LIST_SUCCESS, success: true, payload: data })
    } catch (error) {
        dispatch({ type: LINE_ITEM_LIST_FAIL, payload: error })
        toast.error(error)
    }
}

export const lineItemCreateAction = (body, refetchUserList) => async (dispatch) => {
    dispatch({ type: LINE_ITEM_CREATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/budget_lineitem/create`, body)
        dispatch({ type: LINE_ITEM_CREATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: LINE_ITEM_CREATE_FAIL, payload: error })
        toast.error(error)
    }
}

export const lineItemUpdateAction = (bodyId, body, refetchUserList) => async (dispatch) => {
    dispatch({ type: LINE_ITEM_UPDATE_REQUEST })
    try {
        const data = await fetchWrapper.post(`${BASE_URL}/budget_lineitem/update/${bodyId}`, body)
        dispatch({ type: LINE_ITEM_UPDATE_SUCCESS, payload: data })
        toast.success(data?.message)
        if (data?.success) {
            setTimeout(() => {
                refetchUserList && refetchUserList();
            }, 600);
        }
    } catch (error) {
        dispatch({ type: LINE_ITEM_UPDATE_FAIL, payload: error })
        toast.error(error)
    }
}