import { loadData, saveData, deleteData } from "src/utils/sessionStorage";
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from "../Auth/actionType";

const loginInfo = loadData("loginInfo")

const initState = {
    isAuth: loginInfo ? true : false,
    isLoading: false,
    isLoggedIn: false,
    isError: false,
    token: loginInfo || "",
    errorMessage: ""
}
export const authReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
                isLoggedIn: false
            }
        case LOGIN_SUCCESS:
            saveData("loginInfo", payload)
            return {
                ...state,
                isLoading: true,
                isAuth: true,
                token: payload.token,
                isLoggedIn: false
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: payload.errMessage,
                token: "",
                isLoggedIn:  payload.isLoggedIn !== undefined ?  payload.isLoggedIn : false
            }
        case LOGOUT:
            deleteData("loginInfo")
            deleteData("currentNav")
            return {
                ...state,
                isLoading: false,
                isAuth: false,
                isError: false,
                isLoggedIn: false
            }
        default:
            return state
    }
}