import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useEffect } from "react";
import { useState } from "react";
import noDataImg from 'src/assets/images/no_data.svg';

function StickyHeadTable({
  columns,
  rows,
  loading,
  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  isPopupTable
  // handleViewDialog
}) {
  // const [page, setPage] = React.useState(0);
  const pageInfo = localStorage.getItem('pageInfo');
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isPopupTable) return;
    else localStorage.setItem('pageInfo', newPage);
  };
  const [classN, setClass] = useState(false)

  useEffect(() => {
    if (pageInfo === null) {
      localStorage.setItem('pageInfo', 1)
    }
  }, [pageInfo])

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  setTimeout(() => {
    setClass(true)
  }, 300);

  return (
    <>
      {page && <Box className="py-5">
        <Typography variant="caption" className="text-gray-500">
          Showing results {rows?.length > 0 ? ((page * rowsPerPage) + 1 - rowsPerPage) : 0}-
          {(rowsPerPage * page) - (rowsPerPage - rows?.length)} of {total}
        </Typography>
      </Box>}
      {loading ? (
        <Box className="w-full" sx={{ width: "100%" }}>
          <LinearProgress className="w-full" />
        </Box>
      ) : (
        <Paper m={10} sx={{ width: "100%", overflow: "hidden" }} >
          <TableContainer sx={{ maxHeight: 440, borderRadius: "40px" }} >
            <Table aria-label="sticky table" >
              <TableHead className="bg-slate-200">
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        className="font-semibold text-xs"
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody >
                {rows?.map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      className={classN ? `transform  translate-y-0 z-0 transition duration-700 
                      delay-${i === 0 ? '[0ms]'
                          : i === 1 ? '[50ms]'
                            : i === 2 ? 100
                              : i === 3 ? 150
                                : i === 4 ? 200
                                  : i === 5 ? '[250ms]'
                                    : i === 6 ? 300
                                      : i === 7 ? `[400ms]`
                                        : i === 8 ? `[500ms]`
                                          : i === 9 ? `[600ms]`
                                            : `[700ms]`}  ease-in-out`
                        : `transform  translate-y-full z-0 transition duration-500 ease-in-out opacity-0 `}
                    // onClick={() => handleViewDialog(row?.id)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align="center">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
              {/* )} */}

            </Table>
            {rows?.length < 1 && (
              <Box className="w-full h-96 flex flex-col justify-center items-center space-y-2">
                <Box className="w-64 h-64 flex justify-center items-center  ">
                  <img
                    src={noDataImg}
                    alt="log"

                    className="w-full h-full object-contain"
                  />
                </Box>
                <Typography variant="h5" style={{ fontWeight: 600 }}>No Data Found</Typography>
              </Box>
            )}
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
          {/* <Stack spacing={2}> */}
          {/* <Pagination count={10} shape="rounded" /> */}
          {(rows?.length > 0 && page) && <Box width="100%" display="flex" justifyContent="center" py={1}>
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={Math.ceil(total / rowsPerPage)}
              // variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              color="primary"
            />
          </Box>}
          {/* </Stack> */}
        </Paper>
      )}
    </>
  );
}

export default React.memo(StickyHeadTable);
