import { deleteData, getToken } from "../utils/sessionStorage";
// import * as Crypto from 'crypto-js';
import { downloadCsv } from "./downloadCsv";
import axios from "axios";
import { useCancelToken } from "src/customHooks/hooks";
import { paramsUrl } from "src/utils/halper";
import { toast } from "react-toastify";

// const { newCancelToken, isCancel } = useCancelToken();
let CANCEL_TOKEN_SOURCE = axios.CancelToken.source();

function generateNewCancelTokenSource() {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source();
}
export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  uploadFile,
  exportCsv,
  finishPendingRequests(cancellationReason) {
    CANCEL_TOKEN_SOURCE.cancel(cancellationReason);
    generateNewCancelTokenSource();
  }
};


function get(url, newCancelToken) {

  const requestOptions = {
    method: "GET",
    headers: {
      "x-access-token": getToken(),
    },
    url: url,
    cancelToken: CANCEL_TOKEN_SOURCE.token,
  };
  return axios(requestOptions)
    .then(handleResponse)
    .catch(handleResponse);
}
async function post(url, body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getToken(),
    },
    url: url,
    data: body,
  };
  return axios(requestOptions)
    .then(handleResponse)
    .catch(handleResponse);
}

function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getToken(),
    },
    url: url,
    data: body,
  };
  return axios(requestOptions)
    .then(handleResponse)
    .catch(handleResponse);
}
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "x-access-token": getToken(),
    },
    url: url,
  };
  return axios(requestOptions)
    .then(handleResponse)
    .catch(handleResponse);
}

function handleResponse(response) {
  if (Number(response.status) === 200) {
    return response?.data;
  } else {
    let error = "";
    // if (axios.isCancel(error)) {
    //   console.log("axios request cancelled", response.response.data.message, response);
    // } else {
    if (response.response !== undefined && response.response.status === 500) {
      error = "Internal Server Error";
    } else if (
      response.response !== undefined &&
      response.response.status === 400
    ) {
      error = response.response.data.message;
    } else if (
      response.response !== undefined &&
      response.response.status === 401
    ) {
      error = response.response.data.message;
      window.location.href = "/login";
      deleteData("loginInfo");
    } else {
      error = response.message;
    }
    return Promise.reject(error);
  }
  // }
}

async function uploadFile(body, ext) {
  let file = body[0];
  const baseUrl = process.env.REACT_APP_API_URL;
  let url = `${baseUrl}/getSignedUrl`;
  let requestOptions = {
    method: "post",
    data: [
      {
        file_name: file.name,
        mime_type: `image/${ext}`,
      },
    ],
    url,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": getToken(),
    },
  };
  let responceUrl = await axios(requestOptions)
    .then(handleResponse)
    .catch(handleResponse);
  if (responceUrl.success) {
    let putOptions = {
      method: "PUT",
      data: file,
      url: responceUrl?.data?.url,
      headers: {
        "Content-Type": file.type,
        excludeContentHeader: true,
        "x-access-token": getToken(),
      },
    };
    let putData = await axios(putOptions)
      .then(handleResponse)
      .catch(handleResponse);
    localStorage.setItem('upload', JSON.stringify(responceUrl))
    if (responceUrl.success) {
      return { success: true, url: responceUrl?.data?.file_url };
    } else {
      return { success: false, message: putData.message };
    }
  } else {
    return { success: false, message: responceUrl.message };
  }
}

async function exportCsv(url, filename = "") {
  try {
    const requestOptions = {
      method: "GET",
      url,
      headers: {
        "x-access-token": getToken(),
      },
    };
    let data = await axios(requestOptions)
      .then(handleResponse)
      .catch(handleResponse);
    downloadCsv(data, filename);
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export const fetchApiDataList = async (api, query, setObjectList) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/${api}/list${paramsUrl(query)}`,
      {
        headers: {
          "x-access-token": getToken(),
        }
      }
    );
    setObjectList(data?.data);
  }
  catch (error) {
    if (error instanceof Error) {
      toast.error(error);
    }
  }
}
