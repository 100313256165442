import { Box, Button, Divider, Typography } from '@material-ui/core'
import { FilterList, Chat } from '@material-ui/icons';
import React from 'react'
import { useMemo } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CommonFilter2 from 'src/components/CommonComponents/CommonFilter2';
import DialogView from 'src/components/CommonComponents/DialogView';
import LoadingBox from 'src/components/LoadingBox';
import { ngoListAction } from 'src/Redux/Actions/NgoAction';
import { stateListAction } from 'src/Redux/Actions/StateAction';
import { fetchWrapper } from 'src/services/http_requests';
import theme from 'src/theme';
import { generateSerialNo } from 'src/utils';
import { paramsUrl } from 'src/utils/halper';
import StickyHeadTable from '../../components/CommonComponents/Table';
import FeedbackChat from './feedbackChat';

const FeedbackView = () => {
    const pageInfo = localStorage.getItem("pageInfo");
    const selected = localStorage.getItem("selectedRoute") ? JSON.parse(localStorage.getItem("selectedRoute")) : {};
    const loginInfo = JSON.parse(localStorage.getItem("loginInfo")) || {};
    const loginUserRole = loginInfo?.data?.role_id;

    const { ngos } = useSelector((state) => state?.ngoList);
    const { states } = useSelector((state) => state.statesList);

    const dispatch = useDispatch();

    // table columns (refresh whwnever update somthing in it)
    const columnRef = useRef([
        { id: "sno", label: "S.No" },
        { id: "name", label: "User Name" },
        { id: "mobile", label: "Mobile Number" },
        ...(loginUserRole == 1) ? [{ id: "ngoName", label: "NGO Name" }] : [],
        { id: "state", label: "State" },
        { id: "action", label: "Action", },
    ]);

    // create table rows object
    const createData = (sno, name, mobile, ngoName, state, district, action) => {
        return { sno, name, mobile, ngoName, state, district, action }
    };

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(() => pageInfo ? parseInt(pageInfo) : 1);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState({
        fo: Object.keys(selected)?.length ? selected?.fo ? true : false : true,
        sakhi: selected.sakhi ? true : false,
    });
    const [filterData, setFilterData] = useState({
        role_id: selected?.fo ? "3" : selected?.sakhi ? "4" : "3",
        ngo_id: "",
        state_id: "",
        district_id: "",
        block_id: "",
    });
    const [feedbackData, setFeedbackData] = useState([]);
    const [isLoading, setiIsLoading] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState({})


    // feedback api 
    const fetchFeedbackData = async (query) => {
        setiIsLoading(true);
        try {
            const data = await fetchWrapper.get(
                `${process.env.REACT_APP_API_URL}/feedback/list${paramsUrl(query)}`
            );
            setiIsLoading(false);
            setFeedbackData(data);
        }
        catch (error) {
            setiIsLoading(false);
            toast.error(error);
            throw (error);
        }
    };

    const handleFilterSearch = () => {
        fetchWrapper?.finishPendingRequests();
        if (page == 1) fetchFeedbackData({ ...filterData, page: 1 });
        else {
            localStorage.setItem("pageInfo", page);
            setPage(1);
        }
    };
    const handleRestFilter = () => {
        let spreadedFilter = { ...filterData };
        for (let elem in spreadedFilter) {
            if (elem !== 'role_id') spreadedFilter = { ...spreadedFilter, [elem]: "" };
        }
        setFilterData(spreadedFilter);
        if (page == 1) fetchFeedbackData({ ...spreadedFilter, page: 1 });
        else {
            localStorage.setItem("pageInfo", page);
            setPage(1);
        }
    };

    // feedback api call on page or user change
    useEffect(() => {
        fetchFeedbackData({ ...filterData, page: page });
    }, [page, selectedRoute]);

    // set total
    useEffect(() => {
        if (feedbackData?.total) {
            setTotal(feedbackData?.total);
            localStorage.setItem("totalData", feedbackData?.total);
        } else {
            if (page == 1) {
                setTotal(feedbackData?.data?.length);
                localStorage.setItem("totalData", feedbackData?.data?.length);
            } else setTotal(localStorage.getItem("totalData"));
        }
    }, [feedbackData]);

    // get state nd ngo, setting animation, changing local on unmount
    useEffect(() => {
        if (ngos === undefined || ngos === null) dispatch(ngoListAction({ fetched: "All" }));
        !states && dispatch(stateListAction({ fetched: "All" }));

        window.scrollTo(0, 0);
        return () => {
            localStorage.removeItem("selectedRoute");
            fetchWrapper?.finishPendingRequests();
        }
    }, []);


    // table rows 
    const rows = useMemo(() => {
        return feedbackData?.data?.map((elem, index) => {
            return createData(
                generateSerialNo(index, page),
                elem?.name,
                elem?.mobile,
                loginUserRole == 1 ? elem?.ngo_name : "",
                elem?.state_name,
                elem?.district_name,
                <Box className="space-x-3 z-50">
                    <button
                        className="cursor-pointer"
                        onClick={(event) => {
                            setSelectedFeedback(elem);
                            setOpenView(true);
                        }}
                    ><Chat color="primary" />
                    </button>
                </Box>
            )
        });
    }, [feedbackData]);

    let filtersToDisplay = ["state_id", "district_id", "block_id"];

    return (
        <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
            <Box width="100%" height="100%">
                <Box pb={2}
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Typography>
                        <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">Feedback</span>
                    </Typography>
                    <Box className="space-x-2 ">
                        <Button variant="contained"
                            size="small"
                            startIcon={<FilterList color="primary" />}
                            onClick={() => setShowFilter(!showFilter)}
                            // color="primary"
                            style={{
                                backgroundColor: "white",
                                color: theme.palette.primary.main,
                            }}
                        >
                            Filter
                        </Button>
                    </Box>
                </Box>
                <Divider style={{ maxWidth: "100%" }} />

                <Box className="w-full flex justify-between items-center">
                    <Box className="flex justify-start items-center">
                        {[
                            { label: "FO", name: "fo", fo: true, role_id: '3' },
                            { label: "SAKHI", name: "sakhi", sakhi: true, role_id: '4' },
                        ].map((user) => (
                            <Box
                                key={user.label}
                                variant="contained"
                                className={
                                    selectedRoute[user.name] ?
                                        "px-3 py-1.5 font-semibold capitalize cursor-pointer rounded-tl-md text-black  bg-white  border-b-2 border-primary"
                                        : "px-3 py-1.5 font-semibold capitalize cursor-pointer text-black bg-white  border-b-2"
                                }
                                onClick={() => {
                                    fetchWrapper?.finishPendingRequests();
                                    setFilterData({ ...filterData, role_id: user?.role_id, ngo_id: "", state_id: "" });
                                    localStorage.setItem("totalData", 0);
                                    localStorage.setItem("pageInfo", 1);
                                    setSelectedRoute({
                                        fo: user?.fo ? true : false,
                                        sakhi: user.sakhi ? true : false,
                                    });
                                    localStorage.setItem(
                                        "selectedRoute",
                                        JSON.stringify({
                                            fo: user?.fo ? true : false,
                                            sakhi: user.sakhi ? true : false,
                                        })
                                    );
                                    setPage(1);
                                }}
                            >
                                <Typography variant="caption">
                                    {user.label}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>

                {showFilter ? <CommonFilter2
                    filterData={filterData}
                    showFilter={showFilter}
                    setFilterData={setFilterData}
                    handleFilterSearch={handleFilterSearch}
                    handleRestFilter={handleRestFilter}
                    filtersToDisplay={loginUserRole == 1 ? [...filtersToDisplay, "ngo_id"] : filtersToDisplay}
                /> : null}
                {isLoading ? <LoadingBox type={"table"} /> :
                    <StickyHeadTable
                        columns={columnRef?.current}
                        rows={rows}
                        total={total}
                        loading={isLoading}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={"10"} />
                }
            </Box>
            <DialogView
                openView={openView}
                handleCloseView={() => { setOpenView(false); setSelectedFeedback({}); }}
                title={selectedFeedback?.name}
                subTitle={selectedFeedback?.mobile}
                position={"bottomLeft"}
            >
                <FeedbackChat
                    feedbackData={selectedFeedback}
                    loginUserData={loginInfo?.data}
                />
            </DialogView>
        </Box>
    )
}

export default FeedbackView;