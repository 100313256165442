const { STATE_CREATE_REQUEST, STATE_CREATE_SUCCESS, STATE_CREATE_FAIL, STATE_LIST_REQUEST, STATE_LIST_SUCCESS, STATE_LIST_FAIL, STATE_DELETE_REQUEST, STATE_UPDATE_SUCCESS, STATE_UPDATE_REQUEST, STATE_UPDATE_FAIL, STATE_DELETE_SUCCESS, STATE_DELETE_FAIL } = require("../Conistance/StateConistance");

export const stateCreateReducer = (state = {}, action) => {
    switch(action.type){
        case STATE_CREATE_REQUEST:
            return {loading: true};
        case STATE_CREATE_SUCCESS:
            return { loading: false, success: true, state: action.payload}
        case STATE_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const stateListReducer = (state = [], action) => {
    switch(action.type){
        case STATE_LIST_REQUEST:
            return {loading: true}
        case STATE_LIST_SUCCESS:
            return { loading: false, states: action.payload}
        case STATE_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const stateUpdateReducer = (state = {},action) => {
    switch(action.type){
        case STATE_UPDATE_REQUEST:
            return {loading: true};
        case STATE_UPDATE_SUCCESS:
            return {loading: false , state: action.payload}
        case STATE_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const stateDeleteReducer = (success = '', action) => {
    switch(action.type){
        case STATE_DELETE_REQUEST:
            return {loading: true}
        case STATE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case STATE_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}