import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
  Box,
  InputAdornment
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { RefreshOutlined } from "@material-ui/icons";
// import crisilPng from 'src/assets/images/crisil.png'

function LoginForm({
  handleLogin,
  captchaId,
  handleCaptchaId,
  setIsResetPass,
}) {
  const [inputData, setInputData] = React.useState({
    role: "",
    username: "",
    password: "",
    captcha: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setInputData({
      ...inputData,
      showPassword: !inputData.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Formik
      initialValues={{
        role: inputData.role || "",
        username: inputData.username || "",
        password: inputData.password || "",
        showPassword: inputData.showPassword || "",
        captcha: inputData.captcha || "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255)
          .required("Username is required"),
        password: Yup.string()
          .max(255)
          .required("Password is required"),
        captcha: Yup.string()
          .max(6)
          .required("Captcha is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          handleLogin(values.username, values.password, values.captcha);
          // await signInWithusernameAndPassword(values.username, values.password);
          // if (isMountedRef.current) {
          //   setStatus({ success: true });
          //   setSubmitting(false);

          // }
        } catch (err) {
          console.error(err);
          // if (isMountedRef.current) {
          //   setStatus({ success: false });
          //   setErrors({ submit: err.message });
          //   setSubmitting(false);
          // }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        resetForm,
      }) => {
        return (
          <form
            noValidate
            onSubmit={handleSubmit}
            className=" bg-white rounded-xl flex flex-col w-10/12 sm:w-6/12 6xl:w-5/12   sm:mr-10 px-4 py-6 justify-start items-center z-50"
          // {...rest}
          >
            {/* <Grid
              spacing={1}
              container
              className="md:space-y-2 bg-red-100 space-y-3  flex flex-col w-full lg:w-9/12 h-full justify-center md:justify-center items-center"
            > */}
            <Box
              className="w-full flex justify-center items-center pb-4"
              xs={11}
              md={11}
            >
              <Typography variant="h4" style={{ fontWeight: 600 }}>
                Welcome Back &#128075;
              </Typography>
            </Box>
            <Box className="w-full pt-2 pb-4" xs={11} md={11}>
              {/* <InputLabel className="mb-2">
                <Typography variant="caption" style={{ color: "black" }}>
                  Username
                </Typography>
              </InputLabel> */}
              <TextField
                error={touched.username && errors.username}
                helperText={touched.username && errors.username}
                name="username"
                className="inputRounded"
                placeholder="Enter Username"
                fullWidth
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                style={{ height: "40px", paddingTop: 0 }}
                // label="Username"
                variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              //   style: {
              //     backgroundColor: "white",
              //     paddingRight: "8px",
              //     fontSize: 14,
              //   },
              // }}
              />
            </Box>
            <Box className="w-full" xs={11} md={11}>
              {/* <InputLabel className="mb-2">
                <Typography variant="caption" style={{ color: "black" }}>
                  Password
                </Typography>
              </InputLabel> */}
              <TextField
                error={Boolean(
                  touched.password &&
                  errors.password
                )}
                fullWidth
                helperText={
                  touched.password &&
                  errors.password
                }
                placeholder="Confirm password"
                className="inputRounded"

                name="password"
                onChange={handleChange}
                type={inputData?.showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={handleClickShowPassword}
                      position="end"
                      className="cursor-pointer"
                    >
                      {inputData?.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </InputAdornment>
                  ),
                }}
                // pattern="/^[6-9]\d{9}$/"
                onBlur={handleBlur}
                value={values.confirm_password}
                variant="outlined"
              />
              {/* <OutlinedInput
              required
                // id="standard-adornment-password"
                onChange={handleChange("password")}
                error={touched.password && errors.password}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                name="password"
                placeholder="Enter Password"
                type={inputData.showPassword ? "text" : "password"}
                className="w-full"
                value={values.password}
                style={{ height: "35px" ,backgroundColor: 'blue'}}
                // label="Password"
                variant="outlined"
                // InputLabelProps={{
                //   shrink: true,
                //   style: {
                //     backgroundColor: "white",
                //     paddingRight: "8px",
                //     fontSize: 14,
                //   },
                // }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {inputData.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              /> */}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              md={11}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked style={{ transform: "scale(0.8)", paddingRight: 0, marginRight: 0 }} />}
                label={<Typography variant="caption" style={{ fontSize: '12px' }}>Remember me</Typography>}

              />
              <Box
                // className="underline underline-black cursor-Boxointer text-black font-medium"
                onClick={() => {
                  setIsResetPass(true);
                  localStorage.setItem('resetPass', true)
                  resetForm();
                }}
              >
                <Typography variant="caption" style={{ fontSize: '12px', fontWeight: 600, cursor: 'pointer' }}>
                  Forgot Password?
                </Typography>
              </Box>
            </Box>
            <Box className="w-full space-x-2 py-6" xs={11} md={11}>
              {/* <InputLabel className="mb-2">
                <Typography variant="caption" style={{ color: "black" }}>
                  Enter Captcha
                </Typography>
              </InputLabel> */}
              <Box
                className="w-full flex justify-between items-center space-x-1"
                xs={11}
                md={11}
              >
                <TextField
                  error={touched.captcha && errors.captcha}
                  helperText={touched.captcha && errors.captcha}
                  name="captcha"
                  className="inputRounded"
                  placeholder="Enter Captcha"
                  fullWidth
                  value={values.captcha}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  style={{ borderRadius: 10 }}
                  variant="outlined"
                  label="Captcha"
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      backgroundColor: "white",
                      paddingRight: "8px",
                      fontSize: 14,
                    },
                  }}
                />
                <Box
                  className="w-full flex justify-between items-center relative"
                  xs={5}
                  md={5}
                >
                  <InputLabel className="absolute -top-5">
                    <Typography variant="caption" style={{ color: "black" }}>
                      Captcha Code
                    </Typography>
                  </InputLabel>
                  <Box className="w-10/12 h-10 bg-gray-100 rounded-sm">
                    <img
                      className="w-full h-full object-content"
                      src={captchaId?.captcha}
                      alt="captcha"
                    />
                  </Box>
                  {/* <Button className=''> */}
                  <RefreshOutlined
                    className="hover:text-primary text-gray-500 w-10 h-10"
                    onClick={handleCaptchaId}
                  />
                  {/* </Button> */}
                </Box>
              </Box>
            </Box>

            {errors.submit && (
              <Box xs={11} md={11}>
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              </Box>
            )}
            <Box xs={11} md={12} className="w-full px-20 flex justify-center items-center">
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                // size="large"
                type="submit"
                variant="contained"
                className="w-4/12 "
              >
                Log In
              </Button>
            </Box>
            {/* <Box item sx={11} md={11} paddingTop={2}>
              <Box className="relative flex justify-center items-center space-x-1">
                <span className="absolute w-full right-8 md:right-10  capitalize text-xs md:text-md lg:text-lg text-black md:font-semibold">
                  Development by
                </span>
                <Box className="relative -right-10 md:-right-16 w-28 md:w-44 ">
                  <img
                    className="w-full h-full object-contain"
                    src={crisilPng}
                    alt="crisil-foundation"
                  />
                </Box>
              </Box>
            </Box> */}
            {/* </Box> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default LoginForm;
