const { SCHEME_CREATE_REQUEST, SCHEME_CREATE_SUCCESS, SCHEME_CREATE_FAIL, SCHEME_LIST_REQUEST, SCHEME_LIST_SUCCESS, SCHEME_LIST_FAIL, SCHEME_DELETE_REQUEST, SCHEME_UPDATE_SUCCESS, SCHEME_UPDATE_REQUEST, SCHEME_UPDATE_FAIL, SCHEME_DELETE_SUCCESS, SCHEME_DELETE_FAIL } = require("../Conistance/BlockConistance");

export const schemeCreateReducer = (state = {}, action) => {
    switch(action.type){
        case SCHEME_CREATE_REQUEST:
            return {loading: true};
        case SCHEME_CREATE_SUCCESS:
            return { loading: false, success: true, scheme: action.payload}
        case SCHEME_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const schemeListReducer = (state = [], action) => {
    switch(action.type){
        case SCHEME_LIST_REQUEST:
            return {loading: true}
        case SCHEME_LIST_SUCCESS:
            return { loading: false, schemes: action.payload}
        case SCHEME_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const schemeUpdateReducer = (state = {},action) => {
    switch(action.type){
        case SCHEME_UPDATE_REQUEST:
            return {loading: true};
        case SCHEME_UPDATE_SUCCESS:
            return {loading: false , scheme: action.payload}
        case SCHEME_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const schemeDeleteReducer = (success = '', action) => {
    switch(action.type){
        case SCHEME_DELETE_REQUEST:
            return {loading: true}
        case SCHEME_DELETE_SUCCESS:
            return {loading: false, success: true}
        case SCHEME_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}