const { DISTRICT_CREATE_REQUEST, DISTRICT_CREATE_SUCCESS, DISTRICT_CREATE_FAIL, DISTRICT_LIST_REQUEST, DISTRICT_LIST_SUCCESS, DISTRICT_LIST_FAIL, DISTRICT_DELETE_REQUEST, DISTRICT_UPDATE_SUCCESS, DISTRICT_UPDATE_REQUEST, DISTRICT_UPDATE_FAIL, DISTRICT_DELETE_SUCCESS, DISTRICT_DELETE_FAIL } = require("../Conistance/DistrictConistance");

export const districtCreateReducer = (state = {}, action) => {
    switch(action.type){
        case DISTRICT_CREATE_REQUEST:
            return {loading: true};
        case DISTRICT_CREATE_SUCCESS:
            return { loading: false, success: true, district: action.payload}
        case DISTRICT_CREATE_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
}

export const districtListReducer = (state = [], action) => {
    switch(action.type){
        case DISTRICT_LIST_REQUEST:
            return {loading: true}
        case DISTRICT_LIST_SUCCESS:
            return { loading: false, districts: action.payload}
        case DISTRICT_LIST_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}

export const districtUpdateReducer = (state = {},action) => {
    switch(action.type){
        case DISTRICT_UPDATE_REQUEST:
            return {loading: true};
        case DISTRICT_UPDATE_SUCCESS:
            return {loading: false , district: action.payload}
        case DISTRICT_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const districtDeleteReducer = (success = '', action) => {
    switch(action.type){
        case DISTRICT_DELETE_REQUEST:
            return {loading: true}
        case DISTRICT_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DISTRICT_DELETE_FAIL:
            return {loading: false, success: false};
        default:
            return success
    }
}