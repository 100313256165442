export const rgbnArchivedData = {
    "data": {
        "total_revenue": {
            "milestone": "Total Revenue (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 285000,
                    "target": 375000
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-28",
                    "mou_phase_id": 4,
                    "achieved": 276000,
                    "target": 375000
                }
            ]
        },
        "active_sakhi": {
            "milestone": "Active Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 473,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 235,
                    "target": 450
                }
            ]
        },
        "village_served": {
            "milestone": "Villages serviced",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 287,
                    "target": 261
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 138,
                    "target": 261
                }
            ]
        },
        "earning_sakhi": {
            "milestone": "Earning Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 165,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 201,
                    "target": 450
                }
            ]
        },
        "monthly_income": {
            "milestone": "Monthly average income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 781,
                    "target": 833
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1376,
                    "target": 833
                }
            ]
        },
        "monthly_median": {
            "milestone": "Monthly median income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 500,
                    "target": 366
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 500,
                    "target": 366
                }
            ]
        },
        "linkage": {
            "milestone": "Linkages and related services facilitated",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 5667,
                    "target": 11250
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1518,
                    "target": 11250
                }
            ]
        },
        "scheme_services": {
            "milestone": "Schemes & services offered under BoS",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 40,
                    "target": 40
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 55,
                    "target": 40
                }
            ]
        },
        "outreached": {
            "milestone": "SHGs outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 703,
                    "target": 1200
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 346,
                    "target": 1200
                }
            ]
        },
        "unique_beneficiary": {
            "milestone": "Unique beneficiary outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 10031,
                    "target": 15750
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1414,
                    "target": 15750
                }
            ]
        },
    }
};
export const sestaArchivedData = {
    "data": {
        "total_revenue": {
            "milestone": "Total Revenue (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 244000,
                    "target": 1306250
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-28",
                    "mou_phase_id": 4,
                    "achieved": 411000,
                    "target": 1306250
                }
            ]
        },
        "active_sakhi": {
            "milestone": "Active Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 365,
                    "target": 1050
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 415,
                    "target": 1050
                }
            ]
        },
        "village_served": {
            "milestone": "Villages serviced",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 412,
                    "target": 1050
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 266,
                    "target": 1050
                }
            ]
        },
        "earning_sakhi": {
            "milestone": "Earning Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 295,
                    "target": 1050
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 330,
                    "target": 1050
                }
            ]
        },
        "monthly_income": {
            "milestone": "Monthly average income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 850,
                    "target": 1244
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1245,
                    "target": 1244
                }
            ]
        },
        "monthly_median": {
            "milestone": "Monthly median income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 650,
                    "target": 800
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 600,
                    "target": 800
                }
            ]
        },
        "linkage": {
            "milestone": "Linkages and related services facilitated",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 5614,
                    "target": 51250
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 8566,
                    "target": 51250
                }
            ]
        },
        "scheme_services": {
            "milestone": "Schemes & services offered under BoS",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 35,
                    "target": 60
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 45,
                    "target": 60
                }
            ]
        },
        "outreached": {
            "milestone": "SHGs outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 746,
                    "target": 5400
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 665,
                    "target": 5400
                }
            ]
        },
        "unique_beneficiary": {
            "milestone": "Unique beneficiary outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 7236,
                    "target": 24125
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 6613,
                    "target": 24125
                }
            ]
        },
    }
};
export const gvtAlDsaArchivedData = {
    "data": {
        "total_revenue": {
            "milestone": "Total Revenue (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 6240,
                    "target": 266400
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-28",
                    "mou_phase_id": 4,
                    "achieved": 542000,
                    "target": 266400
                }
            ]
        },
        "active_sakhi": {
            "milestone": "Active Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 333,
                    "target": 333
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 333,
                    "target": 333
                }
            ]
        },
        "village_served": {
            "milestone": "Villages serviced",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 333,
                    "target": 333
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 333,
                    "target": 333
                }
            ]
        },
        "earning_sakhi": {
            "milestone": "Earning Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 333,
                    "target": 333
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 333,
                    "target": 333
                }
            ]
        },
        "monthly_income": {
            "milestone": "Monthly average income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 1857,
                    "target": 800
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1628,
                    "target": 800
                }
            ]
        },
        "monthly_median": {
            "milestone": "Monthly median income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 1170,
                    "target": 1000
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1120,
                    "target": 1000
                }
            ]
        },
        "linkage": {
            "milestone": "Linkages and related services facilitated",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 7364,
                    "target": 8325
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 6303,
                    "target": 8325
                }
            ]
        },
        "scheme_services": {
            "milestone": "Schemes & services offered under BoS",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 37,
                    "target": 38
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 38,
                    "target": 38
                }
            ]
        },
        "outreached": {
            "milestone": "SHGs outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 555,
                    "target": 800
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 540,
                    "target": 800
                }
            ]
        },
        "unique_beneficiary": {
            "milestone": "Unique beneficiary outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 10826,
                    "target": 6660
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 9370,
                    "target": 6660
                }
            ]
        },
    }
};
export const gvtBnsArchivedData = {
    "data": {
        "total_revenue": {
            "milestone": "Total Revenue (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 420000,
                    "target": 157500
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-28",
                    "mou_phase_id": 4,
                    "achieved": 280000,
                    "target": 157500
                }
            ]
        },
        "active_sakhi": {
            "milestone": "Active Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                }
            ]
        },
        "village_served": {
            "milestone": "Villages serviced",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                }
            ]
        },
        "earning_sakhi": {
            "milestone": "Earning Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 345,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 332,
                    "target": 450
                }
            ]
        },
        "monthly_income": {
            "milestone": "Monthly average income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 1214,
                    "target": 350
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 843,
                    "target": 350
                }
            ]
        },
        "monthly_median": {
            "milestone": "Monthly median income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 580,
                    "target": 175
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 175
                }
            ]
        },
        "linkage": {
            "milestone": "Linkages and related services facilitated",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 9296,
                    "target": 6750
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 6297,
                    "target": 6750
                }
            ]
        },
        "scheme_services": {
            "milestone": "Schemes & services offered under BoS",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 40,
                    "target": 40
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 40,
                    "target": 40
                }
            ]
        },
        "outreached": {
            "milestone": "SHGs outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 1840,
                    "target": 900
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1610,
                    "target": 900
                }
            ]
        },
        "unique_beneficiary": {
            "milestone": "Unique beneficiary outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 14899,
                    "target": 13500
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 13646,
                    "target": 13500
                }
            ]
        },
    }
};
export const rbksArchivedData = {
    "data": {
        "total_revenue": {
            "milestone": "Total Revenue (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 45700,
                    "target": 112500
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-28",
                    "mou_phase_id": 4,
                    "achieved": 306000,
                    "target": 112500
                }
            ]
        },
        "active_sakhi": {
            "milestone": "Active Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                }
            ]
        },
        "village_served": {
            "milestone": "Villages serviced",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 450,
                    "target": 450
                }
            ]
        },
        "earning_sakhi": {
            "milestone": "Earning Sakhi cadre",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 263,
                    "target": 450
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 285,
                    "target": 450
                }
            ]
        },
        "monthly_income": {
            "milestone": "Monthly average income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 174,
                    "target": 250
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1075,
                    "target": 250
                }
            ]
        },
        "monthly_median": {
            "milestone": "Monthly median income (in INR)",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 130,
                    "target": 200
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 742,
                    "target": 200
                }
            ]
        },
        "linkage": {
            "milestone": "Linkages and related services facilitated",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 789,
                    "target": 16650
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 8595,
                    "target": 16650
                }
            ]
        },
        "scheme_services": {
            "milestone": "Schemes & services offered under BoS",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 8,
                    "target": 40
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 11,
                    "target": 40
                }
            ]
        },
        "outreached": {
            "milestone": "SHGs outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 1320,
                    "target": 300
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 1570,
                    "target": 300
                }
            ]
        },
        "unique_beneficiary": {
            "milestone": "Unique beneficiary outreached",
            "milestone_id": 4,
            "phaseList": [
                {
                    "start_month": "2023-01-01",
                    "end_month": "2023-01-31",
                    "mou_phase_id": 4,
                    "achieved": 10850,
                    "target": 15000
                },
                {
                    "start_month": "2023-02-01",
                    "end_month": "2023-02-31",
                    "mou_phase_id": 4,
                    "achieved": 13630,
                    "target": 15000
                }
            ]
        },
    }
};
