import { colors, createTheme, MuiThemeProvider } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import React from 'react'


const theme = createTheme({
  overrides: {
    layoutPadding: "52px 39px",
    layoutPaddingTop: "52px",
    MuiButton: {
      root: {
        borderRadius: 30,
        width: "100%",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: `1px solid white`,
        },
      },
      input: {
        height: 20,
        borderRadius: "20px",
      },
    },
    MuiOutlinedInput: {
      marginDense: {
        padding: `0px !important`,
      },
    },
    MuiTextField: {
      root: {
        verticalAlign: "middle",
        fontSize: "12px",
        height: "40px",
        width: '100%',
        padding: 0,
        margin: 0,
        // width: 150,
        "& fieldset": {
          borderRadius: "10px",
          height: "45px",
          padding: 0,
          // border: "solid 2px #0070D8"
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "white",
      },
    },
  },
  palette: {
    action: {
      active: "#6b778c",
    },
    mode: "dark",
    background: {
      default: "#f4f5f7",
      paper: "#ffffff",
    },
    error: {
      contrastText: "#ffffff",
      main: "#f44336",
    },
    secondary: {
      main: colors.red[800],
    },
    primary: {
      contrastText: "#ffffff",
      main: "#D6002A",
    },
    success: {
      contrastText: "#ffffff",
      main: "#4caf50",
    },
    text: {
      primary: "#172b4d",
      secondary: "#6b778c",
    },
    warning: {
      contrastText: "#ffffff",
      main: "#ff9800",
    },
  },
});
function KeyboardDatePickerComponent({ value, name, disableDate, disabled, setIsOpen, require, isOpen, setFieldValue, fromData, setFormData, reset, handleBlur, errors, touched }) {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider minHeight={16} utils={DateFnsUtils}>
        <KeyboardDatePicker
          size='small'
          // required={require}
          error={touched[name] && errors[name]}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          name={name}
          disableFuture
          views={["year", "month", "date"]}
          onYearChange={(date) => date}
          shouldDisableDate={(date) =>
            disableDate
              ? date < disableDate
              : null
          }
          color="primary"
          disabled={disabled}
          className="inputRounded bg-white"
          autoOk={true}
          onClick={() => disabled ? null : setIsOpen(true)}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          okLabel=""
          variant="st"
          placeholder="-select date-"
          format="dd/MM/yy"
          inputProps={{
            readOnly: true,
            style: {
              // height: "12px",
              color: "black",
            },
          }}
          onChange={(date, value) => {
            // console.log('date..',date,value)
            setFieldValue(name, date)
            setFormData({ ...fromData, [name]: date, ...reset })
          }}
          value={
            value === ""
              ? null
              : value
          }
          inputVariant="outlined"
          style={{ borderRadius: 10 }}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export default KeyboardDatePickerComponent