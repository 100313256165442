import {
  Button,
  Divider,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import React, { useState } from "react";
import StickyHeadTable from "src/components/CommonComponents/Table";
import FilterComponent from "src/components/CommonComponents/FilterComponent";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { foCommonFilterData } from "src/utils/filterInputData";
import { useSelector } from "react-redux";
import { stateListAction } from "src/Redux/Actions/StateAction";
import LoadingBox from "src/components/LoadingBox";
import { userListAction } from "src/Redux/Actions/UserAction";
import { filterUserList } from "src/utils/halper";
import { fetchApiDataList, fetchWrapper } from "src/services/http_requests";
import { generateSerialNo } from "src/utils";
import { fetchFoSakhis } from "./fetchAllData";
import Drawer from "src/components/CommonComponents/Drawer";
import FoDetailsCRUDForm from "./FODetailsCRUDForm/FoDetailsCRUDForm";
import {
  EditOutlined,
  FilterList,
  VisibilityOutlined,
} from "@material-ui/icons";
import theme from "src/theme";
import DialogView from "src/components/CommonComponents/DialogView";

const columns = [
  { id: "sno", label: "S.No" },
  { id: "foName", label: "FO Name" },
  { id: "state", label: "State" },
  {
    id: "district",
    label: "District",
  },
  {
    id: "block",
    label: "Block",
  },
  {
    id: "centre",
    label: "Centre",
  },
  {
    id: "nosakhi",
    label: "No. of Sakhis",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];
const columnsDialog = [
  { id: "sno", label: "S.No" },
  { id: "sakhiName", label: "Sakhi Name" },
  {
    id: "village",
    label: "Village",
  },
];

function createData(
  sno,
  foName,
  state,
  district,
  block,
  centre,
  nosakhi,
  status,
  action
) {
  return { sno, foName, state, district, block, centre, nosakhi, status, action };
}
function createDataDialog(sno, sakhiName, village) {
  return { sno, sakhiName, village };
}

function TheFODetailsView() {
  const pageInfo = localStorage.getItem("pageInfo");
  const [open, setOpen] = useState(false);
  const [sakhiCountId, setSakhiCountId] = useState(null);
  const [foSakhis, setFoSakhis] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [crudAction, setCrudAction] = useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });
  const [foDetail, setFoDetail] = useState({});
  const [showFilter, setShowFilter] = useState(false);

  const [filterData, setFilterData] = useState({
    state: "",
    district: "",
    block: "",
    centre: "",
    foName: "",
  });
  const [total, setTotal] = useState(0);
  const [totalDialog, setTotalDialog] = useState(0);
  const [page, setPage] = React.useState(() => pageInfo ? parseInt(pageInfo) : 1);
  const [pageDialog, setPageDialog] = React.useState(1);
  const [foUsers, setFoUsers] = useState([]);
  const [foFilteredList, setFoFilteredList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { states } = useSelector((state) => state.statesList);
  const [districts, setDistricts] = useState(undefined);
  const [blocks, setBlocks] = useState(undefined);
  const [centres, setCentres] = useState(undefined);
  const { loading, users } = useSelector((state) => state.userList);
  const [centerNotAvalablePop, setCenterNotAvalablePop] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSakhiCountId(null);
    setPageDialog(1);
  };

  const filterInputData = foCommonFilterData.map((data) =>
    data.name === "state"
      ? { ...data, options: states?.data }
      : data.name === "district"
        ? { ...data, options: districts }
        : data.name === "block"
          ? { ...data, options: blocks }
          : data.name === "centre"
            ? { ...data, options: centres }
            : data.name === "foName"
              ? { ...data, options: foFilteredList }
              : data
  );

  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      !states && dispatch(stateListAction({ fetched: "All" }));
      fetchApiDataList("user", { role_id: 3, fetched: "All" }, setFoUsers);
    }

    return () => (mounted = false);
  }, [dispatch]);

  useEffect(() => {
    if (foUsers) setFoFilteredList(foUsers);
    else return;
  }, [foUsers]);

  useEffect(() => {
    if (users?.total) {
      setTotal(users?.total);
      localStorage.setItem("totalData", users?.total);
    } else {
      if (page == 1) {
        setTotal(users?.data?.length);
        localStorage.setItem("totalData", users?.data?.length);
      } else setTotal(localStorage.getItem("totalData"));
    }
  }, [users]);

  useEffect(() => {
    if (foSakhis?.total) {
      setTotalDialog(foSakhis?.total);
    } else {
      if (pageDialog == 1) setTotalDialog(0);
    }
  }, [foSakhis])

  useEffect(() => {
    // if (page > 1) {
    let mounted = true;
    if (mounted) {
      dispatch(
        userListAction({
          state_id: filterData.state,
          district_id: filterData?.district,
          block_id: filterData?.block,
          center_id: filterData?.centre,
          fo_id: filterData?.foName,
          page: page,
        })
      );
    }
    return () => (mounted = false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);


  useEffect(() => {
    window.scrollTo(0, 0);
    return () => fetchWrapper?.finishPendingRequests();
  }, []);

  useEffect(() => {
    if (filterData.state) {
      fetchApiDataList("district", { state_id: filterData?.state, fetched: "All" }, setDistricts);
      setFoFilteredList(filterUserList(foUsers, "state_id", filterData.state));
    } else {
      setDistricts(undefined);
      setFilterData({ ...filterData, district: "" });
      setFoFilteredList(foUsers);
    }
  }, [filterData.state]);

  useEffect(() => {
    if (filterData.district) {
      fetchApiDataList("block", { state_id: filterData?.state, district_id: filterData?.district, fetched: "All" }, setBlocks);
      setFoFilteredList(filterUserList(foUsers, "district_id", filterData.district));
    } else {
      setBlocks(undefined);
      setFilterData({ ...filterData, block: "" });
      filterData.state && setFoFilteredList(filterUserList(foUsers, "state_id", filterData.state));
    }
  }, [filterData.district]);

  useEffect(() => {
    if (filterData.block) {
      fetchApiDataList("centre", { state_id: filterData?.state, district_id: filterData?.district, block_id: filterData?.block, fetched: "All" }, setCentres)
      setFoFilteredList(filterUserList(foUsers, "block_id", filterData.block));
    } else {
      setCentres(undefined);
      setFilterData({ ...filterData, center: "" });
      filterData.district && setFoFilteredList(filterUserList(foUsers, "district_id", filterData.district));
    }
  }, [filterData.block]);

  useEffect(() => {
    if (filterData.centre) {
      setFoFilteredList(filterUserList(foUsers, "center_id", filterData.centre));
    } else {
      filterData.block && setFoFilteredList(filterUserList(foUsers, "block_id", filterData.block));
    }
  }, [filterData.centre]);


  const rows = users?.data?.map((item, i) => {
    return createData(
      generateSerialNo(i, page),
      item?.name,
      item?.state_name,
      item?.district_name,
      item?.block_name,
      item?.center_name,
      <button
        className="text-blue-500 cursor-pointer"
        onClick={() => {
          setSakhiCountId(item?.id);
          setOpen(true);
        }}
      >
        {item?.sakhi_count}
      </button>,
      parseInt(item?.is_active) === 0 ? 'Inactive' : "Active",
      <Box className="space-x-3">
        <button
          className="cursor-pointer"
          onClick={() => {
            setFoDetail(item);
            setCrudAction({
              create: false,
              edit: true,
              view: false,
              delete: false,
            });
            setOpenDrawer(true);
          }}
        >
          <EditOutlined color="primary" />
        </button>
        <button
          className="cursor-pointer"
          onClick={() => {
            setFoDetail(item);
            setCrudAction({
              create: false,
              edit: false,
              view: true,
              delete: false,
            });
            setOpenView(true);
          }}
        >
          <VisibilityOutlined color="primary" />
        </button>
      </Box>
    );
  });
  const rowsDialog = foSakhis?.data?.map((item, i) => {
    return createDataDialog(
      generateSerialNo(i, pageDialog),
      item?.name,
      item?.village_name
    );
  });

  const handleFilterSearch = () => {
    if (page == 1) {
      dispatch(
        userListAction({
          role_id: 3,
          state_id: filterData.state,
          district_id: filterData?.district,
          block_id: filterData?.block,
          center_id: filterData?.centre,
          fo_id: filterData?.foName,
          page: 1
        })
      );
    } else setPage(1);
  };
  const handleRestFilter = () => {
    setFilterData({
      state: "",
      district: "",
      block: "",
      centre: "",
      foName: "",
    });
    if (page == 1) dispatch(userListAction({ page: page }));
    else setPage(1);
  };

  useEffect(() => {
    if (sakhiCountId) {
      fetchFoSakhis(
        { role_id: 4, fo_id: sakhiCountId, page: pageDialog },
        setFoSakhis
      );
    }
  }, [sakhiCountId, pageDialog]);

  const refetchUserList = () => {
    handleRestFilter();
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (openDrawer == false && Object.keys(foDetail)?.length) setFoDetail({});
  }, [openDrawer]);


  return (
    <Box className="w-full min-h-screen bg-gray-100 pl-4 pr-5 md:px-4  py-24 sm:py-10">
      {loading ? (
        <LoadingBox />
      ) : (
        <Box width="100%" height="100%">
          <Box
            pb={2}
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography>
              <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
                FO Details
              </span>
            </Typography>
            <Box className="space-x-2 ">
              {/* <Button
                variant="contained"
                size="small"
                // color="primary"
                style={{
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                }}
              >
                <Replay color="primary" />
              </Button> */}
              <Button
                variant="contained"
                size="small"
                startIcon={<FilterList color="primary" />}
                onClick={() => setShowFilter(!showFilter)}
                // color="primary"
                style={{
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                }}
              >
                Filter
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setCrudAction({
                    create: true,
                    edit: false,
                    view: false,
                    delete: false,
                  });
                  setOpenDrawer(true);
                }}
              >
                {/* <Link to="/app/fodetails/create"> */}
                CREATE FO
                {/* </Link> */}
              </Button>
            </Box>
          </Box>
          <Divider style={{ maxWidth: "100%" }} />
          {showFilter ? (
            <FilterComponent
              commonFilterData={filterInputData}
              handleRestFilter={handleRestFilter}
              filterData={filterData}
              setFilterData={setFilterData}
              handleFilterSearch={handleFilterSearch}
            />
          ) : null}
          <StickyHeadTable
            columns={columns}
            rows={rows}
            loading={loading}
            total={total}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Box>
      )}
      <Dialog style={{ maxWidth: "100%" }} open={open} onClose={handleClose}>
        <DialogTitle>
          {users?.data.find((val) => val.id === sakhiCountId)?.name}
        </DialogTitle>
        <DialogContent dividers>
          <StickyHeadTable
            columns={columnsDialog}
            rows={rowsDialog}
            loading={loading}
            total={totalDialog}
            page={pageDialog}
            setPage={setPageDialog}
            rowsPerPage={10}
            // setRowsPerPage={setRowsPerPage}
            isPopupTable={true}
          />
        </DialogContent>
      </Dialog>
      <Drawer
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        title={
          <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
            {crudAction.create && "Create"} {crudAction.edit && "Edit"}{" "}
            {crudAction.view && "View"} FO
          </span>
        }
      >
        <FoDetailsCRUDForm
          userDetails={foDetail}
          crudAction={crudAction}
          refetchUserList={refetchUserList}
          isFormOpen={openDrawer}
          centerNotAvalablePop={centerNotAvalablePop}
          setCenterNotAvalablePop={setCenterNotAvalablePop}
        />
      </Drawer>
      <DialogView
        openView={openView}
        handleCloseView={() => {
          setFoDetail({});
          setOpenView(false);
        }}
        title={
          <span className="capitalize font-semibold  text-md md:text-lg lg:text-xl text-black">
            {crudAction.create && "Create"} {crudAction.edit && "Edit"}{" "}
            {crudAction.view && "View"} FO
          </span>
        }
      >
        <FoDetailsCRUDForm
          userDetails={foDetail}
          crudAction={crudAction}
          refetchUserList={refetchUserList}
          centerNotAvalablePop={centerNotAvalablePop}
          setCenterNotAvalablePop={setCenterNotAvalablePop}
        />
      </DialogView>


      <DialogView
        openView={centerNotAvalablePop}
        handleCloseView={() => {
          setCenterNotAvalablePop(false);
        }}
        title={""}
      >
        <div className="flex flex-col items-center justify-center my-4">
          <h1 className="text-lg" >Center is not available for selected Block.</h1>
          <h1 className="text-lg mb-4">Please create a center for this block</h1>
          <Button
            variant="contained"
            size="medium"
            style={{ color: 'white', background: "#D6002A", height: '34px', }}
            className="h-9 w-[50%]"
            // style={{paddingLeft:27,paddingRight:27}}
            onClick={() => {
              setCenterNotAvalablePop(false);
            }}
          >Ok</Button>
        </div>
      </DialogView>

    </Box>
  );
}

export default TheFODetailsView;
