import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Checkbox, createTheme, Dialog, Grid, InputLabel, MuiThemeProvider, TextField, Typography, colors, RadioGroup, FormControl, FormControlLabel, Radio } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank, CloudUpload, Label, Visibility } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { values } from 'lodash'
import { DropzoneDialog } from 'material-ui-dropzone'
import moment from "moment";
import React from 'react';
import { useState } from 'react'
import { FormLabel } from "react-bootstrap";
import { fetchWrapper } from 'src/services/http_requests'

const theme = createTheme({
    overrides: {
        MuiDropzoneArea: {
            root: {
                scrollY: "hidden",
            },
            icon: {
                color: "#D6002A",
            },
        },
        MuiDropzonePreviewList: {
            removeButton: {
                color: "#D6002A",
                top: "30px",
            },
            root: {
                width: "100%",
                height: "180px",
                padding: "0px",
                overflow: "hidden",
                margin: "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            imageContainer: {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
            },
        },
        MuiDropzoneSnackbar: {
            closeButton: {
                color: "grey",
            },
        },
    },
});

const datePickerTheme = createTheme({
    overrides: {
        layoutPadding: "52px 39px",
        MuiButton: {
            root: {
                borderRadius: 15,
                width: "100%",
            },
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottom: `1px solid white`,
                },
            },
            input: {
                height: 20,
                borderRadius: "10px",

            },
        },
        MuiOutlinedInput: {
            marginDense: {
                padding: `0px !important`,
            },
        },
        MuiTextField: {
            root: {
                verticalAlign: "middle",
                fontSize: "12px",
                // height: "40px",
                width: '100%',
                padding: 0,
                margin: 0,
                // width: 150,
                "& fieldset": {
                    borderRadius: "4px",
                    // height: "45px",
                    // padding: 0,
                    // border: "solid 2px #0070D8"
                },
            },
        },
        // MuiFormLabel: {
        //     root: {
        //         color: "white",
        //     },
        // },
    },
    palette: {
        action: {
            active: "#6b778c",
        },
        mode: "dark",
        background: {
            default: "#f4f5f7",
            paper: "#ffffff",
        },
        error: {
            contrastText: "#ffffff",
            main: "#f44336",
        },
        secondary: {
            main: colors.red[800],
        },
        primary: {
            contrastText: "#ffffff",
            main: "#D6002A",
        },
        tertiory: {
            contrastText: "#E11529",
            main: "#FFDADD"

        },
        success: {
            contrastText: "#ffffff",
            main: "#4caf50",
        },
        text: {
            primary: "#172b4d",
            secondary: "#6b778c",
        },
        warning: {
            contrastText: "#ffffff",
            main: "#ff9800",
        },
    },
});

const CustomInputs = ({
    inputType, label, name,
    helperText, placeholder,
    value, disabled, error,
    handleBlur, onChange,
    required, optionArray,
    multiple, acceptedFiles,
    onClick, crudAction,
    id, min, initialValues, max
}) => {
    const [open, setOpen] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);

    const handleSaveData = async (e, onChange) => {
        try {
            let data = await fetchWrapper.uploadFile(
                e,
                e[0].name.split(".").slice(-1)[0]
            );
            if (data?.success) {
                setOpen(false);
                onChange("file", data?.url);
            }
        } catch (error) {
            console.log("e error", error);
        }
    };

    const optionPrefilledValue = (valueNumber) => {
        let k = multiple ? [] : null;
        if (valueNumber?.length > 0 || typeof valueNumber == 'number') {
            if (multiple) {
                valueNumber = typeof valueNumber == 'number' ? valueNumber.toString() : valueNumber;
                if (valueNumber?.includes(",") || Array.isArray(valueNumber)) {
                    let valueArray = Array.isArray(valueNumber) ? valueNumber : valueNumber.split(",");
                    let array = [];
                    for (let i = 0; i < valueArray?.length; i++) {
                        let k = optionArray?.find(elem => elem?.id && valueArray[i] == elem.id)
                        array.push(k);
                    }
                    k = array?.filter(Boolean);
                } else k = Array.isArray(optionArray) ? optionArray?.filter(elem => elem?.id && value == elem.id)?.filter(Boolean) : [valueNumber];
            } else k = optionArray?.find(
                (val) => val?.id ? val.id == valueNumber : val == valueNumber
            ) || null;
        }
        return k;
    };

    return (
        inputType === "autoComplete" ? (
            <Grid item xs={12} md={crudAction?.view && Object.keys(initialValues)?.length > 4 ? 6 : 12} key={id}>
                <Autocomplete
                    disableCloseOnSelect={multiple}
                    noOptionsText='No Data Found'
                    size='small'
                    id={multiple ? "checkboxes-tags-demo" : "flat-demo"}
                    multiple={multiple}
                    onBlur={onClick}
                    name={name}
                    value={optionPrefilledValue(value)}
                    options={Array.isArray(optionArray) ? optionArray : []}
                    onChange={(event, value) => {
                        let selectedValues = null;
                        if (multiple) {
                            selectedValues = value?.map(item => item?.id);
                        } else selectedValues = value?.id;
                        onChange(name, selectedValues);
                    }}
                    className="autoComplete"
                    disabled={disabled}
                    getOptionLabel={(option) => option?.name ? option?.name : option}
                    required={required}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    backgroundColor: '#fff',
                                    paddingRight: '8px',
                                    fontSize: 14
                                }
                            }}
                            // InputProps={{ ...params.InputProps, style: { fontSize: 15,textOverflow: 'ellipsis',whiteSpace: "nowrap"} }}
                            error={error}
                            helperText={helperText}
                            variant="outlined"
                            // placeholder={disabled ? "" : placeholder}
                            placeholder={crudAction.view || crudAction.delete ? "" : placeholder}
                            className="autoComplete"

                        />
                    )}
                    renderOption={(option, { selected }) => (
                        <div className='flex items-center text-ellipsis overflow-hidden'>
                            {multiple && <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                                style={{ marginRight: 2 }}
                                checked={selected}
                            />}
                            <Typography variant="subtitle2" style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }}>
                                {option?.name ? option?.name : option}
                            </Typography>
                        </div>
                    )}
                />
            </Grid>
        ) : inputType === 'file' ? (<>
            {crudAction?.view || crudAction?.delete || value ? <Grid item xs={12} md={12} key={id}>
                <InputLabel className="mb-2">
                    <Typography variant="caption" style={{ color: "black" }}>
                        File<span className="text-red-500">*</span>
                    </Typography>
                </InputLabel>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenPreview(true)}
                    endIcon={<Visibility />}
                >
                    Review
                </Button>
            </Grid> : null}
            {crudAction?.view || crudAction?.delete ? null :
                (<Grid item xs={12} md={12} className="min-h-40 py-8">
                    <InputLabel className="mb-2">
                        <Typography variant="caption" style={{ color: "black" }}>
                            {label}{required && <span className="text-red-500">*</span>}
                        </Typography>
                    </InputLabel>
                    <MuiThemeProvider theme={theme}>
                        <DropzoneDialog
                            open={open}
                            onSave={(e) => handleSaveData(e, onChange)}
                            acceptedFiles={acceptedFiles}
                            showAlerts={true}
                            showPreviews={true}
                            maxFileSize={5000000}
                            onClose={() => setOpen(false)}
                            Icon={CloudUpload}
                            filesLimit={1}
                            showFileNamesInPreview={false}
                            alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                        />
                    </MuiThemeProvider>
                    <Button
                        variant="contained"
                        component="label"
                        className={`w-full h-32 py-8 border border-red-500 ${disabled ? "cursor-not-allowed" : ""}`}
                        style={{
                            border: "3px dashed #D6002A",
                            backgroundColor: "white",
                        }}
                        onClick={() => disabled == false && setOpen(true)}
                    >
                        <Box className="flex flex-col justify-center items-center text-primary">
                            <CloudUpload className="w-10 h-10" />
                            <p>{label}</p>
                            {/* <p>Drag & Drop file</p>  */}
                        </Box>
                    </Button>
                    {error && <p className='text-[#f44336] font-thin mt-[3px] mx-[10px] text-[11px]'>{helperText}</p>}
                </Grid>)}
            <Dialog
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                // className='w-full '
                fullWidth={true}
                maxWidth={"md"}
            >
                {["jpg", "jpeg", "png"].includes(
                    value?.split(".").slice(-1)[0]
                ) ? (
                    <img
                        className="w-full object-content "
                        src={value}
                        alt="attechedFile"
                    />
                ) : (
                    <iframe
                        src={value}
                        className="w-full"
                        height={1000}
                        title={value}
                    ></iframe>
                )}
            </Dialog>
        </>
        ) : ["date", "year", "month"].includes(inputType) ? (
            <Grid item xs={12} md={crudAction?.view && Object.keys(initialValues || {})?.length > 4 ? 6 : 12} key={id}>
                <MuiThemeProvider theme={datePickerTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            size="small"
                            error={error}
                            label={label}
                            helperText={helperText}
                            onBlur={handleBlur}
                            name={name}
                            shouldDisableDate={(date) => min ? date < min : null}
                            minDate={min}
                            maxDate={max}
                            disabled={disabled}
                            views={inputType === 'year' ? ['year'] : inputType === "month" ? ['year', 'month'] : ["year", "month", "date"]}
                            color="primary"
                            // required={required}
                            variant='outlined'
                            id="outlined-basic"
                            placeholder={placeholder}
                            format={inputType === 'year' ? 'yyyy' : inputType === "month" ? "dd/MM/yyyy" : 'dd/MM/yy'}

                            onChange={(date) => {
                                let selectedDate = "";
                                if (inputType === "month") {
                                    if (name.includes("end_month")) {
                                        selectedDate = moment(date).endOf("month");
                                    } else if (name.includes("start_month")) selectedDate = moment(date).startOf("month");
                                }
                                let dataValue = inputType === 'month' ? selectedDate : date;
                                onChange(name, dataValue);
                            }}
                            value={value || null}
                            inputVariant="outlined"
                            style={{ background: "#fff", borderRadius: 4 }}
                        />
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </Grid>
        ) : inputType === "radio" ? (
            <Grid item xs={12} md={12} key={id}>
                <FormControl className="px-8">
                    <FormLabel id="demo-row-radio-buttons-group-label" className="text-[12px]">{label}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                    >
                        {optionArray?.map((elem, index) => <FormControlLabel key={index} value={elem?.value}
                            control={<Radio sx={{
                                color: "#D6002A",
                                '&.Mui-checked': {
                                    color: "#d6002b9a",
                                },
                            }}
                                disabled={disabled}
                                size="small"
                                onClick={(e) => {
                                    onChange(name, e.target.value === value ? "" : e.target.value)
                                }}
                            />} label={elem?.label} />)}
                    </RadioGroup>
                    {error && <p className='text-[#f44336] font-thin mt-[3px] mx-[10px] text-[11px]'>{helperText}</p>}
                </FormControl>
            </Grid>
        ) : (<Grid item xs={12} md={crudAction?.view && Object.keys(initialValues)?.length > 4 ? 6 : 12} key={id}>
            <TextField
                key={name}
                type={inputType}
                id={label}
                error={error}
                helperText={helperText}
                name={name}
                className="inputRounded"
                label={label}
                placeholder={placeholder}
                fullWidth
                value={value || ""}
                onBlur={handleBlur}
                onChange={onChange}
                disabled={disabled}
                style={{ borderRadius: 10 }}
                variant="outlined"
                inputProps={["tel", "number"].includes(inputType) ? { inputMode: 'numeric', pattern: '[0-9]*' } : {}}
                onWheel={(e) => e.target.blur()}
                InputLabelProps={{
                    shrink: true,
                    style: {
                        backgroundColor: 'white',
                        paddingRight: '8px',
                        fontSize: 14
                    }
                }}
            />
        </Grid>
        )
    )
}

export default CustomInputs