import { Box, Button, colors, createTheme, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchApiDataList } from 'src/services/http_requests';
import { filterUserList } from 'src/utils/halper';
import CustomInputs from './CustomInputs';
import { toast } from 'react-toastify';
import moment from "moment";
import axios from 'axios';
import { getToken } from 'src/utils/sessionStorage';

const generateYearList = () => {
    const currentYear = new Date().getFullYear();
    const yearList = [];
    for (let i = 0; i < 20; i++) {
        yearList.push({ name: (currentYear - i).toString(), id: currentYear - i });
    }
    return yearList;
}


const theme = createTheme({
    overrides: {
        layoutPadding: "52px 39px",
        layoutPaddingTop: "52px",
        MuiButton: {
            root: {
                borderRadius: 2,
                width: "100%",
            },
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottom: `1px solid white`,
                },
            },
            input: {
                height: 14,
                borderRadius: "2px",
            },
        },
        MuiOutlinedInput: {
            marginDense: {
                padding: `0px !important`,
            },
        },
        MuiTextField: {
            root: {
                verticalAlign: "middle",
                fontSize: "12px",
                height: "36px",
                padding: 0,
                margin: 0,
                // width: 150,
                "& fieldset": {
                    borderRadius: "4px",
                    height: "41px",
                    padding: 0,
                    // border: "solid 2px #0070D8"
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: "white",
            },
        },
    },
    palette: {
        action: {
            active: "#6b778c",
        },
        mode: "dark",
        background: {
            default: "#f4f5f7",
            paper: "#ffffff",
        },
        error: {
            contrastText: "#ffffff",
            main: "#f44336",
        },
        secondary: {
            main: colors.red[800],
        },
        primary: {
            contrastText: "#ffffff",
            main: "#D6002A",
        },
        success: {
            contrastText: "#ffffff",
            main: "#4caf50",
        },
        text: {
            primary: "#172b4d",
            secondary: "#6b778c",
        },
        warning: {
            contrastText: "#ffffff",
            main: "#ff9800",
        },
    },
});

const CommonFilter2 = ({
    // commonFilterData,
    filterData,
    setFilterData,
    handleFilterSearch,
    handleRestFilter, filtersToDisplay,
    showFilter
}) => {

    const loginInfo = JSON.parse(localStorage.getItem("loginInfo")) || {};
    const loginUserRole = loginInfo?.data?.role_id;

    const { states } = useSelector(state => state.statesList);
    const [districts, setDistricts] = useState(null);
    const [blocks, setBlock] = useState(null);
    const [villages, setVillages] = useState(null);
    const [centers, setCenters] = useState(null);
    const { ngos } = useSelector((state) => state.ngoList);

    const [ngoList, setNgoList] = useState([]);

    const [mouPoNumber, setMouPoNumber] = useState(null);
    const [budgetPoNumber, setBudgetPoNumber] = useState(null);

    const [foUsers, setFoUsers] = useState(null);
    const [foFilteredData, setFoFilteredData] = useState(foUsers);

    const [sakhiUsers, setSakhiUsers] = useState(null);
    const [sakhiFilteredData, setSakhiFilteredData] = useState(sakhiUsers);

    const [purposeMappers, setPurposeMappers] = useState(null);
    const [purposeList, setPurposes] = useState(null);

    const [users, setUsers] = useState([]);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    const [show, setShow] = useState(false);


    useEffect(() => {
        (parseInt(loginUserRole) === 2 && filtersToDisplay.includes("fo_id")) && fetchApiDataList("user", { role_id: 3, district_id: filterData?.district_id, ngo_id: filterData?.ngo_id, fetched: "All" }, setFoUsers);
        setTimeout(() => setShow(true), 50);
    }, []);

    useEffect(() => {
        (filtersToDisplay.includes("purpose_id") && !purposeList?.length) && fetchApiDataList("purpose", {
            state_id: filterData?.state_id || "",
            purpose_id: filterData?.purpose_id || "",
            fetched: "All",
        }, setPurposes);
    }, [filtersToDisplay]);

    useEffect(() => {
        if (ngos?.data) setNgoList(ngos?.data);
        else return;
    }, [ngos]);

    useEffect(() => {
        if (foUsers) setFoFilteredData(foUsers);
        else setFoFilteredData(null);
    }, [foUsers]);
    useEffect(() => {
        if (sakhiUsers) setSakhiFilteredData(sakhiUsers);
        else setSakhiFilteredData(null);
    }, [sakhiUsers]);

    const getMouPoNumberList = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/mou/getListPONumber?ngo_id=${filterData?.ngo_id}`,
                {
                    headers: {
                        "x-access-token": getToken(),
                    }
                }
            );
            const newData = data?.data?.map(elem => {
                return elem = { id: elem?.id, name: elem?.po_number };
            });
            setMouPoNumber(newData);
        }
        catch (error) {
            toast.error(error);
            throw (error);
        }
    };
    const getBudgetPoNumberList = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/budget_lineitem/poNumberList?ngo_id=${filterData?.ngo_id}`,
                {
                    headers: {
                        "x-access-token": getToken(),
                    }
                }
            );
            const newData = data?.data?.map(elem => {
                return elem = { id: elem?.po_number, name: elem?.po_number };
            });
            setBudgetPoNumber(newData);
        }
        catch (error) {
            toast.error(error);
            throw (error);
        }
    };


    const filterFields = [
        ...(filtersToDisplay?.includes("state_id")) ?
            [{
                lable: "State",
                multiple: false,
                name: "state_id",
                options: states?.data,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("district_id")) ?
            [{
                lable: "District",
                multiple: /(report|dashboard)/.test(window.location.pathname),
                name: "district_id",
                options: districts,
                placeholder: "Enter",
                type: "autoComplete"
            },] : [],
        ...(filtersToDisplay?.includes("block_id")) ?
            [{
                lable: "Block",
                multiple: /(report|dashboard)/.test(window.location.pathname),
                name: "block_id",
                options: blocks,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("village_id")) ?
            [{
                lable: "Village",
                multiple: /dashboard/.test(window.location.pathname),
                name: "village_id",
                options: villages,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("center_id")) ?
            [{
                lable: "Center",
                multiple: false,
                name: "center_id",
                options: centers,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("purpose_id")) ?
            [{
                lable: "Purpose",
                multiple: /dashboard/.test(window.location.pathname),
                name: "purpose_id",
                options: purposeList,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("scheme_id")) ?
            [{
                lable: "Scheme",
                multiple: false,
                name: "scheme_id",
                options: purposeMappers,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("status_id")) ?
            [{
                lable: "Status",
                multiple: false,
                name: "status_id",
                options: [
                    ...(parseInt(loginUserRole) === 2) ?
                        [
                            { id: "1,1", name: "Pending by FO" },
                            { id: "2,1", name: "Pending", },
                            { id: "2,2", name: "Approved", },
                            { id: "2,3", name: "Rejected", }
                        ] :
                        [
                            { id: "1,1", name: "Pending by FO", },
                            { id: "3,1", name: "Rejected by FO", },
                            { id: "2,1", name: "Pending by NGO", },
                            { id: "2,2", name: "Approved by NGO", },
                            { id: "2,3", name: "Rejected by NGO", }
                        ]

                ],
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("from")) ?
            [{
                lable: "Meeting Date From",
                multiple: false,
                name: "from",
                placeholder: "Select Date",
                type: "date"
            }] : [],
        ...(filtersToDisplay?.includes("to")) ?
            [{
                lable: "Meeting Date To",
                multiple: false,
                name: "to",
                placeholder: "Select Date",
                type: "date",
                min: minDate,
                max: maxDate
            }] : [],
        ...(filtersToDisplay?.includes("year")) ? [{
            lable: "Sakhi Batch Year",
            multiple: true,
            name: "year",
            placeholder: "Select Sakhi Batch Year",
            type: "autoComplete",
            options: generateYearList(),
        }] : [],
        ...(filtersToDisplay?.includes("ngo_id")) ?
            [{
                lable: "NGO Name",
                multiple: false,
                name: "ngo_id",
                options: ngoList,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("fo_id")) ?
            [{
                lable: "FO Name",
                multiple: false,
                name: "fo_id",
                options: foFilteredData,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("sakhi_id")) ?
            [{
                lable: "Sakhi Name",
                multiple: false,
                name: "sakhi_id",
                options: sakhiFilteredData,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("name")) ?
            [{
                lable: "User Name",
                multiple: false,
                name: "name",
                options: users,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("mou_po_number")) ?
            [{
                lable: "PO Number",
                multiple: false,
                name: "po_number",
                options: mouPoNumber,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
        ...(filtersToDisplay?.includes("budget_po_number")) ?
            [{
                lable: "PO Number",
                multiple: false,
                name: "po_number",
                options: budgetPoNumber,
                placeholder: "Enter",
                type: "autoComplete"
            }] : [],
    ];

    // api calls based on selected filters

    // actions on state change
    useEffect(() => {
        if (filterData.state_id) {
            filtersToDisplay.includes("district_id") && fetchApiDataList("district", { state_id: filterData?.state_id, fetched: "All" }, setDistricts);
            if (filtersToDisplay.includes("ngo_id")) {
                setNgoList(filterUserList(ngos?.data, "state_id", filterData.state_id));
                setFilterData({ ...filterData, ngo_id: "" });
            }
        } else {
            setFilterData({
                ...filterData,
                block_id: "",
                district_id: "",
                village_id: "",
                fo_id: "",
                ngo_id: ""
            });
            filtersToDisplay.includes("district_id") && setDistricts(null);
            filtersToDisplay.includes("ngo_id") && setNgoList(ngos?.data);
            ((filtersToDisplay.includes("fo_id") && filtersToDisplay.includes("ngo_id") == false)) && fetchApiDataList("user", { role_id: 3, fetched: "All" }, setFoUsers);

        }
    }, [filterData.state_id]);

    // actions on district change
    useEffect(() => {
        if ((Array.isArray(filterData.district_id) && filterData.district_id?.length)
            || (!Array.isArray(filterData.district_id) && filterData.district_id)) {
            filtersToDisplay.includes("block_id") && fetchApiDataList("block", { state_id: filterData?.state_id, district_id: filterData?.district_id, fetched: "All", }, setBlock);
            filtersToDisplay.includes("fo_id") && fetchApiDataList("user", { role_id: 3, district_id: filterData?.district_id, ngo_id: filterData?.ngo_id, fetched: "All" }, setFoUsers);
            if (filtersToDisplay.includes("ngo_id")) {
                setNgoList(filterUserList(ngos?.data, "district_id", filterData.district_id));
                setFilterData({ ...filterData, ngo_id: "" });
            }
            (filtersToDisplay.includes("block_id") || filtersToDisplay.includes("fo_id")) && setFilterData({
                ...filterData,
                block_id: "",
                village_id: "",
                fo_id: ""
            });
        } else {
            if (filtersToDisplay.includes("ngo_id") && filterData?.state_id) {
                setNgoList(filterUserList(ngos?.data, "state_id", filterData.state_id));
                setFilterData({ ...filterData, ngo_id: "" });
            }
            (filtersToDisplay.includes("block_id") || filtersToDisplay.includes("fo_id")) && setFilterData({
                ...filterData,
                block_id: "",
                village_id: "",
                fo_id: ""
            });
            (filtersToDisplay.includes("fo_id") && loginUserRole == 1) && (filterData.ngo_id ? fetchApiDataList("user", { role_id: 3, ngo_id: filterData?.ngo_id, fetched: "All" }, setFoUsers) : setFoUsers(null));
            filtersToDisplay.includes("block_id") && setBlock(null);
        }
    }, [filterData.district_id]);

    // actions on block change
    useEffect(() => {
        if ((Array.isArray(filterData.block_id) && filterData.block_id?.length)
            || (!Array.isArray(filterData.block_id) && filterData.block_id)) {
            filtersToDisplay.includes("village_id") && fetchApiDataList("village", {
                state_id: filterData?.state_id,
                district_id: filterData?.district_id,
                block_id: filterData?.block_id,
                fetched: "All",
            }, setVillages);
            filtersToDisplay.includes("center_id") && fetchApiDataList("centre", {
                state_id: filterData?.state_id,
                district_id: filterData?.district_id,
                block_id: filterData?.block_id,
                fetched: "All",
            }, setCenters);

            filtersToDisplay.includes("fo_id") && setFoFilteredData(filterUserList(foUsers, "block_id", filterData.block_id));
            filtersToDisplay.includes("sakhi_id") && filterData.fo_id && (setSakhiFilteredData(filterUserList(sakhiUsers, "block_id", filterData.block_id)));
        } else {
            (filtersToDisplay.includes("center_id") || filtersToDisplay.includes("village_id")) && setFilterData({
                ...filterData,
                village_id: "",
                centre_id: "",
                fo_id: ""
            });
            filtersToDisplay.includes("village_id") && setVillages(null);
            filtersToDisplay.includes("center_id") && setCenters(null);
            filtersToDisplay.includes("fo_id") && setFoFilteredData(foUsers);
            filtersToDisplay.includes("sakhi_id") && (filterData.fo_id ? setSakhiFilteredData(sakhiUsers) : setSakhiFilteredData(null));
        }
    }, [filterData.block_id]);

    // action on village change
    useEffect(() => {
        if (filterData.village_id) {
            // filtersToDisplay.includes("fo_id") && setFoFilteredData(filterUserList(foUsers, "block_id", filterData.block_id));
            filtersToDisplay.includes("sakhi_id") && filterData.fo_id && (setSakhiFilteredData(filterUserList(sakhiFilteredData, "village_id", filterData.village_id)));
        } else {
            // filtersToDisplay.includes("fo_id") && (filterData.block_id ?
            //     setFoFilteredData(filterUserList(foUsers, "block_id", filterData.block_id)) :
            //     filterData.district_id ?
            //         setFoFilteredData(filterUserList(foUsers, "district_id", filterData.district_id))
            //         : setFoFilteredData(null));
            (filtersToDisplay.includes("sakhi_id") && filterData.fo_id && (
                filterData.block_id ? (setSakhiFilteredData(filterUserList(sakhiUsers, "block_id", filterData.block_id))) : setSakhiFilteredData(sakhiUsers)
            ));
        }
    }, [filterData.village_id])

    // actions on ngo change
    useEffect(() => {
        if (filterData.ngo_id) {
            filtersToDisplay.includes("mou_po_number") && setMouPoNumber(null);
            filtersToDisplay.includes("budget_po_number") && setBudgetPoNumber(null);
            filtersToDisplay.includes("mou_pobudgetmber") && setBudgetPoNumber(null);
            filtersToDisplay.includes("fo_id") && fetchApiDataList("user", { role_id: 3, ngo_id: filterData.ngo_id, district_id: filterData.district_id, block_id: filterData.block_id, village_id: filterData.village_id, fetched: "All" }, setFoUsers);
            filtersToDisplay.includes("mou_po_number") && getMouPoNumberList();
            filtersToDisplay.includes("budget_po_number") && getBudgetPoNumberList();
            setFilterData({ ...filterData, fo_id: "" });
        } else {
            setFilterData({ ...filterData, fo_id: "", po_number: "" });
            (filtersToDisplay.includes("fo_id")) && (filterData.district_id ?
                fetchApiDataList("user", { role_id: 3, district_id: filterData.district_id, block_id: filterData.block_id, village_id: filterData.village_id, fetched: "All" }, setFoUsers)
                : filtersToDisplay.includes("ngo_id") ? setFoFilteredData(null)
                    : fetchApiDataList("user", { role_id: 3, fetched: "All" }, setFoUsers));
            filtersToDisplay.includes("mou_po_number") && setMouPoNumber(null);
            filtersToDisplay.includes("budget_po_number") && setBudgetPoNumber(null);
            filtersToDisplay.includes("mou_pobudgetmber") && setBudgetPoNumber(null);
        }
    }, [filterData.ngo_id]);


    // actions on fo change
    useEffect(() => {
        if (filterData.fo_id) {
            filtersToDisplay.includes("sakhi_id") && fetchApiDataList("user", { role_id: 4, fo_id: filterData?.fo_id, district_id: filterData.district_id, block_id: filterData.block_id, village_id: filterData.village_id, ngo_id: filterData.ngo_id, fetched: "All" }, setSakhiUsers);
        } else {
            filtersToDisplay.includes("sakhi_id") && setFilterData({ ...filterData, sakhi_id: "" });
            filtersToDisplay.includes("sakhi_id") && setSakhiFilteredData(null);
        }
    }, [filterData.fo_id]);

    // actions on purpose change
    useEffect(() => {
        if (filterData.purpose_id) {
            filtersToDisplay.includes("scheme_id") && fetchApiDataList("purpose_mapper", {
                state_id: filterData?.state_id,
                purpose_id: filterData?.purpose_id,
                fetched: "All",
            }, setPurposeMappers);
        } else {
            filtersToDisplay.includes("scheme_id") && setFilterData({ ...filterData, scheme_id: "" });
            filtersToDisplay.includes("scheme_id") && setPurposeMappers(null);
        }
    }, [filterData.purpose_id]);

    useEffect(() => {
        if (filterData.from) {
            setMinDate(filterData.from);
            if (/reports/.test(window.location.pathname)) {
                // if (moment(filterData.from).from("YYYY") !== moment(filterData.year)) setFilterData({ ...filterData, year: "" });
                const selectedYear = moment(filterData.from).year();
                setMaxDate(new Date(selectedYear, "11", "31"));
            } else setMaxDate(undefined);
        } else {
            setMaxDate(undefined);
            setMinDate(undefined);
        }
    }, [filterData.from]);

    // useEffect(() => {
    //     if (filterData.year && /reports/.test(window.location.pathname)) {
    //         if (filterData.from && moment(filterData.from).format("YYYY") !== moment(filterData.year).format("YYYY")) {
    //             setFilterData({ ...filterData, year: "" });
    //             toast.error("Sakhi Batch Year and from date should be of same year");
    //         }
    //     }
    // }, [filterData.year]);

    return (
        <Box className={`w-full py-5 flex flex-col justify-center items-center `} >
            <Grid spacing={3} container className={`z-0 transition duration-500 ease-in-out transform  ${show ? "translate-y-10/12 opacity-100" : "-translate-y-full opacity-0"}`} >
                {filterFields?.map((elem, i) => (
                    <Grid key={i} item xs={6} sm={4} md={2}>
                        <InputLabel
                            id="demo-simple-select-label"
                            style={{ marginBottom: 1, marginLeft: "4px" }}
                        >
                            <Typography
                                variant="caption"
                                color="textPrimary"
                                style={{ fontWeight: 500 }}
                            >
                                {elem?.lable}
                            </Typography>
                        </InputLabel>
                        <CustomInputs
                            name={elem?.name}
                            id={elem?.name}
                            // label={elem?.lable}
                            inputType={elem.type}
                            value={filterData[elem.name]}
                            required={false}
                            onChange={(event, value) => setFilterData({ ...filterData, [event]: value })}
                            error={false}
                            helperText={""}
                            placeholder={elem?.placeholder}
                            disabled={elem?.type === 'autoComplete' ? (elem?.options === null || elem?.options === "") ? true : false : false}
                            multiple={elem?.multiple}
                            optionArray={elem?.options}
                            min={elem?.min}
                            max={elem?.max}
                            crudAction={{ view: false }}
                        // initialValues={initialValues}
                        />
                    </Grid>

                ))}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    className={`z-0 transition duration-500 ease-in-out transform  ${show ? "translate-y-10/12 opacity-100" : "-translate-y-full opacity-0"}`}
                >
                    <InputLabel className="mb-[1.3rem]"></InputLabel>
                    <Box className="w-full flex  justify-end lg:justify-between item-end space-x-2 pb-2 ">
                        <Button
                            variant="contained"
                            size="medium"
                            // color="white"
                            className=" lg:w-full h-9"
                            style={{ backgroundColor: 'white', color: theme.palette.primary.main, height: '34px' }}
                            // style={{paddingLeft:27,paddingRight:27}}
                            onClick={() => handleFilterSearch()}
                        >
                            SEARCH
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ backgroundColor: 'white', color: theme.palette.primary.main, height: '34px' }}
                            className="h-9 lg:w-full"
                            // style={{paddingLeft:27,paddingRight:27}}
                            onClick={() => handleRestFilter()}
                        >
                            RESET
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}

export default React.memo(CommonFilter2);
// check for photos