import React, { useRef, useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import theme from "src/theme";
import * as topojson from "topojson-client";

export default function TopoJSON(props) {
  const layerRef = useRef(null);
  const { data, selectedState, setSelectedState, mapData, ...otherProps } = props;

  function addData(layer, jsonData) {
    if (jsonData.type === "Topology") {
      for (let key in jsonData.objects) {
        let geojson = topojson.feature(jsonData, jsonData.objects[key]);
        layer.addData(selectedState ? { features: geojson.features?.filter(val => val.properties.st_nm === selectedState) } : geojson);
      }
    } else {
      layer.addData(selectedState ? { features: jsonData.features?.filter(val => val.properties.ST_NM === selectedState) } : jsonData);
    }
  }

  const onEachDistrict = (e) => {
    var layer = e.target;
    const geoName = selectedState ? e.target.feature.properties.DISTRICT : e.target.feature.properties.ST_NM;
    const namingData = selectedState ? props?.mapData?.data?.map(item => item.state_name) : props?.mapData?.data?.map(item => item.state_name)
    const stateData = props?.mapData?.data;
    // setSelectedState(geoName)   
    layer.bindPopup(namingData?.includes(geoName) ? `${geoName} <br> Total: ${selectedState ? stateData.find(item => item.state_name === geoName)?.total : stateData.find(item => item.state_name === geoName)?.total}` : `${geoName}`).openPopup()
    layer.setStyle({
      weight: 1,
      color: "gray",
      fillOpacity: 1
    });

  }
  const onEachState = (e) => {

    var layer = e.target;
    const districtName = e.target.feature.properties.ST_NM;
    setSelectedState(districtName)

  }
  /*resets our state i.e no properties should be displayed when a feature is not clicked or hovered over */
  const resetHighlight = (e => {
    var layer = e.target;
    layer.bindPopup().closePopup()
    e.target.setStyle(style(e.target.feature));
  })
  /* this function is called when a feature in the map is hovered over or when a mouse moves out of it, the function calls two functions
    highlightFeature and resetHighlight*/
  const onEachFeature = (feature, layer) => {
    if (!selectedState) {
      if (props?.mapData?.data?.map(item => item?.state_name).includes(feature.properties.ST_NM)) {
        layer.on({
          mouseover: onEachDistrict,
          click: onEachState,
          mouseout: resetHighlight,
        });
      } else {
        layer.on({
          mouseover: onEachDistrict,
          // click: onEachState,
          mouseout: resetHighlight,
        });

      }
    } else {
      layer.on({
        mouseover: onEachDistrict,
        // click: onEachState,
        mouseout: resetHighlight,
      });
    }
  };
  const mapPolygonColorToDensity = (density => {
    let placeWithData = mapData?.data?.find(elem => elem?.state_name === density)
    return placeWithData ? placeWithData?.color : "#f3f3f3";

    // return props?.mapData?.data?.map(item => item.state_name)
    //   .filter(item => item === 'Rajasthan')
    //   ?.includes(density) ? "#a8b918"
    //   : props?.mapData?.data?.map(item => item.state_name)
    //     .filter(item => item === 'Assam')
    //     ?.includes(density) ? '#00b0f0'
    //     : props?.mapData?.data?.map(item => item.state_name)
    //       .includes(density) ? '#A460ED' : '#f3f3f3';
  })
  const style = (feature => {
    return ({
      fillColor: mapPolygonColorToDensity(selectedState ? feature.properties.DISTRICT : feature.properties.ST_NM),
      weight: 0.5,
      opacity: 1,
      color: theme.palette.grey[500],
      dashArray: 1,
      fillOpacity: 1,
      dashColor: 'red'
    });
  });


  // function onEachFeature(feature, layer) {
  //   if (feature.properties) {
  //     const { VARNAME_3, NAME_0 } = feature.properties;
  //     layer.bindPopup(`${VARNAME_3}, ${NAME_0}`);
  //   }
  // }

  useEffect(() => {
    const layer = layerRef.current;
    addData(layer, props.data);
  }, [props.data]);

  return (
    <GeoJSON ref={layerRef} {...otherProps} onEachFeature={onEachFeature} style={style} />
  );
}