export const  NGO_LIST_REQUEST = 'NGO_LIST_REQUEST'
export const NGO_LIST_SUCCESS = 'NGO_LIST_SUCCESS'
export const NGO_LIST_FAIL = 'NGO_LIST_FAIL'

export const NGO_CREATE_FAIL = 'NGO_CREATE_FAIL'
export const NGO_CREATE_REQUEST = 'NGO_CREATE_REQUEST'
export const NGO_CREATE_SUCCESS = 'NGO_CREATE_SUCCESS'

export const NGO_UPDATE_REQUEST = 'NGO_UPDATE_REQUEST'
export const NGO_UPDATE_SUCCESS = 'NGO_UPDATE_SUCCESS'
export const NGO_UPDATE_FAIL = 'NGO_UPDATE_FAIL'

export const NGO_DELETE_REQUEST = 'NGO_DELETE_REQUEST'
export const NGO_DELETE_SUCCESS = 'NGO_DELETE_SUCCESS'
export const NGO_DELETE_FAIL = 'NGO_DELETE_FAIL'