import {
  TextField,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
} from "@material-ui/core";
import { Formik } from "formik";
import { rest } from "lodash";
import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AutocompleteComponent from "src/components/CommonComponents/AutocompleteComponent";
import CustomInputs from "src/components/CommonComponents/CustomInputs";
import { blockListAction } from "src/Redux/Actions/BlockAction";
import {
  centreCreateAction,
  centreDeleteAction,
  centreUpdateAction,
} from "src/Redux/Actions/CentreAction";
import { districtListAction } from "src/Redux/Actions/DistrictAction";
import { stateListAction } from "src/Redux/Actions/StateAction";
import { fetchApiDataList } from "src/services/http_requests";
import * as Yup from "yup";

function CenterDetailsCRUDForm({ crudAction, updateDetails, refetchUserList, isFormOpen }) {

  const { states } = useSelector((state) => state.statesList);

  const dispatch = useDispatch();

  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);

  const initialValues = {
    name: "",
    state_id: "",
    district_id: "",
    block_id: null
  };

  const centerFormInputList = [
    {
      key: "name",
      name: "name",
      label: "Center Name",
      inputType: "text",
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      value: "",
      placeholder: "Enter",
    },
    {
      key: "state_id",
      name: "state_id",
      label: "State",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: states?.data || [],
      value: "",
      placeholder: "Enter",
    },
    {
      key: "district_id",
      name: "district_id",
      label: "District",
      inputType: "autoComplete",
      multiple: false,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: districtList || [],
      value: "",
      placeholder: "Enter",
    },
    {
      key: "block_id",
      name: "block_id",
      label: "Block",
      inputType: "autoComplete",
      multiple: true,
      required: true,
      disabled: crudAction?.delete || crudAction?.view ? true : false,
      optionArray: blockList || [],
      value: "",
      placeholder: "Enter",
    },
  ];

  const onSubmit = (values) => {
    if (!Array.isArray(values?.block_id)) {
      if (values?.block_id?.includes(",")) {
        values = { ...values, block_id: values?.block_id?.split(",") };
      } else { values = { ...values, block_id: [values?.block_id] }; }
    };
    // values.block_id = Array.isArray(values?.block_id) ? values?.block_id?.join(",") : values.block_id;

    crudAction.create
      ? dispatch(centreCreateAction(values, refetchUserList))
      : crudAction.edit
        ? dispatch(centreUpdateAction(updateDetails?.id, values, refetchUserList))
        : dispatch(centreDeleteAction(updateDetails?.id, refetchUserList));
  };

  return (
    <Formik
      enableReinitialize={crudAction.create ? false : true}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name is required'),
        state_id: Yup.string().required('State is required'),
        district_id: Yup.string().required('District is required'),
        block_id: Yup.string().required('Block is required'),
      })}
      onSubmit={onSubmit}
    >
      {function Render({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        setErrors,
        setStatus,
        setSubmitting,
        values,
      }) {
        useEffect(() => {
          if (isFormOpen == false) {
            setFieldValue("name", "");
            setFieldTouched("name", false);
            setFieldValue("state_id", "");
            setFieldTouched("state_id", false);
            setFieldValue("district_id", "");
            setFieldTouched("district_id", false);
            setFieldValue("block_id", "");
            setFieldTouched("block_id", false);
          }
        }, [isFormOpen]);
        useEffect(() => {
          if (updateDetails) {
            setFieldValue("name", updateDetails?.name);
            setFieldValue("state_id", updateDetails?.state_id);
            setFieldValue("district_id", updateDetails?.district_id);
            setFieldValue("block_id", updateDetails?.block_id);
          }
        }, [updateDetails]);

        useEffect(() => {
          if (values?.state_id) {
            fetchApiDataList('district', { state_id: values?.state_id, fetched: "All" }, setDistrictList);
          } else {
            setFieldValue("district_id", "");
            setFieldTouched("district_id", false);
            setFieldValue("block_id", "");
            setFieldTouched("block_id", false);
            setDistrictList([]);
            setBlockList([]);
          }
        }, [values.state_id]);

        useEffect(() => {
          if (values?.district_id) {
            fetchApiDataList('block', { state_id: values?.state_id, district_id: values?.district_id, fetched: "All" }, setBlockList);
          } else {
            setFieldValue("block_id", "");
            setBlockList([]);
          }
        }, [values.district_id]);

        return (
          <form
            className=" bg-transparent m-5 "
            onSubmit={handleSubmit}
            {...rest}
          >
            <Grid container spacing={2} className='bg-white' >
              {centerFormInputList?.map((elem, index) => {
                return (
                  <CustomInputs
                    name={elem?.name}
                    id={elem?.key}
                    label={elem?.label}
                    inputType={elem?.inputType}
                    value={values[elem.name]}
                    required={elem?.required}
                    handleBlur={handleBlur}
                    onClick={() => setFieldTouched(elem.name, true)}
                    onChange={(event, value) => {
                      if (elem.inputType == 'autoComplete') setFieldValue(event, value);
                      else handleChange(event);
                    }}
                    error={errors[elem.name] && touched[elem.name]}
                    helperText={errors[elem.name] && touched[elem.name] ? errors[elem.name] : ""}
                    placeholder={elem?.placeholder}
                    disabled={elem?.disabled}
                    multiple={elem?.multiple}
                    optionArray={elem?.optionArray}
                    acceptedFiles={elem?.acceptedFiles}
                    crudAction={crudAction}
                    initialValues={initialValues}
                  />
                )
              })}

              {crudAction.view ? null : <Grid item xs={12} sx={12} md={12}>
                <Box className="w-full py-5">
                  <Button
                    type="submit"
                    className="w-full"
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    {crudAction.create ? "CREATE"
                      : crudAction.edit ? "EDIT"
                        : crudAction.delete ? "DELETE"
                          : "SUBMIT"}
                  </Button>
                </Box>
              </Grid>}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}

export default React.memo(CenterDetailsCRUDForm);
