import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDown from "@material-ui/icons/ExpandMore";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../Redux/Auth/action";
import { deleteData, loadData } from "src/utils/sessionStorage";
import ExitToAppRounded from "@material-ui/icons/ExitToAppRounded";
import {
  ApartmentOutlined,
  BarChartOutlined,
  Close,
  CloseOutlined,
  Dashboard,
  DashboardOutlined,
  EventNoteOutlined,
  FeedbackOutlined,
  FormatIndentIncreaseTwoTone,
  ImportantDevicesOutlined,
  LocationCityOutlined,
  Person,
  PersonOutline,
  PowerSettingsNew,
  RecentActorsOutlined,
  Settings,
  WorkOutline,
} from "@material-ui/icons";
import { useState } from "react";
import { TbCurrencyRupee } from "react-icons/tb"

const tabsName = [
  {
    role_id: 1,
    label: "Dashboard",
    value: "/app/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    role_id: 1,
    label: "Users",
    value: "/app/admin/users",
    icon: <PersonOutline />,
  },
  {
    role_id: 1,
    label: "Masters",
    value: "/app/admin/masters",
    icon: <LocationCityOutlined />,
  },
  {
    role_id: 1,
    label: "Sakhi Transactions",
    value: "/app/sakhitransactions",
    icon: <BarChartOutlined />,
  },
  {
    role_id: 1,
    label: "Budget",
    value: "/app/admin/budget",
    icon: <div className="text-[21px]"> <TbCurrencyRupee /></div>,
  },
  {
    role_id: 1,
    label: "Training Kit",
    value: "/app/admin/trainingkit",
    icon: <ImportantDevicesOutlined />,
  },
  {
    role_id: 1,
    label: "Reports",
    value: "/app/admin/reports",
    icon: <EventNoteOutlined />,
  },

  {
    role_id: 2,
    label: "Dashboard",
    value: "/app/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    role_id: 2,
    label: "Sakhi Transactions",
    value: "/app/sakhitransactions",
    icon: <BarChartOutlined />,
  },
  {
    role_id: 2,
    label: "FO Details",
    value: "/app/fodetails",
    icon: <RecentActorsOutlined />,
  },
  {
    role_id: 2,
    label: "Centre Details",
    value: "/app/centerdetails",
    icon: <ApartmentOutlined />,
  },
  {
    role_id: 2,
    label: "Sakhi Details",
    value: "/app/sakhidetails",
    icon: <RecentActorsOutlined />,
  },
  {
    role_id: 2,
    label: "Expense",
    value: "/app/expense",
    icon: <div className="text-[21px]"> <TbCurrencyRupee /></div>,
  },
  {
    role_id: 2,
    label: "Reports",
    value: "/app/reports",
    icon: <EventNoteOutlined />,
  },
  {
    role_id: 1,
    label: "Feedback",
    value: "/app/feedback",
    icon: <FeedbackOutlined />,
  },
  {
    role_id: 2,
    label: "Feedback",
    value: "/app/feedback",
    icon: <FeedbackOutlined />,
  },
];

const NabStyle = {
  active:
    "w-full flex justify-start items-center rounded-lg bg-white text-black  space-x-2 p-1 transation transform duration-500 ease-in-out ",
  deactive:
    "w-full flex justify-start items-center rounded-lg hover:bg-white hover:text-black text-white space-x-2 p-1 transation transform duration-500 ease-in-out",
};

function TabsView({ setClass, openNav, setOpenNav }) {
  const { role_id, name, ngo_name } = loadData("loginInfo")?.data;
  const user = loadData("loginInfo")?.data;
  const previousNav = localStorage.getItem("currentNav");
  const [classN, setClassN] = useState(false)
  const [currentNav, setCurrentNav] = useState(Number(0));
  // const [tabs, setTabs] = React.useState(tabsName[0]);
  const [anchorElRep, setAnchorElRep] = React.useState(null);
  const [anchorElMap, setAnchorElMap] = React.useState(null);
  const openRep = Boolean(anchorElRep);
  const openMap = Boolean(anchorElMap);
  const handleClickMap = (event) => {
    setAnchorElMap(event.currentTarget);
  };
  const handleClickRep = (event) => {
    setAnchorElRep(event.currentTarget);
  };
  const handleCloseMap = () => {
    setAnchorElMap(null);
  };
  const handleCloseRep = () => {
    setAnchorElRep(null);
  };

  const handleChange = (event, newValue) => {
    // setTabs(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    setTimeout(() => {
      setClassN(true)
    }, 200);
  }, [])

  useEffect(() => {
    if (previousNav) {
      setCurrentNav(Number(previousNav));
    } else {
      setCurrentNav(0);
    }
  }, [previousNav]);

  return (
    <Box
      className={
        openNav
          ? "top-0 translate-x-0 md:left-0 overflow-hidden w-64 md:w-2/12 h-screen  z-50 fixed left-0 pl-5 pr-4 sm:pr-5  md:px-6 pt-20    flex flex-col justify-between items-start bg-primary transation transform duration-500 ease-in-out"
          : "top-0 -translate-x-full md:translate-x-0 md:left-0 overflow-hidden w-64 md:w-2/12 h-screen  z-50 fixed left-0 pl-5 pr-4 sm:pr-5  md:px-6 pt-20    flex flex-col justify-between items-start bg-primary transation transform duration-500 ease-in-out"
      }
    >
      <Box className="w-full m-h-3/5 flex flex-col justify-start items-start space-y-4">
        <Box
          onClick={() => setOpenNav(!openNav)}
          className="absolute md:hidden z-50 top-24 left-56 text-white  flex justify-center items-center p-1 "
        >
          <Close />
        </Box>
        <Box className="bg-green w-full flex justify-start items-center space-x-2">
          <Avatar
            onClick={handleClick}
            alt="Remy Sharp"
            className="p-0.5 border border-white"
          />
          <Box className="w-full flex flex-col justify-start items-start py-1">
            <Typography style={{ fontWeight: 600, color: "white" }}>
              {name}
            </Typography>
            <Typography
              style={{ fontSize: 10, color: "white" }}
              variant="caption"
            >
              {role_id === 1 ? "Admin" : `NGO (${ngo_name})`}
            </Typography>
          </Box>
        </Box>
        <Box className="w-full flex flex-col justify-start items-start space-y-2">
          {tabsName
            ?.filter((tab) => tab.role_id === role_id)
            ?.map((tab, i) => (
              <Box className={classN ? `w-full transform  translate-y-0 z-0 transition duration-500 delay-${i === 0 ? 100 : i === 1 ? 150 : i === 2 ? 200 : i === 3 ? 300 : i === 4 ? 500 : i === 5 ? 700 : i === 6 ? 1000 : i === 7 ? `[1500ms]` : i === 8 ? `[2000ms]` : i === 9 ? `[2500ms]` : `[2500ms]`}  ease-in-out` : `w-full  transform  translate-y-full z-0 transition duration-500 ease-in-out opacity-0 `}
                key={i} id={i}
              >
                <NavLink
                  className={({ isActive }) => isActive ? NabStyle?.active : NabStyle?.deactive}
                  to={tab?.value}
                  onClick={() => {
                    setCurrentNav(i);
                    setClass(false);
                    localStorage.setItem("pageInfo", 1);
                    localStorage.setItem("totalData", 0);
                    localStorage.setItem("currentNav", i);
                    localStorage.removeItem("selectedMaster");
                  }}
                  key={i}
                >
                  {tab?.icon}{" "}
                  <p className="text-sm" key={i}>
                    {tab?.label}
                  </p>
                </NavLink>
              </Box>
            ))}
        </Box>
      </Box>
      <Box className=" w-full  space-y-2 border-t border-white py-4 ">
        <Link
          className={10 === currentNav ? NabStyle?.active : NabStyle?.deactive}
          to={"/app/admin/setting"}
          onClick={() => {
            setCurrentNav(10);
            setClass(false);
            localStorage.setItem("currentNav", 10);
            localStorage.removeItem("selectedMaster");
          }}
          key={10}
        >
          <Settings />
          <p className="text-sm" key={10}>
            Setting
          </p>
        </Link>
        <Link to={"/app/admin/setting"}>
          {/* <Box
          className="w-full flex justify-start items-center rounded-xl hover:bg-white hover:text-black text-white space-x-2 p-1"
          // onClick={handleLogout}
          >
          
        </Box> */}
        </Link>
        <Box
          className="w-full flex justify-start items-center rounded-xl hover:bg-white hover:text-black text-white space-x-2 p-1"
          onClick={handleLogout}
        >
          <PowerSettingsNew /> <p>Logout</p>
        </Box>
      </Box>

      {/* <Tabs
        value={
          window.location.pathname !== "/" ? window.location.pathname : false
        }
        onChange={handleChange}
        aria-label="wrapped icon position label tabs example"
        className="w-10/12"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        orientation="vertical"
      >
        {tabsName?.filter(tab => tab.role_id === role_id )?.map((tab) =>
          tab.label === "Reports" ? (
            <Box>
              <Tab
                value={tab.value}
                label={<div className='capitalize'> {tab?.label} <ArrowDown style = { {verticalAlign : 'middle'} } /> </div>}
                component={Button}
                id="basic-button"
                aria-controls={openRep ? "basic-menu" : undefined}
                aria-expanded={openRep ? "true" : undefined}
                onClick={handleClickRep}
                wrapped
                // className={`w-5 capitalize flex `}

              />
              <Menu
                className="mt-36 "
                style={{ borderRadius: "0px", width: "500px" }}
                id="basic-menu"
                anchorEl={anchorElRep}
                open={openRep}
                onClose={handleCloseRep}
                // MenuListProps={{
                //   "aria-labelledby": "basic-button",
                // }}
              >
                <Tabs
                  value={
                    window.location.pathname !== "/"
                      ? window.location.pathname
                      : false
                  }
                  onChange={handleChange}
                  aria-label="wrapped icon position label tabs example"
                  className="w-10/12"
                  orientation="vertical"
                >
                  {tab.subTabs.map((subTab) => (
                    <Tab
                      value={subTab.value}
                      label={subTab?.label}
                      component={Link}
                      to={subTab?.value}
                      wrapped
                      className={`w-2/12 capitalize`}
                    />
                  ))}
                </Tabs>
              </Menu>
            </Box>
          ) : tab.label === "Mapping" ? (
            <Box>
              <Tab
                value={tab.value}
                label={<div className='capitalize'> {tab?.label} <ArrowDown style = { {verticalAlign : 'middle'} } /> </div>}
                component={Button}
                id="basic-button"
                aria-controls={openMap ? "basic-menu" : undefined}
                aria-expanded={openMap ? "true" : undefined}
                onClick={handleClickMap}
                wrapped
              />
              <Menu
                className="mt-24 "
                style={{ borderRadius: "0px", width: "500px" }}
                id="basic-menu"
                anchorEl={anchorElMap}
                open={openMap}
                onClose={handleCloseMap}
              >
                <Tabs
                  value={
                    window.location.pathname !== "/"
                      ? window.location.pathname
                      : false
                  }
                  onChange={handleChange}
                  aria-label="wrapped icon position label tabs example"
                  className="w-10/12"
                  orientation="vertical"
                >
                  {tab.subTabs.map((subTab) => (
                    <Tab
                      value={subTab.value}
                      label={subTab?.label}
                      component={Link}
                      to={subTab?.value}
                      wrapped
                      className={`w-3/12 sm:w-2/12 capitalize`}
                    />
                  ))}
                </Tabs>
              </Menu>
            </Box>
          ) : tab.label === "MASTERS" ? (
            <Box>
              <Tab
                value={tab.value}
                label={<div className='capitalize'> {tab?.label} <ArrowDown style = { {verticalAlign : 'middle'} } /> </div>}
                component={Button}
                id="basic-button"
                aria-controls={openMap ? "basic-menu" : undefined}
                aria-expanded={openMap ? "true" : undefined}
                onClick={handleClickMap}
                wrapped
                className='capitalize'
              />
              <Menu
                className="mt-24 "
                style={{ borderRadius: "0px", width: "500px" ,top:26}}
                id="basic-menu"
                anchorEl={anchorElMap}
                open={openMap}
                onClose={handleCloseMap}
                
              >
                <Tabs
                  value={
                    window.location.pathname !== "/"
                      ? window.location.pathname
                      : false
                  }
                  
                  onChange={handleChange}
                  aria-label="wrapped icon position label tabs example"
                  className="w-10/12"
                  orientation="vertical"
                  // style={{top:10}}
                >
                  {tab.subTabs.map((subTab) => (
                    <Tab
                      value={subTab.value}
                      label={subTab?.label}
                      component={Link}
                      to={subTab?.value}
                      wrapped
                      onClick={handleCloseMap}
                      className={`w-3/12 sm:w-2/12 capitalize `}
                    />
                  ))}
                </Tabs>
              </Menu>
            </Box>
          ) : (
            <Tab
              value={tab.value}
              label={tab?.label}
              component={Link}
              to={tab?.value}
              wrapped
              className={`w-3/12 sm:w-2/12 capitalize `}
            />
          )
        )}
      </Tabs> */}

      {/* <Box className="w-2/12 flex justify-end items-center space-x-2 ">
        <Box className="hidden hover:border px-2 hover:border-red-500 h-8 overflow-hidden hover:bg-white bg-gray-100 rounded-lg w-10/12 flex justify-between items-center">
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            className="bg-inherit w-full"
            inputProps={{ "aria-label": "Search" }}
          />
          <IconButton type="sub mit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Box>
      </Box> */}
    </Box>
  );
}

export default TabsView;
